<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">Add New User: {{ this.clientName }}</h5>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      Please enter the email address below and select the corresponding role(s). When ready, click 'Send Email' to issue an invitation to create and verify a profile.
    </div>
    <form [formGroup]="addUserForm" class="clrsgn-form">
      <div formArrayName="users">
        <div *ngFor="let userGroup of addUsersArray.controls; let i = index" [formGroupName]="i">
          <hr *ngIf="i > 0" class="split"/>
          <div class="modal-section">
            New User {{ i + 1 }}
            <span *ngIf="i > 0"> - <span class="fas fa-trash-alt" (click)="removeUserInvite(i)" ngbTooltip="Remove User"></span></span>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="name" class="clrsgn-label">
                Name
              </mat-label>
              <input matInput
                     maxlength="128"
                     type="text"
                     formControlName="name"
                     id="name">
              <app-form-error [group]="addUserForm"
                              [controlName]="'users.' + i + '.name'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="targetEmailAddress" class="clrsgn-label">
                Email Address
              </mat-label>
              <input matInput
                     maxlength="128"
                     type="text"
                     formControlName="targetEmailAddress"
                     id="targetEmailAddress">
              <app-form-error [group]="addUserForm"
                              [controlName]="'users.' + i + '.targetEmailAddress'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="row align-center">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="roles" class="clrsgn-label">
                Role Type(s)
              </mat-label>
              <mat-select formControlName="roles"
                          id="roles"
                          data-cy="accountRoleDropdownList"
                          [multiple]="true">
                <mat-option *ngFor="let role of accountRoles"
                            [value]="role.key">
                  {{ role.value }}
                </mat-option>
              </mat-select>
              <app-form-error [group]="addUserForm"
                              [controlName]="'users.' + i + '.roles'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <button type="button" class="add-another-button" (click)="addUser()">+ Add Another</button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel-button" (click)="close()">Cancel</button>
    <button type="button" class="send-button" (click)="sendInvites()">Send</button>
  </div>
</ng-container>

