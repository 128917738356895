import { Pipe, PipeTransform } from '@angular/core';
import { RoleCodeValueService } from '../services/role-code-value/role-code-value.service';

@Pipe({
  name: 'roleValue',
  standalone: true
})

export class RoleValuePipe implements PipeTransform {

  constructor(private roleCodeValueService: RoleCodeValueService){}

  transform(value: string): string {
    return this.roleCodeValueService.returnRoleCodeValue(value);
  }

}
