import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "edit-remove-dropdown-button",
  styleUrls: ['../../../action-dropdown-button/action-dropdown-button.component.scss'],
  templateUrl: "./edit-remove-dropdown-button.component.html"
})
export class EditRemoveDropdownButtonComponent {
  @Output() editCalled = new EventEmitter();
  @Output() removeCalled = new EventEmitter();

  @Input() shouldEdit: boolean;
  @Input() shouldRemove: boolean;
  @Input() shouldVoid: boolean;

  constructor() { }

  handleEdit() {
    this.editCalled.emit();
  }

  handleRemove() {
    this.removeCalled.emit();
  }
}
