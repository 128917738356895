import { Pipe, PipeTransform } from "@angular/core";

import { ParticipantBase } from "../../models/participant.model";

@Pipe({
  name: "participantSignatureName",
  standalone: true,
})
export class ParticipantSignatureNamePipe implements PipeTransform {
  transform(participant: ParticipantBase): string {
    const trimmedSignatureName = participant?.signatureName?.trim();
    if (!!trimmedSignatureName) {
      return trimmedSignatureName;
    }

    const firstName = participant?.firstName?.trim() ?? "";
    const lastName = participant?.lastName?.trim() ?? "";
    return `${firstName} ${lastName}`.trim();
  }
}
