import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Certificate, CertificateNew } from "../../../../../shared/models/certificate.model";
import { Observable, of } from "rxjs";
import { StateRuleModel } from "../../../../../shared/models/state-rules.model";
import { AppSpinnerModule } from "../../../../../shared/modules/app-spinner.module";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommissionService } from "../../../../../shared/services/commission.service";
import { CertificateService } from "../../../../../shared/services/certificate.service";
import { FormValidators } from "../../../../../shared/validators/form.validators";
import { FormError } from "../../../../../shared/components/app-form-error/form-error";
import { catchError, tap } from "rxjs/operators";
import { CommissionInfoPatch } from "../../../../../shared/models/commission-info.model";


@Component({
  selector: 'app-add-digital-certificate',
  templateUrl: './add-digital-certificate.component.html',
  styleUrls: ['./add-digital-certificate.component.scss']
})
export class AddDigitalCertificate implements OnInit {
  uploadDigitalCertificate: UntypedFormGroup;
  certificateUploadBase64: string;
  returnedCertificate: Certificate;
  certificateUploaded: Observable<boolean> = of(false);
  currentAccountId: number;

  mode: "add" | "edit" = "add";
  selectedRule: Observable<StateRuleModel | undefined>;
  commission;

  constructor(
    private readonly spinner: AppSpinnerModule,
    private readonly sanitizer: DomSanitizer,
    private readonly activeModal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly commissionService: CommissionService,
    private readonly certificateService: CertificateService,
  ) { }

  get certHasErrors() {
    return this.uploadDigitalCertificate
      ?.get("certificate")
      ?.hasError("requiredFileType");
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.uploadDigitalCertificate = new UntypedFormGroup(
      {
        certificate: new UntypedFormControl(null, FormValidators.requiredFileType(["pfx"])),
        password: new UntypedFormControl(''),
      },
      { updateOn: "change" }
    );

    this.certificateUploaded.subscribe(isUploaded => {
      const passwordControl = this.uploadDigitalCertificate.get('password');
      if (isUploaded) {
        passwordControl?.setValidators([Validators.required]);
      } else {
        passwordControl?.clearValidators();
      }
      passwordControl?.updateValueAndValidity();
    });
  }

  async onFileSelected(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    if (target.files === null || target.files.length === 0) {
      return;
    }
    const selectedFile = target.files[0];

    this.spinner.show();

    await this.loadFileAsync(selectedFile);

    target.value = "";
    this.spinner.hide();
  }

  private async loadFileAsync(file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          this.certificateUploadBase64 = this.convertFileToBase64(reader.result as ArrayBuffer);
          this.certificateUploaded = new Observable<boolean>((observer) => {
            observer.next(!this.uploadDigitalCertificate.get("certificate")?.hasError("requiredFileType"));
            observer.complete();
          });
          resolve();
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private convertFileToBase64(fileData: ArrayBuffer): string {
    const bytes = new Uint8Array(fileData);
    let binary = '';
    for (const byte of bytes) {
      binary += String.fromCharCode(byte);
    }
    return window.btoa(binary);
  }

  getSanitizedImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpg;base64," + image
    );
  }

  async saveDigitalCertificate() {
    this.spinner.show();
    FormError.ValidateAllFormFields(this.uploadDigitalCertificate);
    if (!this.uploadDigitalCertificate.valid) {
      this.markFormControlsAsDirty(this.uploadDigitalCertificate);
      this.toastr.warning("Please correct all errors.", "Attention");
      this.spinner.hide();
      return;
    }

    // verify password and pre-save the certificate if it's valid, to get a CertificateMetaDataId
    const certificateNew: CertificateNew = {
      rawData: this.certificateUploadBase64,
      password: this.uploadDigitalCertificate.get("password")?.value
    };

    this.certificateService.addNewCertificate(certificateNew).subscribe(
      (response) => {
        this.returnedCertificate = response.certificate;
        // save certificate
        this.saveCertificate();
      },
      (_error) => {
        this.spinner.hide();
        this.toastr.error("There was a problem saving your Certificate. Please verify your password");
        return [];
      });
  }

  saveCertificate() {
    if (this.commission.id) {
      this.commission.certificate = "";
      this.commission.signature = "";
      this.commission.certificateMetaDataId = this.returnedCertificate.certificateMetaDataId;
      this.commission.certificateFilePathId = this.returnedCertificate.filePathId;
      this.commissionService.updateCommission(this.commission.id, this.commission).pipe(
        tap(() => {
          this.activeModal.close('certificateAdded');
          this.spinner.hide();
          this.toastr.success("Certificate Added");
          this.uploadDigitalCertificate.reset();
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toastr.error(error);
          return [];
        })
      )
        .subscribe();
    }
    else {
      const commissionInfoPatch: CommissionInfoPatch = {
        commissionInfoId: this.commission.commissionInfoId,
        certificateMetaDataId: this.returnedCertificate.certificateMetaDataId,
        expiresOn: this.commission.expiresOn,
        accountId: this.commission.accountId,
        stateCode: this.commission.stateCode,
        number: this.commission.number,
        stamp: this.commission.stamp,
        countyFipsCode: this.commission.countyFipsCode,
        name: this.commission.name
      }
      this.commissionService.patchDraftCommission(commissionInfoPatch).pipe(
        tap(() => {
          this.activeModal.close('certificateAdded');
          this.spinner.hide();
          this.toastr.success("Certificate Added");
          this.uploadDigitalCertificate.reset();
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toastr.error(error);
          return [];
        })
      )
        .subscribe();
    }
  }

  close(): void {
    this.activeModal.close();
  }

  private markFormControlsAsDirty(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof UntypedFormGroup) {
        this.markFormControlsAsDirty(control);
      } else {
        control.markAsDirty();
      }
    });
  }
}
