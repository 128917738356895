import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { DateHelper } from "src/app/shared/helpers/date.helper";
import { OrderFormModalComponent } from "src/app/order-form/order-form.modal";

import { FilterFormComponent } from "./components/filter-form/filter-form.component";
import { Observable} from "rxjs";
import { tap } from "rxjs/operators";
import { OrderDetailStore } from "../order-detail/+state/order-detail.store";
import { FeaturesService } from "../shared/services/features.service";
import { Features } from "../shared/enums/Features";
import { AccountService } from "../shared/services/account.service";
import { Assignee } from "../shared/models/assignee.model";
import { AssigneeService } from "../shared/services/assignee.service";
import { ToastrService } from "ngx-toastr";
import { PaginationComponent } from "./components/pagination/pagination.component";

@UntilDestroy()
@Component({
  templateUrl: "./manage-orders.component.html",
  styleUrls: ["./manage-orders.component.scss"],
})
export class ManageOrdersComponent implements OnInit, AfterViewInit {
  @ViewChild(FilterFormComponent) filterForm: FilterFormComponent;
  @ViewChild(PaginationComponent) paginator:  PaginationComponent;
  closeResult: string;
  currentAssigneeName: string;
  sortColumn: string = "ScheduledDateTime";
  isSortDirectionAscending: boolean = false;
  isOrderManager: boolean;

  isPortalAssigneeEnabled$: Observable<boolean>;

  constructor(
    private readonly accountService: AccountService,
    private assigneeService: AssigneeService,
    private readonly modalService: NgbModal,
    private readonly dateHelper: DateHelper,
    private cdr: ChangeDetectorRef,
    private store: OrderDetailStore,
    private featureService: FeaturesService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {    
    this.setFeatures();
    this.setPortalAssigneeEnabled();
    this.isCurrentUserAnOrderManager();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  convertScheduledDateTime(scheduledDateTime: string) {
    return this.dateHelper.convertDateTimeToLocalDateTime(scheduledDateTime);
  }

  onPaginatorChanged() : void {
    this.filterForm.pageNumber = this.paginator.currentPage;
    this.filterForm.pageSize = this.paginator.currentItemsPerPage;
    this.filterForm.executeSearch();
  }

  sortByColumn(column: string): void {
    if (this.filterForm.sortByColumn == column) {
      this.sortColumnDirectionChange();
    } else {
      this.sortColumnChange();
    }

    this.filterForm.sortByColumn = column;
    this.sortColumn = column;
    this.filterForm.executeSearch();
  }

  sortColumnDirectionChange(): void {
    this.filterForm.isSortOrderAscending =
      !this.filterForm.isSortOrderAscending;
    this.isSortDirectionAscending = this.filterForm.isSortOrderAscending;
  }

  sortColumnChange(): void {
    this.filterForm.pageNumber = 1;
    this.filterForm.isSortOrderAscending = false;
    this.isSortDirectionAscending = false;
  }

  openOrderCreateModal(): void {
    let clientDropDownList: { clientId: number; displayString: string }[] = [];

    const modal = this.modalService.open(OrderFormModalComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.clientDropDownList = clientDropDownList;
    modal.componentInstance.isEdit = false;

    modal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        if (result === "save") {
          this.filterForm.onSearch();
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getModalDismissReason(reason)}`;
      }
    );
  }

  isCurrentUserAnOrderManager(): void {
    this.accountService.getAssigneeClientAccounts().subscribe((result) => {
      if (result.clients?.find(clientAccount => clientAccount.accountType.toUpperCase() === "ORDERMANAGER")) {
        this.isOrderManager = true;
      }
    });
  }

  setAssignee(orderId: number, selectedAssignee: Assignee) { 
    this.assigneeService.getOrderAssigneesByOrderId(orderId).subscribe((result) => {
      this.currentAssigneeName = result.fullName ? result.fullName : "No assignee";

      if (selectedAssignee.accountId > 0) {
        this.assigneeService.updateAssignees(orderId, selectedAssignee).subscribe(
          () => {
            this.toastr.success("Assignee added");
          },
          () => {
            this.toastr.error("Unable to add assignee");
          }
        );
      }
      else if (this.currentAssigneeName !== "No assignee") {
        this.assigneeService.deleteAssignees(orderId).subscribe(
          () => {
            this.toastr.success("Assignee removed");
          },
          () => {
            this.toastr.error("Unable to remove assignee");
          }
        );
      }
    })
  }

  assigneeSearchFn(term: string, item: Assignee): boolean
  {
    return (item.accountId !== 0 && 
            (item.fullName.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            item.emailAddress.toLowerCase().indexOf(term.toLowerCase()) > -1));
  }

  setFeatures(): void
  {
    this.featureService
      .getFeatures()
      .pipe(
        tap(async (result) => {
          this.store.setFeatures(result);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
  
  setPortalAssigneeEnabled(): void
  {
    this.isPortalAssigneeEnabled$ =
      this.featureService.isFeatureEnabled(
        Features.PortalAssignee
      );
  }

  getModalDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
