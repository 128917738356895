import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phoneValue: string | number | null | undefined): string | undefined {
    if (phoneValue === null || phoneValue === undefined) {
      return undefined;
    }
    const cleaned = ('' + phoneValue).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneValue?.toString();
  }
}
