import { Document, NewDocument } from './document.models';
import { Endorsement, NewEndorsement } from './endorsement.models';

export class DocumentAndEndorsements {
  document: Document;
  endorsements: Array<Endorsement>;
}

export class NewDocumentAndEndorsements {
  document: NewDocument;
  endorsements: Array<NewEndorsement>;
}
