<ngx-spinner name="document-edit-spinner" [fullScreen]="false" type="ball-spin-clockwise" size="large" color="#2B0083" bdColor="rgba(51,51,51,0.5)">
</ngx-spinner>

<ng-container>
  <div class="modal-header"><h5 class="modal-title">Edit Document</h5></div>

  <div class="modal-body">
    <form [formGroup]="documentForm" class="clrsgn-form">
      <p>*Required Information</p>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="documentName">Document Name</mat-label>
            <input
              matInput
              type="text"
              class="clrsgn-label"
              formControlName="documentName"
              id="documentName"
              maxlength="200"
            />
            <app-form-error
              [controlName]="'documentName'"
              [group]="documentForm"
            ></app-form-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col-12">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="documentType">Document Type</mat-label>
            <input
              matInput
              type="text"
              class="clrsgn-label"
              formControlName="documentType"
              id="documentType"
              maxlength="32"
            />
            <app-form-error
              [controlName]="'documentType'"
              [group]="documentForm"
            ></app-form-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label>Action Type</mat-label>
            <input
              formControlName="actionType"
              [matAutocomplete]="autocompleteActionType"
              aria-label="Action Type"
              matInput
              placeholder="--Select An Action Type--"
              type="text"
            />
            <mat-autocomplete #autocompleteActionType="matAutocomplete">
              <mat-option
                *ngFor="let actionType of actionTypes"
                [value]="actionType.value"
              >
                {{ actionType.key }}
              </mat-option>
            </mat-autocomplete>
            <app-form-error
              [controlName]="'actionType'"
              [group]="documentForm"
            ></app-form-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label>Page Type</mat-label>
            <input
              formControlName="pageType"
              [matAutocomplete]="autocompletePageType"
              aria-label="Page Type"
              matInput
              placeholder="--Select A Page Type--"
              type="text"
            />
            <mat-autocomplete #autocompletePageType="matAutocomplete">
              <mat-option
                *ngFor="let pageType of pageTypes"
                [value]="pageType.value"
              >
                {{ pageType.key }}
              </mat-option>
            </mat-autocomplete>
            <app-form-error
              [controlName]="'pageType'"
              [group]="documentForm"
            ></app-form-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row marginTopTwentyPx">
        <div class="col-1">
          <input type="checkbox" class="form-control" formControlName="isSmartDoc" id="isSmartDoc" />
        </div>
        <label for="isSmartDoc">Is Smart Doc?</label>
      </div>

    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="amr-btn-outline-primary" (click)="activeModal.close('cancel')">Cancel</button>
    <button type="button" class="amr-btn-default" (click)="onSave()">Save</button>
  </div>
</ng-container>
