import { Injectable, Injector } from "@angular/core";
import { BaseService } from "./base.service";
import { AccountSignatureModel, AccountSignaturesModel } from "../models/account-signature.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AccountSignatureService extends BaseService  {
  className = "AccountSignatureService";

  constructor(injector: Injector) {
    super(injector);
  }

  getSignaturesByAccountId(accountId: number): Observable<AccountSignaturesModel> {
    return this.get<AccountSignaturesModel>(`/v1/accountSignatures/${accountId}`);
  }

  getCurrentAccountSignatures(): Observable<AccountSignaturesModel> {
    return this.get<AccountSignaturesModel>(`/v1/accountSignatures/current`);
  }

  getSignatureById(accountSignatureId: number): Observable<AccountSignatureModel> {
    return this.get<AccountSignatureModel>(`/v1/accountSignature/${accountSignatureId}`);
  }

  saveSignature(model: AccountSignatureModel) {
    return this.post<AccountSignatureModel>('/v1/accountSignature', model);
  }

  deleteSignature(accountSignatureId: number) {
    return this.delete(`/v1/accountSignature/Delete/${accountSignatureId}`);
  }
}
