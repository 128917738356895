import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { Location } from "@angular/common";
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { concatMap, catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";

@Injectable()
export class MsalAuthGuard  {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private location: Location
  ) {}

  /**
   * Builds the absolute url for the destination page
   * @param path Relative path of requested page
   * @returns Full destination url
   */
  getDestinationUrl(path: string): string {
    // Absolute base url for the application (default to origin if base element not present)
    const baseElements = document.getElementsByTagName("base");
    const baseUrl = this.location.normalize(
      baseElements.length ? baseElements[0].href : window.location.origin
    );

    // Path of page (including hash, if using hash routing)
    const pathUrl = this.location.prepareExternalUrl(path);

    // Hash location strategy
    if (pathUrl.startsWith("#")) {
      return `${baseUrl}/${pathUrl}`;
    }

    // If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
    // Since baseUrl also includes /base, can just concatentate baseUrl + path
    return `${baseUrl}${path}`;
  }

  private loginInteractively(
    url: string,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    const redirectStartPage = this.getDestinationUrl(url);
    let clientIdp = "";

    // Check if browser is IE
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE");
    const msie11 = ua.indexOf("Trident/");
    const isIE = msie > 0 || msie11 > 0;

    // Do not read queryparams on IE. We do not support IE.
    if (!isIE) {
      const urlParams = route.queryParams;
      clientIdp = urlParams.client ?? "";
    }

    let popupRequest: PopupRequest = {
      scopes: [environment.apiScope],
      extraQueryParameters: {
        domain_hint: clientIdp,
      },
    };

    let redirectRequest: RedirectRequest = {
      scopes: [environment.apiScope],
      redirectStartPage: redirectStartPage,
      extraQueryParameters: {
        domain_hint: clientIdp,
      },
    };

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      return this.authService.loginPopup(popupRequest).pipe(
        map((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          return true;
        }),
        catchError(() => of(false))
      );
    }

    this.authService.loginRedirect(redirectRequest);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return this.authService.handleRedirectObservable().pipe(
      concatMap(() => {
        const userAccounts = this.authService.instance.getAllAccounts();
        if (!userAccounts.length) {
          return this.loginInteractively(state.url, route);
        }
        return of(true);
      }),
      catchError(() => of(false))
    );
  }
}
