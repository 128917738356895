import { Injectable, Injector } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { BaseService } from "./base.service";
import { NexsysAccount } from "../models/account.models";
import { Account, ClientAccounts, ClientAccountsUserInfo, NewClientAccount } from "../models/account";
import { ClientAccountInfoList } from "../models/client-account-info-list";

@Injectable({
  providedIn: "root"
})
export class AccountService extends BaseService {
  className = "AccountService";

  constructor(injector: Injector) {
    super(injector);
  }

  async registerIdentity(): Promise<NexsysAccount | undefined>{
    return firstValueFrom(this.post<NexsysAccount>("/v1/accounts", Object.create(null)));
  }

  getCurrentAccount() {
    return this.get<Account>("/v1/accountLogins/token");
  }

  getClientAccounts(): Observable<ClientAccounts> {
    return this.get<ClientAccounts>("/v1/accounts/current/clients");
  }

  getClientAccountsByEmailAddress(emailAddress: string): Observable<ClientAccountsUserInfo> {
    return this.get<ClientAccountsUserInfo>(`/v1/clientaccounts/emailaddress/${emailAddress}`);
  }

  getClientAccountsFromOrderId(orderId: number): Observable<ClientAccountInfoList> {
    return this.get<ClientAccountInfoList>(`/v1/orders/${orderId}/clientaccounts`);
  }

  getAssigneeClientAccounts(): Observable<ClientAccounts> {
    return this.get<ClientAccounts>("/v1/accounts/assignees");
  }

  getClientAccountsFromClientId(clientId: number): Observable<ClientAccountInfoList> {
    return this.get<ClientAccountInfoList>(`/v1/clients/${clientId}/clientaccounts`);
  }

  postClientAccount(accountId: number, clientAccount: NewClientAccount): Observable<NewClientAccount> {
    return this.post<NewClientAccount>(`/v1/accounts/${accountId}/clientaccount`, clientAccount);
  }

  deleteClientAccount(clientAccountId: number): Observable<object> {
    return this.delete(`/v1/clientaccounts/${clientAccountId}`);
  }

  deleteAllClientAccounts(accountId: number): Observable<object> {
    return this.delete(`/v1/clientaccounts/accountid/${accountId}`);
  }
}
