import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { combineLatest, Observable, of } from "rxjs";
import { untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";

import { FeaturesService } from "src/app/shared/services/features.service";
import { Features } from "src/app/shared/enums/Features";
import { Order } from "src/app/shared/models/order";
import { OrderStatusDetail } from "src/app/shared/models/orderStatusDetail";

import { OrderDetailStore } from "../../+state/order-detail.store";
import { CancellationCommentsModalComponent } from "../cancellation-comments-modal/cancellation-comments-modal.component";
import { Assignee } from "src/app/shared/models/assignee.model";
import { AssigneeService } from "src/app/shared/services/assignee.service";
import { filter, tap, switchMap } from "rxjs/operators";
import { AccountService } from "src/app/shared/services/account.service";
import { ClientAccounts } from "src/app/shared/models/account";

@Component({
  selector: "app-loan-information",
  templateUrl: "./loan-information.component.html",
  styleUrls: ["./loan-information.component.scss"],
})
export class LoanInformationComponent implements OnInit {
  @Input() toggleLoanInformationFormEdit: boolean;
  @Input() isOrderPreSign: boolean;

  assignees$: Observable<Assignee[]>;
  clientAccountsOrderManager$: Observable<ClientAccounts>;
  currentAssignee$: Observable<Assignee>;
  isPortalAssigneeEnabled$: Observable<boolean>;
  order$: Observable<Order>;
  orderStatus$: Observable<string>;
  orderStatusDetails$: Observable<OrderStatusDetail[]>;
  scheduledTime$: Observable<string | Date>;

  currentAssigneeName: string;
  isOrderManager: boolean;
  orderId: number;

  constructor(
    private accountService: AccountService,
    private assigneeService: AssigneeService,
    private orderDetailStore: OrderDetailStore,
    private readonly modalService: NgbModal,
    private readonly featuresService: FeaturesService,
    private readonly toastr: ToastrService

  ) {}

  ngOnInit() {
    this.order$ = this.orderDetailStore.order$;
    this.scheduledTime$ = this.orderDetailStore.scheduledTime$;
    this.orderStatus$ = this.orderDetailStore.orderStatus$;
    this.orderStatusDetails$ = this.orderDetailStore.orderStatusDetails$;
    this.order$.subscribe((order) => this.orderId = order.id);

    this.isCurrentUserAnOrderManager();
    this.setPortalAssigneeEnabled();
    this.getCurrentOrderAssignee();
    this.assigneeLoader();
  }

  isCurrentUserAnOrderManager(): void {
    this.accountService.getAssigneeClientAccounts().subscribe((result) => {
      if (result.clients?.find(clientAccount => clientAccount.accountType.toUpperCase() === "ORDERMANAGER")) {
        this.isOrderManager = true;
      }
    });
  }

  assigneeLoader(): void {
    this.assignees$ = combineLatest([
      "No Assignee",
      this.isPortalAssigneeEnabled$
      ]).pipe(
      filter(([_translation, isFeatureEnabled]) => isFeatureEnabled),
      switchMap(([translation]) => {
        return combineLatest([of(translation), this.assigneeService.getEligibleAssignees(this.orderId)]);
      }),
      switchMap(([translation, assigneeResponse]) => {
        return of([
          {
            accountId: 0,
            firstName: '',
            lastName: '',
            fullName: translation,
            emailAddress: ''
          } as Assignee,
          ...assigneeResponse?.orderManagers
        ])
      })
    );
  }

  getCurrentOrderAssignee(): void {
    this.assigneeService.getOrderAssigneesByOrderId(this.orderId).subscribe((result) => {
      this.currentAssigneeName = result.fullName ? result.fullName : "No assignee";
    })
  }

  setAssignee(selectedAssignee: Assignee) {
    if (selectedAssignee.accountId > 0) {
      this.assigneeService.updateAssignees(this.orderId, selectedAssignee).subscribe(
        () => {
          this.toastr.success("Assignee added");
          this.getCurrentOrderAssignee();
        },
        () => {
          this.toastr.error("Unable to add assignee");
        }
      );
    }
    else if (this.currentAssigneeName !== "No assignee") {
      this.assigneeService.deleteAssignees(this.orderId).subscribe(
        () => {
          this.toastr.success("Assignee removed");
          this.getCurrentOrderAssignee();
        },
        () => {
          this.toastr.error("Unable to remove assignee");
        }
      );
    }
  }

  assigneeSearchFn(term: string, item: Assignee): boolean
  {
    return (item.accountId !== 0 &&
            (item.fullName.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            item.emailAddress.toLowerCase().indexOf(term.toLowerCase()) > -1));
  }

  setFeatures(): void
  {
    this.featuresService
      .getFeatures()
      .pipe(
        tap(async (result) => {
          this.orderDetailStore.setFeatures(result);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setPortalAssigneeEnabled(): void
  {
    this.isPortalAssigneeEnabled$ = this.featuresService.isFeatureEnabled(
        Features.PortalAssignee
      );
  }

  openCommentsModal(): void {
    this.modalService.open(CancellationCommentsModalComponent, {
      windowClass: "comments-cancel-modal",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
  }
}
