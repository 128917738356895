import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { Order } from "../models/order";
import { BaseService } from "./base.service";
import { SigningUrlDto } from "../models/signing-url.model";
import { OrderUrlResponse } from "../models/order-url-response";
import { OrderRuleAudit } from "../models/order-rule-audit.model";
import { OrderStatus } from "../models/order-status.model";
import { OrderPreSignStatus } from '../models/order-presign-status';
import { OrderStatusDetail } from "../models/orderStatusDetail";
import { shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: "root"
})
export class OrdersService extends BaseService {
  className = "OrdersService";

  constructor(injector: Injector) {
    super(injector);
  }

  getOrder(orderId: number): Observable<Order> {
      return this.get<Order>(`/v1/orders/${orderId}`);
  }

  getOrderStatusDetails(orderId: number): Observable<OrderStatusDetail[]> {
    return this.get<OrderStatusDetail[]>(`/v1/orders/${orderId}/status-detail/history`);
  }

  updateOrder(orderId: number, order: Order): Observable<Order> {
    return this.put<Order>(`/v1/orders/${orderId}`, order);
  }

  getURLs(orderId: number): Observable<OrderUrlResponse> {
    return this.get<OrderUrlResponse>(`/v1/orders/${orderId}/urls`);
  }

  getRuleAudit(orderId: number): Observable<OrderRuleAudit> {
    return this.get<OrderRuleAudit>(`/v1/orders/${orderId}/ruleAudit`);
  }

  getURL(orderId: number): Observable<SigningUrlDto> {
    return this.getWithoutErrorHandling<SigningUrlDto>(`/v1/orders/${orderId}/url/token`).pipe(
      shareReplay()
    );
  }

  cancelOrder(orderId: number, categoryTypeCode: string, comments?: string): Observable<Order> {
    const statusModel = {
      statusCode : "Cancelled",
      categoryTypeCode: categoryTypeCode,
      comments: comments
    } as OrderStatus;

    return this.putAs<OrderStatus, Order>(`/v1/orders/${orderId}/status`, statusModel);
  }

  completeOrder(orderId: number): Observable<any> {
    return this.post<any>(`/v1/orders/${orderId}/irrevocableaction/complete`, null);
  }

  public getPreSignStatus(orderId: number): Observable<OrderPreSignStatus> {
    return this.get<OrderPreSignStatus>(`/v1/orders/${orderId}/presign/status`);
  }
}
