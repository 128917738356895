import { SigningAddress, PropertyAddress } from "../models/address";
import { OrderStatusDetail } from "./orderStatusDetail";

export class Order {
  id: number;
  actualCompleteDateTime: Date | string | null;
  actualStartDateTime: Date | string | null;
  expirationDateTime: Date | string | null;
  statusCode: string;
  clientId: number;
  loanIdentifier: string;
  orderStatusDetail: OrderStatusDetail | null;
  partnerOrderIdentifier: string;
  productType: string;
  propertyAddress: PropertyAddress;
  scheduledDateTime: Date | string;
  signingAddresses: SigningAddress[];
  signingStateCode: string;
  thirdPartyOrderIdentifier: string;
  isPreSign: boolean;
  createdBy: number;
  createdDate: Date | string;
  updatedBy: number;
  updatedDate: Date | string;
}
