import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import { OrderCreateModel } from './order-form.models';
import { OrderSearchResultList } from '../shared/models/orderSearchList';
import { OrderSearchModel } from '../manage-orders/models/order-search.models';

@Injectable({
  providedIn: "root"
})
export class OrderFormService extends BaseService {
  className = "OrderFormService";

  constructor(injector: Injector) {
    super(injector);
  }

  save(model: OrderCreateModel): Observable<OrderCreateModel> {
    return this.post<OrderCreateModel>("/v1/orders", model);
  }

validatePartnerOrderId(partnerOrderIdentifier: string, clientId: number): Observable<OrderSearchResultList> {
    const searchParams = {
      partnerOrderIdentifier,
      clientId,
      startRow: 1,
      isParticipant: false,
    } as OrderSearchModel;
    return partnerOrderIdentifier?.length > 0
      ? this.get<OrderSearchResultList>("/v1/orders/search", searchParams)
      : of({results: [], totalRows: 0})
  }
}
