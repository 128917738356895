import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SystemUserAccount } from "../../../shared/models/system-user-account.model";
import { SystemAccount } from "../../../shared/models/system-account.model";
import { SystemAccountService } from "../../../shared/services/system-account.service";
import { ToastrService } from "ngx-toastr";
import { AppSpinnerModule } from "../../../shared/modules/app-spinner.module";
import { tap } from "rxjs/operators";
import { AppDialogModule } from "../../../shared/components/app-dialog/app-dialog.component";

@Component({
  selector: 'app-user-table-display',
  templateUrl: 'user-table-display.component.html',
  styleUrls: ['user-table-display.component.scss']
})

export class UserTableDisplayComponent {
  @Input() userList: SystemUserAccount[] = [];
  @Input() activeTab: 'system' | 'client' = 'client';
  @Output() systemAccountDeleted = new EventEmitter<void>();
  sortAscending = true;
  sortProperty: string;

  constructor(
    private readonly systemAccountService: SystemAccountService,
    private readonly toastr: ToastrService,
    private readonly spinner: AppSpinnerModule,
    private readonly dialog: AppDialogModule
  ) { }

  sortUserAccounts(property: string): SystemUserAccount[] {
    this.updateSortProperty(property);
    return this.userList.sort((a, b) => {
      const valueA = (typeof a[property] === 'string') ? a[property].toLocaleLowerCase() : a[property];
      const valueB = (typeof b[property] === 'string') ? b[property].toLocaleLowerCase() : b[property];
      if (valueA < valueB) {
        return this.sortAscending ? -1 : 1;
      } else if (valueA > valueB) {
        return this.sortAscending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  updateSortProperty(property: string) {
    if (property === this.sortProperty) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortAscending = true;
    }
    this.sortProperty = property;
  }

  onDelete(systemAccount: SystemAccount): void {
    this.spinner.show();

    this.systemAccountService
      .deleteSystemAccountById(systemAccount.systemAccountId)
      .pipe(
        tap(() => {
          this.spinner.hide();
          this.toastr.success("System account removed successfully.");
          this.systemAccountDeleted.emit();
        })
      )
      .subscribe();
  }

  deleteConfirmationDialog(systemAccount: SystemAccount, userName: string) {
    this.dialog.show({
      title: "Delete System Account",
      message: `Are you sure you want to delete ${userName}\'s system account?`,
      noButtonText: "Cancel",
      yesButtonText: "Delete Account",
    }).result.then((dialogResult) => {
      if (dialogResult === "yes") {
        this.onDelete(systemAccount);
      }
    });
  }
}
