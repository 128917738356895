import { BaseService } from "./base.service";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { StateRuleResponse, StateRulesModel } from "../models/state-rules.model";

@Injectable({
  providedIn: "root"
})
export class StateRulesService extends BaseService {
  className = "StateRulesService";

  constructor(injector: Injector) {
    super(injector);
  }

  getRulesByStateCode(stateCode: string): Observable<StateRuleResponse> {
    return this.get<StateRuleResponse>(`/v1/stateRules/${stateCode}`);
  }

  getAllStateRules() : Observable<StateRulesModel> {
    return this.get<StateRulesModel>(`/v1/stateRules/all`);
  }
}
