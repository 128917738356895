import { Injectable, Injector } from "@angular/core";
import { BaseService } from "./base.service";
import { SystemUserAccount } from "../models/system-user-account.model";

@Injectable({
  providedIn: "root"
})
export class SystemUserAccountService extends BaseService {
  className = "SystemUserAccountService";

  constructor(injector: Injector) {
    super(injector);
  }

  getAllSystemAccountUsers() {
    return this.get<SystemUserAccount[]>('/v1/SystemUserAccountList', []);
  }
}
