import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DigiDocsStore } from '../../+state/digi-docs.store';

@Component({
  selector: 'app-save-template-modal',
  templateUrl: './save-template-modal.component.html',
  styleUrls: ['./save-template-modal.component.scss']
})
export class SaveTemplateModalComponent implements OnInit {
  saveTemplateForm: UntypedFormGroup;

  get templateName() {
    return this.saveTemplateForm.get('templateName');
  }

  constructor(
    private componentStore: DigiDocsStore,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.saveTemplateForm = new UntypedFormGroup({
      templateName: new UntypedFormControl(null, [Validators.required]),
    });
  }

  submitDocumentTemplateAndEndorsements() {
    if (this.saveTemplateForm.valid) {
      const setTemplateNameToSave = this.saveTemplateForm.get('templateName')?.value;
      this.componentStore.setTemplateNameToSave(setTemplateNameToSave);
      this.activeModal.dismiss("save");
    }
  }

  closeModal() {
    this.activeModal.dismiss("cancel");
  }
}
