import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor() { }

  static removeWhiteSpaceFromAllFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const currentValue = formGroup.get(key)?.value;
      if (typeof currentValue === "string" && currentValue) {
        const trimmedValue = currentValue.trim();
        formGroup.get(key)?.setValue(trimmedValue);
      }
    });
  }
}
