import { Injectable } from '@angular/core';
import { DocumentService } from './document.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DocumentDisplayerService {

  constructor(
    private readonly documentService: DocumentService,
    private readonly toastr: ToastrService,
  ) { }

  displayDocument(url: string, format: string, documentName: string) {

    this.documentService.getDocument(url, format, documentName).subscribe(
      (x) => {
        URL.createObjectURL(x);
      },
      (err) => {
        if (err.status == 404) {
          this.toastr.error("Document has been deleted due to a data retention policy.", "Error Loading Document");
        } else {
          this.toastr.error("Sorry, an error occurred while loading your document.", "Error Loading Document");
        }
      }
    );
  }
}
