<ng-container>
  <div>
    <button type="button"
            routerLink="/myaccount/manage-account-signatures" 
            class="amr-btn-outline float-right">
      View All Signatures
    </button>
    <h2>Commissions and Signatures</h2>
    <p class="mt-3">Different states will have varying requirements for commissions and signatures types. Select a state to continue.</p>
    <div id="your-states-text">
      <h2>Your Available States</h2>
      <p>Select the state you want to view or add a new state to your account.</p>
    </div>
    <div class="commissions">    
      <mat-card *ngFor="let activeCommission of activeCommissions" (click)="editCommission(activeCommission.stateCode)">
        <mat-card-content>
          <div>
            <img [src]="getStateImage(activeCommission.stateCode)" alt="State Logo">
          </div>
          <div>
            <h6>{{ getStateByStateCode(activeCommission.stateCode) }}</h6>
          </div>
          <div>
            <span *ngIf="isCommissionExpired(activeCommission); else commissionReady" class="needs-attention">
              <i class="fa-solid fa-triangle-exclamation"></i> 
              Needs Attention
            </span>
            <ng-template #commissionReady><p>Ready</p></ng-template>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngFor="let draftCommission of draftCommissions" (click)="editCommission(draftCommission.stateCode)">
        <mat-card-content>
          <div>
            <img [src]="getStateImage(draftCommission.stateCode)" alt="State Logo">
          </div>
          <div>
            <h6>{{ getStateByStateCode(draftCommission.stateCode) }}</h6>
          </div>
          <span class="needs-attention">
            <i class="fa-solid fa-triangle-exclamation"></i> 
            Needs Attention
          </span>        
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="!addingNewState" (click)="addNewState()">
        <mat-card-content>
          <h6>+</h6>
          <h6>Add New State</h6>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="addingNewState">
        <mat-card-content>
          <h6>Available States</h6>
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label class="clrsgn-label">States</mat-label>
            <mat-select (selectionChange)="editCommission($event.value)">
              <mat-option
                *ngFor="let state of availableCommissionStates"
                [value]="state.code"
              >
                {{ state.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
