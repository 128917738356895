import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { VideoLink } from "../models/videolink.model";

@Injectable({
  providedIn: "root"
})
export class VideoService extends BaseService {
  className = "VideoService";

  constructor(injector: Injector) {
    super(injector);
  }

  getVideoLinks(orderId: number): Observable<Array<VideoLink>> {
    return this.get<Array<VideoLink>>(`/v1/orders/${orderId}/urls/videos`);
  }

  downloadBlobVideo(url: string) {
    return this.http.get(url, {
        responseType: 'blob',
        observe: 'events'
      }
    );
  }
}
