import { Injectable } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { OrderDetailStore } from "src/app/order-detail/+state/order-detail.store";
import { CapabilityParams, CapabilityString, CapabilityType } from "../../directives/capabilities/capabilities-params.model";
import { CapabilitiesHttpService } from "./capabilities.http.service.service";
import { Capabilities, ClientCapabilities, OrderCapabilities, SystemCapabilities } from "./models/capability.model";

@Injectable({
  providedIn: "root",
})
export class CapabilitiesService {
  constructor(
    public store: OrderDetailStore,
    private http: CapabilitiesHttpService
  ) {}

  getSystemCapabilities(): void {
    this.http
      .getSystemCapabilities()
      .pipe(
        tap((response) => {
          const clientCapabilities: ClientCapabilities = {
            capabilities: response.clientCapabilities,
          };
          this.store.setClientCapabilities(clientCapabilities);

          const systemCapabilities: SystemCapabilities = {
            capabilities: response.systemCapabilities,
          };
          this.store.setSystemCapabilities(systemCapabilities);
        }),
        take(1)
      )
      .subscribe();
  }

  getCapabilitiesByOrderId(orderId: number): void {
    this.http
      .getCapabilitiesByOrder(orderId)
      .pipe(
        tap((response) => {
          this.store.setDocumentCapabilities(response.documentCapabilities);
          this.store.setParticipantCapabilities(
            response.participantCapabilities
          );
          this.store.setOrganizerCapabilities(response.organizerCapabilities);

          const orderCapabilities: OrderCapabilities = {
            orderId: response.orderId,
            capabilities: response.orderCapabilities,
          };
          this.store.setOrderCapabilities(orderCapabilities);
        }),
        take(1)
      )
      .subscribe();
  }

  userHasAnyCapability(capabilities: CapabilityParams[]): Observable<boolean> {
    const capabilityPermissions$: Observable<boolean>[] = [];
    for (const capability of capabilities) {
      capabilityPermissions$.push(this.userHasCapability(capability.type, capability.capabilityString, capability.id));
    }
    return combineLatest(capabilityPermissions$).pipe(
      map((responses: boolean[]) => {
        return responses.includes(true);
      })
    );
  }

  userHasCapability(
    type: CapabilityType,
    capabilityString: CapabilityString,
    id: number = 0
  ): Observable<boolean> {
    switch (type) {
      case "order":
        return this.store.orderCapabilities$.pipe(
          map((result) => {
            const hasCapability =
              result?.capabilities?.some(
                (capability) => capability === capabilityString
              ) ?? false;
            return hasCapability;
          })
        );
      case "document":
        return this.store.documentCapabilities$.pipe(
          map((result) => {
            const hasCapability =
              result?.some(
                (documentCapabilities) =>
                  (documentCapabilities.documentId === id &&
                    documentCapabilities.capabilities.some(
                      (capability) => capability === capabilityString
                    )) ??
                  false
              ) ?? false;
            return hasCapability;
          })
        );
      case "participant":
        return this.store.participantCapabilities$.pipe(
          map((result) => {
            const hasCapability =
              result?.some(
                (documentCapabilities) =>
                  (documentCapabilities.participantId === id &&
                    documentCapabilities.capabilities.some(
                      (capability) => capability === capabilityString
                    )) ??
                  false
              ) ?? false;
            return hasCapability;
          })
        );
      case "organizer":
        return this.store.organizerCapabilities$.pipe(
          map((result) => {
            const hasCapability =
              result?.some(
                (organizerCapabilities) =>
                  (organizerCapabilities.organizerId === id &&
                    organizerCapabilities.capabilities.some(
                      (capability) => capability === capabilityString
                    )) ??
                  false
              ) ?? false;
            return hasCapability;
          })
        );
      case "client":
        return this.store.clientCapabilities$.pipe(
          map((result) => {
            return result?.capabilities?.some(
              (capability) => capability === capabilityString
            ) ?? false;
          })
        );
      case "system":
        return this.store.systemCapabilities$.pipe(
          map((result) => {
            return result?.capabilities?.some(
              (capability) => capability === capabilityString
            ) ?? false;
          })
        );
      default:
        return of(false);
    }
  }

  hasSomeCapabilities(capabilities : Capabilities[] | null) : boolean {
    return capabilities && capabilities.some(c => c?.capabilities && c.capabilities.length > 0) ? true : false;
  }

}
