export interface UserRole {
  key: string;
  value: string;
}

export class FormName {
  static ParticipantInformation: "participantInformation" = 'participantInformation';
  static SigningAgentInformation: "signingAgentInformation"  = 'signingAgentInformation';
  static NonBorrowingTitleHolderInformation: "nonBorrowingTitleHolderInformation" = 'nonBorrowingTitleHolderInformation';
  static WitnessInformation: "witnessInformation" = 'witnessInformation';
}
