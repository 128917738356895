export class NewDocumentTemplateEndorsement {

  public participantRole: string;
  public roleSequence: number;
  public endorsementType: string;
  public xPosition: number;
  public yPosition: number;
  public height: number;
  public width: number;
  public pageNumber: number;
  public signatureReference: string;
  public status: string;
  public isRequired: boolean;
  public templateId: number;
  public format: string;

  constructor() { }
}

export class DocumentTemplateEndorsement extends NewDocumentTemplateEndorsement {
  public id?: number;
}
