import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { Commission } from '../models/commission.model';
import { Stamp } from '../models/stamp.model';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { CommissionInfo, CommissionInfoPatch, CommissionInfosModel } from '../models/commission-info.model';

@Injectable({
  providedIn: 'root'
})
export class CommissionService extends BaseService {
  className = "CommissionService";

  constructor(injector: Injector) {
    super(injector);
  }

  public getCommission(id: number | null): Observable<Commission> {
    return this.get<Commission[]>(`/v1/commissions/${id}`).pipe(
      map(commissions => commissions[0])
    );
  }

  public getCommissions(): Observable<Commission[]> {
    return this.get<Commission[]>(`/v1/commissions`);
  }

  public getDraftCommissions(): Observable<CommissionInfosModel> {
    return this.get<CommissionInfosModel>(`/v1/commissionInfo/search`);
  }

  public saveCommission(commission: Commission): Observable<Commission> {
    return this.post<Commission>('/v1/commissions', commission);
  }

  public saveDraftCommission(commissionInfo: CommissionInfo): Observable<CommissionInfo> {
    return this.post<CommissionInfo>('/v1/commissionInfo', commissionInfo);
  }

  public updateCommission(id: number, commission: Commission): Observable<Commission> {
    return this.patch<Commission>(`/v1/commissions/${id}`, commission);
  }

  public updateDraftCommission(commissionInfoPatch: CommissionInfo): Observable<CommissionInfo> {
    return this.patch<CommissionInfo>(`/v1/commissionInfo`, commissionInfoPatch);
  }

  public patchDraftCommission(commissionInfoPatch: CommissionInfoPatch): Observable<CommissionInfoPatch> {
    return this.patch<CommissionInfoPatch>(`/v1/commissionInfo`, commissionInfoPatch);
  }

  public deleteCommission(id: number): Observable<object> {
    return this.delete(`/v1/commissions/${id}`);
  }

  public deleteDraftCommission(id: number): Observable<object> {
    return this.delete(`/v1/commissionInfo/${id}`);
  }

  public getStampCreatorStates(): Observable<string> {
    return this.getText('/v1/config/stampCreator');
  }

  public getOrGenerateStamp(
    stateCode: string,
    countyName: string,
    commissionExpiration: any,
    commissionName: string,
    commissionNumber: string
  ): Observable<Stamp> {
    return this.get('/v1/stamp', {
      stateCode,
      countyName,
      commissionExpiration: commissionExpiration
        ? formatDate(commissionExpiration, 'MMM. dd, yyyy', 'en-US')
        : '',
      commissionName,
      commissionNumber
    });
  }
}
