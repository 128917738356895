import {
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { ListViewParticipant } from "../models/participant.model";

export class FormValidators {
  static zipcode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ctrlValue = control.value || "";
      return /^\d{5}(-\d{4})?$/.test(ctrlValue)
        ? null
        : {
            zipcode: {
              value: ctrlValue,
            },
          };
    };
  }

  static mustBeLength(requiredLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueLength = (control.value || "").length;
      return valueLength === requiredLength
        ? null
        : {
            mustBeLength: {
              expected: requiredLength,
              actual: valueLength,
            },
          };
    };
  }

  static isInteger(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return Number.isInteger(control.value)
        ? null
        : {
            isInteger: {
              value: control.value,
            },
          };
    };
  }

  static isValidDocumentExtension(file: File): boolean {
    const ext = file.name.toLowerCase().split(".").pop();
    return ["pdf", "xml"].some((arrVal) => arrVal === ext);
  }

  static isPfxExtension(file: File): boolean {
    const ext = file.name.toLowerCase().split(".").pop();
    return (
      ["pfx"].some((arrVal) => arrVal === ext)
    );
  }

  static isPngExtension(file: File): boolean {
    const ext = file.name.toLowerCase().split(".").pop();
    return ["png"].some((arrVal) => arrVal === ext);
  }

  static confirmEmailAddressValidator(
    controlName: string,
    controlToMatchName: string
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup?.get(controlName);
      const controlToMatch = formGroup?.get(controlToMatchName);
      const error = { emailNotMatching: true };
      if (
        controlToMatch?.errors &&
        !controlToMatch?.errors.confirmEmailAddressValidator
      ) {
        return null;
      }

      const doesNotMatch = control?.value?.toLowerCase() !== controlToMatch?.value?.toLowerCase();

      if (doesNotMatch) {
        formGroup?.get(controlToMatchName)?.setErrors(error);
      }
      return doesNotMatch ? error : null;
    };
  }

  static confirmOptionalEmailAddressValidator(
    controlName: string,
    controlToMatchName: string
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup?.get(controlName);
      const controlToMatch = formGroup?.get(controlToMatchName);

      // if both controls have null or a blank string, treat it as valid (optional)
      if (
        (control?.value === null && controlToMatch?.value === null) ||
        (control?.value === "" && controlToMatch?.value === null) ||
        (control?.value === null && controlToMatch?.value === "") ||
        (control?.value === "" && controlToMatch?.value === "")
      ) {
        return null;
      }

      // otherwise validate as usual
      return FormValidators.confirmEmailAddressValidator(
        controlName,
        controlToMatchName
      )(formGroup);
    };
  }

  // eslint-disable-next-line max-len
  static sequenceNumberValidator(
    participants: ListViewParticipant[],
    selectedParticipantId: number,
    controlName: string,
    isEditing: boolean
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const error = { sequenceNumberTaken: true };
      if (participants == null || participants.length === 0) {
        return null;
      }

      const selectedSequenceNumber = parseInt(
        formGroup?.get(controlName)?.value || 0,
        10
      );
      if (selectedSequenceNumber < 0) {
        return error;
      }

      const selectedRole = formGroup?.get("participantRole")?.value;

      const forbidden = isEditing
        ? participants.some(
            (p) =>
              p.participantModifiable.id !== selectedParticipantId &&
              p.participantModifiable.role === selectedRole &&
              p.participantModifiable.sequenceNumber === selectedSequenceNumber
          )
        : participants.some(
            (p) =>
              p.participantModifiable.role === selectedRole &&
              p.participantModifiable.sequenceNumber === selectedSequenceNumber
          );
      if (forbidden) {
        formGroup?.get(controlName)?.setErrors(error);
      }
      return forbidden ? error : null;
    };
  }

  static confirmValidSsn(isEditingSSN: boolean) {
    return (formGroup: UntypedFormGroup) => {
      if (!isEditingSSN) {
        return null;
      }

      const control = formGroup?.get("lastFourSsn");

      const required = { required: true };
      if (control?.value == null || control?.value.length === 0) {
        return required;
      }
      const error = { ssnValidation: true };

      if (!control?.value.match("^[0-9]{4}$")) {
        formGroup?.get("lastFourSsn")?.setErrors(error);
        return error;
      }

      return null;
    };
  }

  static confirmOptionalValidSsn(isEditingSSN: boolean): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup?.get("lastFourSsn");

      // if both controls have null or a blank string, treat it as valid (optional)
      if (control?.value === "" || control?.value === null) {
        return null;
      }

      // otherwise validate as usual
      return FormValidators.confirmValidSsn(isEditingSSN)(formGroup);
    };
  }

  static ssnLastFourValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control?.value;
      if (!/^[0-9]{4}$/.test(value)) {
        return {
          ssnValidation: true,
        };
      }
      return null;
    };
  }

  static ssnLastFourOptionalValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control?.value;

      if (!value) {
        return null;
      }

      return FormValidators.ssnLastFourValidator()(control);
    };
  }

  static multipleSigningAgentsValidator(
    participants: ListViewParticipant[],
    isEditing: boolean
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = { multipleSigningAgentsFound: { value: control.value } };
      if (participants == null || participants.length === 0 || isEditing === true) {
        return null;
      }

      const forbidden = participants.some((p) =>
        p.participantModifiable !== null && control.value
          ? p.participantModifiable.role.toUpperCase() ===
            control.value.toUpperCase()
          : false
      );
      return forbidden ? error : null;
    };
  }

  static validateDocumentName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control?.value;
      if (!/^[0-9A-Za-z_\.\-'\s]+$/.test(value)) {
        return {
          validateDocumentName:
            "Document Name can only contain these characters: AlphaNumeric, ., _, -, and '.",
        };
      }
      return null;
    };
  }

  static validateDocumentType(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control?.value;
      if (!/^[0-9A-Za-z_\.\-'\s]+$/.test(value)) {
        return {
          validateDocumentType:
            "Document Type can only contain these characters: AlphaNumeric, ., _, -, and '.",
        };
      }
      return null;
    };
  }

  static requiredFileType(types: string[]) {
    return (control: UntypedFormControl) => {
      const file = control?.value;
      if (file) {
        let extension = "";
        const sections = /(?:\.([^.]+))?$/.exec(file?.name);
        if (sections) {
          extension = sections[1]?.toLowerCase();
        }
        if (!types.includes(extension)) {
          return {
            requiredFileType: true
          };
        }
      }
      return null;
    };
  }
}
