import {
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { CapabilitiesService } from 'src/app/shared/services/capabilities/capabilities.service';
import { CapabilityParams } from '../capabilities-params.model';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[enableIfHasCapability]'
})
export class EnableIfHasCapabilityDirective implements OnInit {

  @Input('enableIfHasCapability') capabilities: CapabilityParams[];

  constructor(
    private el: ElementRef,
    private capabilitiesService: CapabilitiesService
  ) { }

  ngOnInit() {
    this.capabilitiesService.userHasAnyCapability(this.capabilities).pipe(
      tap(hasCapability => {
        if (hasCapability) {
          this.el.nativeElement.disabled = false;
        } else {
          this.el.nativeElement.disabled = true;
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }
}
