import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { catchError, tap } from "rxjs/operators";
import { AccountSignatureModel } from "src/app/shared/models/account-signature.model";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { AccountSignatureService } from "src/app/shared/services/account-signature.service";
import { AppDialogModule } from "../../shared/components/app-dialog/app-dialog.component";
import { AddAccountSignatureComponent } from "../add-account-signature/add-account-signature.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-account-signature",
  templateUrl: "./account-signature.component.html",
  styleUrls: ["./account-signature.component.scss"]
})
export class AccountSignatureComponent implements OnInit {
  signatures: AccountSignatureModel[] = [];
  signatureImage: SafeUrl | null;

  constructor(
    private readonly accountSignatureService: AccountSignatureService,
    private readonly sanitizer: DomSanitizer,
    private readonly toastr: ToastrService,
    private readonly dialog: AppDialogModule,
    private readonly spinner: AppSpinnerModule,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getSignaturesForCurrentUser();
  }

  getSignaturesForCurrentUser(): void {
    this.spinner.show();
    this.accountSignatureService.getCurrentAccountSignatures()
      .pipe(
        tap((response) => {
          this.spinner.hide();
          this.signatures = response.accountSignatures;
        }),
        catchError(() => {
          this.spinner.hide();
          return [];
        }
        )).subscribe();
  }

  deleteSignatureById(accountSignatureId: number) {
    this.spinner.show();
    this.accountSignatureService.deleteSignature(accountSignatureId).subscribe(
      () => {
        this.spinner.hide();
        this.toastr.success("Signature deleted successfully");
        this.getSignaturesForCurrentUser();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.error);
      }
    );
  }

  deleteConfirmationDialog(accountSignatureId: number) {
    this.dialog.show({
      title: "Delete Signature",
      message: "Are you sure you want to delete this signature?",
      noButtonText: "Cancel",
      yesButtonText: "Delete Signature",
    }).result.then((dialogResult) => {
      if (dialogResult === "yes") {
        this.deleteSignatureById(accountSignatureId);
      }
    });
  }

  getSanitizedImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpg;base64," + image
    );
  }

  openAddAccountSignatureModal(mode: "add" | "edit") {
    const modalRef = this.modalService.open(AddAccountSignatureComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.mode = mode;

     modalRef.result.then((result) => {
      if (result === "accountSignatureAdded") {
        this.onAccountSignatureAdded();
      }
    });
  }

  onAccountSignatureAdded(): void {
    this.getSignaturesForCurrentUser();
  }
}
