<div class="modal-header"><h5 class="modal-title">{{title}}</h5></div>

<div class="modal-body">
    <div class="row mt-10">
      <div class="col-12" [innerHTML]="message"></div>
    </div>
</div>

<div class="modal-footer">
  <button type="button" class="amr-btn-outline-primary" (click)="activeModal.close('no')">{{noButtonText}}</button>
  <button type="button" class="amr-btn-outline-warning" (click)="activeModal.close('yes')">{{yesButtonText}}</button>
</div>
