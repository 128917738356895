import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { tap } from "rxjs/operators";

import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { Document } from "src/app/shared/models/document.models";
import { DocumentService } from "src/app/shared/services/document.service";
import { FormError } from 'src/app/shared/components/app-form-error/form-error';
import { KeyValuePair } from "src/app/shared/models/key-value-pair";

@Component({
  templateUrl: "./document-edit-modal.component.html",
  styleUrls: ["./document-edit-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentEditModalComponent implements OnInit {
  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  actionTypes: KeyValuePair[] = [
    { key: "esign", value: "eSign" },
    { key: "print", value: "Print" },
  ];

  pageTypes: KeyValuePair[] = [
    { key: "Letter", value: "Letter" },
    { key: "Legal", value: "Legal" },
  ];
  constructor(
    public activeModal: NgbActiveModal,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
    private readonly documentService: DocumentService
  ) {}

  packageDocumentId: number;
  closingType: string;
  isHybridOrder: boolean;
  iseSignOrder: boolean;
  isPreSignOrder: boolean;
  isPresignEligible: boolean;
  documentForm: UntypedFormGroup;
  document: Document;

  async ngOnInit() {
    this.isHybridOrder = (this.closingType || "").toUpperCase() === "HYBRID";
    this.iseSignOrder = (this.closingType || "").toUpperCase() === "ESIGN";
    this.document = new Document();
    this.initForm();

    this.spinner.show("document-edit-spinner");

    this.documentService
      .getPortalDocByDocumentId(this.packageDocumentId)
      .pipe(
        tap((doc) => {
          this.document = doc;
          this.setFormValue("documentType", doc.type);
          this.setFormValue("documentName", doc.name);
          if (this.isHybridOrder) {
            const actionType = doc.isPaper ? "print" : "esign";
            this.setFormValue("actionType", actionType);
          }
          this.setFormValue("pageType", doc.pageType);
          this.setFormValue("isSmartDoc", doc.isSmartDoc);
          this.setFormValue("isPreSignDoc", doc.isDocPreSign);
          if (doc.isSmartDoc) {
            this.setDisabled("actionType");
            this.setDisabled("pageType");
          }
          if (doc.isDigitized) {
            this.setDisabled("pageType");
          }
          if (this.iseSignOrder || !this.isPresignEligible) {
            this.setDisabled("isPreSignDoc");
          }
          this.spinner.hide("document-edit-spinner");
        })
      )
      .subscribe();
  }

  initForm() {
    this.documentForm = new UntypedFormGroup(
      {
        documentName: new UntypedFormControl(null, [
          Validators.required,
          Validators.maxLength(200),
          this.validDocumentName,
        ]),
        documentType: new UntypedFormControl(null, [
          Validators.required,
          Validators.maxLength(32)
        ]),
        actionType: new UntypedFormControl(
          this.isHybridOrder
            ? { value: null, disabled: false }
            : { value: "esign", disabled: true },
          this.isHybridOrder
            ? [
                Validators.required,
                this.validateByOptions(this.actionTypes, "validActionType"),
              ]
            : null
        ),
        pageType: new UntypedFormControl(null, [
          Validators.required,
          this.validateByOptions(this.pageTypes, "validPageType"),
        ]),
        isPreSignDoc: new UntypedFormControl({
          value: false,
          disabled: this.iseSignOrder,
        }),
        isSmartDoc: new UntypedFormControl({ value: false, disabled: true }),
      },
      {
        updateOn: "blur",
      }
    );
  }

  setFormValue(name: string, value?: string | boolean): void {
    const ctrl = this.control(name);
    if (ctrl === null || ctrl === undefined) {
      return;
    }
    ctrl.setValue(value);
  }

  setDisabled(name: string): void {
    const ctrl = this.control(name);
    if (ctrl === null || ctrl === undefined) {
      return;
    }
    ctrl.disable({ onlySelf: true, emitEvent: false });
  }

  control(name: string): AbstractControl | null {
    return this.documentForm.get(name);
  }

  validDocumentName(control: AbstractControl): ValidationErrors | null {
    const isValidText = /^[0-9A-Za-z#()_\.\-'\s]+$/.test(control.value);
    return isValidText ? null : { validDocumentName: { value: control.value } };
  }

  validateByOptions(options: KeyValuePair[], errorKey: string = "required") {
    return (control: AbstractControl): ValidationErrors | null =>
      options.some((element: KeyValuePair) => element.value === control.value)
        ? null
        : { [errorKey]: { value: control.value } };
  }

  onSave(): void {
    if (this.documentForm.invalid) {
      // invoke the validators on form controls
      Object.keys(this.documentForm.controls).forEach((field) => {
        const control = this.documentForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
      FormError.ValidateAllFormFields(this.documentForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      return;
    }

    this.spinner.show("document-edit-spinner");
    const form = this.documentForm.getRawValue();
    const dto: Document = {
      id: this.packageDocumentId,
      isPaper: form.actionType === "print",
      type: form.documentType,
      name: form.documentName,
      pageType: form.pageType,
      isSmartDoc: form.isSmartDoc,
      sequenceNumber: this.document.sequenceNumber,
      isDocPreSign: form.isPreSignDoc,
      totalPages: this.document.totalPages,
    };
    this.documentService
      .patchDocument(this.packageDocumentId, dto)
      .subscribe(
        () => {
          this.toastr.success("Document updated successfully");
          this.activeModal.close("save");
        },
        (err) => {
          console.error(err);
          this.toastr.error(
            "Sorry, an error occurred while updating your document.",
            "Error"
          );
        }
      )
      .add(() => this.spinner.hide("document-edit-spinner"));
  }
}
