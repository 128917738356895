<ng-container>
  <mat-sidenav-container *ngIf="imageData$ | async as imageData"
                         class="endorsements-sidenav-container">
    <mat-sidenav [disableClose]="true"
                 *ngIf="digitizationParameters$ | async as digitizationParameters"
                 #sidenav
                 mode="side"
                 opened>
      <div class="endorsements-sidenav-container__back">
        <button (click)="handleBackToDocuments()"
                mat-button
                class="endorsements-sidenav-container__back__button">
          <mat-label>
            <img src="assets/images/back.png" alt="back" />Back to Documents
          </mat-label>          
        </button>
      </div>
      <div class="endorsements-sidenav-container__header">Toolbox</div>
      <div class="endorsements-sidenav-container__endorsement-toolbox">
        <button mat-button
                [matMenuTriggerFor]="signatureMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <img src="assets/images/signature.png" alt="signature" />Signature
        </button>
        <mat-menu #signatureMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Signature:</p>
          <button *ngFor="let participant of getParticipants() | async"
                  [disabled]="!participantCanParticipate(participant)"
                  (click)="handleGenerateEndorsementComponent({
              participant,
              imageData,
              isSystem: false,
              endorsementType: 'SIGNATURE'
            })"
                  mat-menu-item>
            <img src="assets/images/signature.png" alt="signature" />
            {{ participant | participantSignatureName }}
            (<span>{{ participant.role | roleValue }}</span>)
          </button>
        </mat-menu>
        <button mat-button
                [matMenuTriggerFor]="initialsMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <img src="assets/images/initials.png" alt="initials" />Initials
        </button>
        <mat-menu #initialsMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Initials:</p>
          <button *ngFor="let participant of getParticipants() | async"
                  [disabled]="!participantCanParticipate(participant)"
                  (click)="handleGenerateEndorsementComponent({
              participant,
              imageData,
              isSystem: false,
              endorsementType: 'INITIALS'
            })"
                  mat-menu-item>
            <img src="assets/images/initials.png" alt="initials" />
            {{ participant | participantSignatureName }}
            (<span>{{ participant.role | roleValue }}</span>)
          </button>
        </mat-menu>
        <button mat-button
                [matMenuTriggerFor]="dateMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <img src="assets/images/date.png" alt="date" />Date
        </button>
        <mat-menu #dateMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Date:</p>
          <button (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'DATE',
                format: 'MM/dd/yyyy'
              })
            "
                  mat-menu-item>
            <img src="assets/images/date.png" alt="date" />
            Full Date (MM/dd/yyyy)
          </button>
          <button (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'DATE',
                format: 'yyyy'
              })
            "
                  mat-menu-item>
            <img src="assets/images/date.png" alt="date" />
            Four Digit Year (yyyy)
          </button>
          <button (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'DATE',
                format: 'yy'
              })
            "
                  mat-menu-item>
            <img src="assets/images/date.png" alt="date" />
            Two Digit Year (yy)
          </button>
          <button (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'DATE',
                format: 'dd'
              })
            "
                  mat-menu-item>
            <img src="assets/images/date.png" alt="date" />
            Day Of Month (dd)
          </button>
          <button (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'DATE',
                format: 'MMMM'
              })
            "
                  mat-menu-item>
            <img src="assets/images/date.png" alt="date" />
            Month Name (January)
          </button>
          <button (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'DATE',
                format: 'MMM'
              })
            "
                  mat-menu-item>
            <img src="assets/images/date.png" alt="date" />
            Month Abbreviated (Jan)
          </button>
          <button (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'DATE',
                format: 'MM'
              })
            "
                  mat-menu-item>
            <img src="assets/images/date.png" alt="date" />
            Month Number (MM)
          </button>
        </mat-menu>
        <button mat-button
                [matMenuTriggerFor]="textboxMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <img src="assets/images/textbox.png" alt="textbox" />Textbox
        </button>
        <mat-menu #textboxMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Textbox:</p>
          <mat-divider></mat-divider>
          <p>Required</p>
          <button *ngFor="let participant of getParticipants() | async"
                  [disabled]="!participantCanParticipate(participant)"
                  (click)="handleGenerateEndorsementComponent({
                  participant,
                  imageData,
                  isSystem: false,
                  endorsementType: 'TEXTBOX'
                })"
                  mat-menu-item>
            <img src="assets/images/textbox.png" alt="textbox" />
            {{ participant | participantSignatureName }}
            (<span>{{ participant.role | roleValue }}</span>)
          </button>
          <mat-divider></mat-divider>
          <p>Optional</p>
          <button *ngFor="let participant of getParticipants() | async"
                  [disabled]="!participantCanParticipate(participant)"
                  (click)="handleGenerateEndorsementComponent({
                  participant,
                  imageData,
                  isSystem: false,
                  endorsementType: 'TEXTBOX',
                  isRequired: false
                })"
                  mat-menu-item>
            <img src="assets/images/textbox.png" alt="textbox" />
            {{ participant | participantSignatureName }}
            (<span>{{ participant.role | roleValue }}</span>)
          </button>
        </mat-menu>
        <button mat-button
                [matMenuTriggerFor]="checkboxMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <img src="assets/images/checkbox.png" alt="checkbox" />Checkbox
        </button>
        <mat-menu #checkboxMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Checkbox:</p>
          <mat-divider></mat-divider>
          <p>Required</p>
          <button *ngFor="let participant of getParticipants() | async"
                  [disabled]="!participantCanParticipate(participant)"
                  (click)="handleGenerateEndorsementComponent({
                  participant,
                  imageData,
                  isSystem: false,
                  endorsementType: 'CHECKBOX'
                })"
                  mat-menu-item>
            <img src="assets/images/checkbox.png" alt="checkbox" />
            {{ participant | participantSignatureName }}
            (<span>{{ participant.role | roleValue }}</span>)
          </button>
          <mat-divider></mat-divider>
          <p>Optional</p>
          <button *ngFor="let participant of getParticipants() | async"
                  [disabled]="!participantCanParticipate(participant)"
                  (click)="handleGenerateEndorsementComponent({
                  participant,
                  imageData,
                  isSystem: false,
                  endorsementType: 'CHECKBOX',
                  isRequired: false
                })"
                  mat-menu-item>
            <img src="assets/images/checkbox.png" alt="checkbox" />
            {{ participant | participantSignatureName }}
            (<span>{{ participant.role | roleValue }}</span>)
          </button>
        </mat-menu>
        <button mat-button
                class="endorsements-sidenav-container__endorsement-toolbox__item"
                (click)="
            handleGenerateEndorsementComponent({
              participant: null,
              imageData,
              isSystem: true,
              endorsementType: 'STATE'
            })
          ">
          <img src="assets/images/state.png" alt="state" />State
        </button>
        <button mat-button
                [disabled]="endorsmentDisabledForOrder()"
                [matMenuTriggerFor]="commisionFieldsMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <img src="assets/images/commission-fields.png" alt="commission fields" />Commission Fields
        </button>
        <mat-menu #commisionFieldsMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Commission Fields:</p>
          <button [disabled]="endorsmentDisabledForOrder()"
                  (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'SIGNINGAGENTCOMMEXPIRE'
              })
            "
                  mat-menu-item>
            <img src="assets/images/commission-fields.png" alt="commission expiration" />
            Commission Expiration Date
          </button>
          <button [disabled]="endorsmentDisabledForOrder()"
                  (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'SIGNINGAGENTCOMMNUMBER'
              })
            "
                  mat-menu-item>
            <img src="assets/images/commission-fields.png" alt="commission number" />
            Commission Number
          </button>
          <button [disabled]="endorsmentDisabledForOrder()"
                  (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'SIGNINGAGENTCOMMCOUNTY'
              })
            "
                  mat-menu-item>
            <img src="assets/images/commission-fields.png" alt="commission county" />
            Commission County
          </button>
          <button [disabled]="endorsmentDisabledForOrder()"
                  (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'SIGNINGAGENTCOMMSTATE'
              })
            "
                  mat-menu-item>
            <img src="assets/images/commission-fields.png" alt="commission state" />
            Commission State
          </button>
        </mat-menu>
        <button [disabled]="endorsmentDisabledForOrder()"
                *ngIf="getParticipants() | async as participants"
                mat-button
                class="endorsements-sidenav-container__endorsement-toolbox__item"
                (click)="handleGenerateNotaryEndorsementComponent({
            participants,
            imageData
          })">
          <img src="assets/images/stamp.png" alt="stamp" />Stamp
        </button>
        <button mat-button
                [disabled]="endorsmentDisabledForOrder()"
                [matMenuTriggerFor]="signingAgentFieldsMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <img src="assets/images/saf.png" alt="signing agent fields" />Signing Agent Fields
        </button>
        <mat-menu #signingAgentFieldsMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Signing Agent Fields:</p>
          <button [disabled]="endorsmentDisabledForOrder()"
                  (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'SIGNINGAGENTCOUNTY'
              })
            "
                  mat-menu-item>
            <img src="assets/images/saf.png" alt="signing agent county" />
            Signing Agent County
          </button>
          <button [disabled]="endorsmentDisabledForOrder()"
                  (click)="
              handleGenerateEndorsementComponent({
                participant: null,
                imageData,
                isSystem: true,
                endorsementType: 'SIGNINGAGENTNAME'
              })
            "
                  mat-menu-item>
            <img src="assets/images/saf.png" alt="signing agent name" />
            Signing Agent Name
          </button>
        </mat-menu>
        <button mat-button
                [matMenuTriggerFor]="printedNameMenu"
                class="endorsements-sidenav-container__endorsement-toolbox__item">
          <i class="fa fa-font" aria-hidden="true"></i>Printed Name
        </button>
        <mat-menu #printedNameMenu="matMenu"
                  class="mat-elevation-z24"
                  overlapTrigger
                  xPosition="after"
                  yPosition="below">
          <p>Select Printed Name:</p>
          <button *ngFor="let participant of getParticipants() | async"
                  [disabled]="!signingAgent(participant)"
                  (click)="handleGenerateEndorsementComponent({
              participant,
              imageData,
              isSystem: false,
              endorsementType: 'PRINTEDNAME'
            })"
                  mat-menu-item>
            <i class="fa fa-font" aria-hidden="true"></i>{{
              participant | participantSignatureName
            }}
            (<span>{{ participant.role | roleValue }}</span>)
          </button>
        </mat-menu>
      </div>
      <div class="endorsements-sidenav-container__docActions">
        <button (click)="handleSubmitDocumentAndEndorsements({ imageData, digitizationParameters })"
                class="endorsements-sidenav-container__docActions__button-save"
                mat-icon-button>
          Save Changes
        </button>
      </div>
      <div class="endorsements-sidenav-container__templates">
        <div class="endorsements-sidenav-container__header">
          <mat-label>
            <p class="mt-10 float-left">Templates</p>
            <button mat-button [matMenuTriggerFor]="templateHelpMenu" class="mt-1">
              <img src="assets/images/help.png" alt="help" />
            </button>
          </mat-label>          
          <mat-menu #templateHelpMenu="matMenu"
                    class="mat-elevation-z24"
                    overlapTrigger
                    xPosition="after"
                    yPosition="below">
            <b>Endorsement Templates</b>
            <p>
              Select an endorsement template created in a previous document, and
              apply the endorsements in the same places to your current
              document.
            </p>
          </mat-menu>
        </div>
        <mat-form-field appearance="fill"
                        class="endorsements-sidenav-container__templates__form"
                        id="digiDocTemplateId">
          <mat-label>Apply Template</mat-label>
          <mat-select (selectionChange)="
              handleGenerateEndorsementsUsingTemplate({
                templateId: $event.value,
                imageData
              })
            "
                      [(value)]="digitizationParameters.selectedTemplate"
                      data-cy="selectTemplateDropdown">
            <mat-option disabled *ngIf="(templateOptions$ | async).length === 0">
              No Templates Created
            </mat-option>
            <mat-option *ngFor="let template of templateOptions$ | async" [value]="template.key">
              {{ template.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="templateID" class="ml-3 mb-3">Template ID: {{ templateID }}</div>
        <div>
          <button *ngIf="getParticipants() | async as participants; else saveTemplateAsNotLoaded"
                  (click)="handleOpenSaveTemplateModalForTemplateName({digitizationParameters, imageData, participants })"
                  class="endorsements-sidenav-container__templates__button-save"
                  mat-icon-button>
            Save Template As
          </button>
          <ng-template #saveTemplateAsNotLoaded>
            <button [disabled]
                    class="endorsements-sidenav-container__templates__button-save"
                    mat-icon-button>
              Save Template As
            </button>
          </ng-template>
          <button *ngIf="
              getParticipants() | async as participants;
              else saveTemplateChangesNotLoaded
            "
                  (click)="
              handleUpdateTemplateEndorsements({
                imageData,
                digitizationParameters,
                templateId: digitizationParameters.selectedTemplate,
                participants
              })
            "
                  class="endorsements-sidenav-container__templates__button-update">
            Save Template Changes
          </button>
          <ng-template #saveTemplateChangesNotLoaded>
            <button [disabled]
                    class="endorsements-sidenav-container__templates__button-update">
              Save Template Changes
            </button>
          </ng-template>
        </div>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>
