<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">Add new System User</h5>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      All new System Accounts must be Amrock employees with an &#64;amrock.com email address. Please enter the email address below and select the corresponding Account Type(s). When ready, click "Add System Users" to verify and add new users.
    </div>
    <form [formGroup]="addSystemUserForm" class="clrsgn-form">
      <div formArrayName="systemUsers">
        <div *ngFor="let systemUserGroup of addSystemUsersArray.controls; let i = index" [formGroupName]="i">
          <hr *ngIf="i > 0" class="split"/>
          <div class="modal-section">
            New System User {{ i + 1 }}
            <span *ngIf="i > 0"> - <span class="fas fa-trash-alt" (click)="removeUserInvite(i)" ngbTooltip="Remove"></span></span>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="emailAddress" class="clrsgn-label">
                Email Address
              </mat-label>
              <input matInput
                     maxlength="128"
                     type="text"
                     formControlName="emailAddress"
                     id="emailAddress">
              <app-form-error [group]="addSystemUserForm"
                              [controlName]="'systemUsers.' + i + '.emailAddress'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="row align-center">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="accountTypes" class="clrsgn-label">
                Account Types
              </mat-label>
              <mat-select formControlName="accountTypes"
                          id="accountTypes"
                          data-cy="accountTypesDropdownList"
                          [multiple]="true">
                <mat-option *ngFor="let type of accountTypes"
                            [value]="type.key">
                  {{ type.value }}
                </mat-option>
              </mat-select>
              <app-form-error [group]="addSystemUserForm"
                              [controlName]="'systemUsers.' + i + '.accountTypes'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <button type="button" class="add-another-button" (click)="addSystemUser()">+ Add Another</button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel-button" (click)="close()">Cancel</button>
    <button type="button" class="add-system-users-button" (click)="addSystemAccounts()">Add System Users</button>
  </div>
</ng-container>

