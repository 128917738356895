<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">Add Client Account</h5>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      Enter the email address below and select the client and corresponding role(s). When ready, click 'Send'. If the user doesn't exist they'll be sent an email to sign up. If the user does exist the client account will be added the next time they log in to the portal.
    </div>
    <form [formGroup]="addUserForm" class="clrsgn-form">
      <div formArrayName="users">
        <div *ngFor="let userGroup of addUsersArray.controls; let i = index" [formGroupName]="i">
          <hr *ngIf="i > 0" class="split"/>
          <div class="modal-section">
            Add New User {{ i + 1 }}
            <span *ngIf="i > 0"> - <span class="fas fa-trash-alt" (click)="removeUserInvite(i)" ngbTooltip="Remove"></span></span>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="name" class="clrsgn-label">
                Name
              </mat-label>
              <input matInput
                     maxlength="128"
                     type="text"
                     formControlName="name"
                     id="name">
              <app-form-error [group]="addUserForm"
                              [controlName]="'users.' + i + '.name'">
              </app-form-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="targetEmailAddress" class="clrsgn-label">
                Email Address
              </mat-label>
              <input matInput
                     maxlength="128"
                     type="text"
                     formControlName="targetEmailAddress"
                     id="targetEmailAddress">
              <app-form-error [group]="addUserForm"
                              [controlName]="'users.' + i + '.targetEmailAddress'">
              </app-form-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="clientId" class="clrsgn-label">
                Client
              </mat-label>
              <mat-select data-cy="clientDropdownList"
                          placeholder="Client"
                          [selectOnTab]="true"
                          id="clientId"
                          formControlName="clientId"
                          #clientSelect>
                          <input matInput placeholder="Filter"
                                      class="clrsgn-form-field mat-option"
                                      formControlName="clientFilter"
                                      (input)="filterClients(i)"
                                      #clientInput>
                          <hr />
                          <mat-option data-cy="clientOptions"
                                      *ngFor="let client of filteredClientList"
                            [value]="client.clientId">
                            {{ client.clientName }}
                </mat-option>
              </mat-select>
              <app-form-error [group]="addUserForm"
                              [controlName]="'users.' + i + '.clientId'">
              </app-form-error>
            </mat-form-field>
          </div>
          <div class="row align-center">
            <mat-form-field appearance="fill" class="invite-form-field">
              <mat-label for="roles" class="clrsgn-label">
                Role Type(s)
              </mat-label>
              <mat-select formControlName="roles"
                          id="roles"
                          data-cy="accountRoleDropdownList"
                          [multiple]="true">
                <mat-option *ngFor="let role of accountRoles"
                            [value]="role.key">
                  {{ role.value }}
                </mat-option>
              </mat-select>
              <app-form-error [group]="addUserForm"
                              [controlName]="'users.' + i + '.roles'">
              </app-form-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <button type="button" class="add-another-button" (click)="addUser()">+ Add Another</button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel-button" (click)="close()">Cancel</button>
    <button type="button" class="send-button" (click)="sendInvites()">Send</button>
  </div>
</ng-container>
