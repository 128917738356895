import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule, DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService
} from "@azure/msal-angular";
import { IPublicClientApplication, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ClipboardModule } from "ngx-clipboard";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";
import { ActionDropdownButtonComponent } from "./action-dropdown-button/action-dropdown-button.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DocumentEndorsementsUploadModalComponent } from "./digitization-tool/components/document-endorsements-upload-modal/document-endorsements-upload-modal.component";
import { DocumentUploadModalComponent } from "./digitization-tool/components/document-upload-modal/document-upload-modal.component";
import { EndorsementDraggableComponent } from "./digitization-tool/components/endorsement-draggable/endorsement-draggable.component";
import { SaveTemplateModalComponent } from "./digitization-tool/components/save-template-modal/save-template-modal.component";
import { UploadFormComponent } from "./digitization-tool/components/upload-form/upload-form.component";
import { DigitizationToolComponent } from "./digitization-tool/digitization-tool.component";
import { RoleValuePipe } from "./digitization-tool/pipes/role-value.pipe";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { EulaComponent } from "./eula/eula.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { FilterFormComponent } from "./manage-orders/components/filter-form/filter-form.component";
import { PaginationComponent } from "./manage-orders/components/pagination/pagination.component";
import { ManageOrdersComponent } from "./manage-orders/manage-orders.component";
import { AddSystemUserModalComponent } from "./manage-system-users/components/add-system-user-modal/add-system-user-modal.component";
import { ClientUserTableDisplayComponent } from "./manage-system-users/components/client-user-table-display/client-user-table-display.component";
import { ManageSystemUsersComponent } from "./manage-system-users/components/manage-system-users.component";
import { SupportAddUserModalComponent } from "./manage-system-users/components/support-add-user-modal/support-add-user-modal.component";
import { TabContainerComponent } from './manage-system-users/components/tab-container/tab-container.component';
import { UserFilterForm } from "./manage-system-users/components/user-filter-form/user-filter-form.component";
import { UserTableDisplayComponent } from "./manage-system-users/components/user-table-display/user-table-display.component";
import { AddUserModalComponent } from "./manage-users/components/add-user-modal/add-user-modal.component";
import { ManageUsersComponent } from "./manage-users/manage-users.component";
import { MsalAuthGuard } from "./msal/msal-auth.guard";
import { CancelOrderModalComponent } from "./order-detail/components/cancel-order-modal/cancel-order-modal.component";
import { CancellationCommentsModalComponent } from './order-detail/components/cancellation-comments-modal/cancellation-comments-modal.component';
import { CompleteOrderModalComponent } from "./order-detail/components/complete-order-modal/complete-order-modal.component";
import { CopyClosingUrlModalComponent } from "./order-detail/components/copy-closing-url-modal/copy-closing-url-modal.component";
import { DocumentEditModalComponent } from "./order-detail/components/document-edit-modal/document-edit-modal.component";
import { DocumentsListComponent } from "./order-detail/components/documents-list/documents-list.component";
import { DownloadVideoModalComponent } from './order-detail/components/download-video-modal/download-video-modal.component';
import { EditRemoveDropdownButtonComponent } from "./order-detail/components/edit-remove-dropdown-button/edit-remove-dropdown-button.component";
import { LoanInformationComponent } from "./order-detail/components/loan-information/loan-information.component";
import { OrderDetailHeaderComponent } from "./order-detail/components/order-detail-header/order-detail-header.component";
import { OrderOrganizerFormModalComponent } from './order-detail/components/order-organizer-form-modal/order-organizer-form-modal.component';
import { OrderOrganizerListComponent } from "./order-detail/components/order-organizer-list/order-organizer-list.component";
import { NonBorrowingTitleHolderInformationFormComponent } from "./order-detail/components/participant-form-modal/non-borrowing-title-holder-information-form/non-borrowing-title-holder-information-form.component";
import { ParticipantFormModalComponent } from "./order-detail/components/participant-form-modal/participant-form-modal.component";
import { ParticipantInformationFormComponent } from "./order-detail/components/participant-form-modal/participant-information-form/participant-information-form.component";
import { SigningAgentInformationFormComponent } from "./order-detail/components/participant-form-modal/signing-agent-information-form/signing-agent-information-form.component";
import { WitnessInformationFormComponent } from "./order-detail/components/participant-form-modal/witness-information-form/witness-information-form.component";
import { ParticipantListComponent } from "./order-detail/components/participant-list/participant-list.component";
import { MultifactorCodeFormComponent } from "./order-detail/components/reset-login/multifactor-code-form/multifactor-code-form.component";
import { ResetLoginModalComponent } from "./order-detail/components/reset-login/reset-login-modal.component";
import { SecurityCodeFormComponent } from "./order-detail/components/reset-login/security-code-form/security-code-form.component";
import { RuleViolationModalComponent } from './order-detail/components/rule-violation-modal/rule-violation-modal.component';
import { OrderDetailComponent } from "./order-detail/order-detail.component";
import { DocumentStatusPipe } from "./order-detail/pipes/document-status.pipe";
import { OrderFormModalComponent } from "./order-form/order-form.modal";
import { AppDialogComponent, AppDialogModule } from "./shared/components/app-dialog/app-dialog.component";
import { FormErrorComponent } from "./shared/components/app-form-error/app-form-error.component";
import { FileUploadComponent } from "./shared/components/file-upload/file-upload.component";
import { SkeletonLoadingListComponent } from "./shared/components/skeleton-loading-list/skeleton-loading-list.component";
import { AllowLinkIfHasCapabilityDirective } from "./shared/directives/capabilities/allow-link-if-has-capability/allow-link-if-has-capability.directive";
import { EnableIfHasCapabilityDirective } from "./shared/directives/capabilities/enable-if-has-capability/enable-if-has-capability.directive";
import { ShowIfHasCapabilityDirective } from "./shared/directives/capabilities/show-if-has-capability/show-if-has-capability.directive";
import { CopyToClipboardDirective } from "./shared/directives/copy-to-clipboard/copy-to-clipboard.directive";
import { OnlyNumberDirective } from "./shared/directives/only-numbers/only-numbers.directive";
import { NgbDateCustomParser } from "./shared/helpers/NgbDateCustomParser";
import { AuthInterceptor } from "./shared/interceptors/auth.interceptor";
import { AppSpinnerModule } from "./shared/modules/app-spinner.module";
import {
  ClosingTypeValuePipe, ParticipantDisplayNamePipe, ParticipantDisplayNameTooltipPipe,
  ParticipantSignatureNamePipe, PhoneNumberPipe, TitleCaseStatusCodePipe
} from "./shared/pipes";
import { ZipCodesService } from './shared/services/zipcodes.service';
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { AccountSignatureComponent } from "./user-profile/account-signature/account-signature.component";
import { AddAccountSignatureComponent } from "./user-profile/add-account-signature/add-account-signature.component";
import { SignaturePreviewComponent } from "./user-profile/add-account-signature/signature-preview/signature-preview.component";
import { ManageCommissionsComponent } from "./user-profile/manage-commissions/manage-commissions.component";
import { AddDigitalCertificate } from "./user-profile/manage-commissions/state-commission-page/components/add-digital-certificate/add-digital-certificate.component";
import { AddSignatureComponent } from "./user-profile/manage-commissions/state-commission-page/components/add-signature/add-signature.component";
import { StateCommissionPage } from "./user-profile/manage-commissions/state-commission-page/state-commission-page.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { _windowFactory, windowToken } from "./window.token";
import { CommissionFormModalComponent } from "./user-profile/manage-commissions/state-commission-page/components/commission-form-modal/commission-form-modal.component";
import { ModalService } from "./shared/services/modal.service";
import { AngularDraggableModule } from "angular2-draggable";
import { IonicModule } from "@ionic/angular";
import { NgxPopperjsModule } from 'ngx-popperjs';

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureB2C.clientID,
      authority: environment.azureB2C.authority,
      redirectUri: environment.azureB2C.redirectUri,
      postLogoutRedirectUri: environment.azureB2C.postLogoutRedirectUri,
      knownAuthorities: [
        environment.azureB2C.authority,
        environment.azureB2C.passwordResetPolicy
      ]
    },
    cache: {
      cacheLocation: isIE ? "localStorage" : "sessionStorage",
      storeAuthStateInCookie: isIE
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUri, [environment.apiScope]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.apiScope],
    },
  };
}

@NgModule({
  declarations: [
    AccountSignatureComponent,
    ActionDropdownButtonComponent,
    AddAccountSignatureComponent,
    AddDigitalCertificate,
    AddSignatureComponent,
    AddSystemUserModalComponent,
    AddUserModalComponent,
    AllowLinkIfHasCapabilityDirective,
    AppComponent,
    AppDialogComponent,
    CancellationCommentsModalComponent,
    CancelOrderModalComponent,
    ClientUserTableDisplayComponent,
    ClosingTypeValuePipe,
    CommissionFormModalComponent,
    CompleteOrderModalComponent,
    CopyClosingUrlModalComponent,
    CopyToClipboardDirective,
    DocumentEditModalComponent,
    DocumentEndorsementsUploadModalComponent,
    DocumentsListComponent,
    DocumentStatusPipe,
    DocumentUploadModalComponent,
    DownloadVideoModalComponent,
    EditRemoveDropdownButtonComponent,
    EnableIfHasCapabilityDirective,
    EndorsementDraggableComponent,
    ErrorPageComponent,
    EulaComponent,
    FileUploadComponent,
    FilterFormComponent,
    FooterComponent,
    FormErrorComponent,
    HeaderComponent,
    LoanInformationComponent,
    ManageCommissionsComponent,
    ManageOrdersComponent,
    ManageSystemUsersComponent,
    ManageUsersComponent,
    MultifactorCodeFormComponent,
    NonBorrowingTitleHolderInformationFormComponent,
    OnlyNumberDirective,
    OrderDetailComponent,
    OrderDetailHeaderComponent,
    OrderFormModalComponent,
    OrderOrganizerFormModalComponent,
    OrderOrganizerListComponent,
    PaginationComponent,
    ParticipantDisplayNameTooltipPipe,
    ParticipantFormModalComponent,
    ParticipantInformationFormComponent,
    ParticipantListComponent,
    PhoneNumberPipe,
    ResetLoginModalComponent,
    RuleViolationModalComponent,
    SaveTemplateModalComponent,
    SecurityCodeFormComponent,
    ShowIfHasCapabilityDirective,
    SignaturePreviewComponent,
    SigningAgentInformationFormComponent,
    SkeletonLoadingListComponent,
    StateCommissionPage,
    SupportAddUserModalComponent,
    TabContainerComponent,
    TermsAndConditionsComponent,
    TitleCaseStatusCodePipe,
    UploadFormComponent,
    UserFilterForm,
    UserProfileComponent,
    UserTableDisplayComponent,
    WitnessInformationFormComponent
  ],
  imports: [
    AngularDraggableModule,
    AppDialogModule,
    AppRoutingModule,
    AppSpinnerModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClipboardModule,
    CommonModule,
    DigitizationToolComponent,
    DragDropModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule,
    NgbModule,
    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxMatTimepickerModule,
    NgxPopperjsModule,
    NgxSpinnerModule,
    ParticipantDisplayNamePipe,
    ParticipantSignatureNamePipe,
    ReactiveFormsModule,
    RoleValuePipe,
    ToastrModule.forRoot(),
  ],
  exports: [ShowIfHasCapabilityDirective, EnableIfHasCapabilityDirective],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParser,
    },
    {
      provide: windowToken,
      useFactory: _windowFactory,
    },
    DatePipe,
    ModalService,
    MsalAuthGuard,
    MsalBroadcastService,
    MsalService,
    provideNgxMask(),
    ZipCodesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
