import { AccountService } from "../../../shared/services/account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSpinnerModule } from "../../../shared/modules/app-spinner.module";
import { Commission } from "../../../shared/models/commission.model";
import { CommissionInfo } from "../../../shared/models/commission-info.model";
import { CommissionService } from "../../../shared/services/commission.service";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { first, switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, firstValueFrom, of } from "rxjs";
import { State } from "../../../shared/services/state";
import { StateRuleModel } from "../../../shared/models/state-rules.model";
import { StateRulesService } from "../../../shared/services/state-rules.service";
import { StatesService } from "../../../shared/services/states.service";
import { ToastrService } from "ngx-toastr";
import { AddSignatureComponent } from "./components/add-signature/add-signature.component";
import { AddDigitalCertificate } from "./components/add-digital-certificate/add-digital-certificate.component";
import { CommissionFormModalComponent } from "./components/commission-form-modal/commission-form-modal.component";
import { NotaryStampService } from "../../../shared/services/notary-stamp.service";
import { AccountSignatureService } from "../../../shared/services/account-signature.service";
import { AppDialogModule } from "src/app/shared/components/app-dialog/app-dialog.component";


@Component({
  selector: "app-state-commission-page",
  templateUrl: "./state-commission-page.component.html",
  styleUrls: ['./state-commission-page.component.scss']
})
export class StateCommissionPage implements OnInit {
  selectedState: State;
  selectedRule: Observable<StateRuleModel | undefined>;
  selectedCommission: Observable<Commission | CommissionInfo>;
  accountId: number;
  isCommissionExpired: boolean;
  isDraftCommission: boolean;
  commissionId;

  hasCommission: Observable<boolean> = of(false);
  ruleLoaded: Observable<boolean> = of(false);

  constructor(
    private readonly accountService: AccountService,
    private readonly accountSignatureService: AccountSignatureService,
    private readonly commissionService: CommissionService,
    private readonly stampService: NotaryStampService,
    private readonly modalService: NgbModal,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly spinner: AppSpinnerModule,
    private readonly stateRulesService: StateRulesService,
    private readonly statesService: StatesService,
    private readonly toastr: ToastrService,
    private readonly dialog: AppDialogModule
  ) { }

  ngOnInit() {
    this.spinner.show();
    const stateCode = this.getStateCode();
    this.selectedState = this.getStateByStateCode(stateCode);
    this.setSelectedStateRule(stateCode);
    this.getAccountId();
  }

  getAccountId() {
    this.accountService.getCurrentAccount().subscribe((account) => {
      this.accountId = account.accountId;
    });
  }

  setSelectedCommission(commissionStateCode: string) {
    this.commissionService.getCommissions().subscribe((commissions) => {
      const activeCommission = commissions.find((commission) => commission.stateCode === commissionStateCode);
      if (activeCommission) {
        this.selectedCommission = of(activeCommission);
        this.commissionId = activeCommission.id;
        this.isDraftCommission = false;
        this.hasCommission = of(true);
        this.checkIfCommissionExpired();
        this.spinner.hide();
        return;
      }    

      this.commissionService.getDraftCommissions().subscribe((commissionInfos) => {
        const draftCommission = commissionInfos.commissions.find((commissionInfo) => commissionInfo.stateCode === commissionStateCode);
        this.spinner.hide();
        if (draftCommission) {
          this.stampService.retrieveStampFile(draftCommission.stampImageFilePathId).subscribe(
            (response) => {
              draftCommission.stamp = response.stamp.base64String;
            }     
          );
          if (draftCommission.accountSignatureId) {
            this.accountSignatureService.getSignatureById(draftCommission.accountSignatureId).subscribe(
              (response) => {
                draftCommission.signature = response.accountSignature?.signatureImageBase64 || null;
              }
            )
          }
          this.selectedCommission = of(draftCommission);
          this.checkIfCommissionExpired();
          this.commissionId = draftCommission.commissionInfoId;
          this.isDraftCommission = true;
          this.hasCommission = of(true);
        }
        if (!this.selectedCommission) {
          this.openCommissionModal("add");
        }
      });
    });
  }

  setSelectedStateRule(commissionStateCode: string) {
    this.stateRulesService.getRulesByStateCode(commissionStateCode).subscribe((response) => {
      if (response && response.stateRules) {
        this.selectedRule = of(response.stateRules);
        this.ruleLoaded = of(true);
        this.setSelectedCommission(commissionStateCode);        
      } else {
        this.toastr.error('Error loading state requirements, please contact Support');
        this.returnHome();
        this.spinner.hide();
      }      
    });
  }


  getStateByStateCode(stateCode: string): State {
    const commissionState = this.statesService.getStateByCode(stateCode);
    if (!commissionState) {
      throw new Error("State not found");
    }
    return commissionState;
  }

  getSanitizedImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpg;base64," + image
    );
  }

  async openCommissionModal(mode: "add" | "edit") {
    let commission;
    if (this.selectedCommission) {
      commission = await firstValueFrom(this.selectedCommission);
    }
    else {
      commission = new CommissionInfo();
      commission.commissionStateCode = this.selectedState.code;
    }
    
    const modalRef = this.modalService.open(CommissionFormModalComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.selectedRule = this.selectedRule;
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.commission = commission;
    modalRef.componentInstance.commissionState = this.selectedState;

    modalRef.result.then((result) => {
      if (result === "commissionAdded") {
        this.reloadCommissions();
      }
      else if (this.selectedCommission == null) {
        this.returnHome();
      }
    });
  }

  returnHome() {
    this.router.navigate(["/myaccount/manage-commissions/"]);
  }

  async openSignatureModal(mode: "add" | "edit") {
    const commission: Commission | CommissionInfo | undefined = await firstValueFrom(this.selectedCommission);
    const modalRef = this.modalService.open(AddSignatureComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.selectedRule = this.selectedRule;
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.commission = commission;

    modalRef.result.then((result) => {
      if (result === "signatureAdded") {
        this.reloadCommissions();
      }
    });
  }

  async openCertificateModal(mode: "add" | "edit") {
    const commission: Commission | CommissionInfo | undefined = await firstValueFrom(this.selectedCommission);
    const modalRef = this.modalService.open(AddDigitalCertificate, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.commission = commission;
    modalRef.componentInstance.selectedRule = this.selectedRule;

    modalRef.result.then((result) => {
      if (result === "certificateAdded") {
        this.reloadCommissions();
      }
    });
  }

  reloadCommissions() {
    this.spinner.show();
    this.setSelectedCommission(this.selectedState.code);
  }

  getStateCode(): string {
    const routeId = this.route.snapshot.paramMap.get('statecode');
    if (routeId === null || routeId === undefined) {
      return "";
    }
    return routeId;
  }

  deleteCommission() {
    this.dialog.show({
      title: "Delete Commission",
      message: `Are you sure you want to delete the ${this.selectedState.name} commission?`,
      noButtonText: "Cancel",
      yesButtonText: "Delete Commission",
    }).result.then((dialogResult) => {
      if (dialogResult === "yes") {
        if(!this.isDraftCommission) {
          this.selectedCommission.pipe(first(),
            switchMap(() => this.commissionService.deleteCommission(this.commissionId))).subscribe(
              () => {
                this.toastr.success('Commission Deleted');
                this.returnHome();
              },
              () => {
                this.toastr.error('Commission Deletion Failed');
              }
          );
        }
        else {
          this.selectedCommission.pipe(first(),
            switchMap(() => this.commissionService.deleteDraftCommission(this.commissionId))).subscribe(
              () => {
                this.toastr.success('Draft Commission Deleted');
                this.returnHome();
              },
              () => {
                this.toastr.error('Draft Commission Deletion Failed');
              }
          );
        }
      }
    });
  }

  checkIfCommissionExpired(): void {
    this.selectedCommission.pipe(first()).subscribe(commission => {
      this.isCommissionExpired = new Date(commission.expiresOn).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    });
  }
}
