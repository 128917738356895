import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleCodeValueService {

  constructor() { }

  public returnRoleCodeValue(roleCode): string{
    switch (roleCode){
      case "BORROWER":
        return "Borrower";
      case "SIGNINGAGENT":
        return "Signing Agent";
      case "SYSTEM":
        return "System";
      case "NBTH":
        return "Non-Borrowing Title Holder";
      case "SELLER":
        return "Seller";
      case "WITNESS":
        return "Witness";
      case "SIGNER":
        return "Signer";
      case "DATE":
        return "Date";
      case "SIGNINGAGENTCOUNTY":
        return "Signing Agent County";
      case "BORROWERIDDOCNUMBER":
        return "Borrower ID Doc Number";
      case "STATE":
        return "State";
      case "SIGNINGAGENTNAME":
        return "Signing Agent Name";
      case "SIGNINGAGENTCOMMEXPIRE":
        return "Commission Expiration Date";
      case "SIGNINGAGENTCOMMNUMBER":
        return "Commission Number";
      case "SIGNINGAGENTCOMMCOUNTY":
        return "Commission County";
      case "SIGNINGAGENTCOMMSTATE":
        return "Commission State";
      case "PRINTEDNAME":
        return "Printed Name";
      default:
        return "";
    }
  }
}
