import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { OrdersService } from "src/app/shared/services/orders.service";
import { TypeLookupService } from "src/app/shared/services/typelookup.service";
import { Router } from "@angular/router";
import { TypeLookup, TypeLookupList } from "src/app/shared/models/typelookup.models";
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn} from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { catchError, map, startWith, tap } from "rxjs/operators";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./cancel-order-modal.component.html",
  styleUrls: ["./cancel-order-modal.component.scss"],
})
export class CancelOrderModalComponent implements OnInit {
  @Input() orderId: number;
  @Input() isTypeCommentsOnly: boolean;
  public cancellationForm: UntypedFormGroup;
  optOutReasons: TypeLookup[];
  filteredOptOutReasons: Observable<TypeLookup[]>;
  reasonControl: AbstractControl<any, any>;
  fixedCategoryTypeCode: string;

  constructor(
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
    private readonly activeModal: NgbActiveModal,
    private readonly ordersService: OrdersService,
    private readonly typeLookupService: TypeLookupService,
    private readonly router: Router
  ) {
    this.reasonControl = new UntypedFormControl("", [
      Validators.maxLength(200),
      this.confirmValidCancellationReason(),
      Validators.required
    ]);

    this.cancellationForm = new UntypedFormGroup({
      reason: this.reasonControl,
      comments: new UntypedFormControl("", Validators.maxLength(200)),
    });

    this.fixedCategoryTypeCode = "commentsOnly";
    this.isTypeCommentsOnly = this.fixedCategoryTypeCode === "commentsOnly";
  }

  async ngOnInit(): Promise<void> {
    await this.getOptOutReasons();

    this.filteredOptOutReasons = this.reasonControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterOptOutReasons(value || ""))
    );
  }

  //Gets the values for the opt out dropdown
  async getOptOutReasons() {
    await this.typeLookupService
      .getOptOutReasons()
      .then((optOutReasons: TypeLookupList) => {
        this.optOutReasons = optOutReasons.typeLookups;
      });
  }

  private filterOptOutReasons(value: string): TypeLookup[] {
    return this.optOutReasons.filter((typeLookup: TypeLookup) =>
      typeLookup.value.toLowerCase().includes(value.toLowerCase())
    );
  }

  private confirmValidCancellationReason(): ValidatorFn {
    return (formControl: UntypedFormControl) => {
      if(!this.isTypeCommentsOnly){
        const validReason = new RegExp(`^${formControl.value.toLowerCase()}$`);
        const validReasons = this.optOutReasons
          ? [
              ...this.optOutReasons?.filter((option) =>
                option.typeLookupCode.toLowerCase().match(validReason)
              ),
              ...this.optOutReasons?.filter((option) =>
                option.value.toLowerCase().match(validReason)
              ),
            ]
          : [];      

        return validReasons.length > 0 ? null : { reason: true };
        }

        else {
          const commentReason:string = formControl.value;
          return commentReason.replace(/\s/g, '').length > 0 ? null : { reason: true };
        }
    };
  }
  optOutReasonsDisplay(optOutReasons: TypeLookup[]) {
    return (typeLookupCode: string): string =>
      optOutReasons?.filter((el) => el.typeLookupCode === typeLookupCode)[0]
        ?.value ?? "";
  }

  close(): void {
    this.activeModal.dismiss();
  }

  cancel() {
    if (!this.cancellationForm.valid) {
      return;
    }

    const reasonValue = this.isTypeCommentsOnly ? "commentsOnly" : this.cancellationForm.get("reason")?.value;
    const categoryTypeCode = this.optOutReasons.filter(
      (optOutReason) =>
        optOutReason.value.toLowerCase() === reasonValue.toLowerCase() ||
        optOutReason.typeLookupCode.toLowerCase() === reasonValue.toLowerCase()
    )[0]?.typeLookupCode;

    this.spinner.show();
    this.ordersService
    .cancelOrder(
      this.orderId,
      categoryTypeCode,
      (this.isTypeCommentsOnly ? this.cancellationForm.get("reason")?.value : this.cancellationForm.get("comments")?.value)
      )
      .pipe(
        tap(() => {
          this.activeModal.close();
          const toastMessage = "Order Canceled!";

          this.spinner.hide();

          this.toastr.success(toastMessage);

          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["/order-detail", this.orderId]));
        }),
        catchError(() => {
          this.activeModal.close();

          this.spinner.hide();

          const toastMessage = "Error: Order not Canceled!";
          this.toastr.error(toastMessage);
          return [];
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
