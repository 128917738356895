import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { filter, takeUntil } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";

import { MsalIdToken } from "./shared/models/account.models";
import { PendoService } from "./shared/services/pendo-adapter/services/pendo.service";
import { DOCUMENT } from "@angular/common";
import { ModalService } from "./shared/services/modal.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "ClearSign Portal";
  loggedIn: boolean;
  public userInfo: any = null;
  public isIframe: boolean;
  userToken: any;
  passwordResetTfp: string = "B2C_1_PasswordReset";
  angularloads: number = 0;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay: boolean = false;
  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private toastrService: ToastrService,
    private pendoService: PendoService,
    private readonly modalService: ModalService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    //This is to avoid reload during acquireTokenSilent() because of hidden iframe

    if (this.authService.instance.getAllAccounts().length > 0) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    const head = this._document.getElementsByTagName('head')[0];

    // Add the pendo snippet <script> tag to the document head
    if (environment.pendo.isEnabled) {
      // When the environment.envName has a value of 'root', it's the local environment, so replace the label
      // with 'local' for the purposes of pendo events
      head.appendChild(this.pendoService.getSnippet(environment.pendo.apiKey));
    }

    this.initPendoAnonymous();

    this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$))
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;

        // We need to reject id tokens that were not issued with the default sign-in policy.
        // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
        // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        if (payload.account) {
          this.loggedIn = true;
          this.initPendoUser();
        }

        if ((payload.idTokenClaims as MsalIdToken)?.tfp === this.passwordResetTfp) {
          this.toastrService.warning("Password has been reset successfully. \nPlease sign-in with your new password.");
          return this.logout();
        }

        return result;
      });

    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.instance.setActiveAccount(result.account);
          this.initPendoUser();
          window.location.reload();
        }
      },
      error: (error) => this.handleError(error),
    });
  }

  handleError(error) {
    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.message.includes("AADB2C90118")) {
      // login request with reset authority
      let resetPasswordFlowRequest = {
        scopes: [environment.apiScope],
        authority: environment.azureB2C.passwordResetPolicy,
      };

      this.login(resetPasswordFlowRequest);
    }
    // Check for user cancelled MFA
    else if (error.message.includes("AADB2C90091")) {
      // redirect user to login/sign up page
      this.logout();
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  initPendoUser() {
    if (environment.pendo.isEnabled) {
      this.pendoService.initializePendoAsUser();
    }
  }

  initPendoAnonymous() {
    if (environment.pendo.isEnabled) {
      this.pendoService.initializePendoAsAnonymous();
    }
  }
  get modalServiceInstance() {
    return this.modalService;
  }

  //allows authority to be reassigned to SignUp_SignIn after password reset
  logout() {
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
