<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">Participant Links</h5>
  </div>
  <div *ngIf="participant.participantUrl?.url" class="modal-body nex-form">
    <label for="signingLink">Copy link to signing session:</label>
    <div class="input-group mb-3">
      <input type="text"
             readonly="true"
             class="form-control"
             id="signingLink"
             [defaultValue]="participant.participantUrl?.url"
             #closingUrlInput />
      <div class="input-group-append">
        <button type="button"
                class="amr-btn-default copy-button input-group-text"
                [copy-to-clipboard]="participant.participantUrl?.url"
                (copied)="copySigningSessionLink()">
          <span>Copy Link</span>
        </button>
      </div>
    </div>
    <p *ngIf="isSigningSessionLinkCopied" class="nex-green-txt">
      The link has been copied!
    </p>
  </div>
  <div *ngIf="
      participant.participantUrl?.consumerPortalUrl
    "
       class="modal-body nex-form">
    <label for="consumerPoralLink">Copy link to consumer portal:</label>
    <div class="input-group mb-3">
      <input type="text"
             readonly="true"
             class="form-control"
             id="consumerPoralLink"
             [defaultValue]="participant.participantUrl?.consumerPortalUrl"
             #consumerPortalUrlInput />
      <div class="input-group-append">
        <button type="button"
                class="amr-btn-default copy-button input-group-text"
                [copy-to-clipboard]="participant.participantUrl?.consumerPortalUrl"
                (copied)="copyConsumerPortalLink()">
          <span>Copy Link</span>
        </button>
      </div>
    </div>
    <p *ngIf="isConsumerPortalLinkCopied" class="nex-green-txt">
      The link has been copied!
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="amr-btn-outline-primary close-button"
            (click)="closeModal()">
      Close
    </button>
  </div>
</ng-container>
