<ng-container>
    <div
            [showIfHasCapability]="[
      {
        capabilityString: 'CanAccessSupportWing',
        type: 'system'
      }
    ]"
        class="capabilities"
    >
        <div class="content85percent mt-3">
          <h1>Support: Manage Users</h1>
          <p>Use the tab to switch between managing client accounts and system accounts. Add users or use the search function to find specific users. Once located, you have the ability to manage them.</p>

            <app-tab-container
                    (tabChange)="onTabChange($event)"></app-tab-container>

            <div *ngIf="activeTab === 'system'" class="width75">
                <button type="button"
                        class="clrsgn-btn-default float-right"
                        (click)="openAddSystemUserModal()">
                   + Add System User
                </button>
            </div>

            <app-user-filter [activeTab]="activeTab"
                             [userList]="activeTab === 'system' ? systemUserAccounts : clientUserAccounts"
                             (filteredUserList)="onFilteredUserList($event)"></app-user-filter>
            <app-user-table-display [activeTab]="activeTab"
                                    [userList]="filteredUserList"
                                    (systemAccountDeleted)="onSystemAccountDeleted()"
                                    [userList]="filteredUserList"></app-user-table-display>
            <app-client-user-table-display [activeTab]="activeTab"></app-client-user-table-display>
        </div>
    </div>
</ng-container>
