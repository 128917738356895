<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">Complete Order</h5>
  </div>
  <div class="modal-body">
    <span>
      Are you sure you wish to complete this order? <br />
      This action will:
    </span>
    <ul>
      <li><span>Complete the order</span></li>
      <li><span>Incur Charges"</span></li>
      <li><span>Vault any eNotes</span></li>
      <li><span>Make the order inaccessible to participants</span></li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="amr-btn-outline-warning" (click)="close()">No</button>
    <button type="button" class="amr-btn-default" (click)="complete()">Yes, Complete Order</button>
  </div>
</ng-container>
