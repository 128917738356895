import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { DayLightSavingRule } from '../models/day-light-saving-rule.model';
import { ZipCodeInfo } from '../models/zipcode-info';
import moment from 'moment';
import { DayOfWeek } from '../enums/dayOfWeek';

@Injectable({
  providedIn: 'root'
})
export class UtcConversionsService extends BaseService {

  className = "UtcConversionsService";

  // NOTE: all US timezones have the same daylight saving rule.
  //  - Begin on the 2nd Sunday of the 3rd month at 2:00 AM.
  //  - End on the 1st Sunday of the 11th month at 2:00 AM.
  public readonly dayLightSavingStart = new DayLightSavingRule("03", "02");
  public readonly dayLightSavingEnd = new DayLightSavingRule("11", "01");
  public localDateTime: Date;
  public utcDateTime: Date;

  constructor(
    injector: Injector) {
    super(injector);
  }

  public async createUTCDateTimeBasedOnZip(zipInformation: ZipCodeInfo, date: string, time: string) {
    let scheduledMoment = moment(`${date}T${time}`, moment.ISO_8601);
    let standardOffset = zipInformation.standardUTCOffset;
    if (zipInformation.observesDaylightSavingTime) {
      let dayLightSavingOffset = this.getDayLightHourOffset(scheduledMoment);
      standardOffset = standardOffset + dayLightSavingOffset;
    }

    let standardUTCOffsetString = this.getStandardOffsetString(standardOffset);

    let scheduledMomentString = scheduledMoment.toISOString(true);
    let scheduledMomentWithoutOffset = scheduledMomentString.substring(0, scheduledMomentString.indexOf("."));
    let scheduledMomentWithUTCOffset = moment(`${scheduledMomentWithoutOffset}-${standardUTCOffsetString}:00`, moment.ISO_8601);

    return scheduledMomentWithUTCOffset.toISOString();
  }

  public async createLocalDateTimeBasedOnZip(zipInformation: ZipCodeInfo, dateTime: string) {
    let standardOffset = zipInformation.standardUTCOffset;
    let scheduledMoment = moment(`${dateTime}+${this.getStandardOffsetString(standardOffset)}:00`, moment.ISO_8601);

    let dayLightSavingOffset = 0;
    if (zipInformation.observesDaylightSavingTime) {
      dayLightSavingOffset = this.getDayLightHourOffset(scheduledMoment);
      scheduledMoment.add(dayLightSavingOffset, "hour");
    }

    return scheduledMoment.toISOString();
  }

  private getDayLightHourOffset(dateTime: moment.Moment): number {
    var daylightStartDate = this.getDaylightSavingDateTime(dateTime.year(), this.dayLightSavingStart);
    var daylightEndDate = this.getDaylightSavingDateTime(dateTime.year(), this.dayLightSavingEnd);
    return dateTime.isBetween(daylightStartDate, daylightEndDate) ? 1 : 0;
  }

  private getDaylightSavingDateTime(year: number, rule: DayLightSavingRule): moment.Moment {
    // start at the 1st day of the month at 2:00 AM, and then we'll find the Nth occurrence of Sunday.
    var value = moment(`${year}-${rule.month}-01`);
    var dayDiff = DayOfWeek.Sunday - value.day();
    if (dayDiff < 0) {
      dayDiff += 7;
    }
    dayDiff += 7 * (Number(rule.nthDayOfWeek) - 1);
    if (dayDiff > 0) {
      value.add(dayDiff, "days");
    }

    let utcDate = value.toISOString(false);
    let utcDateWithoutOffset = utcDate.substring(0, utcDate.indexOf('T'));
    return moment(`${utcDateWithoutOffset}T02:00`);
  }

  private getStandardOffsetString(standardUTCOffset: number): string {
    let standardUTCOffsetString = standardUTCOffset < -9
      ? (-1 * standardUTCOffset).toString()
      : `0${(-1 * standardUTCOffset)}`;

    return standardUTCOffsetString;
  }
}
