<footer>
  <div class="nex-footer text-center">
    <a class="consent-link" target="blank" href="https://www.amrock.com/terms-of-use ">Terms of Use</a>
    |
    <a class="consent-link" [routerLink]="['/eula']" routerLinkActive="router-link-active" >EULA</a>
    |
    <a class="consent-link" target="blank" href="https://www.amrock.com/privacy-policy">Privacy Policy</a>

    <br/>
    ©2000-{{this.currentYear}}. Amrock, All rights reserved.
  </div>
</footer>
