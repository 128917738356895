import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { catchError, tap } from "rxjs/operators";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { SystemAccountService } from "../../../shared/services/system-account.service";
import { SystemAccountByEmail } from "../../../shared/models/system-account-by.email";

@UntilDestroy()
@Component({
  selector: 'add-system-user-modal',
  templateUrl: './add-system-user-modal.component.html',
  styleUrls: ['./add-system-user-modal.component.scss']
})
export class AddSystemUserModalComponent implements OnInit {
  addSystemUserForm: UntypedFormGroup;
  addSystemUsersArray: UntypedFormArray;
  accountTypes = [
    { key: "ClearSignSupport", value: "Clear Sign Support" }
  ];

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly systemAccountService: SystemAccountService,
    private readonly fb: UntypedFormBuilder,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.addSystemUserForm = new UntypedFormGroup({
      systemUsers: this.fb.array([])
    });

    this.addSystemUsersArray = this.addSystemUserForm.get('systemUsers') as UntypedFormArray;
    this.addSystemUser();
  }

  addSystemUser() {
    const systemUserForm = this.fb.group({
      emailAddress: ['', [
        Validators.email,
        Validators.maxLength(200),
        Validators.required,
      ]],
      accountTypes: [[], Validators.required],
    });
    this.addSystemUsersArray.push(systemUserForm);
  }

  removeUserInvite(index: number) {
    this.addSystemUsersArray.removeAt(index);
  }

  close(): void {
    this.activeModal.close('no system account added');
  }

  addSystemAccounts() {
    if (this.addSystemUserForm.invalid) {
      this.markFormControlsAsDirty(this.addSystemUserForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      return;
    }

    this.spinner.show();

    for (const systemUserGroup of this.addSystemUsersArray.controls) {
      const systemAccountByEmailModel = this.createSystemAccountByEmailModel(systemUserGroup.value);
      this.systemAccountService.postNewSystemAccountByEmail(systemAccountByEmailModel)
        .pipe(
          tap(() => {
            this.activeModal.close('systemAccountAdded');
            const toastMessage = "System Account Added";

            this.spinner.hide();
            this.toastr.success(toastMessage);
          }),
          catchError(() => {
            this.spinner.hide();

            const toastMessage = "Failed to add System Account";
            this.toastr.error(toastMessage);
            return [];
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  private createSystemAccountByEmailModel(systemUserGroup): SystemAccountByEmail {
    const systemAccountByEmail: SystemAccountByEmail = {
      emailAddress: systemUserGroup.emailAddress,
      accountTypes: systemUserGroup.accountTypes
    };
    return systemAccountByEmail;
  }

  private markFormControlsAsDirty(formGroup: UntypedFormGroup | UntypedFormArray) {
    Object.values(formGroup.controls).forEach(control => {
        if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
          this.markFormControlsAsDirty(control);
        } else {
          control.markAsDirty();
        }
      });
  }
}
