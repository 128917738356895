import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { Participant, ParticipantModifiable, ParticipantVerificationPatch } from "../models/participant.model";
import { BaseService } from "./base.service";
import { shareReplay } from 'rxjs/operators';
import { ProductType } from "../enums/productType";
import { VerificationType, VERIFICATION_TYPES } from "../enums/verificationType";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class ParticipantsService extends BaseService {
  className = "ParticipantsService";

  constructor(injector: Injector) {
    super(injector);
  }

  public addParticipant(orderId: number, participant: ParticipantModifiable): Observable<ParticipantModifiable[]> {
    return this.post<ParticipantModifiable[]>(`/v1/orders/${orderId}/participants`, [participant]);
  }

  public getParticipants(orderId: number): Observable<Participant[]> {
    return this.get<Participant[]>(`/v1/orders/${orderId}/participants`, []).pipe(
      shareReplay()
    );
  }

  public updateParticipant(participantId: number, participant: ParticipantModifiable): Observable<ParticipantModifiable> {
    return this.patch<ParticipantModifiable>(`/v1/participants/${participantId}`, participant);
  }

  public deleteParticipant(participantId: number): Observable<object> {
    return this.delete(`/v1/participants/${participantId}`);
  }

  public patchParticipantVerification(
    participantId: number,
    patchedVerification: ParticipantVerificationPatch
  ): Observable<ParticipantVerificationPatch> {
    return this.patch(`/v1/participants/${participantId}/verification`, patchedVerification);
  }

  public getProductVerificationTypes(productType: ProductType | string): VerificationType[] {
    if (productType === ProductType.RemoteSigning) {
      if (environment.features.enableMultiFactorForRON === "true") {  //User Story 48869: Create an Azure Feature Flag for PreSign
            return VERIFICATION_TYPES;                                //Consider moving enableMultiFactorROn & isMultifactor to
      }                                                               //an Azure feature flag as well
      return VERIFICATION_TYPES.filter(vt => vt !== VerificationType.MultiFactor);
    }

    return VERIFICATION_TYPES;
  }
}
