import { Injectable, ElementRef } from "@angular/core";
import { sortBy } from "lodash-es";
import { ImageData } from "../../models/image-data.model";
import { DigitizationParameter } from "../../models/digitization-parameter.model";
import { EndorsementCompentInfo } from "../../models/endorsement-componenet-info.model";
import { EndorsementLocation as NewEndorsementLocation } from '../../../shared/models/endorsement.models';

@Injectable({
  providedIn: "root",
})
export class GenerateDocEndorsementLocationService {
  public endorsementLocations: any;

  public generatedLocations: any;

  generateLocationsForPng(
    imageOverlayDiv: ElementRef,
    imageData: ImageData,
    digitizationParameters: DigitizationParameter
  ): NewEndorsementLocation[] {
    const endorsements: NewEndorsementLocation[] = [];
    const components = imageOverlayDiv.nativeElement.children;
    const endorsementComponents = this.populateEndorsementComponents(
      components,
      imageData
    );
    const endorsementsSortedByPage = sortBy(
      endorsementComponents,
      "pageNumber"
    );
    for (const endorsementInformation of endorsementsSortedByPage) {
      const endorsement: NewEndorsementLocation = {
        documentId: digitizationParameters?.mySelectedDocument?.id ?? 0,
        status: "Signable",
        documentEndorsementLocationId: endorsementInformation.endoresementData.attributes.documentEndorsementLocationId?.value,
        participantId: endorsementInformation.endoresementData.attributes.participantId.value,
        endorsementType: endorsementInformation.endoresementData.attributes.endorsementtype.value,
        height: endorsementInformation.endoresementData.offsetHeight,
        isRequired: endorsementInformation.endoresementData.attributes.isEndorsementRequired.value === "true",
        pageNumber: this.fetchImagePageNumber(endorsementInformation.xyCoordinates.posY, imageData),
        participantRole: endorsementInformation.endoresementData.attributes.endorsementRole.value,
        signatureReference: "",
        xPosition: endorsementInformation.xyCoordinates.posX,
        yPosition: endorsementInformation.xyCoordinates.posY,
        width: endorsementInformation.endoresementData.offsetWidth,
        isProviderCalculated: true,
        format: endorsementInformation.endoresementData.attributes.format?.value ?? null
      };
      endorsements.push(endorsement);
    }
    return endorsements;
  }

  populateEndorsementComponents(components: any[], imageData: ImageData): any {
    const endorsementComponents: EndorsementCompentInfo[] = [];

    for (const component of components) {
      const endoresementComponent = component.children[0];
      const xyCoordinates = this.fetchXYCoordinatesForEndoresement(
        endoresementComponent
      );

      const endorsementComponentInfo = {
        pageNumber: this.fetchImagePageNumber(xyCoordinates.posY, imageData),
        endoresementData: endoresementComponent,
        xyCoordinates,
      };

      endorsementComponents.push(endorsementComponentInfo);
    }

    return endorsementComponents;
  }

  fetchXYCoordinatesForEndoresement(endoresement: any): any {
    const xyCoordinates = endoresement.style.transform
      .split("(")[1]
      .split(")")[0]
      .split(",");
    const posX = Number(xyCoordinates[0].replace("px", ""));
    const posY = Number(xyCoordinates[1].replace("px", ""));
    return { posX, posY };
  }

  fetchImagePageNumber(posY, imageData: ImageData): number {
    const posYAdj = posY * imageData.dpiConversionFactor;
    return Math.floor(posYAdj / imageData.pagePixelHeightLender) + 1;
  }

  fetchImageTotalPageNumber(imgData: ImageData): number {
    const imageLenderHeight = imgData.height * imgData.dpiConversionFactor;
    return Math.floor(imageLenderHeight / imgData.pagePixelHeightLender);
  }
}
