import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})

export class DateHelper {

  public convertDateTimeToLocalDateTime(dateTime: string): Date {
    const year = dateTime.substring(0, 4);
    const month = dateTime.substring(5, 7);
    const day = dateTime.substring(8, 10);
    const hours = dateTime.substring(11, 13);
    const mins = dateTime.substring(14, 16);
    const convertedDateTime = year + "-" + month + "-" + day + "T" + hours + ":" + mins;

    return new Date(convertedDateTime);
  }

  public getDateTimeStringWithoutOffset(dateTime: string): string {
    const year = dateTime.substring(0, 4);
    const month = dateTime.substring(5, 7);
    const day = dateTime.substring(8, 10);
    const hours = dateTime.substring(11, 13);
    const mins = dateTime.substring(14, 16);

    return year + "-" + month + "-" + day + "T" + hours + ":" + mins;
  }
}
