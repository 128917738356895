<ng-container>
  <ng-container *ngIf="(combinedOrganizers$ | async); else loading">
    <table class="table table-striped marginTopTwentyPx orderOrganizerListTable">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Role</th>
          <th scope="col">Email Address</th>
          <th scope="col" *ngIf="showActions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let orderOrganizer of (combinedOrganizers$ | async)" data-cy="orderOrganizerRecord">
          <td [attr.data-cy]="'organizer-' + orderOrganizer.id"
              class="order-organizer-cell">
            <div ngbTooltip="{{ orderOrganizer | participantDisplayNameTooltip }}">
              {{ orderOrganizer | participantDisplayName }}
            </div>
          </td>
          <td class="order-organizer-cell">{{getFriendlyRole(orderOrganizer)}}</td>
          <td class="order-organizer-cell">{{orderOrganizer.email}}</td>
          <td class="order-organizer-cell" *ngIf="showActions">
            <action-dropdown-button (actionCalled)="handleActionCall($event)" [actions]="orderOrganizer.actions"></action-dropdown-button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template #loading>
    <div class="jumbotron empty-jumbotron-message">
      <div class="d-flex justify-content-center align-items-center">
        <span class="jumbotron-heading">
          Loading Order Organizers
        </span>
        <span class="fas fa-spinner fa-spin nexsys-green-accent fa-2x ml-2"></span>
      </div>
      <app-skeleton-loading-list [rowCount]="2"
                                 [tableName]="'organizers'"></app-skeleton-loading-list>
    </div>
  </ng-template>
</ng-container>
