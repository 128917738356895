import { Injectable, Injector } from "@angular/core";
import {
  DocumentTemplate,
  NewDocumentTemplate,
} from "../../models/document-template.model";
import {
  DocumentTemplateEndorsement,
  NewDocumentTemplateEndorsement,
} from "../../models/document-template-endorsement.model";
import { Observable } from "rxjs";
import { BaseService } from "../../../shared/services/base.service";

@Injectable({
  providedIn: "root",
})
export class DocumentTemplateService extends BaseService {
  className = "DocumentTemplateService";

  constructor(injector: Injector) {
    super(injector);
  }

  getTemplates(): Observable<DocumentTemplate[]> {
    return this.getWithoutErrorHandling<DocumentTemplate[]>(`/v1/templates`);
  }

  getTemplateEndorsements(
    templateId: number
  ): Observable<DocumentTemplateEndorsement[]> {
    return this.get<DocumentTemplateEndorsement[]>(
      `/v1/templates/${templateId}/endorsements`
    );
  }

  saveDocumentTemplate(
    model: NewDocumentTemplate
  ): Observable<DocumentTemplate> {
    return this.post<NewDocumentTemplate>(`/v1/templates`, model);
  }

  saveDocumentTemplateEndorsements(
    templateId: number,
    model: Array<NewDocumentTemplateEndorsement>
  ): Observable<Array<DocumentTemplateEndorsement>> {
    return this.put<Array<NewDocumentTemplateEndorsement>>(
      `/v1/templates/${templateId}/endorsements`,
      model
    );
  }
}
