import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

import { DigiDocsStore } from "src/app/digitization-tool/+state/digi-docs.store";
import { Document } from "src/app/shared/models/document.models";
import { DocumentStatus } from "../../../shared/enums/documentStatus";

import { DocumentEditorListDropDownButtonComponent } from "../document-editor-list-dropdown-button/document-editor-list-dropdown-button";
import { getAvailableActions } from "../document-editor-utilities/get-available-actions";

@UntilDestroy()
@Component({
  standalone: true,
  selector: "app-document-editor-list",
  templateUrl: "./document-editor-list.component.html",
  styleUrls: ["./document-editor-list.component.scss"],
  imports: [
    CommonModule,
    DocumentEditorListDropDownButtonComponent,
    MatSortModule,
    MatTableModule,
    NgbModule,
  ],
})
export class DocumentEditorListComponent {
  getAvailableActions = getAvailableActions;
  dataSource: MatTableDataSource<Document>;
  displayedColumns: string[] = [
    "name",
    "digitized",
    "uploaded",
    "updated",
    "status",
    "preSign",
    "actions",
  ];

  @Output() displaySelectedDocumentId = new EventEmitter<number>();
  @Output() deleteByDocumentId = new EventEmitter<number>();
  @Output() downloadByDocumentId = new EventEmitter<{
    docId: number;
    docName: string;
  }>();
  
  documentList: Document[];
  documentStatuses = DocumentStatus;

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit()
  {
    this.initList();
  }

  handleDeleteByDocumentId(docId: number) {
    this.deleteByDocumentId.emit(docId);
  }

  handleDownloadByDocumentId(docId: number, docName: string) {
    this.downloadByDocumentId.emit({ docId, docName });
  }

  handleEditByDocumentId(
    documentId: number,
    isVoid: boolean = false,
    canEdit: boolean = true
  ) {
    if (!isVoid && canEdit) {
      this.displaySelectedDocumentId.emit(documentId);
    }
  }

  uppercaseFirstLetterOnly(word: string) {
    return word.replace(
      /\S*/g,
      (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
    );
  }

  constructor(private readonly componentStore: DigiDocsStore) {
    this.initList();
  }

  initList() {
    combineLatest([
      this.componentStore.myDocuments$,
      this.componentStore.voidedDocuments$,
      this.componentStore.showVoidedDocuments$,
    ])
      .pipe(
        tap(([myDocuments, voidedDocuments, showVoidedDocuments]) => {
          this.dataSource = new MatTableDataSource(
            showVoidedDocuments
              ? [...myDocuments, ...voidedDocuments]
              : myDocuments
          );

          this.documentList = myDocuments;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
          ): string | number =>
            sortHeaderId
              .split(".")
              .reduce(
                (accumulator, key) => accumulator && accumulator[key],
                data
              ) as string | number;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
