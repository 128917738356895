export class EndorsementBase {
  height?: number;
  isProviderCalculated?: boolean;
  isRequired?: boolean;
  pageNumber?: number;
  participantId: number;
  signatureReference?: string | null;
  type?: string;
  participantRole?: string;
  width?: number;
  xPosition?: number;
  yPosition: number;
  adjustedXPosition?: number;
  adjustedYPosition?: number;
  endorsementType: string;
  format?: string;

  constructor() {
    this.height = 0;
    this.isProviderCalculated = false;
    this.isRequired = false;
    this.pageNumber = 0;
    this.participantId = 0;
    this.signatureReference = "";
    this.type = "";
    this.participantRole = "";
    this.width = 0;
    this.xPosition = 0;
    this.yPosition = 0;
    this.adjustedXPosition = 0;
    this.adjustedYPosition = 0;
    this.format = "";
  }
}

export class Endorsement extends EndorsementBase {
  id?: number;
  documentId?: number;
  status?: string;

  constructor() {
    super();
    this.id = 0;
    this.documentId = 0;
    this.status = "";
  }
}

export class NewEndorsement extends EndorsementBase {
  documentEndorsementLocationId: number;
}

export class EndorsementLocation extends NewEndorsement {
  documentId: number;
  status: string;
}
