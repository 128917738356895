import { BaseService } from "./base.service";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { CertificateNew, CertificateResponse } from "../models/certificate.model";

@Injectable({
  providedIn: "root"
})
export class CertificateService extends BaseService {
  className = "CertificateService";

  constructor(injector: Injector) {
    super(injector);
  }

  addNewCertificate(model: CertificateNew): Observable<CertificateResponse> {
    return this.postAny<CertificateResponse>(`/v1/certificate`, model);
  }
}
