<div class="filter-users" *ngIf="activeTab === 'system'">
  <mat-form-field appearance="fill" class="clrsgn-form-field search-input">
    <mat-label class="clrsgn-label">Search Users</mat-label>
    <input matInput
      type="text"
           [(ngModel)]="filter"
           (input)="performFilter(filter, userList)"
           class="search-input" />
  </mat-form-field>
</div>
