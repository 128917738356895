<div class="add-certificate">
  <div class="modal-header">
    <div *ngIf="mode === 'edit'" class="title">Edit Digital Certificate</div>
    <div *ngIf="mode === 'add'" class="title">Add Digital Certificate</div>
  </div>
  <ng-container>
    <div class="modal-body">
      <form [formGroup]="uploadDigitalCertificate" class="nex-form">
        <ng-container>
          <div class="form-group">
            <mat-label for="certificate" class="clrsgn-label">Upload New Digital Certificate:</mat-label>
            <app-file-upload id="digitalCertificateFileUpload" formControlName="certificate" accept=".pfx" (change)="onFileSelected($event)"></app-file-upload>
            <app-form-error [group]="uploadDigitalCertificate"
                            [controlName]="'certificate'"
                            [customErrorMessage]="'Please select a .PFX file'"></app-form-error>
          </div>
          <mat-form-field appearance="fill" *ngIf="certificateUploaded | async">
            <mat-label for="password" class="clrsgn-label">
              Password:
            </mat-label>
            <input matInput
                   maxlength="128"
                   type="password"
                   formControlName="password"
                   id="name"
                   (keydown.enter)="saveDigitalCertificate()">
          </mat-form-field>
        </ng-container>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="amr-btn-outline-primary mr-3" (click)="close()">Cancel</button>
      <button type="button" class="amr-btn-default" (click)="saveDigitalCertificate()">Save</button>
    </div>
  </ng-container>
</div>
