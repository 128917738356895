import {DatePipe} from "@angular/common";
import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {saveAs} from "file-saver";
import {ToastrService} from "ngx-toastr";
import {
  faFilePdf,
  faLayerGroup,
  faArrowsAltV,
} from "@fortawesome/free-solid-svg-icons";
import {combineLatest, firstValueFrom, Observable} from "rxjs";
import {catchError, filter, finalize, map, take, tap} from "rxjs/operators";
import {DeviceDetectorService} from "ngx-device-detector";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

import {AppDialogModule} from "src/app/shared/components/app-dialog/app-dialog.component";
import {FormError} from 'src/app/shared/components/app-form-error/form-error';
import {AppSpinnerModule} from "src/app/shared/modules/app-spinner.module";
import {AccountService} from "src/app/shared/services/account.service";
import {CapabilitiesService} from "src/app/shared/services/capabilities/capabilities.service";
import {Client} from "src/app/shared/interfaces/client";
import {ClientAccountInfo} from "src/app/shared/models/client-account-info";
import {DateHelper} from "src/app/shared/helpers/date.helper";
import {Document} from "src/app/shared/models/document.models";
import {DocumentDisplayerService} from "src/app/shared/services/document-displayer.service";
import {DocumentService} from "src/app/shared/services/document.service";
import {environment} from "src/environments/environment";
import {Features} from "src/app/shared/enums/Features";
import {FeaturesService} from "src/app/shared/services/features.service";
import {FormValidators} from "src/app/shared/validators/form.validators";
import {NotarizationInformationService} from "src/app/shared/services/notarization-information.service";
import {Order} from "src/app/shared/models/order";
import {
  OrderPreSignStatus,
  OrderParticipantPresignStatus,
} from "src/app/shared/models/order-presign-status";
import {OrderOrganizerService} from 'src/app/shared/services/order-organizer.service';
import {OrderStatusDetail} from "src/app/shared/models/orderStatusDetail";
import {OrdersService} from "src/app/shared/services/orders.service";
import {ParticipantAddress} from "src/app/shared/models/participant-address";
import {
  ListViewParticipant,
  ParticipantModifiable,
} from "src/app/shared/models/participant.model";
import {ParticipantsService} from "src/app/shared/services/participants.service";
import {State} from "src/app/shared/services/state";
import {StatesService} from "src/app/shared/services/states.service";
import {UtcConversionsService} from "src/app/shared/services/utc-conversions.service";
import {VideoLink} from "src/app/shared/models/videolink.model";
import {VideoService} from "src/app/shared/services/video.service";
import {ZipCodeInfo} from "src/app/shared/models/zipcode-info";
import {ZipCodesService} from "src/app/shared/services/zipcodes.service";

import {CopyClosingUrlModalComponent} from "./components/copy-closing-url-modal/copy-closing-url-modal.component";
import {DownloadVideoModalComponent} from "./components/download-video-modal/download-video-modal.component";
import {OrderDetailHeaderComponent} from "./components/order-detail-header/order-detail-header.component";
import {OrderDetailStore} from "./+state/order-detail.store";
import {
  OrderOrganizerFormModalComponent
} from "./components/order-organizer-form-modal/order-organizer-form-modal.component";
import {ParticipantFormModalComponent} from "./components/participant-form-modal/participant-form-modal.component";
import {ResetLoginModalComponent} from "./components/reset-login/reset-login-modal.component";
import {RuleViolationModalComponent} from "./components/rule-violation-modal/rule-violation-modal.component";
import {OrderRuleAudit} from "../shared/models/order-rule-audit.model";

@UntilDestroy()
@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  @ViewChild("participantModal") participantModal: TemplateRef<any>; // Note: TemplateRef
  @ViewChild(OrderDetailHeaderComponent)
  orderDetailHeaderComponent: OrderDetailHeaderComponent;
  @ViewChild("browserNotSupportedModal")
  browserNotSupportedModal: TemplateRef<any>;

  faFilePdf = faFilePdf;
  faLayerGroup = faLayerGroup;
  faArrowsAltV = faArrowsAltV;

  public id: number;
  public loanInformation: UntypedFormGroup;
  public toggleLoanInformationFormEdit: boolean;
  public orderInfo: Order;
  public scheduledDate: string | Date;
  public displayDate: string | null;
  public displayTime: string | null;
  public updatedDate: any;
  public isOrderReadyOrInprogress: boolean;
  public isOrderReady: boolean;
  public isEditingSsn = false;
  public participantModifiable: ParticipantModifiable;
  public emailAddress: string;
  public confirmEmailAddress: string;
  public editingParticipantId: number;

  isCompleteCancelledOrOptout$: Observable<boolean>;
  isOrderLocked$: Observable<boolean>;
  orderStatus$: Observable<string>;
  orderIsPresign$: Observable<boolean>;
  order$: Observable<Order>;
  orderStatusDetails$: Observable<OrderStatusDetail[]>;

  clients: ClientAccountInfo[];
  ruleViolations: OrderRuleAudit;
  isSelectedFromClientAccounts = false;
  isRON: boolean;
  isIPEN: boolean;
  isKnownSignerRON: boolean;
  iseSign: boolean;
  isHybrid: boolean;
  isPreSign: boolean;
  isIEOrEdge: boolean;
  isAllowedBrowser: boolean;
  isOrderOrganizersEnabled$: Observable<boolean>;
  showVoidedDocs = false;
  isVideoDownloading = false;
  isVideoDownloadAvailable = false;

  readonly closingTypes = [
    {key: "Hybrid", value: "Hybrid"},
    {key: "IPEN", value: "In Person Electronic Notarization"},
    {key: "RemoteSigning", value: "Remote Online Notarization"},
  ];

  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  participants$: Observable<ListViewParticipant[]>;
  documents$: Observable<Document[]>;
  voidedDocuments: Array<Document> = [];
  voidedDocuments$: Observable<Document[]>;
  notarizationInformationDocuments: Array<Document> = [];
  downloadDocumentsUrl = "";
  joinSigningTooltipText = "";
  printDocumentsUrl = "";
  joinSigningUrl: string | null = null;
  downloadNotarizationInformationPdfUrl = "";
  downloadExecutedPackageUrl = "";
  clientsDropDownList: Client[] = [];
  isOrderCancelled = false;
  isOrderOptedOut = false;
  isOrderComplete = false;
  isOrderSessionComplete = false;
  isOrderOrganizersShown = false;
  sequenceNumbers: number[];
  states: State[];
  participantPresignStatuses: Record<number, OrderParticipantPresignStatus> = {};

  canCompleteOrder: boolean;
  documentsReadyToComplete: boolean;
  canJoinSigning: boolean;

  hasDigitizedDocuments: boolean;


  constructor(
    private readonly route: ActivatedRoute,
    private readonly ordersService: OrdersService,
    private readonly participantsService: ParticipantsService,
    private readonly modalService: NgbModal,
    private readonly datePipe: DatePipe,
    private readonly documentService: DocumentService,
    private readonly notarizationInformationService: NotarizationInformationService,
    private readonly accountService: AccountService,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
    private readonly dialog: AppDialogModule,
    private readonly router: Router,
    private readonly videoService: VideoService,
    private readonly dateHelper: DateHelper,
    private readonly utcConversionsService: UtcConversionsService,
    private readonly zipCodesService: ZipCodesService,
    private store: OrderDetailStore,
    private statesService: StatesService,
    private deviceService: DeviceDetectorService,
    private documentDisplayer: DocumentDisplayerService,
    private capabilitiesService: CapabilitiesService,
    private featuresService: FeaturesService,
    private orderOrganizerService: OrderOrganizerService
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  public minDate: {};

  ngOnInit() {
    window.scrollTo(0, 0);

    this.setupRedirectForCapabilitiesChange();
    this.states = this.statesService.all;
    this.sequenceNumbers = [...Array(10).keys()].map((x) => ++x);

    this.id = this.route.snapshot.params.id;
    this.capabilitiesService.getCapabilitiesByOrderId(this.id);
    this.order$ = this.setOrder(this.id);
    this.getClientIds();

    this.loadDocuments();
    this.loadNotarizationInformationDocuments();
    this.loadPreSignStatusForOrder();
    this.getRuleAudit();

    this.toggleLoanInformationFormEdit = false;
    this.initForm();
    this.isOrderLocked$ = this.store.isOrderLocked$;
    this.orderStatus$ = this.store.orderStatus$;
    this.isCompleteCancelledOrOptout$ = this.orderStatus$.pipe(
      map(
        (orderStatus) =>
          orderStatus === "COMPLETE" ||
          orderStatus === "CANCELLED" ||
          orderStatus === "OPTOUT"
      )
    );
    this.testIfIsAllowedBrowser();
    this.orderIsPresign$ = this.isOrderPreSign();

    this.orderStatusDetails$ = this.setOrderStatusDetails(this.id);

    this.orderStatusDetails$.pipe(untilDestroyed(this)).subscribe();

    this.orderOrganizerService
      .getOrderOrganizers(this.id)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.store.setOrganizers(result);
      });

    this.setFeatures();
    this.setOrderOrganizersEnabled();
    this.setVideoDownloadAvailability();
  }

  initForm() {
    this.loanInformation = new UntypedFormGroup(
      {
        clientId: new UntypedFormControl("", [
          Validators.required,
          FormValidators.isInteger(),
        ]),
        loanNumber: new UntypedFormControl("", [Validators.maxLength(100)]),
        partnerOrderIdentifier: new UntypedFormControl("", [Validators.maxLength(20)]),
        thirdPartyOrderIdentifier: new UntypedFormControl("", [
          Validators.maxLength(32),
        ]),
        propertyAddress1: new UntypedFormControl("", [Validators.maxLength(200)]),
        propertyAddress2: new UntypedFormControl("", Validators.maxLength(200)),
        streetAddress1: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(200),
        ]),
        streetAddress2: new UntypedFormControl("", Validators.maxLength(200)),
        city: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(200),
        ]),
        state: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(200),
        ]),
        zip: new UntypedFormControl("", [
          Validators.required,
          FormValidators.zipcode(),
        ]),
        closingDate: new UntypedFormControl("", [Validators.required]),
        closingTime: new UntypedFormControl("", [Validators.required]),
        closingType: new UntypedFormControl(""),
        packageId: new UntypedFormControl("", [Validators.maxLength(100)]),
        isPreSign: new UntypedFormControl(""),
      },
      {
        updateOn: "blur",
      }
    );
  }

  private getAbsoluteDomainUrl(): string {
    if (
      window &&
      "location" in window &&
      "protocol" in window.location &&
      "host" in window.location
    ) {
      return window.location.protocol + "//" + window.location.host;
    }
    return "";
  }

  private testIfIsAllowedBrowser(): void {
    const domainName = this.getAbsoluteDomainUrl().toLocaleLowerCase();
    this.isAllowedBrowser =
      domainName.includes("localhost") ||
      domainName.includes("portal-dev.npclearsign.amrock.com") ||
      domainName.includes("portal-test.npclearsign.amrock.com") ||
      (!this.isRON && !this.isKnownSignerRON) ||
      this.deviceService.browser === "Chrome" ||
      this.deviceService.browser === "MS-Edge-Chromium";

    this.joinSigningTooltipText = this.isAllowedBrowser
      ? ""
      : "Please use the latest Google Chrome or Microsoft Edge to join.";
  }

  displayPdf(url: string, documentName: string, statusToAvoid?: string) {
    if (
      url == null ||
      url.length < 1 ||
      (statusToAvoid != null &&
        this.orderInfo != null &&
        this.orderInfo.statusCode === statusToAvoid)
    ) {
      return;
    }
    this.documentDisplayer.displayDocument(
      url,
      "application/pdf",
      documentName
    );
  }

  isParticipantModifiableSigningAgent(): boolean {
    if (
      this.participantModifiable == null ||
      this.participantModifiable.role == null
    ) {
      return false;
    }
    return this.participantModifiable.role === "SIGNINGAGENT";
  }

  setOrderStatusDetails(orderId: number): Observable<OrderStatusDetail[]> {
    return this.ordersService.getOrderStatusDetails(orderId).pipe(
      tap(async (result) => {
        this.store.setOrderStatusDetails(result);
      })
    );
  }

  setOrder(orderId: number): Observable<Order> {
    return this.ordersService.getOrder(orderId).pipe(
      tap(async (result) => {
        this.store.setOrder(result);
        this.store.setOrderStatus(result.statusCode);
        this.orderInfo = result;
        this.isRON =
          (this.orderInfo.productType || "").toUpperCase() === "REMOTESIGNING";
        this.isIPEN =
          (this.orderInfo.productType || "").toUpperCase() === "IPEN";
        this.isKnownSignerRON =
          (this.orderInfo.productType || "").toUpperCase() === "KNOWNSIGNERRON";
        this.isHybrid =
          (this.orderInfo.productType || "").toUpperCase() === "HYBRID";
        this.isPreSign = this.orderInfo.isPreSign;
        this.isOrderReadyOrInprogress =
          this.orderInfo.statusCode === "INPROGRESS" ||
          this.orderInfo.statusCode === "READY"
            ? true
            : false;
        this.isOrderReady =
          this.orderInfo.statusCode === "READY" ? true : false;

        const zipInformation = await this.zipCodesService.getZipCodeInfo(
          result.propertyAddress.zipCode
        );
        const dateTimeString = this.dateHelper.getDateTimeStringWithoutOffset(
          result.scheduledDateTime.toString()
        );
        this.scheduledDate =
          await this.utcConversionsService.createLocalDateTimeBasedOnZip(
            zipInformation as ZipCodeInfo,
            dateTimeString
          );
        if (this.scheduledDate) {
          this.displayDate = this.getDisplayDate(this.scheduledDate);
          this.loanInformation.get("closingDate")?.patchValue(this.displayDate);
          const scheduledTime = this.dateHelper.convertDateTimeToLocalDateTime(
            this.scheduledDate
          );
          this.store.setScheduledTime(scheduledTime);
          this.displayTime = this.datePipe.transform(scheduledTime, "HH:mm:ss");
          this.loanInformation.get("closingTime")?.patchValue(this.displayTime);
        }

        this.isOrderCancelled =
          this.orderInfo.statusCode.indexOf("CANCELLED") > -1;
        this.isOrderOptedOut =
          this.orderInfo.statusCode.indexOf("OPTOUT") > -1;
        this.isOrderComplete =
          this.orderInfo.statusCode.indexOf("COMPLETE") > -1;
        this.isOrderSessionComplete =
          this.orderInfo.statusCode.indexOf("SESSIONCOMPLETE") > -1;
      })
    );
  }

  async updateOrder(orderId: number) {
    const updateOrder = this.orderInfo;
    const form = this.loanInformation.value;

    const datePart =
      form.closingDate.day < 10
        ? `0${form.closingDate.day}`
        : form.closingDate.day;
    const monthPart =
      form.closingDate.month < 10
        ? `0${form.closingDate.month}`
        : form.closingDate.month;
    this.scheduledDate = `${form.closingDate.year}-${monthPart}-${datePart}`;
    const zipInformation = await this.zipCodesService.getZipCodeInfo(
      updateOrder.propertyAddress.zipCode
    );
    let scheduledTime = form.closingTime;
    this.store.setScheduledTime(scheduledTime);
    if (this.scheduledDate && scheduledTime) {
      const utcScheduledDateTime =
        await this.utcConversionsService.createUTCDateTimeBasedOnZip(
          zipInformation as ZipCodeInfo,
          this.scheduledDate,
          scheduledTime.toString()
        );
      updateOrder.scheduledDateTime = utcScheduledDateTime;
    }

    this.ordersService.updateOrder(orderId, updateOrder).subscribe(
      async (result) => {
        this.orderInfo = result;
        const dateTimeString = this.dateHelper.getDateTimeStringWithoutOffset(
          result.scheduledDateTime.toString()
        );
        this.scheduledDate =
          await this.utcConversionsService.createLocalDateTimeBasedOnZip(
            zipInformation as ZipCodeInfo,
            dateTimeString
          );
        if (this.scheduledDate) {
          this.displayDate = this.getDisplayDate(this.scheduledDate);
          this.loanInformation.get("closingDate")?.patchValue(this.displayDate);
          scheduledTime = this.dateHelper.convertDateTimeToLocalDateTime(
            this.scheduledDate
          );
          this.store.setScheduledTime(scheduledTime);
          this.displayTime = this.datePipe.transform(scheduledTime, "HH:mm:ss");
          this.loanInformation.get("closingTime")?.patchValue(this.displayTime);
        }

        this.spinner.hide();
        this.toastr.success("Order has been updated.");
        this.toggleLoanInformationFormEdit = false;
      },
      (err) => {
        this.toastr.error("Failed to update order.");
        console.log("updateOrder:", err);
        this.spinner.hide();
      }
    );
  }

  private getDisplayDate(expiresOn: string): any {
    const year = Number(expiresOn.substring(0, 4));
    const month = Number(expiresOn.substring(5, 7));
    const day = Number(expiresOn.substring(8, 10));
    return {year, month, day};
  }

  hasRole(): boolean {
    return this.participantModifiable.role != null;
  }

  private loadClientAccounts() {
    this.spinner.show();
    const orderId = this.orderInfo?.id;
    if (orderId > 0) {
      this.accountService
        .getClientAccountsFromOrderId(orderId)
        .subscribe((clientAccountList) => {
          this.store.setClientAccountInfoList(clientAccountList);
          this.clients = clientAccountList.clients.filter(
            (client) => client.accountType.toUpperCase() === "SIGNINGAGENT"
          );

          this.store.setClientAccountInfoList(clientAccountList);
        })
        .add(this.spinner.hide());
    }
  }

  private loadParticipants() {
    this.participants$ = combineLatest([
      this.participantsService.getParticipants(this.id),
      this.ordersService.getURLs(this.id),
    ]).pipe(
      map(([responseParticipants, urls]) => {
        let participants: ListViewParticipant[] = [];
        if (responseParticipants && responseParticipants.length > 0) {
          participants = responseParticipants.map((p) => {
            if (p.participantAddress === null) {
              p.participantAddress = new ParticipantAddress();
            }

            if (urls !== null && urls.participantUrls !== null) {
              const filteredUrls = urls.participantUrls.filter((url) => {
                return parseInt(url.participantId, 10) === p.id;
              });

              if (
                filteredUrls.length > 0 && (filteredUrls[0].url !== null ||
                  filteredUrls[0].consumerPortalUrl !== null)
              ) {
                return new ListViewParticipant(p, filteredUrls[0]);
              }
            }
            return new ListViewParticipant(p, null);
          });
        }
        return participants;
      }),
      tap((participants) => {
        this.store.setParticipants(participants);
        this.loadClientAccounts();
        this.loadSigningUrl();
        this.capabilitiesService.getCapabilitiesByOrderId(this.id);
      })
    );
  }

  private loadPreSignStatusForOrder() {
    firstValueFrom(this.ordersService
      .getPreSignStatus(this.id))
      .then((response) => this.formatPreSignOrderStatus(response as OrderPreSignStatus))
      .catch((err) => console.error(err));
  }

  private formatPreSignOrderStatus(response: OrderPreSignStatus) {
    const statusesByParticipantId = {} as Record<
      number,
      OrderParticipantPresignStatus
    >;
    response.participantPreSignStatuses.forEach((presignStatus) => {
      const packageUserId = presignStatus.packageUserId;
      if (packageUserId !== undefined && !isNaN(packageUserId)) {
        statusesByParticipantId[packageUserId] = presignStatus;
      }
    });
    this.participantPresignStatuses = statusesByParticipantId;
  }

  addParticipantAction() {
    this.store.setIsEditing(false);
  }

  editParticipantAction(participant: ListViewParticipant) {
    this.store.setIsEditing(true);
    participant.participantModifiable = this.removeWhiteSpace(
      participant.participantModifiable
    );
    this.participantModifiable = participant.participantModifiable;
    this.emailAddress = participant.participantModifiable.email;
    this.confirmEmailAddress = participant.participantModifiable.email;
    this.editingParticipantId = participant.id;
    this.openParticipantModal(participant);
  }

  resetLoginAction(participant: ListViewParticipant) {
    this.store.setIsEditing(true);
    participant.participantModifiable = this.removeWhiteSpace(
      participant.participantModifiable
    );
    this.participantModifiable = participant.participantModifiable;
    this.openResetLoginModal(participant);
  }

  openAddOrderOrganizer(): void {
    const modalRef = this.modalService.open(OrderOrganizerFormModalComponent, {
      backdrop: "static",
      size: "lg",
    });

    modalRef.componentInstance.orderId = this.id;
  }

  openParticipantModal(participant: ListViewParticipant | null = null) {
    const modalRef = this.modalService.open(ParticipantFormModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    if (participant) {
      modalRef.componentInstance.selectedParticipant = participant;
    }
    modalRef.componentInstance.clients = this.clients;
    modalRef.componentInstance.sequenceNumbers = this.sequenceNumbers;
    modalRef.componentInstance.order = this.orderInfo;
    modalRef.componentInstance.participantPresignStatuses =
      this.participantPresignStatuses;
    modalRef.result.then(
      () => {
      },
      (result) => {
        if (result !== "cancel") {
          this.loadDocuments();
        }
      }
    );
    this.participants$
      .pipe(
        filter((participants) => !!participants),
        tap((participants) => {
          if (participants) {
            modalRef.componentInstance.participants = participants;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  openResetLoginModal(participant: ListViewParticipant | null = null) {
    const modalRef = this.modalService.open(ResetLoginModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    modalRef.componentInstance.productType = this.orderInfo.productType;
    if (participant) {
      modalRef.componentInstance.selectedParticipant = participant;
    }
    modalRef.result.then(
      () => {
      },
      (result) => {
        if (result !== "cancel") {
          this.loadDocuments();
        }
      }
    );
    this.participants$
      .pipe(
        filter((participants) => !!participants),
        tap((participants) => {
          if (participants) {
            modalRef.componentInstance.participants = participants;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  canEditAccessForParticipant(participantId: number): boolean {
    const participantPresignStatus =
      this.participantPresignStatuses[participantId];
    if (!participantPresignStatus) {
      return true;
    }

    if (
      participantPresignStatus.isPreSign &&
      (participantPresignStatus.isStarted ||
        participantPresignStatus.isComplete)
    ) {
      return true;
    }

    return false;
  }

  private removeWhiteSpace(
    participantModifiable: ParticipantModifiable
  ): ParticipantModifiable {
    if (participantModifiable) {
      for (const [key, value] of Object.entries(participantModifiable)) {
        if (
          typeof value === "string" &&
          value !== null &&
          value !== undefined
        ) {
          participantModifiable[key] = value.trim();
        }
      }
    }
    return participantModifiable;
  }

  private openGetClosingUrlModal(participant: ListViewParticipant) {
    const modal = this.modalService.open(CopyClosingUrlModalComponent, {
      backdrop: "static",
    });

    modal.componentInstance.participant = participant;
  }

  getClosingUrlAction(participant: ListViewParticipant) {
    this.openGetClosingUrlModal(participant);
  }

  removeParticipantAction(participant: ListViewParticipant) {
    this.openRemoveConfirmationModal(participant);
  }

  openRemoveConfirmationModal(participant: ListViewParticipant) {
    const name = `${participant.participantModifiable.firstName} ${participant.participantModifiable.lastName}`;
    const displayName = name.replace('<', '&lt;').replace('>', '&gt;').replace('</', '&lt;&sol;');
    this.dialog
      .show({
        title: "Delete Participant",
        message:
          `Remove ${displayName} from the order?<br/><br/><strong>Note:</strong> Removing a participant will also remove ` +
          "all endorsements from your documents. If you want the endorsements to remain intact, please edit the participant instead.",
        noButtonText: "Cancel",
        yesButtonText: "Delete Participant",
      })
      .result.then((response) => {
      if (response === "yes") {
        this.removeParticipant(participant);
      }
    });
  }

  private removeParticipant(participant: ListViewParticipant) {
    this.spinner.show();
    this.participantsService
      .deleteParticipant(participant.id)
      .subscribe(
        () => {
          this.loadDocuments();
          this.toastr.success("Participant removed.");
        },
        () => {
          this.toastr.error(`Unable to remove participant`);
        }
      )
      .add(() => this.spinner.hide());
  }

  shouldShowAddParticipantField(): boolean {
    return (
      this.participantModifiable.role !== null &&
      this.participantModifiable.role !== "null"
    );
  }

  shouldShowAddParticipantNonSigningAgentField(): boolean {
    return (
      this.shouldShowAddParticipantField() &&
      this.participantModifiable.role !== "SIGNINGAGENT"
    );
  }

  loadDocuments(): void {
    this.documentsReadyToComplete = false;

    this.documents$ = this.documentService.getListByOrderId(this.id).pipe(
      tap((docs) => {
        if (docs.length > 0) {
          this.downloadDocumentsUrl = `${environment.apiUri}/v1/documents/${this.id}/combine`;
        }

        if (docs.find((d) => d.isDigitized) !== undefined) {
          this.hasDigitizedDocuments = true;
        } else {
          this.hasDigitizedDocuments = false;
        }

        if (docs.find((p) => p.isPaper || false) !== undefined) {
          this.printDocumentsUrl = `${environment.apiUri}/v1/documents/${this.id}/combine/print`;
        }
        const doc = docs.find(
          (document) => document.type === "ExecutedClosingPackage"
        );

        if (doc !== undefined && doc !== null) {
          this.downloadExecutedPackageUrl = `${environment.apiUri}/v1/documents/${doc?.id}/downloadas/ExecutedClosingPackage-${this.id}`;
        }
        if (
          docs &&
          (docs.find((d) => d.status?.toUpperCase() === "SIGNED") ||
            docs.find((d) => d.status?.toUpperCase() === "PARTSIGNED"))
        ) {
          this.documentsReadyToComplete = true;
          this.showCompleteOrderButton();
        }

        this.updateCanJoinSigning();
      }),
      take(1),
      map((documents) => {
        const sortedDocuments = [
          ...documents.sort((a, b) => a.sequenceNumber - b.sequenceNumber),
        ];
        return sortedDocuments;
      }),
      tap(() => {
        this.capabilitiesService.getCapabilitiesByOrderId(this.id);
      })
    );
    this.documents$.subscribe();
    this.voidedDocuments$ = this.documentService
      .getVoidListByOrderId(this.id)
      .pipe(
        tap((docs) => {
          this.voidedDocuments = docs;
        })
      );
    this.voidedDocuments$.subscribe();
    this.loadParticipants();
  }

  showCompleteOrderButton(): void {
    this.canCompleteOrder = false;
    if (this.documentsReadyToComplete && this.isHybrid && this.isOrderSessionComplete) {
      this.canCompleteOrder = true;
    }
  }

  private loadNotarizationInformationDocuments(): void {
    this.spinner.show();
    this.notarizationInformationService
      .getListByOrderId(this.id)
      .subscribe((notarizationInformationDocs) => {
        this.notarizationInformationDocuments = notarizationInformationDocs;
        const doc = this.notarizationInformationDocuments.find(
          (_) => _.type === "NotarizationInformation"
        );
        if (doc !== undefined && doc !== null) {
          this.downloadNotarizationInformationPdfUrl = `${environment.apiUri}/v1/notarizationInformation/${doc.id}/downloadas/NotarizationInformation-${this.id}`;
        }
      })
      .add(() => this.spinner.hide());
  }

  private loadSigningUrl(): void {
    if (!this.isOrderReadyOrInprogress) {
      this.joinSigningUrl = "";
      this.updateCanJoinSigning();
      return;
    }

    this.ordersService
      .getURL(this.id)
      .pipe(
        tap((url) => {
          if (url) {
            this.joinSigningUrl = url.url ?? "";
          } else {
            this.joinSigningUrl = "";
          }
        }),
        catchError((err) => {
          this.joinSigningUrl = "";
          this.spinner.hide();
          if (err.status === 404) {
            return [];
          }
          throw err;
        }),
        finalize(() => {
          this.updateCanJoinSigning();
        })
      )
      .subscribe();
  }

  isOrderPreSign(): Observable<boolean> {
    return this.documents$.pipe(
      map((documents) => {
        if (!documents || documents.length === 0) {
          return false;
        }
        return documents.some((doc) => doc.isDocPreSign);
      }),
      untilDestroyed(this)
    );
  }

  onDownloadClosingVideoClick(): void {
    if (this.orderInfo != null && !(this.isRON || this.isKnownSignerRON) && (!this.isOrderComplete || !this.isOrderOptedOut || !this.isOrderCancelled)) {
      return;
    }

    this.spinner.show();
    this.videoService
      .getVideoLinks(this.id)
      .subscribe((links) => {
        if (
          links == null ||
          links.length === 0 ||
          (links[0].url || "").length === 0
        ) {
          this.toastr.warning("There is no video for this order.");
          return;
        }

        if (links.length > 1) {
          this.openDownloadVideosModal(links);
          return;
        }

        this.isVideoDownloading = true;
        this.videoService
          .downloadBlobVideo(links[0].url)
          .subscribe(blob => {
            if (blob['body']) {
              saveAs(blob['body'], "archive.mp4");
              this.isVideoDownloading = false;
            }
          })
      })
      .add(() => this.spinner.hide());
  }

  async setVideoDownloadAvailability() {
    this.order$.subscribe((order) => {
      if ((order.productType === "RemoteSigning" || order.productType === "KnownSignerRON") &&
        (order.statusCode === "COMPLETE" || order.statusCode === "OPTOUT" || order.statusCode === "CANCELLED")) {
        this.videoService
          .getVideoLinks(this.id)
          .subscribe((links) => {
            if (
              !(links == null ||
                links.length === 0 ||
                (links[0].url || "").length === 0)
            ) {
              this.isVideoDownloadAvailable = true;
            }
          });
      }
    });
  }

  openDownloadVideosModal(links: VideoLink[]) {
    const modal = this.modalService.open(DownloadVideoModalComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.videoLinks = links;
  }

  openSigningSession(url: string) {
    if (this.ruleViolations.documentRules.length > 0) {
      const modalRef = this.modalService.open(RuleViolationModalComponent, {
        backdrop: "static",
        size: "lg",
      });

      modalRef.componentInstance.ruleViolations = this.ruleViolations;
      modalRef.componentInstance.orderId = this.id;
      return;
    }
    if (url == null || url.length < 1) {
      return;
    }
    if (this.isAllowedBrowser) {
      window.open(url, "_blank");
    } else {
      this.modalService.open(this.browserNotSupportedModal, {size: "md"});
    }
  }

  openDigiDocs(): void {
    this.router.navigate(["/digi-docs/" + this.id]);
  }

  getClientIds(): void {
    this.accountService.getClientAccounts().subscribe((result) => {
      this.store.setClientAccounts(result);
      const orderDetailComponent = this;
      const clientMap = new Map();
      result.clients.forEach((client) => {
        if (!clientMap.has(client.clientId)) {
          clientMap.set(client.clientId, true);
          orderDetailComponent.clientsDropDownList.push({
            clientId: client.clientId,
            clientName: client.clientName,
          });
        }
      });
    });
  }

  setFeatures(): void {
    this.featuresService
      .getFeatures()
      .pipe(
        tap(async (result) => {
          this.store.setFeatures(result);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setOrderOrganizersEnabled(): void {
    this.isOrderOrganizersEnabled$ = this.featuresService.isFeatureEnabled(
      Features.OrderOrganizers
    );
  }

  onCancelOrder() {
    this.ngOnInit();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  // Listen for changes either to capabilities or client accounts
  // If the user loses all capabilities on the order and is not associated with a client account, redirect to the manage orders page
  // This handles an edge case where an Order Organizer could remove themselves from the order
  setupRedirectForCapabilitiesChange(): void {
    combineLatest([
      this.store.orderCapabilities$,
      this.store.documentCapabilities$,
      this.store.participantCapabilities$,
      this.store.organizerCapabilities$,
      this.store.clientAccounts$,
      this.store.systemCapabilities$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
           orderCapabilities,
           documentCapabilities,
           participantCapabilities,
           organizerCapabilities,
           clientAccounts,
           systemCapabilities,
         ]) => {
          const hasSystemCapabilities =
            systemCapabilities &&
            this.capabilitiesService.hasSomeCapabilities([systemCapabilities]);
          const hasOrderCapabilities =
            orderCapabilities &&
            this.capabilitiesService.hasSomeCapabilities([orderCapabilities]);
          const hasDocumentCapabilities =
            this.capabilitiesService.hasSomeCapabilities(documentCapabilities);
          const hasParticipantCapabilities =
            this.capabilitiesService.hasSomeCapabilities(
              participantCapabilities
            );
          const hasOrganizerCapabilities =
            this.capabilitiesService.hasSomeCapabilities(organizerCapabilities);

          const hasClientAccounts =
            clientAccounts?.clients && clientAccounts.clients.length > 0
              ? true
              : false;

          if (
            !hasSystemCapabilities &&
            !hasOrderCapabilities &&
            !hasDocumentCapabilities &&
            !hasParticipantCapabilities &&
            !hasOrganizerCapabilities &&
            !hasClientAccounts
          ) {
            this.router.navigate(["/manage-orders"]);
          }
        }
      );
  }

  toggleShowOrderOrganizers() {
    this.isOrderOrganizersShown = !this.isOrderOrganizersShown;
  }

  getRuleAudit() {
    this.ordersService
      .getRuleAudit(this.id)
      .pipe(
        tap((ruleViolations) => {
          this.ruleViolations = ruleViolations;
        })
      )
      .subscribe();
  }

  updateCanJoinSigning(): void {
    this.canJoinSigning = false;
    if (this.hasDigitizedDocuments && this.isOrderReadyOrInprogress && this.joinSigningUrl !== "") {
      this.canJoinSigning = true;
    }
  }

  ngOnDestroy(): void {
    this.closeModal();
  }
}
