import { Pipe, PipeTransform } from "@angular/core";

import { OrderOrganizerBase } from "../../models/order-organizer.model";
import { ParticipantBase } from "../../models/participant.model";

@Pipe({
  name: "participantDisplayName",
  standalone: true,
})
export class ParticipantDisplayNamePipe implements PipeTransform {
  transform(participant: ParticipantBase | OrderOrganizerBase): string {
    const firstName = participant?.firstName?.trim() ?? "";
    const lastName = participant?.lastName?.trim() ?? "";
    const fullName = `${firstName} ${lastName}`.trim();

    return fullName.length > 36 ? `${fullName.substring(0, 35)}...` : fullName;
  }
}
