import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from '../models/client.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: "root"
})
export class ClientService extends BaseService {
  className = "ClientService";
  constructor(injector: Injector) {
    super(injector);
  }

  getClientListByAccountType(accountTypes: string[]): Observable<Client[]> {
    return this.get<Client[]>(`/v1/clients/GetByAccount?accountTypes=${accountTypes}`);
  }
}
