<div *ngIf="documentList.length > 0">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="name"
          sortActionDescription="Sort by name"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            (click)="
              handleEditByDocumentId(
                element.id,
                element.isVoid,
                element.capabilities?.includes('CanEditDocument')
              )
            "
            class="document-name"
            [ngClass]="{
              'document-name--link pointer':
                !element.isVoid && element.capabilities?.includes('CanEditDocument')
            }"
            >{{ element.name }}</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="digitized">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="isDigitized"
          sortActionDescription="Sort by digitization"
        >
          Digitized
        </th>
        <td mat-cell *matCellDef="let element">
          <i *ngIf="element.isDigitized === true" class="fa-regular fa-circle-check digitized-true"></i>
          <i *ngIf="element.isDigitized === false" class="fa-regular fa-circle-xmark digitized-false"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="uploaded">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="createdDate"
          sortActionDescription="Sort by uploaded date"
        >
          Uploaded
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdDate | date: "MM/dd/YYYY hh:mm aa" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updated">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="updatedDate"
          sortActionDescription="Sort by update date"
        >
          Updated
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.updatedDate | date: "MM/dd/YYYY hh:mm aa" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="status"
          sortActionDescription="Sort by status"
        >
          Status
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'document-status--warning': element.isVoid
          }"
        >
          <ng-container [ngSwitch]="element.status">
            <span *ngSwitchCase="documentStatuses.NeedsAttention.toString()" class="document-status-needs-attention" ngbTooltip="{{ element.errorText }}" placement="bottom">
              <i class="fa-solid fa-triangle-exclamation"></i> {{ uppercaseFirstLetterOnly(element.status) }}
            </span>
            <span *ngSwitchCase="documentStatuses.Voided.toString()" class="document-status-voided">
              <i class="fa-regular fa-circle-xmark"></i> {{ uppercaseFirstLetterOnly(element.status) }}
            </span>
            <span *ngSwitchCase="documentStatuses.Ready.toString()" class="document-status-ready">
              <i class="fa-regular fa-circle-check"></i> {{ uppercaseFirstLetterOnly(element.status) }}
            </span>
            <span *ngSwitchDefault>
              {{ uppercaseFirstLetterOnly(element.status) }}
            </span>
          </ng-container>          
        </td>
      </ng-container>

      <ng-container matColumnDef="preSign">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="isDocPreSign"
          sortActionDescription="Sort by pre-sign"
        >
          Pre-Sign Eligible
        </th>
        <td mat-cell *matCellDef="let element" >
          <i *ngIf="element.isDocPreSign === true" class="fa-regular fa-circle-check presign-eligible"></i>
          <i *ngIf="element.isDocPreSign === false" class="fa-regular fa-circle-xmark presign-ineligible"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <document-editor-list-dropdown-button
            (editByDocumentId)="handleEditByDocumentId($event)"
            (deleteByDocumentId)="handleDeleteByDocumentId($event)"
            (downloadByDocumentId)="handleDownloadByDocumentId($event, element.name)"
            [actions]="getAvailableActions(element)"
            [docId]="element.id"
          ></document-editor-list-dropdown-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
