import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { AppSpinnerModule } from 'src/app/shared/modules/app-spinner.module';
import { FormError } from 'src/app/shared/components/app-form-error/form-error';
import { ListViewParticipant, ParticipantVerificationPatch } from 'src/app/shared/models/participant.model';
import { OrderDetailStore } from 'src/app/order-detail/+state/order-detail.store';
import { ParticipantsService } from 'src/app/shared/services/participants.service';
import { VerificationType } from 'src/app/shared/enums/verificationType';

@UntilDestroy()
@Component({
  selector: 'app-security-code-form',
  templateUrl: './security-code-form.component.html',
  styleUrls: ['./security-code-form.component.scss']
})
export class SecurityCodeFormComponent implements OnInit {
  securityCodeForm: UntypedFormGroup;
  participants$: Observable<ListViewParticipant[]>;

  @Input() verificationTypes: VerificationType[];
  @Input() selectedVerificationType: VerificationType;
  @Input() selectedParticipant: ListViewParticipant;

  @Output() onVerificationChange: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();

  constructor(
    public store: OrderDetailStore,
    private modalService: NgbModal,
    private spinner: AppSpinnerModule,
    private toastr: ToastrService,
    private participantsService: ParticipantsService
  ) { }

  ngOnInit(): void {
    this.participants$ = this.store.participants$;
    this.participants$.pipe(
      filter(participants => participants !== undefined),
      tap(() => {
        this.initForm();
        this.patchForm();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  isFieldInvalidDirty(field: string): boolean {
    return this.securityCodeForm.get(field)?.invalid &&
      (this.securityCodeForm.get(field)?.touched ||
        this.securityCodeForm.get(field)?.dirty)
      ? true
      : false;
  }

  initForm() {
    this.securityCodeForm = new UntypedFormGroup(
      {
        verificationType: new UntypedFormControl(VerificationType.Pin, {
          validators: [
            this.validateByOptions(
              this.verificationTypes,
              "validVerificationType"
            ),
          ],
          updateOn: "change",
        }),
        securityCode: new UntypedFormControl(null, [
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.required,
        ]),
      },
    );
  }

  patchForm() {
    this.securityCodeForm
      .get("verificationType")
      ?.setValue(this.selectedVerificationType, {
        onlySelf: true,
      });
  }

  resetModal(reason: "cancel" | "saved") {
    this.modalService.dismissAll(reason);
    this.store.setSelectedParticipantRole(null);
    this.store.setIsEditing(false);
    this.spinner.hide();
  }

  onParticipantSaveSuccess() {
    this.toastr.success('Participant saved successfully.');
    this.resetModal("saved");
  }

  onParticipantSaveError(error: any) {
    this.toastr.error(error.message);
  }

  onDropdownChange(event: Event){
    const spanElement = event.target as HTMLSpanElement;
    const verificationType = spanElement?.innerText.trim() as VerificationType;

    this.onVerificationChange.emit(verificationType);
  }

  validateByOptions(options: VerificationType[], errorKey: string = "required") {
    return (control: AbstractControl): ValidationErrors | null =>
      options?.some((element: VerificationType) => element === control.value)
        ? null
        : { [errorKey]: { value: control.value } };
  }

  onSecurityCodeSuccess() {
    this.toastr.success('Participant saved successfully.');
    this.resetModal("saved");
  }

  onSecurityCodeError(error: any) {
    this.toastr.error(error.message);
  }

  removeWhiteSpaceFromAllFields() {
    Object.keys(this.securityCodeForm.controls).forEach(key => {
      const currentValue = this.securityCodeForm.get(key)?.value;
      if (typeof currentValue === "string" && currentValue !== null && currentValue !== undefined) {
        const trimmedValue = currentValue.trim();
        this.securityCodeForm.get(key)?.setValue(trimmedValue);
      }
    });
  }

  filterToNumbers(value: string): string {
    if (!value) {
      return value;
    }

    return value.replace(/\D/g, "");
  }


  createPatchVerification(): ParticipantVerificationPatch{
    const formValue = this.securityCodeForm.getRawValue();

    let participantVerificationPatch: ParticipantVerificationPatch =  {
      phoneNumber:  this.selectedParticipant.participantModifiable.phoneNumber ?? "",
      verificationType: formValue.verificationType,
      pin: formValue.securityCode,
    }

    return participantVerificationPatch;
  }

  patchVerification() {
    if (this.securityCodeForm.valid) {
      this.spinner.show();
      this.removeWhiteSpaceFromAllFields();
      let patchedVerification = this.createPatchVerification();
      this.participantsService
        .patchParticipantVerification(this.selectedParticipant.id, patchedVerification)
        .subscribe(
          (_) => {
            this.onSecurityCodeSuccess();
            this.spinner.hide();
          },
          (error) => {
            this.onSecurityCodeError(error);
            this.spinner.hide();
          }
        );
    } else {
      FormError.ValidateAllFormFields(this.securityCodeForm);
    }
  }

}
