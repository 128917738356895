import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, take, tap } from "rxjs/operators";

import { OrderDetailStore } from "src/app/order-detail/+state/order-detail.store";
import { VerificationType } from "src/app/shared/enums/verificationType";
import { OrderParticipantPresignStatus } from "src/app/shared/models/order-presign-status";
import {
  ListViewParticipant,
  ParticipantModifiable,
} from "src/app/shared/models/participant.model";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { ParticipantsService } from "src/app/shared/services/participants.service";
import { State } from "src/app/shared/services/state";
import { StatesService } from "src/app/shared/services/states.service";
import { FormValidators } from "src/app/shared/validators/form.validators";

import { environment } from "../../../../../environments/environment";
import { FormError } from "src/app/shared/components/app-form-error/form-error";
import { FormName, UserRole } from "../participant-form-modal.models";
import { AccountType } from "src/app/shared/enums/accountTypes";

@UntilDestroy()
@Component({
  selector: "app-witness-information-form",
  templateUrl: "./witness-information-form.component.html",
  styleUrls: ["./witness-information-form.component.scss"],
})
export class WitnessInformationFormComponent implements OnInit {
  isEditing$: Observable<boolean>;
  selectedParticipantRole$: Observable<string | null>;
  participantRoleValueChanges$: Observable<any> | undefined;

  witnessInformationForm: UntypedFormGroup;
  isEditingSSN = false;
  states: State[];
  selectedStates: State[];
  participants$: Observable<ListViewParticipant[]>;
  customErrorMessage: string;

  @Input() userRoles: UserRole[];
  @Input() sequenceNumbers: number[];
  @Input() verificationTypes: VerificationType[];
  @Input() selectedParticipant: ListViewParticipant;
  @Input() orderId: number;
  @Input() participantPresignStatuses: Record<
    number,
    OrderParticipantPresignStatus
  >;

  constructor(
    private statesService: StatesService,
    private modalService: NgbModal,
    private spinner: AppSpinnerModule,
    public store: OrderDetailStore,
    private participantsService: ParticipantsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.participants$ = this.store.participants$;
    this.states = this.statesService.all;
    this.selectedStates = this.states;
    this.isEditing$ = this.store.isEditing$;
    combineLatest([this.participants$, this.isEditing$])
      .pipe(
        tap(([participants, isEditing]) => {
          this.initForm(participants, isEditing);
          this.togglePresignRelatedFields();
          this.watchAndToggleForm();
          this.watchAndUpdateSelectedParticipantRole();
          if (isEditing) {
            this.patchForm();
            this.determineEditAccessForParticipant();
          } else {
            this.editParticipantSsn();
            this.witnessInformationForm.updateValueAndValidity();
          }
        }),
        take(1)
      )
      .subscribe();
  }

  initForm(participants: ListViewParticipant[], isEditing: boolean) {
    this.witnessInformationForm = new UntypedFormGroup(
      {
        participantRole: new UntypedFormControl(null, {
          validators: [Validators.maxLength(200), Validators.required],
          updateOn: "change",
        }),
        partnerParticipantIdentifier: new UntypedFormControl(null, [
          Validators.maxLength(12),
        ]),
        sequenceNumber: new UntypedFormControl(null, {
          updateOn: "change",
        }),
        emailAddress: new UntypedFormControl(null, [
          Validators.maxLength(128),
          Validators.email,
          this.duplicateNonSAandSAEmailsValidator(participants),
        ]),
        confirmEmailAddress: new UntypedFormControl(null, [
          Validators.maxLength(128),
          Validators.email,
        ]),
        phoneNumber: new UntypedFormControl(null, [
          Validators.pattern(/^[2-9]\d{9}$/),
        ]),
        verificationType: new UntypedFormControl(null, {
          updateOn: "change",
        }),
        firstName: new UntypedFormControl(null, [Validators.maxLength(200)]),
        lastName: new UntypedFormControl(null, [Validators.maxLength(200)]),
        prefix: new UntypedFormControl(null, Validators.maxLength(200)),
        suffix: new UntypedFormControl(null, Validators.maxLength(200)),
        signatureName: new UntypedFormControl("", [Validators.maxLength(250)]),
        lastFourSsn: new UntypedFormControl(null, [Validators.maxLength(4)]),

        // Address stuff
        propertyAddress1: new UntypedFormControl(null, [
          Validators.maxLength(100),
        ]),
        propertyAddress2: new UntypedFormControl("", [
          Validators.maxLength(50),
        ]),
        city: new UntypedFormControl(null, [Validators.maxLength(50)]),
        propertyState: new UntypedFormControl(),
        zip: new UntypedFormControl(null, [
          Validators.maxLength(5),
          Validators.minLength(5),
        ]),
      },
      {
        updateOn: "blur",
        validators: [
          FormValidators.sequenceNumberValidator(
            participants,
            this.selectedParticipant?.id ?? 0,
            "sequenceNumber",
            isEditing
          ),
          FormValidators.confirmOptionalEmailAddressValidator(
            "emailAddress",
            "confirmEmailAddress"
          ),
          FormValidators.confirmOptionalValidSsn(this.isEditingSSN),
        ],
      }
    );
  }

  duplicateNonSAandSAEmailsValidator(
    participants: ListViewParticipant[]
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = { duplicateNonSAandSAEmailsValidator: true };
      const currentEmailValue: string = control?.value;
      const currentSigningAgent = participants?.find(
        (p) => p.participantModifiable.role === AccountType.SigningAgent
      );
      let duplicateEmailsExist: boolean = false;
      const currentEmailLower = currentEmailValue?.toLowerCase() ?? "";

      duplicateEmailsExist =
        currentSigningAgent?.participantModifiable?.email?.toLowerCase() ===
        currentEmailLower;

      if (duplicateEmailsExist) {
        this.customErrorMessage = "Email is being used by a Signing Agent";
        return error;
      }
      return null;
    };
  }

  patchForm() {
    this.witnessInformationForm
      .get("participantRole")
      ?.setValue(this.selectedParticipant?.participantModifiable?.role, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("partnerParticipantIdentifier")
      ?.setValue(
        this.selectedParticipant?.participantModifiable
          ?.partnerParticipantIdentifier,
        { onlySelf: true }
      );
    this.witnessInformationForm
      .get("emailAddress")
      ?.setValue(this.selectedParticipant?.participantModifiable?.email, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("confirmEmailAddress")
      ?.setValue(this.selectedParticipant?.participantModifiable?.email, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("phoneNumber")
      ?.setValue(this.selectedParticipant?.participantModifiable?.phoneNumber, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("verificationType")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.verificationType,
        {
          onlySelf: true,
        }
      );
    this.witnessInformationForm
      .get("firstName")
      ?.setValue(this.selectedParticipant?.participantModifiable?.firstName, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("lastName")
      ?.setValue(this.selectedParticipant?.participantModifiable?.lastName, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("prefix")
      ?.setValue(this.selectedParticipant?.participantModifiable?.prefix, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("suffix")
      ?.setValue(this.selectedParticipant?.participantModifiable?.suffix, {
        onlySelf: true,
      });
    this.witnessInformationForm
      .get("signatureName")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.signatureName,
        { onlySelf: true }
      );
    this.witnessInformationForm
      .get("propertyAddress1")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.streetAddress1,
        { onlySelf: true }
      );
    this.witnessInformationForm
      .get("propertyAddress2")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.streetAddress2,
        { onlySelf: true }
      );
    this.witnessInformationForm
      .get("city")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.city,
        { onlySelf: true }
      );
    this.witnessInformationForm
      .get("propertyState")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.stateCode,
        { onlySelf: true }
      );
    this.witnessInformationForm
      .get("zip")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.zipCode,
        { onlySelf: true }
      );
    this.witnessInformationForm
      .get("sequenceNumber")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.sequenceNumber,
        { onlySelf: true }
      );
  }

  togglePresignRelatedFields() {
    const participantId = this.selectedParticipant?.id ?? 0;
    this.canEditAccessForParticipant(participantId)
      ? this.enablePresignRelatedFields()
      : this.disablePresignRelatedFields();
  }

  canEditAccessForParticipant(participantId: number): boolean {
    const participantPresignStatus =
      this.participantPresignStatuses[participantId];
    if (!participantPresignStatus) {
      return true;
    }

    if (
      participantPresignStatus.isPreSign &&
      (participantPresignStatus.isStarted ||
        participantPresignStatus.isComplete)
    ) {
      return false;
    }

    return true;
  }

  watchAndToggleForm() {
    this.participantRoleValueChanges$ = this.witnessInformationForm
      .get("participantRole")
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        tap((value) => {
          if (value !== AccountType.Witness) {
            if (value === AccountType.SigningAgent) {
              this.store.setSelectedParticipantRole(value);
              this.store.setCurrentForm(FormName.SigningAgentInformation);
              return;
            }
            if (value === AccountType.NonBorrowingTitleHolder) {
              this.store.setSelectedParticipantRole(value);
              this.store.setCurrentForm(FormName.NonBorrowingTitleHolderInformation);
              return;
            }

            this.store.setSelectedParticipantRole(value);
            this.store.setCurrentForm(FormName.ParticipantInformation);
          }
        }),
        untilDestroyed(this)
      );

    this.participantRoleValueChanges$?.subscribe();
  }

  watchAndUpdateSelectedParticipantRole() {
    this.selectedParticipantRole$ = this.store.selectedParticipantRole$.pipe(
      tap((selectedParticipantRole) => {
        this.witnessInformationForm
          .get("participantRole")
          ?.setValue(selectedParticipantRole);
      }),
      untilDestroyed(this)
    );
    this.selectedParticipantRole$.subscribe();
  }

  isFieldInvalidDirty(field: string): boolean {
    return this.witnessInformationForm.get(field)?.invalid &&
      (this.witnessInformationForm.get(field)?.touched ||
        this.witnessInformationForm.get(field)?.dirty)
      ? true
      : false;
  }

  disablePresignRelatedFields() {
    this.witnessInformationForm.get("participantRole")?.disable();
    this.witnessInformationForm.get("sequenceNumber")?.disable();
    this.witnessInformationForm.get("signatureName")?.disable();
  }

  enablePresignRelatedFields() {
    this.witnessInformationForm.get("participantRole")?.enable();
    this.witnessInformationForm.get("sequenceNumber")?.enable();
    this.witnessInformationForm.get("signatureName")?.enable();
  }

  resetModal(reason: "cancel" | "saved") {
    this.modalService.dismissAll(reason);
    this.store.setSelectedParticipantRole(null);
    this.store.setIsEditing(false);
    this.spinner.hide();
  }

  addParticipant() {
    FormError.ValidateAllFormFields(this.witnessInformationForm);

    if (this.witnessInformationForm.valid) {
      this.spinner.show();
      this.removeWhiteSpaceFromAllFields();
      const participant = this.createParticipant();
      this.participantsService
        .addParticipant(this.orderId, participant)
        .subscribe(
          (_) => {
            this.onParticipantSaveSuccess();
            this.spinner.hide();
          },
          (error) => {
            this.onParticipantSaveError(error);
            this.spinner.hide();
          }
        );
    }
  }

  updateParticipant() {
    if (this.witnessInformationForm.valid) {
      this.spinner.show();
      this.removeWhiteSpaceFromAllFields();
      const participant = this.createParticipant();
      this.participantsService
        .updateParticipant(this.selectedParticipant.id, participant)
        .subscribe(
          (_) => {
            this.onParticipantSaveSuccess();
            this.spinner.hide();
          },
          (error) => {
            this.onParticipantSaveError(error);
            this.spinner.hide();
          }
        );
    } else {
      FormError.ValidateAllFormFields(this.witnessInformationForm);
    }
  }

  removeWhiteSpaceFromAllFields() {
    Object.keys(this.witnessInformationForm.controls).forEach((key) => {
      const currentValue = this.witnessInformationForm.get(key)?.value;
      if (
        typeof currentValue === "string" &&
        currentValue !== null &&
        currentValue !== undefined
      ) {
        const trimmedValue = currentValue.trim();
        this.witnessInformationForm.get(key)?.setValue(trimmedValue);
      }
    });
  }

  getParticipantAddressPayload(formValue) {
    const city = formValue.city;
    const stateCode = formValue.propertyState;
    const streetAddress1 = formValue.propertyAddress1;
    const streetAddress2 = formValue.propertyAddress2;
    const zipCode = formValue.zip;
    const zip4 = null;

    if (
      !city ||
      !stateCode ||
      !streetAddress1 ||
      !zipCode
    ) {
      return null;
    }

    return {
      city: city,
      stateCode: stateCode,
      streetAddress1: streetAddress1,
      streetAddress2: streetAddress2,
      zipCode: zipCode,
      zip4: zip4,
    };
  }

  createParticipant(): ParticipantModifiable {
    const formValue = this.witnessInformationForm.getRawValue();

    return {
      email: formValue.emailAddress,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phoneNumber: this.filterToNumbers(formValue.phoneNumber),
      verificationType: formValue.verificationType,
      partnerParticipantIdentifier: formValue.partnerParticipantIdentifier,
      prefix: formValue.prefix,
      role: formValue.participantRole,
      sequenceNumber: formValue.sequenceNumber,
      signatureName: formValue.signatureName,
      suffix: formValue.suffix,
      participantAddress: this.getParticipantAddressPayload(formValue),
      pin: formValue.lastFourSsn,
      ssn: formValue.lastFourSsn,
    };
  }

  onParticipantSaveSuccess() {
    this.toastr.success("Participant saved successfully.");
    this.resetModal("saved");
  }

  onParticipantSaveError(error: any) {
    this.toastr.error(error.message);
  }

  editParticipantSsn() {
    this.isEditingSSN = true;
    this.witnessInformationForm
      ?.get("lastFourSsn")
      ?.setValidators(FormValidators.ssnLastFourOptionalValidator());
  }

  filterToNumbers(value: string): string {
    if (!value) {
      return value;
    }

    return value.replace(/\D/g, "");
  }

  stateSearch(value: string): State[] {
    let filter = value.toLowerCase();
    return this.states.filter((option) =>
      option.name.toLowerCase().startsWith(filter)
    );
  }

  determineEditAccessForParticipant() {
    const participantPresignStatus =
      this.participantPresignStatuses[this.selectedParticipant.id];

    if (!participantPresignStatus) {
      return;
    }

    if (
      participantPresignStatus.isPreSign &&
      (participantPresignStatus.isStarted ||
        participantPresignStatus.isComplete)
    ) {
      this.disablePresignRelatedFields();
    }
  }

  isMultiFactorEnabled() {
    return (
      environment.features.isMultiFactorEnabled === "true" &&
      this.verificationTypes.includes(VerificationType.MultiFactor)
    );
  }
}
