<table id="systemAccountsSectionTable" class="users-table table table-striped table-responsive" aria-describedby="System User Accounts" *ngIf="activeTab === 'system'">
  <thead>
    <tr>
      <th scope="col" class="name-table-header sortable" style="width: 20vw" (click)="sortUserAccounts('firstName')">
        First Name
        <span *ngIf="sortProperty === 'firstName'">
          <span class="fas fa-arrow-down" *ngIf="!sortAscending"></span>
          <span class="fas fa-arrow-up" *ngIf="sortAscending"></span>
        </span>
      </th>
      <th scope="col" class="name-table-header sortable" style="width: 20vw" (click)="sortUserAccounts('lastName')">
        Last Name
        <span *ngIf="sortProperty === 'lastName'">
          <span class="fas fa-arrow-down" *ngIf="!sortAscending"></span>
          <span class="fas fa-arrow-up" *ngIf="sortAscending"></span>
        </span>
      </th>
      <th scope="col" class="name-table-header sortable" style="width: 30vw" (click)="sortUserAccounts('email')">
        Email Address
        <span *ngIf="sortProperty === 'email'">
          <span class="fas fa-arrow-down" *ngIf="!sortAscending"></span>
          <span class="fas fa-arrow-up" *ngIf="sortAscending"></span>
        </span>
      </th>
      <th scope="col" class="name-table-header" style="width: 20vw">
        System Account Type
      </th>
      <th scope="col" class="name-table-header" style="width: 10vw">
        Action
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let systemUserAccount of userList">
      <td>{{ systemUserAccount.firstName }}</td>
      <td>{{ systemUserAccount.lastName }}</td>
      <td>{{ systemUserAccount.email }}</td>
      <td *ngFor="let systemAccount of systemUserAccount.systemAccounts">
        {{ systemAccount.accountType }}
      </td>
      <td>
        <div *ngFor="let systemAccount of systemUserAccount.systemAccounts">
          <button type="button" class="clrsgn-btn-outline-default action-btn pt-2 reset-btn" (click)="deleteConfirmationDialog(systemAccount, systemUserAccount.firstName)">
            <i class="fas fa-trash-alt action-icon cursor-hand"></i> Delete
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>
