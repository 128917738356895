<div class="signature-preview-container">
  <div class="signature-preview-base">
    <div class="header">
      Signature Review:
    </div>
    <div class="content">
      Ensure that your signature appears legible within the various sized signature fields below. If it doesn't meet the required standards according to local regulations, you may need to redraw it and resubmit it to support.
    </div>
    <div class="content">
      <div class="signature-preview">
        <div class="preview-title col-1 right">Small: </div>
        <div class="small-preview"><img [src]="signatureImage" alt="Signature Image" class="signature-overlay-image-s"></div>
      </div>
      <div class="signature-preview">
        <div class="preview-title col-1 right">Medium: </div>
        <div class="medium-preview"><img [src]="signatureImage" alt="Signature Image" class="signature-overlay-image-m"></div>
      </div>
      <div class="signature-preview">
        <div class="preview-title col-1 right">Large: </div>
        <div class="large-preview"><img [src]="signatureImage" alt="Signature Image" class="signature-overlay-image-l"></div>
      </div>
      <div class="sig-line">
        <div class="col-1 sig-row"> </div>
        <div class="sig-col">
          <hr />
          <div class="subtext">Your Signature Preview</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="preview-title">Common issues to look out for</div>
      <ul>
        <li>Is it the right file?</li>
        <li>Does it look as it should?</li>
        <li>Is it readable?</li>
        <li>Does the file have too much space on the top and bottom, making it too small, like in the sample below?</li>
        <div class="sig-line sig-col">
          <div class="sig-row">
            <img src="assets/images/bad-signature.png" alt="Bad Signature" class="bad-signature-image">
            <img src="assets/images/cancel.png" alt="Bad signature error icon" class="error-icon" />
          </div>
          <hr/>
          <div class="subtext">Poor Signature Example</div>
        </div>
      </ul>

      <div class="preview-title">Need help or to resubmit a signature?</div>

      Contact Support at <a href="tel:8449131386">(844) 913-1386</a> or <a href="mailto:CreatMyStamp@amrock.com">CreateMyStamp&#64;Amrock.com</a>
    </div>
  </div>
</div>
