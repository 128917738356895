import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  take,
  tap
} from 'rxjs/operators';

import { AccountType } from "src/app/shared/enums/accountTypes";
import { AppSpinnerModule } from 'src/app/shared/modules/app-spinner.module';
import { ClientAccounts } from 'src/app/shared/models/account';
import { ClientAccountInfo } from 'src/app/shared/models/client-account-info';
import { FormError } from 'src/app/shared/components/app-form-error/form-error';
import { FormValidators } from "src/app/shared/validators/form.validators";
import { ListViewParticipant, ParticipantModifiable } from 'src/app/shared/models/participant.model';
import { Order } from 'src/app/shared/models/order';
import { OrderDetailStore } from 'src/app/order-detail/+state/order-detail.store';
import { ParticipantsService } from 'src/app/shared/services/participants.service';

import { FormName, UserRole } from '../participant-form-modal.models';
import { SystemAccountService } from '../../../../shared/services/system-account.service';

@UntilDestroy()
@Component({
  selector: 'app-signing-agent-information-form',
  templateUrl: './signing-agent-information-form.component.html',
  styleUrls: ['./signing-agent-information-form.component.scss']
})
export class SigningAgentInformationFormComponent implements OnInit {
  selectedParticipantRole$: Observable<string | null>;
  signingAgentRoleValueChanges$: Observable<any> | undefined;

  signingAgentInformationForm: UntypedFormGroup;
  participants$: Observable<ListViewParticipant[]>;

  order$: Observable<Order>;
  clientAccounts$: Observable<ClientAccounts | null>;

  isEditing$: Observable<boolean>;

  @Input() userRoles: UserRole[];
  @Input() sequenceNumbers: number[];
  @Input() clients: ClientAccountInfo[];
  @Input() selectedParticipant: ListViewParticipant;
  @Input() orderId: number;

  clientsSearch: any;

  customErrorMessage: string;
  isSelectedFromClientAccounts: boolean;
  isSettlementAgent = false;
  isSystemAccountUser = false;
  clientId: number;

  constructor(
    public store: OrderDetailStore,
    private modalService: NgbModal,
    private spinner: AppSpinnerModule,
    private toastr: ToastrService,
    private participantsService: ParticipantsService,
    private readonly systemAccountService: SystemAccountService,
  ) { }

  ngOnInit(): void {
    this.clientsSearch = this.clients.map((x) => {
      return {
        id: x.id,
        accountId: x.accountId,
        accountType: x.accountType,
        clientId: x.clientId,
        firstName: x.firstName,
        lastName: x.lastName,
        emailAddress: x.emailAddress,
        fullSearch: `${x.firstName} ${x.lastName} (${x.emailAddress})`
      }
    });

    this.participants$ = this.store.participants$;
    this.isEditing$ = this.store.isEditing$;
    combineLatest([
      this.participants$,
      this.isEditing$
    ]).pipe(
      filter(participants => participants !== undefined),
      tap(([participants, isEditing]) => {
        this.initForm(participants, isEditing);
        this.watchAndToggleForm();
        this.watchAndUpdateSelectedParticipantRole();
        if (isEditing) {
          this.patchForm();
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    //Checking if the user is a Settlement Agent for the current clientID or a system user
    //We only want Settlement Agents and system users to be able to see the Agent dropdown
    this.order$ = this.store.order$;
    this.order$.pipe(
      tap(currentOrder => {
        this.clientId = currentOrder.clientId;
      }),
      take(1)
    ).subscribe();

    this.clientAccounts$ = this.store.clientAccounts$;
    this.clientAccounts$.pipe(
      tap(currentClientAccounts => {
        if (currentClientAccounts &&
          currentClientAccounts.clients?.find(clientAccount => clientAccount.accountType.toUpperCase() === "SETTLEMENTAGENT" &&
            clientAccount.clientId === this.clientId)
        ) {
          this.isSettlementAgent = true;
        }
      }),
      take(1)
    ).subscribe();

    this.checkIfSystemAccountUser();
  }

  initForm(participants: ListViewParticipant[], isEditing: boolean) {
    this.signingAgentInformationForm = new UntypedFormGroup(
      {
        participantRole: new UntypedFormControl(null, {
          validators: [
            Validators.maxLength(200),
            Validators.required,
            FormValidators.multipleSigningAgentsValidator(participants, isEditing),
          ],
          updateOn: "change",
        }),
        existingClient: new UntypedFormControl(null),
        emailAddress: new UntypedFormControl("", [
          Validators.maxLength(128),
          Validators.email,
          Validators.required,
          this.duplicateNonSAandSAEmailsValidator(participants)
        ]),
        confirmEmailAddress: new UntypedFormControl("", [
          Validators.maxLength(128),
          Validators.email,
          Validators.required,
        ]),
        firstName: new UntypedFormControl("", [
          Validators.maxLength(200),
          Validators.required,
        ]),
        lastName: new UntypedFormControl("", [
          Validators.maxLength(200),
          Validators.required,
        ]),
      },
      {
        updateOn: "blur",
        validators: [FormValidators.confirmEmailAddressValidator('emailAddress', 'confirmEmailAddress')]
      }
    );
    const participantRole = 'participantRole';
    this.signingAgentInformationForm.controls[participantRole].updateValueAndValidity();
  }

  patchForm() {
    this.signingAgentInformationForm
      .get("participantRole")
      ?.setValue(this.selectedParticipant?.participantModifiable?.role, {
        onlySelf: true,
      });
    this.signingAgentInformationForm
      .get("emailAddress")
      ?.setValue(this.selectedParticipant?.participantModifiable?.email, {
        onlySelf: true,
      });
    this.signingAgentInformationForm
      .get("confirmEmailAddress")
      ?.setValue(this.selectedParticipant?.participantModifiable?.email, {
        onlySelf: true,
      });
    this.signingAgentInformationForm
      .get("firstName")
      ?.setValue(this.selectedParticipant?.participantModifiable?.firstName, {
        onlySelf: true,
      });
    this.signingAgentInformationForm
      .get("lastName")
      ?.setValue(this.selectedParticipant?.participantModifiable?.lastName, {
        onlySelf: true,
      });
  }

  checkIfSystemAccountUser() {
    this.systemAccountService.getCurrentSystemAccounts()
      .pipe(
        tap((systemAccounts) => {
          if (systemAccounts.length > 0) {
            this.isSystemAccountUser = true;
          }
        })
      ).subscribe();
  }

  duplicateNonSAandSAEmailsValidator(
    participants: ListViewParticipant[],
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = { duplicateNonSAandSAEmailsValidator: true };
      const currentEmailValue: string = control?.value;
      let duplicateEmailsExist: boolean = false;
      const currentEmailLower = currentEmailValue?.toLowerCase() ?? "";

      if (this.selectedParticipant?.participantModifiable?.role !== "SIGNINGAGENT") {
        for (const participant of participants) {
          if (participant?.participantModifiable?.email.toLowerCase() === currentEmailLower) {
            duplicateEmailsExist = true;
            this.customErrorMessage = `Email is being used by a ${this.getUserRoleString(participant?.participantModifiable?.role)}`;
            break;
          }
        }
      }

      if (duplicateEmailsExist) {
        return error;
      }
      return null;
    };
  }

  getUserRoleString(role: string): string | undefined {
    return this.userRoles.find(u => u.key.toLowerCase() === role.toLowerCase())?.value;
  }

  watchAndToggleForm() {
    this.signingAgentRoleValueChanges$ = this.signingAgentInformationForm.get('participantRole')?.valueChanges.pipe(
      distinctUntilChanged(),
      tap(value => {
        if (value !== AccountType.SigningAgent) {
          if (value === AccountType.NonBorrowingTitleHolder) {
            this.store.setSelectedParticipantRole(value);
            this.store.setCurrentForm(FormName.NonBorrowingTitleHolderInformation);
            return;
          }
          if (value === AccountType.Witness) {
            this.store.setSelectedParticipantRole(value);
            this.store.setCurrentForm(FormName.WitnessInformation);
            return;
          }

          this.store.setSelectedParticipantRole(value);
          this.store.setCurrentForm(FormName.ParticipantInformation);
        }
      }),
      untilDestroyed(this)
    );

    this.signingAgentRoleValueChanges$?.subscribe();
  }

  watchAndUpdateSelectedParticipantRole() {
    this.selectedParticipantRole$ = this.store.selectedParticipantRole$.pipe(
      tap(selectedParticipantRole => {
        this.signingAgentInformationForm.get('participantRole')?.setValue(selectedParticipantRole);
      }),
      untilDestroyed(this)
    );
    this.selectedParticipantRole$.subscribe();
  }

  onClientChanged(event: any) {
    if (event === "removeSelection") {
      this.ngOnInit();
    }
    else {
      const selectedAgentId = +event?.id;
      this.isSelectedFromClientAccounts = (selectedAgentId >= -1) ? true : false;
      const selectedAgent = this.clients.find(agent => +agent.id === selectedAgentId) ?? null;
      this.setAgentFields(selectedAgent);
    }
  }

  setAgentFields(selectedAgent: ClientAccountInfo | null) {
    this.signingAgentInformationForm?.get('firstName')?.setValue(selectedAgent?.firstName ?? null);
    this.signingAgentInformationForm?.get('lastName')?.setValue(selectedAgent?.lastName ?? null);
    this.signingAgentInformationForm?.get('emailAddress')?.setValue(selectedAgent?.emailAddress ?? null);
    this.signingAgentInformationForm?.get('confirmEmailAddress')?.setValue(selectedAgent?.emailAddress ?? null);
    selectedAgent !== null ? this.disableAgentFields() : this.enableAgentFields();
  }

  disableAgentFields() {
    this.signingAgentInformationForm?.get('firstName')?.disable();
    this.signingAgentInformationForm?.get('lastName')?.disable();
    this.signingAgentInformationForm?.get('emailAddress')?.disable();
    this.signingAgentInformationForm?.get('confirmEmailAddress')?.disable();
  }

  enableAgentFields() {
    this.signingAgentInformationForm?.get('firstName')?.enable();
    this.signingAgentInformationForm?.get('lastName')?.enable();
    this.signingAgentInformationForm?.get('emailAddress')?.enable();
    this.signingAgentInformationForm?.get('confirmEmailAddress')?.enable();
  }

  resetModal(reason: "cancel" | "saved") {
    this.modalService.dismissAll(reason);
    this.store.setSelectedParticipantRole(null);
    this.store.setIsEditing(false);
    this.spinner.hide();
  }

  addParticipant() {
    if (this.signingAgentInformationForm.valid) {
      this.spinner.show();
      this.removeWhiteSpaceFromAllFields();
      const participant = this.createParticipant();
      this.participantsService
        .addParticipant(this.orderId, participant)
        .subscribe(
          (_) => {
            this.onParticipantSaveSuccess();
            this.spinner.hide();
          },
          (error: Error) => {
            this.onParticipantSaveError(error);
            this.spinner.hide();
          }
        );
    } else {
      FormError.ValidateAllFormFields(this.signingAgentInformationForm);
    }
  }

  updateParticipant() {
    if (this.signingAgentInformationForm.valid) {
      this.spinner.show();
      this.removeWhiteSpaceFromAllFields();
      const participant = this.createParticipant();
      this.participantsService.updateParticipant(this.selectedParticipant.id, participant).subscribe(
        (_) => {
          this.onParticipantSaveSuccess();
          this.spinner.hide();
        },
        (error) => {
          this.onParticipantSaveError(error);
          this.spinner.hide();
        }
      );
    } else {
      FormError.ValidateAllFormFields(this.signingAgentInformationForm);
    }
  }

  removeWhiteSpaceFromAllFields() {
    Object.keys(this.signingAgentInformationForm.controls).forEach(key => {
      const currentValue = this.signingAgentInformationForm.get(key)?.value;
      if (typeof currentValue === "string" && currentValue !== null && currentValue !== undefined) {
        const trimmedValue = currentValue.trim();
        this.signingAgentInformationForm.get(key)?.setValue(trimmedValue);
      }
    });
  }

  createParticipant(): ParticipantModifiable {
    const formValue = this.signingAgentInformationForm.getRawValue();
    return {
      email: formValue.emailAddress,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phoneNumber: '',
      participantAddress: null,
      role: formValue.participantRole,
      sequenceNumber: 1
    };
  }

  onParticipantSaveSuccess() {
    this.toastr.success('Participant saved successfully.');
    this.resetModal("saved");
  }

  onParticipantSaveError(error: any) {
    this.toastr.error(error.error.error);
  }
}
