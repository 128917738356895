import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { NewDocument, Document } from "../models/document.models";
import { environment } from "../../../environments/environment";
import { HttpHeaders } from "@angular/common/http";
import { map, mergeMap } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { Mime } from 'mime';

import { PdfToPngService } from "src/app/digitization-tool/services/pdf-to-png/pdf-to-png.service";

@Injectable({
  providedIn: "root"
})
export class DocumentService extends BaseService {
  className = "DocumentService";

  pdfToPngService: PdfToPngService;

  constructor(injector: Injector, pdfToPngService: PdfToPngService ) {
    super(injector);

    this.pdfToPngService = pdfToPngService
  }

  getByDocumentId(documentId: number): Observable<Document> {
    return this.get<Document>(`/v1/documents/${documentId}`);
  }

  getPdfBlobByDocumentId(documentId: number): Observable<Blob> {
    const headers = new HttpHeaders().set("Accept", "application/pdf");
    return this.http.get(`${environment.apiUri}/v1/documents/${documentId}/download`,
      { headers, responseType: "blob" });
  }

  getBytesByDocumentId(documentId: number): Observable<string> {
    return this.getPdfBlobByDocumentId(documentId).pipe(
      mergeMap((blob) => blob.arrayBuffer()),
      mergeMap((arrayBuffer) =>
        this.pdfToPngService.getPngFromPdfjs(arrayBuffer, 1 + (1 / 3))
      )
    );
  }

  getPortalDocByDocumentId(documentId: number): Observable<Document> {
    return this.get<Document>(`/v1/documents/${documentId}/portal`);
  }

  getListByOrderId(orderId: number): Observable<Array<Document>> {
    return this.get<Array<Document>>(`/v1/orders/${orderId}/documents/portal`);
  }

  getVoidListByOrderId(orderId: number): Observable<Array<Document>> {
    return this.get<Array<Document>>(`/v1/orders/${orderId}/documents/portal/void`);
  }

  addDocument(orderId: number, model: NewDocument): Observable<NewDocument> {
    return this.post<NewDocument>(`/v1/orders/${orderId}/documents`, model);
  }

  patchDocument(documentId: number, model: Document): Observable<Document> {
    return this.patch<Document>(`/v1/documents/${documentId}`, model);
  }

  deleteDocument(documentId: number): Observable<object> {
    return this.delete(`/v1/documents/${documentId}`);
  }

  getDocument(url: string, format: string, documentName: string) {
  const mime = new Mime();
  return this.http.get(url, {
    responseType: "blob",
    observe: "response"
  })
    .pipe(
      map((res: any) => {
        var blob = new Blob([res.body], { type: format });
        const ext = mime.getExtension(format);

        if (ext && !documentName.endsWith('.' + ext)) {
          documentName += '.' + ext;
        }

        saveAs(blob, documentName);
        return blob;
      })
    );
}
}
