<div #endorsement
     [ngbTooltip]="tipContent"
     placement="bottom"
     [ngDraggable]="isEditable"
     [ngResizable]="isEditable"
     id="{{ id }}"
     [position]="dynamicEndorsementLocation"
     [attr.name]="name"
     [attr.participantId]="participantId"
     [attr.endorsementtype]="endorsementType.key"
     [attr.endorsementRole]="endorsementRole"
     [attr.isEndorsementRequired]="isRequired"
     [attr.documentEndorsementLocationId]="documentEndorsementLocationId"
     [attr.format]="format"
     [ngClass]="{
    'draggable-action-item': isEditable,
    'non-editable-action-item': !isEditable
  }"
     [ngStyle]="{
    width: endorsementControlDimensions.width,
    height: endorsementControlDimensions.height
  }">
  <ng-template #tipContent>
    <div [innerHtml]="toolTipText"></div>
  </ng-template>
  <button *ngIf="isEditable" class="btn remove-endorsement-button" (click)="removeMe(index)">
    <span class="fas fa-times"></span>
  </button>
</div>
