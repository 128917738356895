import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { ZipCodeInfo } from '../models/zipcode-info';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZipCodesService extends BaseService {
  className = "ZipCodesService";

  constructor(injector: Injector) {
    super(injector);
  }

  public getZipCodeInfo(zipCode: string): Promise<ZipCodeInfo | undefined> {
    return firstValueFrom(this.get<ZipCodeInfo>(`/v1/zipcodes/${zipCode}`));
  }

  public getZipCodeInfoObservable(zipCode: string): Observable<ZipCodeInfo> {
    return this.getWithoutErrorHandling<ZipCodeInfo>(`/v1/zipcodes/${zipCode}`);
  }
}
