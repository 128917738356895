export class ImageData {
  public documentName: string;
  public dpiActual: number;
  public dpiConversionFactor: number;
  public fileName: string;
  public height: number;
  public pagePixelHeightActual: number;
  public pagePixelHeightLender: number;
  public src: any;
  public width: number;

  constructor() {
    this.documentName = "";
    this.fileName = "";
    this.width = 0;
    this.height = 0;
    this.src = "";
    this.dpiActual = 0;
    this.pagePixelHeightLender = 0;
    this.pagePixelHeightActual = 0;
    this.dpiConversionFactor = 0;
  }
}
