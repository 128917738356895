import { Pipe, PipeTransform } from "@angular/core";

import { OrderOrganizerBase } from "../../models/order-organizer.model";
import { ParticipantBase } from "../../models/participant.model";

@Pipe({
  name: "participantDisplayNameTooltip",
})
export class ParticipantDisplayNameTooltipPipe implements PipeTransform {
  transform(participant: ParticipantBase | OrderOrganizerBase): string {
    const firstName = participant?.firstName ?? "";
    const lastName = participant?.lastName ?? "";
    const fullName = `${firstName} ${lastName}`.trim();

    return fullName.length > 36 ? fullName : "";
  }
}
