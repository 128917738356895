import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { NewDocumentAndEndorsements } from "../models/document-and-endorsements.models";

@Injectable({
  providedIn: "root"
})
export class DocumentAndEndorsementsService extends BaseService {
  className = "DocumentAndEndorsementsService";

  constructor(injector: Injector) {
    super(injector);
  }

  saveDocumentAndEndorsements(orderId: number, model: Array<NewDocumentAndEndorsements>): Observable<Array<NewDocumentAndEndorsements>> {
    return this.post<Array<NewDocumentAndEndorsements>>(`/v1/orders/${orderId}/documentsAndEndorsements`, model);
  }
}
