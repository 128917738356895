import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { catchError, tap } from "rxjs/operators";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { EmailInviteService } from "src/app/shared/services/email-invite.service";
import { EmailInviteNew } from "src/app/shared/models/email-invite.model";

@UntilDestroy()
@Component({
  selector: 'add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent implements OnInit {
  public clientId: number;
  public clientName: string;
  addUserForm: UntypedFormGroup;
  addUsersArray: UntypedFormArray;
  accountRoles: any[] = [
    { key: "CLIENTADMIN", value: "Client Admin" },
    { key: "ORDERMANAGER", value: "Order Manager" },
    { key: "SETTLEMENTAGENT", value: "Settlement Agent" },
    { key: "SIGNINGAGENT", value: "Signing Agent" }
  ]

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly emailService: EmailInviteService,
    private readonly fb: UntypedFormBuilder,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit() {
    this.addUserForm = new UntypedFormGroup({
      users: this.fb.array([])
    });

    this.addUsersArray = this.addUserForm.get('users') as UntypedFormArray;
    this.addUser();
  }

  addUser() {
    const userForm = this.fb.group({
      name: ['', Validators.maxLength(200)],
      targetEmailAddress: ['', [
        Validators.email,
        Validators.maxLength(200),
        Validators.required
      ]],
      roles: [[], Validators.required],
    });
    this.addUsersArray.push(userForm);
  }

  removeUserInvite(index: number) {
    this.addUsersArray.removeAt(index);
  }

  close(): void {
    this.activeModal.dismiss();
  }

  sendInvites() {
    if (this.addUserForm.invalid) {
      this.markFormControlsAsDirty(this.addUserForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      return;
    }

    this.spinner.show();

    for (const userGroup of this.addUsersArray.controls) {
      const emailInviteModel = this.createEmailInviteModel(userGroup.value);
      this.emailService.saveInvite(emailInviteModel)
        .pipe(
          tap(() => {
            this.activeModal.close();
            const toastMessage = "Invite Sent!"

            this.spinner.hide();

            this.toastr.success(toastMessage);

          }),
          catchError(() => {
            this.activeModal.close();

            this.spinner.hide();

            const toastMessage = "Error: Invite Not Sent";
            this.toastr.error(toastMessage);
            return [];
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  private createEmailInviteModel(userGroup): EmailInviteNew {
    const emailInviteNew: EmailInviteNew = {
      clientId: this.clientId,
      targetEmailAddress: userGroup.targetEmailAddress,
      roles: userGroup.roles,
      name: userGroup.name
    };
    return emailInviteNew;
  }

  private markFormControlsAsDirty(formGroup: UntypedFormGroup | UntypedFormArray) {
    Object.values(formGroup.controls).forEach(control => {
        if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
          this.markFormControlsAsDirty(control);
        } else {
          control.markAsDirty();
        }
      });
  }
}
