import { UntypedFormControl, UntypedFormGroup, UntypedFormArray } from "@angular/forms";

export class FormError {
  constructor(message: string | ((formControl: UntypedFormControl) => string), errorType: string) {

    if (typeof message === 'string') {
      this.message = message;
    } else {
      this.messageFunc = message;
    }

    this.errorType = errorType;
  }

  // Should be ordered by priority.
  public static readonly formErrors = [
    new FormError(
      "This field is required.",
      "required"
    ),
    new FormError(
      "Please select a valid date.",
      "ngbDate"
    ), new FormError(
      "Sequence number must be unique.",
      "existingSequenceNumber"
    ), new FormError(
      "Verification Type may only be set to one of the options provided.",
      "validVerificationType"
    ), new FormError(
      "Name may only contain letters, numbers, underscores, dashes, single quotes, or spaces.",
      "validDocumentName"
    ), new FormError(
      "All of the pages within the document must be the same size.",
      "isValidPageSize"
    ), new FormError(
      "The page size could not be determined.",
      "unknownPageSize"
    ), new FormError(
      "Action Type may only be set to one of the options provided.",
      "validActionType"
    ), new FormError(
      "Page Type may only be set to one of the options provided.",
      "validPageType"
    ), new FormError(
      "Please enter a valid email address.",
      "email"
    ), new FormError(
      "Email address doesn't match.",
      "emailNotMatching"
    ), new FormError(
      "Please enter a 5 digit zipcode",
      "zipcode"
    ),
      new FormError(
      "Please enter a valid zipcode",
      "zipAsyncValidator"
    ),
    new FormError(
      "Phone number doesn't match.",
      "phoneNotMatching"
    ),
    new FormError(
      "There is already a Signing Agent assigned to this order. Only one Signing Agent can be on an order.",
      "multipleSigningAgentsFound"
    ), new FormError(
      "Date cannot be in the past",
      "earlierThanToday"
    ), new FormError(
      "This sequence number has already been assigned.",
      "sequenceNumberTaken"
    ),
      new FormError(
        "SSN must be exactly 4 digits",
        "ssnValidation"
    ),
    new FormError(
      "Only .PDF files are allowed",
      "requiredFileType"
    ),
    FormError.getExactLengthFormError(),
  ];

  private message: string;
  private messageFunc: (formControl: UntypedFormControl) => string;
  private errorType: string;

  getMessage(formControl: UntypedFormControl): string {
    return this.message ?? this.messageFunc(formControl);
  }

  static isFieldInvalidDirty(group: UntypedFormGroup | UntypedFormArray, fieldName: string): boolean {
    const formControl = group.get(fieldName);

    return (formControl instanceof UntypedFormControl)
      && formControl.invalid
      && formControl.dirty;
  }

  static ValidateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
        control.updateValueAndValidity();
      } else if (control instanceof UntypedFormGroup) {
        this.ValidateAllFormFields(control);
      }
    });
  }

  private static getExactLengthFormError(): FormError {
    const minLength = 'minlength';

    return new FormError(
      (formControl: UntypedFormControl) => {
        if (formControl.errors == null || !formControl.hasError(minLength)) {
          return '';
        }

        const requiredLength = formControl.errors[minLength].requiredLength;
        const numText = this.getNumberText(requiredLength);
        const charText = requiredLength === 1 ? 'character' : 'characters';

        return `This field must have exactly ${numText} ${charText}.`;
      },
      minLength,
    );
  }

  private static getNumberText(num: number): string {
    const numMap = new Map([
      [0, 'zero'],
      [1, 'one'],
      [2, 'two'],
      [3, 'three'],
      [4, 'four'],
      [5, 'five'],
      [6, 'six'],
      [7, 'seven'],
      [8, 'eight'],
      [9, 'nine'],
    ]);

    const numText = numMap.get(num);

    return numText !== undefined ? numText : num.toString();
  }

  public isError(formControl: UntypedFormControl): boolean {
    return formControl.hasError(this.errorType);
  }
}
