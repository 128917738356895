import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { CountyLookupList } from '../models/countylookup.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountyLookupService extends BaseService {
  className = "CountyLookupService";

  constructor(injector: Injector) {
    super(injector);
  }

  getCountyList(stateCode: string): Observable<CountyLookupList> {
    return this.get<CountyLookupList>(`/v1/countyLookups/${stateCode}`);
  }
}
