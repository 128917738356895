import { OrderOrganizer } from "src/app/shared/models/order-organizer.model";
import { DropdownAction } from 'src/app/action-dropdown-button/dropdown-action';

export abstract class OrderOrganizerAction extends DropdownAction {
  organizer: OrderOrganizer;

  constructor(actionText: string, isDestructive: boolean, organizer: OrderOrganizer, action: any) {
    super(actionText, isDestructive, action);
    this.organizer = organizer;
  }
}
