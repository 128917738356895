export abstract class DropdownAction {
  actionText: string;
  isDestructive: boolean;
  action: (any) => void;

  constructor (actionText: string, isDestructive: boolean, action: (any) => void) {
    this.actionText = actionText;
    this.isDestructive = isDestructive;
    this.action = action;
  }
}
