import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { ToastrService } from "ngx-toastr";
import { AppSpinnerModule } from "../shared/modules/app-spinner.module";
import { TermsAndConditionsService } from "../shared/services/terms-and-conditions.service";

@Component({
  selector: "app-eula",
  templateUrl: "./eula.component.html",
  styleUrls: ["./eula.component.scss"]
})
export class EulaComponent implements OnInit {
  static readonly TypeCode = "EULATerms";

  eulaFileContent: string;
  hasContent: boolean;
  hasAcceptedEula: boolean;
  eulaReturnUrl: string;
  constructor(
    private readonly router: Router,
    private readonly authService: MsalService,
    private readonly toastr: ToastrService,
    private readonly spinner: AppSpinnerModule,
    private readonly termsAndConditionsService: TermsAndConditionsService
  ) { }

  ngOnInit() {
    this.eulaReturnUrl = sessionStorage.getItem("EULAReturnURL") ?? "/manage-orders";
    this.spinner.show();
    this.termsAndConditionsService.getContent(EulaComponent.TypeCode).subscribe(
      async (content) => {
        this.eulaFileContent = (content || "");
        this.hasContent = !!(this.eulaFileContent.length);
        this.hasAcceptedEula = await this.termsAndConditionsService.hasAcceptedAsync(EulaComponent.TypeCode);
        if (!this.hasAcceptedEula) {
          this.toastr.info("After reading the following terms and conditions, please acknowledge your acceptance by clicking the 'I ACCEPT' button at the bottom of this page.");
        }
      },
      (err) => {
        if (err.status === 404) {
          this.eulaFileContent = `Sorry, the '${EulaComponent.TypeCode}' type code is not configured in this environment.`;
        }
        console.error(err);
      }
    ).add(() => this.spinner.hide());
  }

  onAccept(): void {
    this.spinner.show();
    this.termsAndConditionsService.saveAccepted(EulaComponent.TypeCode, true).subscribe(
      () => {
        this.toastr.success("EULA accepted successfully.");
        this.hasAcceptedEula = true;
        this.router.navigate([this.eulaReturnUrl]);
      },
      (err) => console.error("onAccept:", err)
    ).add(() => this.spinner.hide());
  }

  onDecline(): void {
    this.spinner.show();
    this.termsAndConditionsService.saveAccepted(EulaComponent.TypeCode, false).subscribe(
      () => { this.authService.logout(); },
      (err) => console.error("onDecline:", err)
    ).add(() => this.spinner.hide());
  }
}
