import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientAccountInfo } from 'src/app/shared/models/client-account-info';
import { OrderParticipantPresignStatus } from 'src/app/shared/models/order-presign-status';
import { ListViewParticipant } from 'src/app/shared/models/participant.model';
import { OrderDetailStore } from '../../+state/order-detail.store';
import { UserRole } from './participant-form-modal.models';
import { VerificationType } from 'src/app/shared/enums/verificationType';
import { Order } from 'src/app/shared/models/order';
import { ParticipantsService } from 'src/app/shared/services/participants.service';
import { FormName } from './participant-form-modal.models';

@Component({
  selector: 'app-participant-form-modal',
  templateUrl: './participant-form-modal.component.html',
  styleUrls: ['./participant-form-modal.component.scss']
})
export class ParticipantFormModalComponent implements OnInit {
  userRoles: UserRole[] = [
    { key: "BORROWER", value: "Borrower" },
    { key: "NBTH", value: "Non-Borrowing Title Holder" },
    { key: "SELLER", value: "Seller" },
    { key: "SIGNINGAGENT", value: "Signing Agent" },
    { key: "WITNESS", value: "Witness" },
    { key: "SIGNER", value: "Signer" }
  ];
  verificationTypes: VerificationType[];
  @Input() sequenceNumbers: number[];
  @Input() clients: ClientAccountInfo[];
  @Input() selectedParticipant: ListViewParticipant;
  @Input() order: Order;
  @Input() participantPresignStatuses: Record<number, OrderParticipantPresignStatus>;

  isEditing$: Observable<boolean>;
  currentForm$: Observable<string>;
  FormName = FormName;
  isSelectedFromClientAccounts: boolean;

  constructor(
    public store: OrderDetailStore,
    public participantService: ParticipantsService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.isEditing$ = this.store.isEditing$;
    this.currentForm$ = this.store.currentFormName$;
    this.verificationTypes = this.participantService.getProductVerificationTypes(this.order?.productType);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
