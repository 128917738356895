import { Component, OnInit } from '@angular/core';
import { AppSpinnerModule } from '../../shared/modules/app-spinner.module';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormValidators } from '../../shared/validators/form.validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AccountSignatureService } from '../../shared/services/account-signature.service';
import { AccountSignatureModel } from '../../shared/models/account-signature.model';
import { AccountService } from '../../shared/services/account.service';
import { catchError, tap } from 'rxjs/operators';
import { FormError } from "../../shared/components/app-form-error/form-error";
import { CommissionInfo } from '../../shared/models/commission-info.model';

@Component({
  selector: 'app-add-account-signature',
  templateUrl: './add-account-signature.component.html',
  styleUrls: ['./add-account-signature.component.scss']
})
export class AddAccountSignatureComponent implements OnInit {
  uploadSignatureForm: UntypedFormGroup;
  selectedSignatureBase64: string;
  signatureUploaded: Observable<boolean>;
  hasSignature: boolean;
  currentAccountId: number;

  mode: "add" | "edit" = "add";

  selectedCommission: CommissionInfo;
  selectedFileName: string;

  constructor(
    private readonly accountService: AccountService,
    private readonly accountSignatureService: AccountSignatureService,
    private readonly spinner: AppSpinnerModule,
    private readonly sanitizer: DomSanitizer,
    private readonly activeModal: NgbActiveModal,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
    if (!this.currentAccountId) {
      this.getCurrentAccount();
    }    
  }

  initForm() {
    if (this.mode === "add") {
      this.uploadSignatureForm = new UntypedFormGroup(
        {
          signature: new UntypedFormControl(null, FormValidators.requiredFileType(["png"])),
          name: new UntypedFormControl(''),
        },
        { updateOn: "change" }
      );
    } else if (this.mode === "edit") {
      this.uploadSignatureForm = new UntypedFormGroup(
        {
          signature: new UntypedFormControl(this.selectedSignatureBase64, FormValidators.requiredFileType(["png"])),
          name: new UntypedFormControl(''),
        },
        { updateOn: "change" }
      );
    }
  }

  async onFileSelected(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    if (target.files === null || target.files.length === 0) {
      return;
    }
    const selectedSignatureFile = target.files[0];

    // validate file
    if (selectedSignatureFile.size > 21500) {
      this.toastr.warning(
        `The file '${selectedSignatureFile.name}' is too large. Please select a file 21KB or smaller.`,
        "Warning"
      );
      target.value = "";
      return;
    }

    this.spinner.show();

    await this.loadFileAsync(selectedSignatureFile);

    target.value = "";
    this.spinner.hide();
  }

  private async loadFileAsync(file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          this.selectedSignatureBase64 = this.convertFileToBase64(reader.result as ArrayBuffer);
          this.selectedFileName = file.name.substring(0, file.name.lastIndexOf('.')); //set the file name without the extension
          this.signatureUploaded = new Observable<boolean>((observer) => {
            observer.next(!this.uploadSignatureForm.get("signature")?.hasError("requiredFileType"));
            observer.complete();
          });
          resolve();
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private convertFileToBase64(fileData: ArrayBuffer): string {
    const bytes = new Uint8Array(fileData);
    let binary = '';
    for (const byte of bytes) {
      binary += String.fromCharCode(byte);
    }
    return window.btoa(binary);
  }

  getSanitizedImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpg;base64," + image
    );
  }

  async saveSignature() {
    this.spinner.show();
    FormError.ValidateAllFormFields(this.uploadSignatureForm);
    if (!this.uploadSignatureForm.valid) {
      this.markFormControlsAsDirty(this.uploadSignatureForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      this.spinner.hide();
      return;
    }
    const value = this.uploadSignatureForm.getRawValue();

    // get current date time to append to the signature name
    const now = new Date();
    const formattedDateTime = `${now.getFullYear()}_${(now.getMonth() + 1).toString()
      .padStart(2, '0')}_${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString()
        .padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}`;

    const signatureName = `${this.selectedFileName}-${formattedDateTime}`;

    const signatureModel: AccountSignatureModel = {
      name: value.name || signatureName,
      accountId: this.currentAccountId,
      signatureImageBase64: this.selectedSignatureBase64,
      accountSignatureId: 0,
      filePathId: 0
    }

    this.accountSignatureService.saveSignature(signatureModel)
      .pipe(
        tap(() => {
          this.activeModal.close('accountSignatureAdded');
          this.spinner.hide();
          this.toastr.success("Signature Added");
          this.uploadSignatureForm.reset();
        }),
        catchError(() => {
          this.spinner.hide();
          this.toastr.error("Failed to add Signature");
          return [];
        })
      )
      .subscribe();
  }

  close(): void {
    this.activeModal.close();
  }

  getCurrentAccount(): void {
    this.accountService.getCurrentAccount()
      .pipe(
        tap((account) => {
          this.currentAccountId = account.accountId;
        }),
      )
      .subscribe();
  }

  private markFormControlsAsDirty(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof UntypedFormGroup) {
        this.markFormControlsAsDirty(control);
      } else {
        control.markAsDirty();
      }
    });
  }
}
