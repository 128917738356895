import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FileUploadComponent,
    multi: true
  }]
})
export class FileUploadComponent implements ControlValueAccessor  {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  file: File | null = null;

  @Input() buttonText = "Choose File";
  @Input() dragDropText = "or drag and drop file here";

  @Input() accept: string;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;
  }

  constructor( private host: ElementRef<HTMLInputElement> ) {
  }

  writeValue() {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched() {
  }
}
