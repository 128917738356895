<div class="modal-header">
  <div class="modal-title-container">
    <h5 class="modal-title" id="resetLoginModalLabel">Reset Login</h5>
    <h5 class="modal-title">{{selectedParticipant.participantModifiable.firstName}} {{selectedParticipant.participantModifiable.lastName}}</h5>
  </div>
</div>
  <app-multifactor-code-form
    *ngIf="currentVerificationType === VerificationType.MultiFactor"
    [selectedParticipant]="selectedParticipant"
    [verificationTypes]="verificationTypes"
    [selectedVerificationType]="currentVerificationType"
    (onVerificationChange)="setVerificationType($event)"
    ></app-multifactor-code-form>

  <app-security-code-form
    *ngIf="currentVerificationType === VerificationType.Pin"
    [selectedParticipant]="selectedParticipant"
    [verificationTypes]="verificationTypes"
    [selectedVerificationType]="currentVerificationType"
    (onVerificationChange)="setVerificationType($event)"
    ></app-security-code-form>
