import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'closingTypeValue'
})
export class ClosingTypeValuePipe implements PipeTransform {

  transform(value: any): string {
    switch (value){
      case "RemoteSigning":
        return "Remote Online Notarization";
      case "IPEN":
        return "In Person Notarization";
      case "eSign":
        return "eSign";
      case "KnownSignerRON":
        return "Known Signer RON";
      default:
        return "Hybrid";
    }
  }

}
