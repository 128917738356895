import { Component, Input, OnInit } from '@angular/core';

export type SkeletonRowName = "participants" | "documents" | "organizers";
@Component({
  selector: 'app-skeleton-loading-list',
  templateUrl: './skeleton-loading-list.component.html',
  styleUrls: ['./skeleton-loading-list.component.scss']
})
export class SkeletonLoadingListComponent implements OnInit {
  @Input() rowCount: number;
  @Input() tableName: SkeletonRowName;
  rows: number[];
  constructor() { }

  ngOnInit(): void {
    this.rows = [...Array(this.rowCount).keys()].map((x) => ++x);
  }

}
