import { Injectable, Injector } from "@angular/core";
import { BaseService } from "./base.service";
import { TypeLookupList } from "../models/typelookup.models";

@Injectable({
  providedIn: "root"
})
export class TypeLookupService extends BaseService {
  className = "TypeLookupService";

  constructor(injector: Injector) {
    super(injector);
  }

  async getDocumentTypesAsync(): Promise<TypeLookupList> {
    return this.getListAsync("PackageDocument", "DocumentTypeCode");
  }

  async getOptOutReasons(): Promise<TypeLookupList> {
    return this.getListAsync("PackageStatusDetail", "CategoryTypeCode");
  }

  async getListAsync(tableName: string, columnName: string): Promise<TypeLookupList> {
    const self = this;

    return new Promise<TypeLookupList>((resolve, reject) => {
      self.get<TypeLookupList>(`/v1/typelookups/${tableName}/${columnName}`)
        .subscribe(
          (typeLookupList) => {
            // sort by the "value" prop
            const typeLookups = typeLookupList.typeLookups.sort((a, b) =>
              a.value.toLowerCase() > b.value.toLowerCase() ? 1
                : (b.value.toLowerCase() > a.value.toLowerCase() ? -1 : 0));
            typeLookupList.typeLookups = typeLookups;
            resolve(typeLookupList);
          },
          (err) => {
            console.error("getListAsync:", err);
            reject(err);
          });
    });
  }
}
