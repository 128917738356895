<ng-container>
  <div class="modal-header">
    <h5 class="modal-title" id="endorsementLocationsModelLabel">Document Upload</h5>
  </div>
  <div class="modal-body scroll-modal-body">
    <ng-container [ngSwitch]="(documentUploadResult$ | async)">
      <div *ngSwitchCase="'failure'">Sorry, something went wrong. Please try saving again.</div>
      <div *ngSwitchDefault>Document and endorsements are being added to your order.</div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="amr-btn-outline-primary" (click)="closeModal()">Close</button>
  </div>
</ng-container>
