import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { OrderStatusDetail } from 'src/app/shared/models/orderStatusDetail';
import { OrderDetailStore } from '../../+state/order-detail.store';

@Component({
  selector: 'app-cancellation-comments-modal',
  templateUrl: './cancellation-comments-modal.component.html',
  styleUrls: ['./cancellation-comments-modal.component.scss']
})
export class CancellationCommentsModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal,
              private orderDetailStore: OrderDetailStore,
              ) { }

  orderStatusDetails$: Observable<OrderStatusDetail[]>
  orderStatus$: Observable<string>;

  ngOnInit(): void {
    this.orderStatus$ = this.orderDetailStore.orderStatus$;
    this.orderStatusDetails$ = this.orderDetailStore.orderStatusDetails$;
  }

  closeModal() {
    this.activeModal.close();
  }
}
