import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-tab-container',
  templateUrl: './tab-container.component.html',
  styleUrls: ['./tab-container.component.scss']
})
export class TabContainerComponent {
  @Output() tabChange = new EventEmitter<'system' | 'client'>();
  activeTab = 'client';

  changeTab(tab: 'system' | 'client') {
    this.tabChange.emit(tab);
    this.activeTab = tab;
  }
}
