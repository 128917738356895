import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FormError } from '../../../../../shared/components/app-form-error/form-error';
import { AccountSignatureModel } from '../../../../../shared/models/account-signature.model';
import { CommissionInfoPatch } from '../../../../../shared/models/commission-info.model';
import { Commission } from '../../../../../shared/models/commission.model';
import { StateRuleModel } from '../../../../../shared/models/state-rules.model';
import { AppSpinnerModule } from '../../../../../shared/modules/app-spinner.module';
import { AccountSignatureService } from '../../../../../shared/services/account-signature.service';
import { CommissionService } from '../../../../../shared/services/commission.service';
import { FormValidators } from '../../../../../shared/validators/form.validators';


@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.scss']
})
export class AddSignatureComponent implements OnInit {
  uploadSignatureForm: UntypedFormGroup;

  hasSignature: boolean;
  signatureUploaded: Observable<boolean>;
  accountSignatureSelected: Observable<boolean> = of(false);
  selectedAccountSignature: AccountSignatureModel;

  mode: "add" | "edit";
  selectedRule: Observable<StateRuleModel>;
  commission;

  signatures: AccountSignatureModel[] = [];
  selectedSignatureBase64: string | null;
  uploadSignatureName: string;
  returnedSignature: AccountSignatureModel;

  constructor(
    private readonly accountSignatureService: AccountSignatureService,
    private readonly activeModal: NgbActiveModal,
    private readonly commissionService: CommissionService,
    private readonly sanitizer: DomSanitizer,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getSignaturesForCurrentUser();
    if (this.mode === "edit" && this.commission.signature !== null) {
      this.selectedSignatureBase64 = this.commission.signature;
      this.hasSignature = true;
    }

    this.uploadSignatureForm.get('accountSignature')?.valueChanges.subscribe(
      (signature: AccountSignatureModel) => {
        this.selectAccountSignature(signature);
      }
    );
  }

  initForm() {
    this.uploadSignatureForm = new UntypedFormGroup(
      {
        accountSignature: new UntypedFormControl([]),
        signature: new UntypedFormControl(null, FormValidators.requiredFileType(["png"])),
        name: new UntypedFormControl(''),
      },
      { updateOn: "change" }
    );
  }

  patchForm() {
    this.uploadSignatureForm.patchValue({
      signature: this.selectedSignatureBase64
    });
  }

  getSignaturesForCurrentUser(): void {
    this.spinner.show();
    this.accountSignatureService.getCurrentAccountSignatures()
      .pipe(
        tap((response) => {
          this.spinner.hide();
          this.signatures = response.accountSignatures;
        }),
        catchError(() => {
          this.spinner.hide();
          return [];
        }
        )).subscribe();
  }

  selectAccountSignature(signature: AccountSignatureModel) {
    if (!signature || !signature.accountSignatureId) {
      return;
    }
    const selectedSignature = this.signatures.find((s) => s.accountSignatureId === signature.accountSignatureId);
    this.selectedAccountSignature = selectedSignature || this.selectedAccountSignature;
    this.selectedSignatureBase64 = selectedSignature?.signatureImageBase64 || null;
    this.accountSignatureSelected = of(true);
    this.hasSignature = true;
  }

  async onFileSelected(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    if (target.files === null || target.files.length === 0) {
      return;
    }
    const selectedSignatureFile = target.files[0];

    // validate file
    if (selectedSignatureFile.size > 21500) {
      this.toastr.warning(
        `The file '${selectedSignatureFile.name}' is too large. Please select a file 21KB or smaller.`,
        "Warning"
      );
      target.value = "";
      return;
    }

    // validate file type
    if (selectedSignatureFile.type !== 'image/png') {
      this.toastr.warning(
        `The file '${selectedSignatureFile.name}' is not a PNG image. Please select a PNG image.`,
        "Warning"
      );
      target.value = "";
      return;
    }

    this.spinner.show();

    await this.loadFileAsync(selectedSignatureFile);

    target.value = "";
    this.spinner.hide();
  }

  private async loadFileAsync(file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          this.selectedSignatureBase64 = this.convertFileToBase64(reader.result as ArrayBuffer);
          this.uploadSignatureName = file.name.substring(0, file.name.lastIndexOf('.')); //set the file name without the extension
          await this.saveAccountSignature();
          this.signatureUploaded = new Observable<boolean>((observer) => {
            observer.next(!this.uploadSignatureForm.get("signature")?.hasError("requiredFileType"));
            observer.complete();
          });
          resolve();
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private convertFileToBase64(fileData: ArrayBuffer): string {
    const bytes = new Uint8Array(fileData);
    let binary = '';
    for (const byte of bytes) {
      binary += String.fromCharCode(byte);
    }
    return window.btoa(binary);
  }

  getSanitizedImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpg;base64," + image
    );
  }

  async saveAccountSignature() {
    if (this.selectedSignatureBase64 === null) {
      this.markFormControlsAsDirty(this.uploadSignatureForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      this.spinner.hide();
      return;
    }

    // get current date time to append to the signature name
    const now = new Date();
    const formattedDateTime = `${now.getFullYear()}_${(now.getMonth() + 1).toString()
      .padStart(2, '0')}_${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString()
        .padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}`;
    const signatureName = `${this.uploadSignatureName}-${formattedDateTime}`;

    const signatureModel: AccountSignatureModel = {
      name: signatureName,
      accountId: this.commission.accountId,
      signatureImageBase64: this.selectedSignatureBase64,
      accountSignatureId: 0,
      filePathId: 0
    }

    this.accountSignatureService.saveSignature(signatureModel).subscribe(
      (response) => {
        this.selectedAccountSignature = response.accountSignature!;
        this.signatures.push(this.selectedAccountSignature);
        this.uploadSignatureForm.patchValue({
          accountSignature: this.selectedAccountSignature
        });
      },
      (_error) => {
        this.spinner.hide();
        this.toastr.error("Failed to add Signature");
        return [];
      });
  }

  async saveSignature() {
    this.spinner.show();
    FormError.ValidateAllFormFields(this.uploadSignatureForm);
    if (!this.uploadSignatureForm.valid) {
      this.markFormControlsAsDirty(this.uploadSignatureForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      this.spinner.hide();
      return;
    }

    if (!this.selectedAccountSignature) {
      this.toastr.warning("Please select an existing signature or upload a new one.", "Attention");
      this.spinner.hide();
      return;
    }

    // If this is an active commission, we need to build the Commission model and patch it.
    // There's not an easy way to check this without refactoring the models to include more info, so we'll just check for the presence of the 'id' property.
    if (this.commission.id) {

      const commissionPatch: Commission = {
        id: this.commission.id,
        accountId: this.commission.accountId,
        countyFIPSCode: this.commission.countyFIPSCode,
        expiresOn: this.commission.expiresOn,
        name: this.commission.name,
        number: this.commission.number,
        stamp: this.commission.stamp,
        stateCode: this.commission.stateCode,
        signature: "",
        accountSignatureId: this.selectedAccountSignature.accountSignatureId,
        signatureFilePathId: this.selectedAccountSignature.filePathId,
        certificate: "",
      }
      this.commissionService.updateCommission(commissionPatch.id, commissionPatch).pipe(
        tap(() => {
          this.activeModal.close('signatureAdded');
          this.spinner.hide();
          this.toastr.success("Signature Added");
          this.uploadSignatureForm.reset();
        }),
        catchError(() => {
          this.spinner.hide();
          this.toastr.error("Failed saving signature");
          return [];
        })
      )
        .subscribe();
    }

    // If this is a draft commission, we need to build the CommissionInfo model and patch it
    if (this.commission.commissionInfoId) {
      const commissionInfoPatch: CommissionInfoPatch = {
        commissionInfoId: this.commission.commissionInfoId,
        accountSignatureId: this.selectedAccountSignature.accountSignatureId,
        expiresOn: this.commission.expiresOn,
        accountId: this.commission.accountId,
        stateCode: this.commission.stateCode,
        number: this.commission.number,
        stamp: this.commission.stamp,
        countyFipsCode: this.commission.countyFipsCode,
        name: this.commission.name
      }
      this.commissionService.patchDraftCommission(commissionInfoPatch).pipe(
        tap(() => {
          this.activeModal.close('signatureAdded');
          this.spinner.hide();
          this.toastr.success("Signature Added");
          this.uploadSignatureForm.reset();
        }),
        catchError(() => {
          this.spinner.hide();
          this.toastr.error("Failed to add Signature");
          return [];
        })
      )
        .subscribe();
    }
  }

  close(): void {
    this.activeModal.close();
  }

  private markFormControlsAsDirty(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof UntypedFormGroup) {
        this.markFormControlsAsDirty(control);
      } else {
        control.markAsDirty();
      }
    });
  }
}
