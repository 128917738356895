import { ClearSignPortalEnvironment } from "./environment.types";

export const environment: ClearSignPortalEnvironment = {
  envName: "#{environment}#",
  production: false,
  azureB2C: {
    clientID: "#{clientID}#", // required to initialize MSAL module
    authority: "#{authority}#", // In Azure B2C, it is of the form https://<instance>/tfp/<tenantId>/<policyName>/,
    passwordResetPolicy: "#{passwordResetPolicy}#",
    redirectUri: "#{redirectUri}#",
    postLogoutRedirectUri: "#{postLogoutRedirectUri}#", // default is redirect uri
    amrockPostLogoutRedirectUri: "#{amrockPostLogoutRedirectUri}#",
    navigateToLoginRequestUrl: true, // Ability to turn off default navigation to start page after login. Default is true. This is used only for redirect flows.
  },
  apiUri: "#{apiUri}#",
  appIdUri: "#{appIdUri}#",
  apiScope: "#{apiScope}#",
  pdfToPngUri: "#{pdfToPngUri}#",
  pdfToPngKey: "#{PdfToPngKey}#",
  rulesEngineUri: "#{rulesEngineUri}#",
  rulesEngineKey: "#{RulesEngineKey}#",
  features: {
    isIPENEnabled: "#{isIPENEnabled}#",
    isESignEnabled: "#{isESignEnabled}#",
    isMultiFactorEnabled: "#{isMultiFactorEnabled}#",
    verifyDigitalCertificate: "#{verifyDigitalCertificate}#",
    enableMultiFactorForRON: "#{enableMultiFactorForRON}#",
  },
  pendo: {
    apiKey: "#{pendoApiKey}#",
    isEnabled: true
  }
};
