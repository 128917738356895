import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { filter, map } from "rxjs/operators";
import { ClientAccounts } from "src/app/shared/models/account";
import { ClientAccountInfoList } from "src/app/shared/models/client-account-info-list";
import { FeatureConfiguration } from "src/app/shared/models/feature-configuration.model";
import { Order } from "src/app/shared/models/order";
import { OrderStatusDetail } from "src/app/shared/models/orderStatusDetail";
import { OrderOrganizer } from "src/app/shared/models/order-organizer.model";
import { ListViewParticipant } from "src/app/shared/models/participant.model";
import {
  ClientCapabilities,
  DocumentCapabilities,
  OrderCapabilities,
  OrganizerCapabilities,
  ParticipantCapabilities,
  SystemCapabilities,
} from "src/app/shared/services/capabilities/models/capability.model";
import { FormName } from "../components/participant-form-modal/participant-form-modal.models";

type currentFormType =
  | "nonBorrowingTitleHolderInformation"
  | "witnessInformation"
  | "participantInformation"
  | "signingAgentInformation";

export interface OrderDetailState {
  order: Order;
  scheduledTime: string | Date;
  orderStatus: string;
  orderStatusDetails: OrderStatusDetail[];
  isEditing: boolean;
  currentForm: currentFormType;
  selectedParticipantRole: string | null;
  clientCapabilities: ClientCapabilities | null;
  systemCapabilities: SystemCapabilities | null;
  documentCapabilities: DocumentCapabilities[] | null;
  participantCapabilities: ParticipantCapabilities[] | null;
  organizerCapabilities: OrganizerCapabilities[] | null;
  orderCapabilities: OrderCapabilities | null;
  participants: ListViewParticipant[];
  organizers: OrderOrganizer[];
  clientAccountInfoList: ClientAccountInfoList;
  clientAccounts: ClientAccounts | null;
  features: FeatureConfiguration[];
}

@Injectable({
  providedIn: "root",
})
export class OrderDetailStore extends ComponentStore<OrderDetailState> {
  readonly orderStatus$ = this.select((state) => state.orderStatus);
  readonly isOrderLocked$ = this.select((state) => state.orderStatus).pipe(
    map((orderStatus) => orderStatus !== "READY")
  );
  readonly order$ = this.select((state) => state.order);
  readonly participants$ = this.select((state) => state.participants);
  readonly isEditing$ = this.select((state) => state.isEditing);
  readonly scheduledTime$ = this.select((state) => state.scheduledTime);
  readonly currentFormName$ = this.select((state) => state.currentForm);
  readonly selectedParticipantRole$ = this.select(
    (state) => state.selectedParticipantRole
  );
  readonly currentOrderId$ = this.select((state) => state.order?.id ?? 0);
  readonly clientCapabilities$ = this.select(
    (state) => state.clientCapabilities
  ).pipe(filter((result) => result !== null));
  readonly systemCapabilities$ = this.select(
    (state) => state.systemCapabilities
  ).pipe(filter((result) => result !== null));
  readonly orderCapabilities$ = this.select(
    (state) => state.orderCapabilities
  ).pipe(filter((result) => result !== null));
  readonly documentCapabilities$ = this.select(
    (state) => state.documentCapabilities
  ).pipe(filter((result) => result !== null));
  readonly orderStatusDetails$ = this.select(
    (state) => state.orderStatusDetails
  ).pipe(filter((result) => result !== null));
  readonly participantCapabilities$ = this.select(
    (state) => state.participantCapabilities
  ).pipe(filter((result) => result !== null));
  readonly organizerCapabilities$ = this.select(
    (state) => state.organizerCapabilities
  ).pipe(filter((result) => result !== null));
  readonly organizers$ = this.select((state) => state.organizers);
  readonly clientAccountInfoList$ = this.select(
    (state) => state.clientAccountInfoList
  );
  readonly clientAccounts$ = this.select((state) => state.clientAccounts);
  readonly features$ = this.select((state) => state.features);
  readonly enabledFeatures$ = this.select((state) => state.features).pipe(
    map((features) => features.filter((f) => f.enabled))
  );

  readonly setOrder = this.updater((state, order: Order) => ({
    ...state,
    order,
  }));

  readonly setScheduledTime = this.updater(
    (state, scheduledTime: string | Date) => ({
      ...state,
      scheduledTime,
    })
  );

  readonly setOrderStatus = this.updater((state, orderStatus: string) => ({
    ...state,
    orderStatus,
  }));

  readonly setOrderStatusDetails = this.updater(
    (state, orderStatusDetails: OrderStatusDetail[]) => ({
      ...state,
      orderStatusDetails,
    })
  );

  readonly setIsEditing = this.updater((state, isEditing: boolean) => ({
    ...state,
    isEditing,
  }));

  readonly setCurrentForm = this.updater(
    (
      state,
      currentForm: currentFormType
    ) => ({
      ...state,
      currentForm,
    })
  );

  readonly setParticipants = this.updater(
    (state, participants: ListViewParticipant[]) => ({
      ...state,
      participants,
    })
  );

  readonly setOrganizers = this.updater(
    (state, organizers: OrderOrganizer[]) => ({
      ...state,
      organizers,
    })
  );

  readonly setClientAccountInfoList = this.updater(
    (state, clientAccountInfoList: ClientAccountInfoList) => ({
      ...state,
      clientAccountInfoList,
    })
  );

  readonly setSelectedParticipantRole = this.updater(
    (state, selectedParticipantRole: string | null) => ({
      ...state,
      selectedParticipantRole,
    })
  );

  readonly setClientCapabilities = this.updater(
    (state, clientCapabilities: ClientCapabilities) => ({
      ...state,
      clientCapabilities,
    })
  );

  readonly setSystemCapabilities = this.updater(
    (state, systemCapabilities: SystemCapabilities) => ({
      ...state,
      systemCapabilities,
    })
  );

  readonly setOrderCapabilities = this.updater(
    (state, orderCapabilities: OrderCapabilities) => ({
      ...state,
      orderCapabilities,
    })
  );

  readonly setParticipantCapabilities = this.updater(
    (state, participantCapabilities: ParticipantCapabilities[]) => ({
      ...state,
      participantCapabilities,
    })
  );

  readonly setDocumentCapabilities = this.updater(
    (state, documentCapabilities: DocumentCapabilities[]) => ({
      ...state,
      documentCapabilities,
    })
  );

  readonly setOrganizerCapabilities = this.updater(
    (state, organizerCapabilities: OrganizerCapabilities[]) => ({
      ...state,
      organizerCapabilities,
    })
  );

  readonly setClientAccounts = this.updater(
    (state, clientAccounts: ClientAccounts) => ({
      ...state,
      clientAccounts,
    })
  );

  readonly setFeatures = this.updater(
    (state, features: FeatureConfiguration[]) => ({
      ...state,
      features,
    })
  );

  constructor() {
    super({
      order: new Order(),
      scheduledTime: "",
      orderStatus: "",
      orderStatusDetails: [],
      isEditing: false,
      currentForm: FormName.ParticipantInformation,
      selectedParticipantRole: "",
      clientCapabilities: null,
      systemCapabilities: null,
      orderCapabilities: null,
      documentCapabilities: null,
      participantCapabilities: null,
      participants: [],
      organizers: [],
      clientAccountInfoList: new ClientAccountInfoList(),
      organizerCapabilities: null,
      clientAccounts: null,
      features: [],
    });
  }
}
