import { Component, Input} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from "@angular/forms";

import { FormError } from "./form-error";

@Component({
  selector: "app-form-error",
  templateUrl: "./app-form-error.component.html",
  styleUrls: ["./app-form-error.component.scss"]
})
export class FormErrorComponent {
  @Input() group: UntypedFormGroup | UntypedFormArray;
  @Input() controlName: string;
  @Input() showFormLevelErrors = false;
  @Input() customErrorMessage: string;

  shouldShow(): boolean {
    return FormError.isFieldInvalidDirty(this.group, this.controlName);
  }

  getErrorMessage(): string {
    const formControl = this.group.get(this.controlName) as UntypedFormControl;

    if (formControl.errors === null) {
      return "";
    }

    for (const formError of FormError.formErrors) {
      if (formError.isError(formControl) && !this.customErrorMessage) {
        return formError.getMessage(formControl);
      }
      else{
        if (this.customErrorMessage){
         return this.customErrorMessage;
        }
      }
    }

    return "";
  }
}
