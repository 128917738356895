import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
} from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { KeyValuePair } from "src/app/shared/models/key-value-pair";

import { Order } from "src/app/shared/models/order";
import { Participant } from "src/app/shared/models/participant.model";
import { ParticipantSignatureNamePipe } from "src/app/shared/pipes";

import { DigiDocsSection, DigiDocsStore } from "../../+state/digi-docs.store";
import { DigitizationParameter } from "../../models/digitization-parameter.model";
import { ImageData } from "../../models/image-data.model";
import { RoleValuePipe } from "../../pipes/role-value.pipe";

export interface EndorsementComponentOutput {
  participant: Participant;
  imageData: ImageData | null;
  isSystem: boolean;
  endorsementType: string;
  format?: string;
  isRequired: boolean;
}

export interface NotaryEndorsementComponentOutput {
  participants: Participant[];
  imageData: ImageData | null;
}

export interface SubmitDocumentAndEndorsementsOutput {
  digitizationParameters: DigitizationParameter;
  imageData: ImageData | null;
}

export interface EndorsementUsingTemplateOutput {
  imageData: ImageData | null;
  templateId: number | null;
}

export interface OpenSaveTemplateModalForTemplateNameOutput {
  digitizationParameters: DigitizationParameter;
  imageData: ImageData | null;
  participants: Participant[];
}

export interface UpdateTemplateEndorsementsOutput {
  imageData: ImageData;
  digitizationParameters: DigitizationParameter;
  templateId: number;
  participants: Participant[];
}

@Component({
  standalone: true,
  selector: "app-endorsements-sidenav",
  templateUrl: "./endorsements-sidenav.component.html",
  styleUrls: ["./endorsements-sidenav.component.scss"],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    ParticipantSignatureNamePipe,
    RoleValuePipe,
  ],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: "cdk-overlay-pane__endorsements-sidenav",
      },
    },
  ],
})
export class EndorsementsSidenavComponent implements OnInit {
  @Input() digitizationParameters$: Observable<DigitizationParameter>;
  @Input() imageData$: Observable<ImageData>;
  @Input() order: Order;
  @Input() participants$: Observable<Participant[]>;
  @Input() templateID: number | null;
  @Input() templateOptions$: Observable<KeyValuePair[]>;

  @Output() generateEndorsementComponent =
    new EventEmitter<EndorsementComponentOutput>();
  @Output() generateNotaryEndorsementComponent =
    new EventEmitter<NotaryEndorsementComponentOutput>();
  @Output() submitDocumentAndEndorsements =
    new EventEmitter<SubmitDocumentAndEndorsementsOutput>();
  @Output() generateEndorsementUsingTemplate =
    new EventEmitter<EndorsementUsingTemplateOutput>();
  @Output() openSaveTemplateModalForTemplateName =
    new EventEmitter<OpenSaveTemplateModalForTemplateNameOutput>();
  @Output() updateTemplateEndorsements =
    new EventEmitter<UpdateTemplateEndorsementsOutput>();

  constructor(private readonly componentStore: DigiDocsStore) {}

  ngOnInit() {
  }

  handleBackToDocuments() {
    this.componentStore.sectionToDisplay(DigiDocsSection.LIST);
    this.componentStore.setRibbonTabToDisplay("File");
  }

  handleOpenSaveTemplateModalForTemplateName({
    digitizationParameters,
    imageData,
    participants
  }: OpenSaveTemplateModalForTemplateNameOutput) {
    this.openSaveTemplateModalForTemplateName.emit({
      digitizationParameters,
      imageData,
      participants
    });
  }

  handleUpdateTemplateEndorsements({
    imageData,
    digitizationParameters,
    templateId,
    participants
  }: UpdateTemplateEndorsementsOutput) {
    this.updateTemplateEndorsements.emit({
      imageData,
      digitizationParameters,
      templateId,
      participants
    });
  }

  handleGenerateEndorsementsUsingTemplate({
    templateId,
    imageData,
  }: EndorsementUsingTemplateOutput) {
    this.generateEndorsementUsingTemplate.emit({
      imageData,
      templateId,
    });
  }

  handleGenerateNotaryEndorsementComponent({
    participants,
    imageData,
  }: NotaryEndorsementComponentOutput) {
    this.generateNotaryEndorsementComponent.emit({
      participants,
      imageData,
    });
  }

  handleGenerateEndorsementComponent({
    participant,
    imageData,
    isSystem,
    endorsementType,
    format,
    isRequired = true,
  }: EndorsementComponentOutput) {
    this.generateEndorsementComponent.emit({
      participant,
      imageData,
      isSystem,
      endorsementType,
      format,
      isRequired
    });
  }

  handleSubmitDocumentAndEndorsements({
    digitizationParameters,
    imageData,
  }: SubmitDocumentAndEndorsementsOutput) {
    this.submitDocumentAndEndorsements.emit({
      digitizationParameters,
      imageData,
    });
  }

  endorsmentDisabledForOrder() {
    // Signing agent actions and system fields are not valid on hybrid orders
    if (this.order?.productType === "Hybrid") {
      return true;
    }

    return false;
  }

  participantCanParticipate(participant: Participant) {
    // Signing agent actions and system fields are not valid on hybrid orders
    if (this.order?.productType === "Hybrid") {
      if (participant.role === "SIGNINGAGENT") {
        return false;
      }
    }

    return true;
  }

  signingAgent(
    participant: Participant
  ) {
    // Signing agent printed name are not valid, use Signing Agent Name system endorsement
    if (participant.role === "SIGNINGAGENT") {
      return false;
    }

    return true;
  }

  getParticipants() {
    return this.participants$.pipe(
      tap((participants: Participant[]) =>
        participants.map((participant) => {
          if (participant.role === "WITNESS" && !participant.firstName && !participant.lastName) {
            participant.firstName = "Witness";
            participant.lastName = participant.sequenceNumber.toString();
          }
          return participant;
        })
      )
    );
  }
}
