<ngx-spinner name="document-upload-spinner" [fullScreen]="false" type="ball-spin-clockwise" size="large" color="#2B0083" bdColor="rgba(51,51,51,0.5)">
  <div style="padding-top: 200px;" align="center">
    <h5 class="document-upload-spinner-message blink">
      {{ spinnerMessage }}
    </h5>
    <br />
  </div>
</ngx-spinner>
<ng-container>
  <div class="modal-header"><h5 class="modal-title">Add Documents</h5></div>

  <div class="modal-body">
    <form [formGroup]="documentForm" class="nex-form">
      <div class="document-upload-header container-fluid">
        <div class="row no-gutters">
          <div class="col-12 col-md-4">
            <span class="document-upload-step" [ngClass]="{'inactive':documentStep > 0}">
              <span class="step-number-1" [ngClass]="{'checked':documentStep > 0}"></span>
              Preparing Documents
            </span>
          </div>
          <div class="col-12 col-md-4">
            <span class="document-upload-step" [ngClass]="{'inactive':documentStep !== 1}">
              <span class="step-number-2" [ngClass]="{'checked':documentStep > 1}"></span>
              Select Documents
            </span>
          </div>
          <div class="col-12 col-md-4">
            <span class="document-upload-step" [ngClass]="{'inactive':documentStep < 2}">
              <span class="step-number-3" [ngClass]="{'checked':documentStep >= 2}"></span>
              Classify Documents
            </span>
          </div>
        </div>
      </div>

      <div class="step-1" *ngIf="documentStep === 0">
        <h3 class="text-left mt-4">Helpful Tips:</h3>
        <ul>
          <li>
            <h4>Separate Legal and Letter Documents</h4>
            &#x2022; <span>The pages within the document being uploaded should only contain Legal or Letter sized pages, not both.</span>
          </li>
          <li>
            <h4>Check for Uniform Page Size</h4>
            &#x2022; <span>Please make sure the pages within the document are correctly sized as Legal (8.5" x 14") or Letter (8.5" x 11") paper size.</span>
          </li>
          <li>
            <h4>Split out Documents</h4>
            &#x2022; <span>If there are several pages within the document package, consider separating the document into smaller individual documents.</span>
          </li>
        </ul>
      </div>

      <div class="step-2" *ngIf="documentStep === 1">
        <div class="drop-container">
          <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onDocumentSelected($event)" />
          <label for="fileDropRef">Choose File</label>
          <span>Or drag and drop</span>
        </div>
        <div *ngIf="documents.controls.length > 0" class="mt-10 documentList container-fluid">
          <div *ngFor="let docGroup of documentsFormArray?.controls; let i = index;" class="document row no-gutters">
            <div class="col-11 justify-content-start align-items-center">
              <span class="documentName force-wrap">
                {{documentsFormArray.controls[i].get('file')?.value.name}}
              </span>
            </div>
            <div class="col-1 justify-content-end align-items-center text-end">
              <a href="javascript:void(0);"
                (click)="removeDocument(i)"
                attr.aria-label="Remove"
                attr.title="Remove"></a>
            </div>
          </div>
        </div>
      </div>

      <div class="step-3" *ngIf="documentStep > 1">
        <p>*Required Information</p>
        <div id="classifyDocumentList" class="container-fluid" formArrayName="documents">
          <div class="row" *ngFor="let docGroup of documentsFormArray?.controls; let i = index;" [formGroupName]="i" data-cy="uploadedDocument">
            <div id="colDocumentFile" class="col-12 col-lg-12 col-xl-2">
              {{documentsFormArray?.controls[i].get('file')?.value.name}}
              <br/>
            </div>
            <div id="colDocumentName" class="col-12 col-md-6 col-lg-4 col-xl-2">
              <div>
                <mat-form-field appearance="fill">
                  <mat-label for="documentName">Document Name</mat-label>
                  <input matInput type="text" formControlName="documentName" maxlength="200" />
                  <mat-error *ngIf="isFieldInvalidDirty(docGroup, 'documentName')">
                    {{getErrorMessage(docGroup, 'documentName')}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div id="colDocumentType" class="col-12 col-md-6 col-lg-4 col-xl-2">
              <div>
                <mat-form-field appearance="fill" floatLabel="auto">
                  <mat-label for="documentType">Document Type</mat-label>
                  <input matInput type="text" formControlName="documentType" maxlength="32" />
                  <mat-error *ngIf="isFieldInvalidDirty(docGroup, 'documentType')">
                    {{getErrorMessage(docGroup, 'documentType')}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div id="colDocumentActionType" class="col-12 col-md-6 col-lg-4 col-xl-2">
              <div>
                <mat-form-field appearance="fill">
                  <mat-label for="actionType">Action Type</mat-label>
                  <mat-select formControlName="actionType"
                            data-cy="actionTypeDropdown">
                      <mat-option *ngFor="let action of actionTypes" [value]="action.key">{{ action.value }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="isFieldInvalidDirty(docGroup, 'actionType')">
                    {{getErrorMessage(docGroup, 'actionType')}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div id="colDocumentPageType" class="col-12 col-md-6 col-lg-4 col-xl-2">
              <div>
                <mat-form-field appearance="fill">
                  <mat-label for="pageType">Page Type</mat-label>
                  <mat-select formControlName="pageType"
                            data-cy="pageTypeDropdown">
                    <mat-option *ngFor="let page of pageTypes" [value]="page.key">{{ page.value }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="isFieldInvalidDirty(docGroup, 'pageType')">
                    {{getErrorMessage(docGroup, 'pageType')}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-2">
              <div>
                <mat-form-field appearance="fill">
                  <mat-label for="isSmartDoc">Is Smart Doc</mat-label>
                  <mat-select formControlName="isSmartDoc"
                            data-cy="isSmartDocDropdown">
                    <mat-option *ngFor="let option of smartDocOptions" [value]="option.key">{{ option.value }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="isFieldInvalidDirty(docGroup, 'isSmartDoc')">
                    {{getErrorMessage(docGroup, 'isSmartDoc')}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer">
    <button id="uploadCancel"
      type="button"
      class="amr-btn-outline-primary"
      [ngClass]="{'btn-no-outline':documentStep > 0}" (click)="activeModal.close('cancel')"
      [disabled]="isUploading">
        Cancel
    </button>
    <button id="uploadPrevious"
      type="button"
      class="amr-btn-outline-primary"
      *ngIf="documentStep > 0"
      (click)="onDocumentPreviousStep()"
      [disabled]="isUploading">
        Back
    </button>
    <button id="uploadNext"
      type="button"
      class="amr-btn-default"
      *ngIf="documentStep > -1 && documentStep < 2" (click)="onDocumentNextStep()"
      [disabled]="isUploading || (documents.controls.length === 0 && documentStep !== 0)">
        Next
    </button>
    <button id="uploadAction"
      type="button"
      class="amr-btn-default"
      *ngIf="documentStep > 1" (click)="onUploadDocuments()"
      [disabled]="isUploading">
        Upload Documents
    </button>
  </div>
</ng-container>
