import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { TermsAndConditions } from "../models/terms-and-conditions.models";

@Injectable({
  providedIn: "root"
})
export class TermsAndConditionsService extends BaseService {
  className = "AccountService";

  constructor(injector: Injector) {
    super(injector);
  }

  getContent(typeCode: string): Observable<string> {
    return this.getText(`/v1/termsAndConditions/${typeCode}`);
  }

  async hasAcceptedAsync(typeCode: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.get<boolean>(`/v1/termsAndConditions/${typeCode}/accepted`).subscribe(
        (accepted) => {
          resolve(accepted);
        },
        (err) => {
          console.error("hasAcceptedEula:", err);
          reject(err);
        });
    });
  }

  saveAccepted(typeCode: string, isAccepted: boolean): Observable<TermsAndConditions> {
    const model = new TermsAndConditions(typeCode, isAccepted);
    return this.post<TermsAndConditions>("/v1/termsAndConditions/accept", model);
  }
}
