import { BaseService } from "./base.service";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { NotaryStampResponse } from "../models/notary-stamp.model";

@Injectable({
  providedIn: "root"
})
export class NotaryStampService extends BaseService {
  className = "NotaryStampService";

  constructor(injector: Injector) {
    super(injector);
  }

  retrieveStampFile(filePathId: number): Observable<NotaryStampResponse> {
    return this.get<NotaryStampResponse>(`/v1/notaryStamp/${filePathId}`);
  }
}
