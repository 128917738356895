<div class="modal-header">
  <h5 class="modal-title">Download Signing Video</h5>
</div>

<div class="modal-body">
  <h5>
    There are multiple videos for this signing. Please download all available
    videos below for your records.
  </h5>

  <div class="button-container download-button-container">
    <button
      *ngFor="let link of videoLinks; let i = index"
      class="btn btn-white download-button"
      (click)="download(i)"
    >
      <span class="fas fa-download download-button-icon"></span>
      <br />
      <span class="download-button-text">Video {{ i + 1 }}</span>
    </button>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="amr-btn-default"
    (click)="closeModal()"
  >
    Done
  </button>
</div>
