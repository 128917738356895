import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import {
  faFilePdf,
  faLayerGroup,
  faArrowsAltV,
} from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { ToastrService } from "ngx-toastr";

import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { DocumentService } from "src/app/shared/services/document.service";
import { FormError } from "src/app/shared/components/app-form-error/form-error";
import { FormValidators } from "src/app/shared/validators/form.validators";
import { KeyValuePair } from "src/app/shared/models/key-value-pair";
import { LazyInjectService } from "src/app/shared/services/lazy-inject/lazy-inject.service";
import { NewDocument } from "src/app/shared/models/document.models";
import { PAGE_SIZES } from "src/app/digitization-tool/models/digitization-parameter.model";
import { PdfjsService, PDFPage } from "src/app/shared/services/pdfjs/pdfjs.service";

@Component({
  templateUrl: "./document-upload-modal.component.html",
  styleUrls: ["./document-upload-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentUploadModalComponent implements OnInit {
  private pdfjsService: PdfjsService;

  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  actionTypes: KeyValuePair[] = [
    { key: "esign", value: "Esign" },
    { key: "print", value: "Print" },
  ];

  pageTypes: KeyValuePair[] = [
    { key: "Letter", value: "Letter" },
    { key: "Legal", value: "Legal" },
  ];

  smartDocOptions: KeyValuePair[] = [
    { key: true, value: "Yes" },
    { key: false, value: "No" },
  ];

  faFilePdf = faFilePdf;
  faLayerGroup = faLayerGroup;
  faArrowsAltV = faArrowsAltV;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly documentService: DocumentService,
    private lazyInjector: LazyInjectService
  ) {}

  orderId: number;
  closingType: string;
  isHybridOrder: boolean;
  isESignOrder: boolean;
  documentForm: UntypedFormGroup;
  documents: UntypedFormArray;
  documentStep: number;
  spinnerMessage: string;
  isUploading: boolean;

  async ngOnInit() {
    this.documentStep = 0;
    this.spinnerMessage = "";
    this.documents = this.formBuilder.array([]);
    this.documentForm = this.formBuilder.group({
      documents: this.documents,
    });
    this.isHybridOrder = (this.closingType || "").toUpperCase() === "HYBRID";
    this.isESignOrder = (this.closingType || "").toUpperCase() === "ESIGN";
    this.pdfjsService = await this.lazyInjector.get<PdfjsService>(() =>
      import("src/app/shared/services/pdfjs/pdfjs.service").then(
        (m) => m.PdfjsService
      )
    );
  }

  control(index: number, name: string): AbstractControl | null {
    return this.documents.controls[index].get(name);
  }

  validDocumentName(control: AbstractControl): ValidationErrors | null {
    const isValidText = /^[0-9A-Za-z#()_\.\-'\s]+$/.test(control.value);
    return isValidText ? null : { validDocumentName: { value: control.value } };
  }

  async onDocumentSelected(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    if (target.files === null || target.files.length === 0) {
      return;
    }
    this.documents = this.documentForm.get("documents") as UntypedFormArray;
    const files = Array.from(target.files);

    // validate all files
    for (const file of files) {
      if (!FormValidators.isValidDocumentExtension(file)) {
        this.toastr.warning(
          `The file '${file.name}' is not allowed.  Please select a different file.`,
          "Warning"
        );
        target.value = "";
        return;
      }
    }

    this.showSpinner(true, "Loading documents...");

    for (const file of files) {
      if(file.type === "application/pdf") {
        await this.loadPdfAsync(file);
      }
      else if(file.type === "text/xml") {
        await this.loadXmlAsync(file);
      }
    }

    target.value = "";
    this.showSpinner(false);
  }

  private getUniquePageSize(pages: PDFPage[]): PAGE_SIZES | boolean {
    return pages.reduce(
      (acc, curr) => (acc === curr.size ? curr.size : false),
      pages[0]?.size
    );
  }

  private isValidPageSize(control: AbstractControl): ValidationErrors | null {
    const isValid = control.value && Object.values(PAGE_SIZES).includes(control.value as PAGE_SIZES)

    if (control.value === PAGE_SIZES.UNKNOWN) {
      return { unknownPageSize: { value: control.value } }
    }

    return isValid ? null : { isValidPageSize: { value: control.value } }
  }

  private async loadPdfAsync(file: File): Promise<FileReader> {
    const self = this;
    return new Promise<FileReader>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const pageSizesResult = await this.pdfjsService.getPageSizes(reader.result as ArrayBuffer, {
            start: 1,
            max: null,
          });

          const pdfPages: PDFPage[] | null = pageSizesResult
            ? pageSizesResult[1]
            : null

          let documentName = file.name.split(".")[0];

          const bytes = Array.from(
            new Uint8Array(reader.result as ArrayBuffer)
          );
          const base64 = window.btoa(
            bytes.map((c) => String.fromCharCode(c)).join("")
          );
          const document: NewDocument = {
            id: 0,
            sequenceNumber: 1,
            name: file.name,
            bytes: base64,
          };

          const validPageSize = pdfPages
            ? await this.getUniquePageSize(pdfPages)
            : false;

          self.documents.push(
            self.formBuilder.group(
              {
                file: document,
                documentName: new UntypedFormControl(documentName, [
                  Validators.required,
                  Validators.maxLength(200),
                  this.validDocumentName,
                ]),
                documentType: new UntypedFormControl("", [
                  Validators.required,
                  Validators.maxLength(32)
                ]),
                actionType: new UntypedFormControl(
                  this.isHybridOrder
                    ? { value: null, disabled: false }
                    : { value: "esign", disabled: true },
                  Validators.required
                ),
                pageType: new UntypedFormControl(
                  validPageSize,
                  [
                    Validators.required,
                    this.isValidPageSize
                  ]
                ),
                isDocPreSign: new UntypedFormControl({
                  value: false,
                  disabled: this.isESignOrder,
                }),
                isSmartDoc: new UntypedFormControl({
                  value: false,
                  disabled: true,
                })
              },
              {
                updateOn: "blur",
              }
            )
          );

          resolve(reader);
        } catch (err) {
          console.error("Error:", [err, file]);
          reject(err);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private async loadXmlAsync(file: File): Promise<boolean> {
    const self = this;
    return new Promise<boolean>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          let documentName = file.name.split(".")[0];

          const bytes = Array.from(
            new Uint8Array(reader.result as ArrayBuffer)
          );
          const base64 = window.btoa(
            bytes.map((c) => String.fromCharCode(c)).join("")
          );
          const document: NewDocument = {
            id: 0,
            sequenceNumber: 1,
            name: file.name,
            bytes: base64,
          };

          self.documents.push(
            self.formBuilder.group(
              {
                file: document,
                documentName: new UntypedFormControl(documentName, [
                  Validators.required,
                  Validators.maxLength(200),
                  this.validDocumentName,
                ]),
                documentType: new UntypedFormControl("", [
                  Validators.required,
                  Validators.maxLength(32)
                ]),
                actionType: new UntypedFormControl(
                  { value: "esign", disabled: true },
                  Validators.required
                ),
                pageType: new UntypedFormControl(
                  { value: "Legal", disabled: true },
                  Validators.required
                ),
                isDocPreSign: new UntypedFormControl({
                  value: false,
                  disabled: this.isESignOrder,
                }),
                isSmartDoc: new UntypedFormControl({
                  value: true,
                  disabled: true,
                }),
              },
              {
                updateOn: "blur",
              }
            )
          );

          resolve(true);
        } catch (err) {
          console.error("Error:", [err, file]);
          reject(err);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  removeDocument(index: number): void {
    this.documents = this.documentsFormArray as UntypedFormArray;
    this.documents.removeAt(index);
  }

  get documentsFormArray(): UntypedFormArray {
    return this.documentForm.get("documents") as UntypedFormArray;
  }

  onDocumentNextStep(): void {
    this.documentStep++;
  }

  onDocumentPreviousStep(): void {
    this.documentStep--;
  }

  async onUploadDocuments(): Promise<void> {
    if (this.documentForm.invalid) {
      // invoke the validators on form controls
      (this.documentForm.get("documents") as UntypedFormArray).controls.forEach(
        (ctrl) => {
          FormError.ValidateAllFormFields(ctrl as UntypedFormGroup);
        }
      );

      this.toastr.warning("Please correct all errors.", "Warning");
      return;
    }

    this.showSpinner(true);
    this.isUploading = true;

    const documentCount = this.documents.controls.length;
    let allUploaded = true;
    for (let i = 0; i < documentCount; i++) {
      const controls = (this.documents.controls[i] as UntypedFormGroup).controls;
      const document = controls.file.value as NewDocument;
      const trimmedDocumentName = this.truncateToLength(document.name, 40);
      this.spinnerMessage = `Uploading ${
        i + 1
      } of ${documentCount}... ${trimmedDocumentName}`;

      // This will be the name of the document once it is actually uploaded.
      const documentName = controls.documentName.value;

      document.type = controls.documentType.value;
      document.pageType = controls.pageType.value;
      document.isPaper = controls.actionType.value === "print";
      document.isSmartDoc = controls.isSmartDoc.value;
      document.isDocPreSign = controls.isDocPreSign.value;

      const uploaded = await this.uploadDocumentAsync({
        ...document,
        name: documentName,
      }).catch((err) => console.error(err));
      if (uploaded) {
        this.toastr.success(
          `Document '${trimmedDocumentName}' uploaded successfully`
        );
      } else {
        this.toastr.error(`Unable to upload ${trimmedDocumentName}`);
        allUploaded = false;
      }
    }

    this.isUploading = false;
    this.showSpinner(false);
    if (allUploaded) {
      this.activeModal.close("saved");
    }
  }

  private truncateToLength(value: string, maxLength: number): string {
    const truncatedMaxLength =
      maxLength > value.length ? value.length : maxLength;
    return value.substr(0, truncatedMaxLength);
  }

  private async uploadDocumentAsync(document: NewDocument): Promise<boolean> {
    const self = this;

    return new Promise<boolean>((resolve, reject) => {
      self.documentService.addDocument(self.orderId, document).subscribe(
        () => {
          resolve(true);
        },
        (err) => {
          console.error("uploadDocumentAsync:", err);
          reject(err);
        }
      );
    });
  }

  private showSpinner(display: boolean, msg?: string): void {
    this.spinnerMessage = msg || "";

    if (display) {
      this.spinner.show("document-upload-spinner");
      return;
    }

    this.spinner.hide("document-upload-spinner");
  }

  getErrorMessage(group: UntypedFormArray, controlName: string) : string
  {
    let result = "";

    const formControl = group.get(controlName) as UntypedFormControl;

    if (!!formControl && formControl.errors !== null)
    {
      for (const formError of FormError.formErrors) {
        if (formError.isError(formControl))
        {
          result = formError.getMessage(formControl);
          break;
        }
      }
    }

    return result;
  }
}
