import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppSpinnerModule } from 'src/app/shared/modules/app-spinner.module';
import { CapabilitiesService } from 'src/app/shared/services/capabilities/capabilities.service';
import { FormError } from 'src/app/shared/components/app-form-error/form-error';
import { FormHelperService } from 'src/app/shared/helpers/form-helper.service';
import { FormValidators } from 'src/app/shared/validators/form.validators';
import { ModifiedOrderOrganizer, OrderOrganizer } from 'src/app/shared/models/order-organizer.model';
import { OrderOrganizerService } from 'src/app/shared/services/order-organizer.service';

import { OrderDetailStore } from '../../+state/order-detail.store';
import { UserRole } from '../participant-form-modal/participant-form-modal.models';

@UntilDestroy()
@Component({
  selector: 'app-order-organizer-form-modal',
  templateUrl: './order-organizer-form-modal.component.html',
  styleUrls: ['./order-organizer-form-modal.component.scss']
})
export class OrderOrganizerFormModalComponent implements OnInit, OnDestroy {

  informationForm: UntypedFormGroup;
  selectableRoles: UserRole[] = [
    { key: "ORDERMANAGER", value: "Order Manager" }
  ];

  duplicateOrderOrganizerErrorMessage: string;

  @Input() orderId: number;
  @Input() selectedOrderOrganizer: OrderOrganizer;

  orderOrganizers: OrderOrganizer[];
  orderOrganizersSub: Subscription;
  isSelectedFromClientAccounts: boolean;

  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  constructor(
    private modalService: NgbModal,
    private spinner: AppSpinnerModule,
    private store: OrderDetailStore,
    private orderOrganizerService: OrderOrganizerService,
    private toastr: ToastrService,
    private capabilitiesService: CapabilitiesService) {
      this.orderOrganizersSub = this.store.organizers$
        .pipe(
          tap((organizers) => {
            if (organizers) {
              this.orderOrganizers = organizers;
            }
          })
        ).subscribe();
    }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.orderOrganizersSub) {
      this.orderOrganizersSub.unsubscribe();
    }
  }

  initForm(): void {
    this.informationForm = new UntypedFormGroup(
    {
      email: new UntypedFormControl("", [
        Validators.maxLength(128),
        Validators.email,
        Validators.required
      ]),
      confirmEmail: new UntypedFormControl("", [
        Validators.maxLength(128),
        Validators.email,
        Validators.required,
      ]),
      firstName: new UntypedFormControl("", [
        Validators.maxLength(128),
        Validators.required,
      ]),
      lastName: new UntypedFormControl("", [
        Validators.maxLength(128),
        Validators.required,
      ]),
    },
    {
      updateOn: "blur",
      validators: [
        FormValidators.confirmEmailAddressValidator('email', 'confirmEmail'),
        this.duplicateOrderOrganizer()
      ]
    });

    if(this.selectedOrderOrganizer) {
      this.informationForm.setValue({
        email: this.selectedOrderOrganizer.email,
        confirmEmail: this.selectedOrderOrganizer.email,
        firstName: this.selectedOrderOrganizer.firstName,
        lastName: this.selectedOrderOrganizer.lastName
      })
    }
  }

  resetModal(reason: "cancel" | "saved"): void {
    this.modalService.dismissAll(reason);
  }

  saveOrderOrganizer(): void {
    if(this.informationForm.valid) {
      this.spinner.show();

      FormHelperService.removeWhiteSpaceFromAllFields(this.informationForm);

      const modifiedOrderOrganizer: ModifiedOrderOrganizer = {
        email: this.informationForm.get('email')?.value,
        firstName: this.informationForm.get('firstName')?.value,
        lastName: this.informationForm.get('lastName')?.value
      };

      if(this.selectedOrderOrganizer) {

        this.orderOrganizerService.updateOrderOrganizer(this.selectedOrderOrganizer.id, modifiedOrderOrganizer)
        .subscribe(
          () => {
            this.onSaveOrderOrganizerSuccess();
          },
          error => {
            this.onSaveOrderOrganizerError(error);
          }
        )
      } else {
        this.orderOrganizerService.addOrderOrganizers(this.orderId, [modifiedOrderOrganizer])
        .subscribe(
          () => {
            this.onSaveOrderOrganizerSuccess();
          },
          error => {
            this.onSaveOrderOrganizerError(error);
          }
        )
      }
    } else {
      FormError.ValidateAllFormFields(this.informationForm);
    }
  }

  reloadOrderOrganizers(): void {
    this.orderOrganizerService
    .getOrderOrganizers(this.orderId)
    .pipe(untilDestroyed(this))
    .subscribe(result => {
      this.store.setOrganizers(result);
    });
  }

  onSaveOrderOrganizerSuccess() : void {
    this.reloadOrderOrganizers();

    // refresh the capabilities when the new organizer has been added
    this.capabilitiesService.getCapabilitiesByOrderId(this.orderId);

    this.spinner.hide();
    this.toastr.success('Order organizer saved successfully');
    this.resetModal("saved");
  }

  onSaveOrderOrganizerError(error: any) : void {
    this.spinner.hide();
    this.toastr.error(error.message);
  }

 duplicateOrderOrganizer(): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {

      const emailControl = formGroup?.get('email');
      const email = emailControl?.value?.toString().toUpperCase().trim();

      if(!this.orderOrganizers
        || this.orderOrganizers.length == 0
        || !email){
          return null;
        }

        const forbidden = this.orderOrganizers.some(o => o.email.toUpperCase() === email
          && o.id !== this.selectedOrderOrganizer?.id);

        const error = {
          duplicateOrderOrganizer : true
        };

        if(forbidden) {
          this.duplicateOrderOrganizerErrorMessage = 'This email address is already on the order';
          emailControl?.setErrors(error);
        }

        return forbidden ? error : null;
    };
  }
}
