import { NgModule } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@NgModule({})
export class AppSpinnerModule {
  constructor(private readonly spinner: NgxSpinnerService) { }

  show(name?: string) {
    this.spinner.show(name || undefined, { fullScreen: true });
  }

  hide(name?: string) {
    this.spinner.hide(name || undefined);
  }
}
