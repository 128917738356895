<ng-container>
  <div class="page-header-bar">
    <div class="content85percent">
      <div class="float-left">
        <h1>Orders</h1>
      </div>
    </div>
  </div>
  <div class="content85percent">
    <app-filter-form></app-filter-form>
    <div>
      <h2 class="float-left">Order Search</h2>
      <ng-container>
        <div class="float-right"
             [showIfHasCapability]="[
              {
                capabilityString: 'CanCreateOrder',
                type: 'system'
              },
              {
                capabilityString: 'CanCreateOrder',
                type: 'client'
              }
             ]">
          <button type="button"
                  class="clrsgn-btn-default float-right"
                  (click)="openOrderCreateModal()">
            Create Order
          </button>
        </div>
      </ng-container>      
    </div>

    <table id="orderSearchSectionTable" class="table table-striped table-responsive"
           data-cy="orderTableSection">
      <thead>
        <tr>
          <th scope="col" class="text-left sortable" [ngClass]="{'active-sort-column': sortColumn == 'PackageId'}" style="width: 9vw" (click)="sortByColumn('PackageId')">
            Order #
            <span class="fas fa-arrow-down" *ngIf="!isSortDirectionAscending && sortColumn === 'PackageId'"></span>
            <span class="fas fa-arrow-up" *ngIf="isSortDirectionAscending && sortColumn === 'PackageId'"></span>
          </th>
          <th scope="col" class="text-left sortable" [ngClass]="{'active-sort-column': sortColumn == 'ClientLoanNumber'}" style="width: 12vw" (click)="sortByColumn('ClientLoanNumber')">
            Loan #
            <span class="fas fa-arrow-down sort-desc-icon" *ngIf="!isSortDirectionAscending && sortColumn === 'ClientLoanNumber'"></span>
            <span class="fas fa-arrow-up sort-asc-icon" *ngIf="isSortDirectionAscending && sortColumn === 'ClientLoanNumber'"></span>
          </th>
          <th scope="col" class="text-left sortable" [ngClass]="{'active-sort-column': sortColumn == 'VendorOrderNumber'}" style="width: 12vw" (click)="sortByColumn('VendorOrderNumber')">
            3rd Party Order ID
            <span class="fas fa-arrow-down sort-desc-icon" *ngIf="!isSortDirectionAscending && sortColumn === 'VendorOrderNumber'"></span>
            <span class="fas fa-arrow-up sort-asc-icon" *ngIf="isSortDirectionAscending && sortColumn === 'VendorOrderNumber'"></span>
            <span class="fa-regular fa-circle-question tooltip-icon" ngbTooltip="An additional number created by the 3rd party title service used to track the order within the Clear Sign system" placement="bottom" openDelay="500"></span>
          </th>
          <th *ngIf="isOrderManager && (isPortalAssigneeEnabled$ | async)" scope="col" class="text-left" style="width: 14vw">
            Assigned To
          </th>
          <th scope="col" class="text-left sortable" [ngClass]="{'active-sort-column': sortColumn == 'ScheduledDateTime'}" style="width: 15vw" (click)="sortByColumn('ScheduledDateTime')">
            Scheduled Date/Time
            <span class="fas fa-arrow-down sort-desc-icon" *ngIf="!isSortDirectionAscending && sortColumn === 'ScheduledDateTime'"></span>
            <span class="fas fa-arrow-up sort-asc-icon" *ngIf="isSortDirectionAscending && sortColumn === 'ScheduledDateTime'"></span>
            <span class="fa-regular fa-circle-question tooltip-icon" ngbTooltip="This is the date and time the signing is scheduled for. The scheduled time is based on the property address' time zone." placement="bottom" openDelay="500"></span>
          </th>
          <th scope="col" class="text-left" style="width: 11vw">
            Participants
          </th>
          <th scope="col" class="text-left" style="width: 15vw">
            Address
          </th>
          <th scope="col" class="text-left sortable" [ngClass]="{'active-sort-column': sortColumn == 'ProductType'}" style="width: 7vw" (click)="sortByColumn('ProductType')">
            Type
            <span class="fas fa-arrow-down sort-desc-icon" *ngIf="!isSortDirectionAscending && sortColumn === 'ProductType'"></span>
            <span class="fas fa-arrow-up sort-asc-icon" *ngIf="isSortDirectionAscending && sortColumn === 'ProductType'"></span>
            <span class="fa-regular fa-circle-question tooltip-icon" ngbTooltip="Closing types: RON (Remote Online Notarization), KRON (Known Signer Remote Online Notarization), IPEN (In-Person Electronic Notarization), Hybrid (partially in-person and online)" placement="bottom" openDelay="500"></span>
          </th>
          <th scope="col" class="text-left sortable" [ngClass]="{'active-sort-column': sortColumn == 'StatusCode'}" style="width: 8vw" (click)="sortByColumn('StatusCode')">
            Status
            <span class="fas fa-arrow-down sort-desc-icon" *ngIf="!isSortDirectionAscending && sortColumn === 'StatusCode'"></span>
            <span class="fas fa-arrow-up sort-asc-icon" *ngIf="isSortDirectionAscending && sortColumn === 'StatusCode'"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <div *ngIf="
            (filterForm?.searchResult$ | async) !== undefined;
            then displayResults;
            else displayEmpty
          "></div>
        <ng-template #displayEmpty>
          <tr #displayEmpty>
            <td colspan="7" style="text-align: center">
              <em>No results found</em>
            </td>
          </tr>
        </ng-template>
        <ng-template #displayResults>
          <ng-container *ngIf="filterForm?.searchResult$ | async as results">
            <tr *ngFor="let result of results.results" data-cy="orderRecord">
              <td>
                <a [routerLink]="['/order-detail', result.order.id]"
                   class="clrsgn-link">{{ result.order.id }}</a>
              </td>
              <td>
                {{ result.order.loanIdentifier }}
              </td>
              <td>
                {{ result.order.thirdPartyOrderIdentifier }}
              </td>
              <td *ngIf="isOrderManager && (isPortalAssigneeEnabled$ | async)">
                  <ng-select
                  class="assignee-dropdown"
                  [items]="(result.assigneesList$ | async)"
                  bindLabel="fullName"
                  [virtualScroll]="true"
                  [clearable]="false"
                  [searchFn]="assigneeSearchFn"
                  bindValue="accountId"
                  labelForId="assignees"
                  (change)="setAssignee(result.order.id, $event)"
                  [placeholder]="(result.currentAssigneeName$ | async)"
                  >
                  <ng-template ng-option-tmp let-item="item">
                    <div
                      class="text-truncate"
                      ngbTooltip="{{ item.fullName }} {{item.emailAddress}}"
                      placement="right"
                      container="body"
                      openDelay="500"
                    >
                      <span class="font-weight-bold">{{item.fullName}}</span> <p class="emailAddress text-truncate">{{item.emailAddress}}</p>
                    </div>
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item" let-search="searchTerm" let-index="index">
                    <div
                      class="text-truncate"
                    >
                      {{item.fullName}}
                    </div>
                  </ng-template>
                </ng-select>
              </td>
              <td>
                {{
                  convertScheduledDateTime(result.order.scheduledDateTime)
                    | date: "M/d/yyyy &nbsp;&nbsp;&nbsp; h:mm a"
                }}
              </td>
              <td [innerHTML]="result.borrowerNames.join('<br/>')"></td>
              <td>
                {{ result.order.propertyAddress.streetAddress1 }}<br />
                {{ result.order.propertyAddress.city }},
                {{ result.order.propertyAddress.stateCode }}
                {{ result.order.propertyAddress.zipCode }}
              </td>
              <td *ngIf="result.order.productType === 'RemoteSigning'">
                RON
              <td *ngIf="result.order.productType === 'IPEN'">
                IPEN
              <td *ngIf="result.order.productType === 'Hybrid'">
                Hybrid
              </td>
              <td *ngIf="result.order.productType === 'KnownSignerRON'">
                Known Signer RON
              <td *ngIf="result.order.productType === 'eSign'">
                eSign
              </td>
              <td>
                {{ result.order.statusCode | titleCaseStatusCode }}
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </tbody>
    </table>

    <div class="row pagination-section">
      <div class="col align-self-end">
        <app-pagination-form
          *ngIf="(filterForm?.searchResult$ | async)"
          [numPages]="filterForm?.totalPages"
          [currentPage]="filterForm?.pageNumber"
          [currentItemsPerPage]="filterForm?.pageSize"
          [canGoForward]="filterForm?.canMoveForward"
          [canGoBackward]="filterForm?.canMoveBackward"
          (change)="onPaginatorChanged()">
        </app-pagination-form>
      </div>
    </div>
  </div>
</ng-container>
