import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { catchError, distinctUntilChanged, filter, first, map, switchMap, tap } from "rxjs/operators";
import { OrderDetailStore } from "src/app/order-detail/+state/order-detail.store";
import { Features } from "src/app/shared/enums/Features";
import { ShowOnlyType } from "src/app/shared/enums/ShowOnlyType";
import { ClientAccount, ClientAccounts } from "src/app/shared/models/account";
import { Assignee, AssigneeResponseModel } from "src/app/shared/models/assignee.model";
import { DisplayStatus } from "src/app/shared/models/display-status";
import { OrderSearchResultList } from "src/app/shared/models/orderSearchList";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { AssigneeService } from "src/app/shared/services/assignee.service";
import { FeaturesService } from "src/app/shared/services/features.service";
import { OrderSearchModel } from "../../models/order-search.models";
import { OrderSearchService } from "../../services/order-search.service";
import { FormError } from 'src/app/shared/components/app-form-error/form-error';
import { Client } from "../../../shared/models/client.model";
import { ClientService } from "../../../shared/services/client.service";
import { SystemAccountService } from "../../../shared/services/system-account.service";
import { AccountService } from "../../../shared/services/account.service";
import { MatSelect } from "@angular/material/select";

export enum AcceptedDateTypes {
  Start = 'start',
  End = 'end',
  UTC = 'utc'
}

@UntilDestroy()
@Component({
  selector: "app-filter-form",
  templateUrl: "./filter-form.component.html",
  styleUrls: ["./filter-form.component.scss"],
})
export class FilterFormComponent implements OnInit, AfterViewInit {
  readonly numberOfAssigneesToDisplay: number = 5;
  readonly allUnassignedTranslationKey: string = 'manageOrders.filterForm.allUnassigned';
  readonly allOrganizationsTranslationKey: string = 'manageOrders.filterForm.allOrganizations';
  readonly allMyOrdersTranslationKey: string = 'manageOrders.filterForm.allMyOrders';
  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  @ViewChild('clientSelect') clientSelect: MatSelect;
  @ViewChild('clientInput') clientInput: ElementRef;

  ranges = {
    Default: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)],
  };

  searchForm: UntypedFormGroup;
  searchScheduledDate: UntypedFormGroup;

  orderStatuses: DisplayStatus[];

  isOrderManager: boolean;
  pageNumber: number;
  totalPages: number;
  pageSize: number = 10;
  canMoveBackward: boolean;
  canMoveForward: boolean;
  sortByColumn: string = "ScheduledDateTime";
  isSortOrderAscending: boolean = false;
  allMyOrdersState: any = {
    isSelected: false,
    resetViewMyOrders: false,
  };

  currentSearch: OrderSearchModel;

  clientAccountsSettlementAgent$: Observable<ClientAccounts>;
  clientAccountsOrderManager$: Observable<ClientAccounts>;
  clients$: Observable<Client[]>;
  filteredClients: Client[];
  searchResult$: Observable<OrderSearchResultList>;
  isPortalAssigneeEnabled$: Observable<boolean>;

  // The items to display in the assignees dropdown.
  filteredAssignees$: Observable<Assignee[]>;
  // Assignee search criteria.
  assigneesInput$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // Signal to the assignees dropdown if we're loading or not.
  assigneesLoading = false;
  // Unfiltered unassignee list from the end point.
  unfilteredAssignees$: Observable<Assignee[]>;

  defaultScheduledDate: any = {
    startDate: this.ranges.Default[0],
    endDate: this.ranges.Default[1]
  }

  minDate: Date = new Date('2020-01-01');

  constructor(
    private readonly accountService: AccountService,
    private readonly clientService: ClientService,
    private readonly orderSearchService: OrderSearchService,
    private readonly systemAccountService: SystemAccountService,
    private readonly spinner: AppSpinnerModule,
    private toastr: ToastrService,
    private featureService: FeaturesService,
    private assigneeService: AssigneeService,
    private store: OrderDetailStore
  ) { }

  ngOnInit(): void {
    this.setFeatures();
    this.setPortalAssigneeEnabled();
    this.initObservablesAndSearch();
    this.initOrderStatus();
    this.initSearchFilters();
  }

  ngAfterViewInit(): void {
    this.clientSelect.openedChange.pipe(
      untilDestroyed(this)
    ).subscribe((opened) => {
      if (opened) {
        this.clientInput.nativeElement.focus();
      }
    });
  }

  async onSearch() {
    this.pageNumber = 1;
    const form = this.searchForm.getRawValue();
    const searchScheduledDateForm = this.searchScheduledDate.getRawValue();
    const startDate: Date = this.getDate(searchScheduledDateForm, AcceptedDateTypes.Start);
    const endDate: Date = this.getDate(searchScheduledDateForm, AcceptedDateTypes.End);

    this.currentSearch = {
      loanIdentifier: this.trimSpaces(form.loanNumber) || "",
      borrowerLastName: form.clientLastName || "",
      streetAddress1: form.address || "",
      scheduledDateTimeStart: startDate.toISOString(),
      scheduledDateTimeEnd: endDate.toISOString(),
      vendorOrderNumber: this.trimSpaces(form.thirdPartyOrderIdentifier) || "",
      clientId: form.client || "",
      assigneeAccountId: form.assignee || "",
      startRow: 1,
      maxRow: this.pageSize,
      isParticipant: form.viewMyOrders,
      statusCode: form.orderStatus || "",
      sortByColumn: this.sortByColumn,
      isSortOrderAscending: this.isSortOrderAscending,
      showOnly: this.mapFormAssigneeToShowType(form.assignee)
    };

    if (this.searchForm.invalid || this.searchScheduledDate.invalid) {
      this.toastr.warning("Please correct all errors.", "Attention");
      return;
    }

    this.executeSearch();
  }

  private mapFormAssigneeToShowType(assignee: any): string[] {
    const result: string[] = [];

    if (assignee === 0) {
      result.push(ShowOnlyType.OnlyUnAssignedOrders);
    }

    return result;
  }

  private getDate(form: any, dateType: AcceptedDateTypes) {
    switch (dateType) {
      case AcceptedDateTypes.Start:
        const startDate = new Date(form.startDate);
        startDate.setHours(0, 0, 0, 0);
        return startDate;
      case AcceptedDateTypes.End:
        const endDate = new Date(form.endDate);
        endDate.setHours(23, 59, 59, 999);
        return endDate;
    }
    return new Date();
  }

  onReset(): void {
    let currentClientId = this.searchForm.get("client")?.value;
    this.searchForm.reset();

    this.searchForm.get("client")?.patchValue(currentClientId);
    this.searchForm.get("orderStatus")?.patchValue("");
    this.searchScheduledDate.get("startDate")?.patchValue(new Date(this.defaultScheduledDate.startDate));
    this.searchScheduledDate.get("endDate")?.patchValue(new Date(this.defaultScheduledDate.endDate));

    this.searchForm.get("viewMyOrders")?.patchValue(this.allMyOrdersState.isSelected);
    this.allMyOrdersState.resetViewMyOrders = false;

    this.onSearch();
  }

  onSetClient(clientId: number): void {
    if (clientId?.toString() === '') {
      this.allMyOrdersState.isSelected = true;
      this.allMyOrdersState.resetViewMyOrders = this.searchForm.get('viewMyOrders')?.value;
      this.searchForm.get('viewMyOrders')?.setValue(true);
      this.searchForm.get('viewMyOrders')?.disable();
    }
    else if (this.allMyOrdersState.isSelected) {
      this.allMyOrdersState.isSelected = false;
      this.searchForm.get('viewMyOrders')?.setValue(this.allMyOrdersState.resetViewMyOrders);
      this.searchForm.get('viewMyOrders')?.enable();
    }

    this.searchForm.controls['client'].setValue(clientId);
  }

  onSetOrderStatus(selectedOrderStatus): void {
    this.searchForm.controls['orderStatus'].setValue(selectedOrderStatus);
  }

  onSetAssignee(selectedAssignee: Assignee): void {
    this.searchForm.controls['assignee'].setValue(selectedAssignee.accountId);
  }

  onSearchViewSwap(): void {
    this.onSearch();
  }

  executeSearch(): void {
    window.scrollTo(0, 0);
    this.totalPages = 0;
    this.spinner.show();
    if (this.pageNumber == 1) {
      this.currentSearch.startRow = this.pageNumber;
    } else {
      this.currentSearch.startRow = (this.pageNumber - 1) * this.pageSize + 1;
    }
    this.currentSearch.maxRow = this.pageSize;
    this.currentSearch.sortByColumn = this.sortByColumn || "";
    this.currentSearch.isSortOrderAscending = this.isSortOrderAscending;

    this.searchResult$ = this.orderSearchService
      .search(this.currentSearch)
      .pipe(
        map((result) => {
          for (let r of result.results) {
            r.assigneesList$ = this.eligibleAssigneeLoader(r.order.id);
            r.currentAssigneeName$ = this.getCurrentOrderAssignee(r.order.id);
          }
          return result;
        }),
        tap((searchResult) => {
          this.totalPages = Math.ceil(searchResult.totalRows / this.pageSize);
          if (this.totalPages < 1) {
            this.pageNumber = 0;
          }

          this.canMoveBackward = this.totalPages > 0 && this.pageNumber > 1;
          this.canMoveForward =
            this.totalPages > 0 && this.pageNumber < this.totalPages;
          this.spinner.hide();
        }),
        catchError((err) => {
          console.log(err);
          this.toastr.error(
            "Sorry, an error occurred while performing the search.",
            "Error"
          );
          this.spinner.hide();
          throw err;
        }),
        untilDestroyed(this)
      );
  }

  initObservablesAndSearch(): void {
    this.isOrderManager = false;

    this.clientLoader();
    this.orderManagerLoader();
    this.assigneeLoader("");
  }

  clientLoader() {
    const accountTypes = ['SettlementAgent', 'SigningAgent', 'OrderManager'];
    const systemUser = [];
    this.systemAccountService.getCurrentSystemAccounts()
      .pipe(
        tap((systemAccounts) => {
          if (systemAccounts.length > 0) {
            this.clients$ = this.clientService.getClientListByAccountType(systemUser).pipe();
            this.performClientFilter();
          }
          else {
            this.clients$ = this.clientService.getClientListByAccountType(accountTypes);
            this.performClientFilter();
          }
        }),
        switchMap(() => this.clients$.pipe(first()))
      )
      .subscribe((clients) => {
        if (clients && clients.length > 0) {
          this.searchForm
            .get("client")
            ?.patchValue(clients[0].clientId);

            this.onSearch();
        }
      });
  }

  orderManagerLoader(): void {
    this.clientAccountsOrderManager$ = this.accountService.getAssigneeClientAccounts()
      .pipe(
        map(clientAccounts => {
          let clients = clientAccounts.clients?.filter((clientAccount) => clientAccount.accountType.toUpperCase() === "ORDERMANAGER" && clientAccount.clientId != 0)
            ?.sort(function (a, b) {
              return a.clientName?.localeCompare(b.clientName, 'en', { 'sensitivity': 'base' });
            })
            .filter(
              (thing, index, self) =>
                self.findIndex((t) => t.clientId === thing.clientId) === index
            );

          if (clientAccounts.clients?.find((clientAccount) => clientAccount.accountType.toUpperCase() === "ORDERMANAGER") && clients.length === 0) {
            const allMyOrdersOption = {
              clientId: '',
              clientName: 'All Organizations'
            } as any as ClientAccount;
            clients.push(allMyOrdersOption);
          }

          return {
            clients,
          };
        }),
        tap((clientAccounts) => {
          if (clientAccounts.clients?.length > 0) {
            this.isOrderManager = true;
          }
        })
      );
  }

  trimSpaces(searchItem: string | null) {
    if (typeof searchItem === "string") {
      return searchItem.trim();
    } else {
      return searchItem;
    }
  }

  // This method is really complicated and difficult to read
  // but at the time of this comment it was the easiest way
  // to the best of our ability to accomplish the end goal.
  // In the future this really should be refactored to take advantage
  // of the RXJS store when the Portal UI gets refactored as a whole
  // to reduce the amount of observable maddness inside this particular
  // component.
  assigneeLoader(search: string): void {
    const emptyResponse: AssigneeResponseModel = {
      orderManagers: []
    };

    this.unfilteredAssignees$ = this.assigneeService.getAssignees().pipe(
      catchError(() => of(emptyResponse)),
      map((response) => {
        return response?.orderManagers;
      })
    );


    this.filteredAssignees$ = combineLatest([
      // Unfiltered assignees
      this.unfilteredAssignees$,
      // Client accounts for our current order manager user.
      this.clientAccountsOrderManager$,
      // Whether or not the feature is even enabled.
      this.isPortalAssigneeEnabled$
    ]).pipe(
      // Don't proceed unless we have distinct values from
      // the last time this pipe executed.
      distinctUntilChanged(),
      // If we have no associated client accounts OR
      // the feature is not even enabled then stop here.
      filter(([_assignees, clientAccounts, isFeatureEnabled]) => isFeatureEnabled && clientAccounts.clients?.length > 0),
      // Signal to the ng-select dropdown that we are loading the list.
      tap(() => this.assigneesLoading = true),
      // Getting down to the meat of the matter here is where
      // we will actually get the assignees and filter them out
      // using our search term.
      map(([assignees]) => {
        const termEmpty: boolean = (!!!search || search?.trim().length < 1);
        // This will ultimately be the list of items
        // that the ng-select dropdown will be using.
        // We'll populate the list using the response
        // filtered by our search term if applicable.
        const filteredAssignees: Assignee[] = (assignees?.filter(
          (assignee) => termEmpty || assignee.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1) ?? [])
          .slice(0, this.numberOfAssigneesToDisplay);

        // If we have a empty search term then add the 'All Unassigned'
        // option to the list.
        if (termEmpty) {
          filteredAssignees.unshift({
            accountId: 0,
            firstName: '',
            lastName: '',
            fullName: 'All unassigned',
            emailAddress: ''
          } as Assignee);
        }
        // Signal to the ng-select dropdown that we're finished loading.
        this.assigneesLoading = false;

        return filteredAssignees;
      })
    );
  }

  eligibleAssigneeLoader(orderId): Observable<Assignee[]> {
    return this.isPortalAssigneeEnabled$.pipe(
      filter(isFeatureEnabled => isFeatureEnabled),
      switchMap(() => this.assigneeService.getEligibleAssignees(orderId)),
      map(assigneeResponse => [
        {
          accountId: 0,
          firstName: '',
          lastName: '',
          fullName: 'No assignee',
          emailAddress: ''
        } as Assignee,
        ...assigneeResponse?.orderManagers
      ])
    );
  }

  initSearchFilters(): void {
    this.searchForm = new UntypedFormGroup({
      loanNumber: new UntypedFormControl("", Validators.maxLength(100)),
      clientLastName: new UntypedFormControl("", Validators.maxLength(100)),
      address: new UntypedFormControl("", Validators.maxLength(100)),
      thirdPartyOrderIdentifier: new UntypedFormControl("", Validators.maxLength(100)),
      client: new UntypedFormControl(""),
      clientFilter: new UntypedFormControl(""),
      assignee: new UntypedFormControl(""),
      orderStatus: new UntypedFormControl(""),
      viewMyOrders: new UntypedFormControl(false)
    });

    this.searchScheduledDate = new UntypedFormGroup({
      startDate: new UntypedFormControl(
        new Date(this.defaultScheduledDate.startDate),
        Validators.required
      ),
      endDate: new UntypedFormControl(
        new Date(this.defaultScheduledDate.endDate),
        Validators.required
      ),
    });
  }

  initOrderStatus(): void {
    this.orderStatuses = [
      {
        value: "",
        displayValue: "All order statuses"
      },
      {
        value: "READY",
        displayValue: "Ready",
      },
      {
        value: "INPROGRESS",
        displayValue: "In Progress",
      },
      {
        value: "SIGNING",
        displayValue: "Signing",
      },
      {
        value: "SIGNINGFAILED",
        displayValue: "Signing Failed",
      },
      {
        value: "OPTOUT",
        displayValue: "Opt-Out",
      },
      {
        value: "CANCELLED",
        displayValue: "Cancelled",
      },
      {
        value: "SESSIONCOMPLETE",
        displayValue: "Session Complete",
      },
      {
        value: "VAULTING",
        displayValue: "Vaulting",
      },
      {
        value: "VAULTINGFAILED",
        displayValue: "Vaulting Failed",
      },
      {
        value: "COMPLETE",
        displayValue: "Complete",
      },
    ];
  }

  getCurrentOrderAssignee(orderId: number): Observable<string> {
    return this.assigneeService.getOrderAssigneesByOrderId(orderId)
      .pipe(
        map((result) => {
          return result.fullName ? result.fullName : "No assignee";
        })
      )
  }

  setFeatures(): void {
    this.featureService
      .getFeatures()
      .pipe(
        tap(async (result) => {
          this.store.setFeatures(result);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setPortalAssigneeEnabled(): void {
    this.isPortalAssigneeEnabled$ =
      this.featureService.isFeatureEnabled(
        Features.PortalAssignee
      );
  }

  performClientFilter() {
    const filterBy = this.searchForm.get("clientFilter")?.value;
    this.clients$.pipe(
      map((clients) => {
        return clients.filter((client) => {
          return client.clientName
            .toLocaleLowerCase()
            .includes(filterBy.toLocaleLowerCase());
        });
      })
    ).subscribe((clients) => {
      this.filteredClients = clients;
    })
  }
}


// TODO: Toggle Ascending Descending
// Reset Ascending vs Descending and call from 1st page if new column is selected
// maybe make some enums for property names
// check if sort by column changed to handle initial to be default desc?
