import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { userFriendlyRoleMap } from "./user-friendly-role-map";
import { combineLatest, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { UntilDestroy } from "@ngneat/until-destroy";

import { CapabilitiesService } from "src/app/shared/services/capabilities/capabilities.service";
import { environment } from "src/environments/environment";
import { ListViewParticipant } from "src/app/shared/models/participant.model";
import { OrderDetailStore } from "src/app/order-detail/+state/order-detail.store";
import { OrderParticipantPresignStatus } from "src/app/shared/models/order-presign-status";

import { PackageParticipantAction } from "./actions/package-participant-action";
import { PackageParticipantEditAction } from "./actions/package-participant-edit-action";
import { PackageParticipantRemoveAction } from "./actions/package-participant-remove-action";
import { PackageParticipantResetLogin } from "./actions/package-participant-reset-login";

@UntilDestroy()
@Component({
  selector: "app-participant-list",
  templateUrl: "./participant-list.component.html",
  styleUrls: ["./participant-list.component.scss"],
})
export class ParticipantListComponent implements OnInit, OnDestroy {
  private actionHandler: (ParticipantListUser) => void;

  constructor(
    public store: OrderDetailStore,
    private capabilitiesService: CapabilitiesService,
  ) {}

  // TODO: Make an api function for getting this map (from the UserRole table).

  @Input() participants: ListViewParticipant[];
  @Input() showSessionLink: boolean;
  @Input() showActions: boolean;
  @Input() participantPresignStatuses: Record<
    number,
    OrderParticipantPresignStatus
  > = {};
  @Input() orderId: number;

  @Output() geturlCalled = new EventEmitter<ListViewParticipant>();
  @Output() editActionCalled = new EventEmitter<ListViewParticipant>();
  @Output() resetLoginActionCalled = new EventEmitter<ListViewParticipant>();
  @Output() removeActionCalled = new EventEmitter<ListViewParticipant>();

  canGetLink$: Observable<boolean>;
  orderSubscription: Subscription;
  productType: string;

  ngOnInit() {
    this.determineCanGetLink();
    this.orderSubscription = this.store.order$.subscribe(
      (order) => (this.productType = order.productType)
    );
  }

  ngOnDestroy(): void {
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }
  }

  determineCanGetLink() {
    this.canGetLink$ = this.store.orderStatus$.pipe(
      map((orderStatus) => {
        return (orderStatus === "READY" || orderStatus === "INPROGRESS");
      })
    );
  }

  getFriendlyRole(participant: ListViewParticipant): string {
    return userFriendlyRoleMap[participant.participantModifiable.role];
  }

  getActionsForUser(
    participant: ListViewParticipant
  ): Observable<PackageParticipantAction[]> {
    return combineLatest([
      this.store.isOrderLocked$,
      this.capabilitiesService.userHasCapability(
        "participant",
        "CanEditParticipant",
        participant?.id
      ),
      this.capabilitiesService.userHasCapability(
        "participant",
        "CanRemoveParticipant",
        participant?.id
      ),
      this.capabilitiesService.userHasCapability(
        "participant",
        "CanResetLogin",
        participant?.id
      ),
    ]).pipe(
      map(
        ([
          isOrderLocked,
          canEditParticipant,
          canRemoveParticipant,
          canResetLogin,
        ]) => {
          const actions = [] as PackageParticipantAction[];

          if (canRemoveParticipant && !isOrderLocked) {
            actions.unshift(
              new PackageParticipantRemoveAction(
                participant,
                this.handleRemoveAction
              )
            );
          }

          if (canResetLogin) {
            actions.unshift(
              new PackageParticipantResetLogin(
                participant,
                this.handleResetLoginAction
              )
            );
          }

          if (canEditParticipant && !isOrderLocked) {
            actions.unshift(
              new PackageParticipantEditAction(
                participant,
                this.handleEditAction
              )
            );
          }

          return actions;
        }
      )
    );
  }

  participantHasLink(participant: ListViewParticipant) {
    var isRon =
      this.productType?.toUpperCase() === "REMOTESIGNING" ||
      this.productType?.toUpperCase() === "KNOWNSIGNERRON";
    if (!!participant.participantUrl?.consumerPortalUrl && !isRon) {
      return true;
    }
    return !!participant.participantUrl?.url;
  }

  handleGeturlAction(participant: ListViewParticipant) {
    this.geturlCalled.emit(participant);
  }

  handleEditAction(participant: ListViewParticipant) {
    this.editActionCalled.emit(participant);
  }

  handleResetLoginAction(participant: ListViewParticipant) {
    this.resetLoginActionCalled.emit(participant);
  }

  handleRemoveAction(participant: ListViewParticipant) {
    this.removeActionCalled.emit(participant);
  }

  handleActionCall(action: PackageParticipantAction) {
    this.actionHandler = action.action;
    this.actionHandler(action.participant);
  }

  translateParticipantIdToPreSignStatus(participantId: number) {
    const participantPresignStatus =
      this.participantPresignStatuses[participantId];
    if (!participantPresignStatus || !participantPresignStatus.isPreSign) {
      return "";
    }

    if (participantPresignStatus.isComplete) {
      return "Complete";
    }

    if (participantPresignStatus.isStarted) {
      return "Started";
    }

    return "Not Started";
  }

  isMultiFactorEnabled(): boolean {
    return environment.features.isMultiFactorEnabled === "true";
  }
}
