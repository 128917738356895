import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "friendlyDocStatus" })
export class DocumentStatusPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    switch (value.toUpperCase()) {
      case "SIGNED":
        return "Signed";
      case "PARTSIGNED":
        return "Partially Signed";
      default:
        return "Signable";
    }
  }
}
