import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { tap, withLatestFrom, delay, take } from 'rxjs/operators';

import { FormError } from 'src/app/shared/components/app-form-error/form-error';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LazyInjectService } from 'src/app/shared/services/lazy-inject/lazy-inject.service';
import { PdfjsService, PDFPage } from "src/app/shared/services/pdfjs/pdfjs.service";

import { AppSpinnerModule } from '../../../shared/modules/app-spinner.module';
import { DigiDocsStore, DigiDocsSection } from '../../+state/digi-docs.store';
import { DigitizationParameter, PAGE_SIZES } from '../../models/digitization-parameter.model';
import { Document } from '../../../shared/models/document.models';
import { FormValidators } from '../../../shared/validators/form.validators';
import { from, Observable, Subscription } from 'rxjs';
import { ImageData } from '../../models/image-data.model';
import { PdfToPngService } from '../../services/pdf-to-png/pdf-to-png.service';
import { UploadModalCloseReason } from './upload-form.types';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent implements OnInit, OnDestroy {
  private pdfjsService: PdfjsService;

  docUploadForm: UntypedFormGroup;

  fileUploadErrorMessage: string;

  digitizationParameters: Subscription;
  digitizationParameters$: Observable<DigitizationParameter>;
  imageData$: Observable<ImageData>;
  documentFileName$: Observable<string>;
  templateOptions$: Observable<KeyValuePair[]>;

  @ViewChild("docImage") docImage: ElementRef;
  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  constructor(
    private activeModal: NgbActiveModal,
    private componentStore: DigiDocsStore,
    private pdfToPngService: PdfToPngService,
    private lazyInjector: LazyInjectService,
    private readonly spinner: AppSpinnerModule
  ) { }

  async ngOnInit(): Promise<void> {
    this.digitizationParameters$ = this.componentStore.digitizationParameters$;
    this.documentFileName$ = this.componentStore.documentFileName$;
    this.imageData$ = this.componentStore.imageData$;
    this.templateOptions$ = this.componentStore.templateOptions$;
    this.initForm();

    this.pdfjsService = await this.lazyInjector.get<PdfjsService>(() =>
      import("src/app/shared/services/pdfjs/pdfjs.service").then(
        (m) => m.PdfjsService
      )
    );
  }

  private getUniquePageSize(pages: PDFPage[]): PAGE_SIZES | boolean {
    return pages.reduce(
      (acc, curr) => (acc === curr.size ? curr.size : false),
      pages[0]?.size
    );
  }

  async updatePaperSize(paperSizes, uniquePaperSize) {
    const paperSize = paperSizes.find(({ value }) => value === uniquePaperSize);

    (this.docUploadForm.get("paperSize") as AbstractControl).setValue(paperSize);
    const paramsToUpdate: Partial<DigitizationParameter> = {
      selectedPaperSize: paperSize,
    };

    await this.componentStore.updateDigitizationParameters(paramsToUpdate);
  }

  async handleFileChange() {
    const file = this.docUploadForm.get("documentFile")?.value;

    if (file) {
      const fileReader = await this.getFileReader(file, "ArrayBuffer");
      const pageSizesResult = await this.pdfjsService.getPageSizes(fileReader.result as ArrayBuffer, {
        start: 1,
        max: null,
      });

      const pdfPages: PDFPage[] | null = pageSizesResult
        ? pageSizesResult[1]
        : null

      if (pdfPages) {
        this.digitizationParameters = this.digitizationParameters$.subscribe(
          async ({ paperSizes }) =>
            await this.updatePaperSize(
              paperSizes,
              this.getUniquePageSize(pdfPages)
            )
        );
      }
    }
  }

  initForm() {
    this.docUploadForm = new UntypedFormGroup({
      actionType: new UntypedFormControl({ value: 'eSign', disabled: true }),
      documentFile: new UntypedFormControl(null, [Validators.required, FormValidators.requiredFileType(["pdf"])]),
      documentName: new UntypedFormControl(null, [Validators.required, FormValidators.validateDocumentName]),
      documentType: new UntypedFormControl(null, [Validators.required, FormValidators.validateDocumentType]),
      paperSize: new UntypedFormControl(null, [Validators.required]),
      template: new UntypedFormControl(null)
    });
  }

  async uploadDocument() {
    if (this.docUploadForm.valid) {
      const formValue = this.docUploadForm.value;
      const imgSrc = await this.fetchImgFileData(formValue.documentFile);
      this.spinner.show();
      this.componentStore.setPdfBytes(imgSrc);
      this.pdfToPngService
        .getPngFromPdf(imgSrc)
        .pipe(
          withLatestFrom(this.componentStore.imageOverlayDivDimensions$),
          delay(1000),
          tap(([res, imageOverlayDivDimensions]) => {
            const imgDataUpdates: Partial<ImageData> = {};
            imgDataUpdates.src = "data:image/png;base64," + res;
            imgDataUpdates.fileName = formValue.documentName;
            imgDataUpdates.width = imageOverlayDivDimensions.widthRaw;
            imgDataUpdates.height = imageOverlayDivDimensions.heightRaw;
            const mySelectedDocument = this.getTemporaryDocument(formValue);
            const digitizationParameterUpdates: Partial<DigitizationParameter> = {
              mySelectedDocument,
              documentName: formValue?.documentName,
              selectedPaperSize: formValue?.paperSize,
              selectedDocumentType: formValue?.documentType,
              selectedTemplate: formValue?.template
            };
            this.componentStore.updateDigitizationParameters(digitizationParameterUpdates);
            this.componentStore.updateImageData(imgDataUpdates);
            this.spinner.hide();
            const closeReason: UploadModalCloseReason = (!formValue?.template) ? 'complete' : 'applyTemplate';
            const selectedTemplate = formValue?.template ?? null;
            this.componentStore.setRibbonTabToDisplay("Apply");
            this.componentStore.sectionToDisplay(DigiDocsSection.DOCUMENTS);
            this.closeUploadDocumentModel(closeReason, selectedTemplate);
          }),
          take(1)
        )
        .subscribe();
    } else {
      this.fileUploadErrorMessage = 'Please upload ".PDF" file';
    }
  }

  async getPngFromPdfjs(scale = 1) {
    if (this.docUploadForm.valid) {
      const formValue = this.docUploadForm.value;
      const imgSrc = await this.fetchImgFileData(formValue.documentFile);
      this.spinner.show();
      this.componentStore.setPdfBytes(imgSrc);
      from(this.pdfToPngService.getPngFromPdfjs(await formValue.documentFile.arrayBuffer(), scale))
        .pipe(
          withLatestFrom(this.componentStore.imageOverlayDivDimensions$),
          delay(1000),
          tap(([res, imageOverlayDivDimensions]) => {
            const imgDataUpdates: Partial<ImageData> = {};
            imgDataUpdates.src = res;
            imgDataUpdates.fileName = formValue.documentName;
            imgDataUpdates.width = imageOverlayDivDimensions.widthRaw;
            imgDataUpdates.height = imageOverlayDivDimensions.heightRaw;
            const mySelectedDocument = this.getTemporaryDocument(formValue);
            this.componentStore.addDocumentToDigitizationParameters(mySelectedDocument);
            const digitizationParameterUpdates: Partial<DigitizationParameter> = {
              mySelectedDocument,
              documentName: formValue?.documentName,
              selectedPaperSize: formValue?.paperSize,
              selectedDocumentType: formValue?.documentType,
              selectedTemplate: formValue?.template
            };
            this.componentStore.updateDigitizationParameters(digitizationParameterUpdates);
            this.componentStore.updateImageData(imgDataUpdates);
            this.spinner.hide();
            const closeReason: UploadModalCloseReason = (!formValue?.template) ? 'complete' : 'applyTemplate';
            const selectedTemplate = formValue?.template ?? null;
            this.componentStore.setRibbonTabToDisplay("Apply");
            this.componentStore.sectionToDisplay(DigiDocsSection.DOCUMENTS);
            this.closeUploadDocumentModel(closeReason, selectedTemplate);
          }),
          take(1)
        )
        .subscribe();
    } else {
      this.fileUploadErrorMessage = 'Please upload ".PDF" file';
    }
  }

  private getTemporaryDocument(formValue: any) {
    const mySelectedDocument = new Document();
    mySelectedDocument.id = 0;
    mySelectedDocument.isDigitized = true;
    mySelectedDocument.isPaper = false;
    mySelectedDocument.isPresignEligible = true;
    mySelectedDocument.isSmartDoc = false;
    mySelectedDocument.status = "SIGNABLE";
    mySelectedDocument.name = formValue?.documentName;
    mySelectedDocument.pageType = formValue?.paperSize?.value;
    return mySelectedDocument;
  }

  closeUploadDocumentModel(reason: UploadModalCloseReason, selectedTemplate: KeyValuePair | null = null) {
    if (reason === "applyTemplate" && selectedTemplate) {
      const digitizationParameters: Partial<DigitizationParameter> = {
        selectedTemplate
      };
      this.componentStore.updateDigitizationParameters(digitizationParameters);
    }

    this.activeModal.dismiss(reason);
  }

  displayFile(file: File | null) {
    const fileNameToUpdate: Partial<DigitizationParameter> = {};
    if (file) {
      this.fetchImgFileData(file);
      fileNameToUpdate.documentName = file?.name;
      this.fileUploadErrorMessage = "";
    } else {
      this.componentStore.resetImageData();
      fileNameToUpdate.documentName = "Choose File";
      const error = 'Please upload ".PDF" file only';
      this.fileUploadErrorMessage = error;
    }
    this.componentStore.updateDigitizationParameters(fileNameToUpdate);
  }

  private getFileReader(
    file: File,
    type: "DataURL" | "ArrayBuffer" = "DataURL"
  ): Promise<FileReader> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      if (type === "DataURL") {
        reader.readAsDataURL(file);
      }

      if (type === "ArrayBuffer") {
        reader.readAsArrayBuffer(file);
      }

      reader.onload = () => {
        resolve(reader);
      };
    });
  }

  public async fetchImgFileData(file: File): Promise<string> {
    const reader = await this.getFileReader(file);
    return reader.result !== null
      ? reader.result.toString().split("base64,")[1]
      : "";
  }

  getErrorMessage(group: UntypedFormArray, controlName: string) : string
  {
    let result = "";

    const formControl = group.get(controlName) as UntypedFormControl;

    if (!!formControl && formControl.errors !== null)
    {
      for (const formError of FormError.formErrors) {
        if (formError.isError(formControl))
        {
          result = formError.getMessage(formControl);
          break;
        }
      }
    }

    return result;
  }

  ngOnDestroy(): void {
    this.digitizationParameters?.unsubscribe();
  }
}
