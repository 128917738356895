<div class="modal-header">
  <h5 class="modal-title">Rule Violations</h5>
</div>

<div class="modal-body">
  <div 
    *ngIf="ruleViolations.documentRules.length > 0"
    class="document-rule-section"       
  >
    <img src="assets/images/fa-exclamation-circle-o.png" class="ml-2 float-left exclamation-img"/>
    <span class="ml-3">
      Document(s) need attention. Open the 
      <span class="document-rule-text-link" (click)="openDigiDocs()">document editor</span>
      and fix any errors to continue.
    </span>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="amr-btn-default"
    (click)="closeModal()"
  >
    Close
  </button>
</div>
