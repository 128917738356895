import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from "src/app/shared/services/orders.service";
import { Router } from "@angular/router";

@Component({
  templateUrl: "./complete-order-modal.component.html",
  styleUrls: ["./complete-order-modal.component.scss"]
})
export class CompleteOrderModalComponent {
  @Input() orderId: number;
  @Output() completing = new EventEmitter<number>();

  constructor(
    private readonly toastr: ToastrService,
    private readonly activeModal: NgbActiveModal,
    private readonly ordersService: OrdersService,
    private readonly router: Router,
  ) {}

  close(): void {
    this.activeModal.dismiss();
  }

  complete(): void {
    this.activeModal.close();
    this.completing.emit(this.orderId);
    this.ordersService.completeOrder(this.orderId)
      .subscribe(() => {
        const toastMessage = "Order Completed!";
        this.toastr.success(toastMessage);

        // Force Angular to reload the page. onInit logic in order-detail is complicated and
        // trying to handle refresh there could introduce bugs
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/order-detail', this.orderId]));
      }, () => {
        const toastMessage = "Error: Order not Completed!";
        this.toastr.error(toastMessage);
        this.completing.error(this.orderId);
      })
  }
}
