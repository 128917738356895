import { Document } from '../../../app/shared/models/document.models';
import { KeyValuePair } from '../../../app/shared/models/key-value-pair';

interface PaperSize {
  key: {
    width: PAGE_DIMENSIONS.LETTER_WIDTH | PAGE_DIMENSIONS.LEGAL_WIDTH,
    height: PAGE_DIMENSIONS.LETTER_HEIGHT | PAGE_DIMENSIONS.LEGAL_HEIGHT
  },
  value: PAGE_SIZES.LETTER | PAGE_SIZES.LEGAL
}

export enum PAGE_DIMENSIONS {
  LETTER_WIDTH = 8.5,
  LETTER_HEIGHT = 11,
  LEGAL_WIDTH = 8.5,
  LEGAL_HEIGHT = 14
}

export enum PAGE_RATIOS {
  LETTER = 0.7727,
  LEGAL = 0.6071,
}

export enum PAGE_SIZES {
  LETTER = 'Letter',
  LEGAL = 'Legal',
  UNKNOWN = 'Unknown',
}

export class DigitizationParameter {
  public actionType: string;
  public defaultUploadPlaceHolderTxt: string;
  public documentName: string;
  public documentType: string;
  public documentTypes: KeyValuePair[];
  public endorsementTypes: KeyValuePair[];
  public lenderDpi: number;
  public myDocuments: Document[];
  public mySelectedDocument: Document | null;
  public numberOfBorrower: KeyValuePair[];
  public numberOfNBTH: KeyValuePair[];
  public numberOfSeller: KeyValuePair[];
  public paperSizes: PaperSize[];
  public roles: KeyValuePair[];
  public selectedDocumentType: any;
  public selectedEndorsementType: string | null;
  public selectedNumberOfBorrower: number;
  public selectedNumberOfNBTH: number;
  public selectedNumberOfSeller: number;
  public selectedPaperSize: PaperSize | null;
  public selectedRole: any;
  public selectedTemplate: KeyValuePair | null;

  constructor() {
    this.actionType = "eSign";
    this.defaultUploadPlaceHolderTxt = "Choose File";
    this.documentName = "";
    this.documentType = "";
    this.documentTypes = this.getValuesForDocumentTypesDropdown();
    this.endorsementTypes = KeyValuePair[0];
    this.documentName = this.defaultUploadPlaceHolderTxt;
    this.lenderDpi = 72;
    this.myDocuments = [];
    this.mySelectedDocument = new Document();
    this.numberOfBorrower = this.getValuesForNumberDropdown();
    this.numberOfNBTH = this.getValuesForNumberDropdown();
    this.numberOfSeller = this.getValuesForNumberDropdown();
    this.paperSizes = this.getPaperSizes();
    this.roles = this.fetchDefaultRoles();
    this.selectedDocumentType = "";
    this.selectedEndorsementType = null;
    this.selectedNumberOfBorrower = 1;
    this.selectedNumberOfNBTH = 0;
    this.selectedNumberOfSeller = 0;
    this.selectedPaperSize = null;
    this.selectedRole = null;
    this.selectedTemplate = null;
  }

  private fetchDefaultRoles(): KeyValuePair[] {
    return [
      {
        key: {
          userRole: "Borrower",
          userType: "Borrower 1"
        },
        value: "Borrower 1"
      },
      { key: {
          userRole: "SigningAgent",
          userType: "Signing Agent"
        },
        value: "Signing Agent"
      }
    ];
  }

  private getValuesForNumberDropdown(): KeyValuePair[] {
    return [
      { key: 0, value: 0 },
      { key: 1, value: 1 },
      { key: 2, value: 2 },
      { key: 3, value: 3 },
      { key: 4, value: 4 },
      { key: 5, value: 5 },
      { key: 6, value: 6 },
      { key: 7, value: 7 },
      { key: 8, value: 8 },
      { key: 9, value: 9 },
      { key: 10, value: 10 }
    ];
  }

  private getValuesForDocumentTypesDropdown(): KeyValuePair[] {
    return [
      { key: "GrantorsAffidavit", value: "Grantors Affidavit" },
      { key: "URLA", value: "Uniform Residential Loan Application" },
      { key: "NoticeofRighttoCancel", value: "Notice of Right to Cancel" }
    ];
  }

  private getPaperSizes(): PaperSize[] {
    return [
      {
        key: {
          width: PAGE_DIMENSIONS.LETTER_WIDTH,
          height: PAGE_DIMENSIONS.LETTER_HEIGHT
        },
        value: PAGE_SIZES.LETTER
      },
      {
        key: {
          width: PAGE_DIMENSIONS.LEGAL_WIDTH,
          height: PAGE_DIMENSIONS.LEGAL_HEIGHT
        },
        value: PAGE_SIZES.LEGAL
      }
    ];
  }
}
