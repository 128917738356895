export class ImageOverlayDivDimensions {
  width: string;
  widthRaw: number;
  height: string;
  heightRaw: number;

  constructor() {
    this.width = "0px";
    this.widthRaw = 0;
    this.height = "0px";
    this.widthRaw = 0;
  }
}
