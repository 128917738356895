<ng-container>
    <div class="paginationContainer">
        <div class="container">
            <div class="row">
                <div class="col-5 text-left align-middle">
                    <label for="itemsPerPage">
                        Items Per Page:
                    </label>
                    <mat-select
                      id="itemsPerPage"
                      [(ngModel)]="currentItemsPerPage"
                      (selectionChange)="changeSelectedItemsPerPage()"
                    >
                      <mat-option
                        *ngFor="let items of itemsPerPage"
                        [value]="items"
                        >
                        {{ items }}
                      </mat-option>
                    </mat-select>
                </div>
                <div class="col-3 text-center align-middle">
                    <div id="pageNumber">
                        Page {{ currentPage }} of {{ numPages }}
                    </div>
                </div>
              <div class="col-4 text-right align-middle">
                  <button id="goFirst"
                          attr.aria-label="Go to first page"
                          (click)="goToFirstPage()"
                          [disabled]="_btnDisabledGoFirst ? true : null"
                          data-title="Go to first page">
                    <img src="assets/images/nav-jump-back.svg" alt="Go to first page" />
                  </button>
                    <button id="goPrevious"
                            attr.aria-label="Go to previous page"
                            (click)="goToPreviousPage()"
                            [disabled]="_btnDisabledGoPrevious ? true : null"
                            data-title="Go to previous page">
                      <img src="assets/images/nav-back.svg" alt="Go to previous page" />
                    </button>
                    <button id="goNext"
                            attr.aria-label="Go to next page"
                            (click)="goToNextPage()"
                            [disabled]="_btnDisabledGoNext ? true : null"
                            data-title="Go to next page">
                      <img src="assets/images/nav-forward.svg" alt="Go to next page" />
                    </button>
                    <button id="goLast"
                            attr.aria-label="Go to last page"
                            (click)="goToLastPage()"
                            [disabled]="_btnDisabledGoLast ? true : null"
                            data-title="Go to last page">
                      <img src="assets/images/nav-jump-forward.svg" alt="Go to last page"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
