export class DocumentBase {
  id: number;
  isPaper?: boolean;
  pageType?: string;
  sequenceNumber: number;
  totalPages?: number;
  type?: string;
  name: string;
  isSmartDoc?: boolean;
  isDigitized?: boolean;
  isDocPreSign?: boolean;
  isPresignEligible?: boolean;
  isVoid?: boolean;
  status?: string;
  errorText?: string;
  attributes?: Attribute[];

  constructor() {
    this.isPaper = false;
    this.pageType = "";
    this.sequenceNumber = 0;
    this.totalPages = 0;
    this.type = "";
    this.name = "";
    this.isSmartDoc = false;
    this.isDocPreSign = false;
    this.isVoid = false;
    this.status = "";
  }
}

export class Attribute {
  key: string;
  value: string;
}

export class Document extends DocumentBase {
  createdDate?: Date;
  typeName?: string;
  updatedDate?: Date;

  constructor() {
    super();
    this.id = 0;
    this.createdDate = new Date();
    this.typeName = "";
  }
}

export class NewDocument extends DocumentBase {
  bytes: string;

  constructor() {
    super();
    this.bytes = "";
  }
}

export class DocumentBytes {
  bytes: string;

  constructor() {
    this.bytes = "";
  }
}
