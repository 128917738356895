import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

export interface Action {
  actionText: string;
  isDestructive: boolean;
}

@Component({
  standalone: true,
  selector: "document-editor-list-dropdown-button",
  templateUrl: "./document-editor-list-dropdown-button.html",
  styleUrls: ["./document-editor-list-dropdown-button.scss"],
  imports: [CommonModule, NgbModule],
})
export class DocumentEditorListDropDownButtonComponent {
  @Input() actions: Action[];
  @Input() docId!: number;

  @Output() editByDocumentId = new EventEmitter<number>();
  @Output() deleteByDocumentId = new EventEmitter<number>();
  @Output() downloadByDocumentId = new EventEmitter<number>();

  handleActionCall(event: Event) {
    return (action: Action) => {
      if (action.actionText === "Edit") {
        this.editByDocumentId.emit(this.docId);
      }

      if (action.actionText === "Delete") {
        // disable the delete button when clicked
        (event.target as HTMLButtonElement).setAttribute(
          "disabled",
          "disabled"
        );

        this.deleteByDocumentId.emit(this.docId);
      }

      if (action.actionText === "Download") {
        this.downloadByDocumentId.emit(this.docId);
      }
    };
  }
}
