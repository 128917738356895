import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MSAL_INSTANCE } from "@azure/msal-angular";
import { IPublicClientApplication } from "@azure/msal-browser";
import { ToastrService } from "ngx-toastr";
import { AppSpinnerModule } from "../shared/modules/app-spinner.module";
import { TermsAndConditionsService } from "../shared/services/terms-and-conditions.service";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"]
})
export class TermsAndConditionsComponent implements OnInit {
  static readonly TypeCode = "TermsAndConditionsCodeHere";
  shouldShowTerms = false;

  constructor(
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly spinner: AppSpinnerModule,
    private readonly termsAndConditionsService: TermsAndConditionsService
  ) { }

  async ngOnInit() {
    await this.termsAndConditionsService.hasAcceptedAsync(TermsAndConditionsComponent.TypeCode).then((res) => {
      if (res) {
        this.router.navigate(["/"]);
      } else {
        this.shouldShowTerms = true;
      }
    });
  }

  acceptTerms() {
    this.spinner.show();
    this.termsAndConditionsService.saveAccepted(TermsAndConditionsComponent.TypeCode, true).subscribe(
      () => {
        this.toastr.success("Terms accepted successfully.");
        this.router.navigate(["/"]);
      },
      (err) => { console.error("acceptTerms:", err); },
      () => this.spinner.hide());
  }

  declineTerms() {
    this.spinner.show();
    this.termsAndConditionsService.saveAccepted(TermsAndConditionsComponent.TypeCode, false).subscribe(
      () => { this.msalInstance.logout(); },
      (err) => { console.error("declineTerms:", err); },
      () => this.spinner.hide());
  }
}
