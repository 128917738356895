import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, debounceTime, take, tap } from 'rxjs/operators';
import { CommissionService } from 'src/app/shared/services/commission.service';
import { County } from '../../../../../shared/interfaces/county';
import { StateRuleModel } from '../../../../../shared/models/state-rules.model';
import { State } from '../../../../../shared/services/state';
import { FormError } from '../../../../../shared/components/app-form-error/form-error';
import { CountyLookupService } from '../../../../../shared/services/county-lookup.service';
import { AppSpinnerModule } from '../../../../../shared/modules/app-spinner.module';
import { FormValidators } from '../../../../../shared/validators/form.validators';
import { CommissionInfo, CommissionInfoPatch } from '../../../../../shared/models/commission-info.model';
import { Commission } from '../../../../../shared/models/commission.model';


@Component({
  selector: "app-commission-form-modal",
  templateUrl: "./commission-form-modal.component.html",
  styleUrls: ["./commission-form-modal.component.scss"],
})
export class CommissionFormModalComponent implements OnInit {
  commissionForm: UntypedFormGroup;
  countyDropDownList: County[] = [];

  selectedStampBase64: string;
  hasStamp: boolean;
  noCommissionLocation: boolean;
  stampUploaded: Observable<boolean>;

  commissionState: State;
  mode: "add" | "edit";
  selectedRuleValue: StateRuleModel | undefined;
  selectedRule: Observable<StateRuleModel | undefined>;
  commission;

  displayDate: any;
  public minDate: {};
  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  constructor(
    private readonly commissionService: CommissionService,
    private readonly countyLookupService: CountyLookupService,
    private readonly sanitizer: DomSanitizer,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
    private readonly activeModal: NgbActiveModal
  ) { }

  get stampHasErrors() {
    return this.commissionForm?.get("stamp")?.hasError("requiredFileType");
  }

  get countySelected(): boolean {
    const countyValueLength = this.commissionForm.get('county')?.value?.length;
    return !!countyValueLength;
  }

  ngOnInit() {
    this.minDate = new Date();
    this.initForm();
    this.setState(this.commissionState.code);
    if (this.mode === "edit") {
      this.patchForm();
    }

    this.commissionForm.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          if (this.selectedRuleValue?.isAutoGeneratedStampAllowed) {
            this.generateStamp();
          }
        })
      )
      .subscribe();

    this.selectedRule.pipe(take(1)).subscribe(rule => {
      this.selectedRuleValue = rule;
    });

    if (this.commission.commissionStampBase64 || this.commission.stamp) {
      this.hasStamp = true;
    }
  }

  initForm() {
    this.commissionForm = new UntypedFormGroup(
      {
        state: new UntypedFormControl(null, [
          Validators.maxLength(150),
          Validators.required,
        ]),
        commissionExpiration: new UntypedFormControl(null, [
          Validators.maxLength(200),
          Validators.required,
        ]),
        county: new UntypedFormControl(null, [
          Validators.maxLength(200),
          Validators.required,
        ]),
        commissionName: new UntypedFormControl(null, [
          Validators.maxLength(100),
          Validators.required,
        ]),
        commissionNumber: new UntypedFormControl(null, [
          this.requireCommissionNumberBasedOnStateRule(),
          Validators.maxLength(16)]),
        stamp: new UntypedFormControl(null, [
          this.requireStampBasedOnStateRule(),
          FormValidators.requiredFileType(["png"]),
        ]),
      },
      { updateOn: "change" }
    );
  }

  patchForm() {
    this.selectedStampBase64 = this.commission.stamp;
    if (this.commission.id) {
      this.commissionForm.patchValue({
        commissionExpiration: new Date(this.commission.expiresOn),
        county: this.commission.countyFIPSCode,
        commissionName: this.commission.name,
        commissionNumber: this.commission.number
      });
    } else {
      this.commissionForm.patchValue({
        commissionExpiration: new Date(this.commission.expiresOn),
        county: this.commission.countyFipsCode,
        commissionName: this.commission.name,
        commissionNumber: this.commission.number
      });
    }
  }

  setState(stateCode: string) {
    this.resetCommissionFormAndRelatedProperties();
    this.commissionForm.get('state')?.setValue(stateCode);
    this.commissionForm.get('state')?.disable();
    this.getCountyListForCurrentCommissionState(stateCode);
  }

  saveCommission(): void {
    FormError.ValidateAllFormFields(this.commissionForm);
    if (this.commissionForm.valid) {
      this.spinner.show();
      if (this.mode === "add") {
        this.addCommission();
      } else {
        this.updateCommission();
      }
    } else {
      this.toastr.warning("Please correct all errors.", "Attention");
    }
  }

  addCommission() {
    const value = this.commissionForm.getRawValue();

    const datePart =
      value.commissionExpiration.getDate() < 10
        ? `0${value.commissionExpiration.getDate()}`
        : value.commissionExpiration.getDate();
    const monthPart =
      (value.commissionExpiration.getMonth() + 1) < 10
        ? `0${value.commissionExpiration.getMonth() + 1}`
        : value.commissionExpiration.getMonth() + 1;
    const expirationDate = new Date(
      value.commissionExpiration.getFullYear(),
      Number(monthPart) - 1,
      Number(datePart)
    );

    const commission: CommissionInfo = {
      commissionInfoId: 0,
      accountId: 0,
      certificateFilePathId: 0,
      stampImageFilePathId: 0,
      stamp: this.selectedStampBase64,
      stateCode: value.state,
      expiresOn: expirationDate,
      number: value.commissionNumber,
      countyFipsCode: value.county,
      signatureFilePathId: 0,
      signature: null,
      name: value.commissionName,
      accountSignatureId: 0,
      certificateMetaDataId: 0,
    };
    this.commissionService.saveDraftCommission(commission).pipe(
      tap(() => {
        this.closeOnSuccess();
      }),
      catchError(() => {
        this.errorOnSave();
        return [];
      })
    )
      .subscribe();
  }

  closeOnSuccess() {
    this.spinner.hide();
    this.toastr.success("Commission Added");
    this.commissionForm.reset();
    this.activeModal.close('commissionAdded');
  }

  errorOnSave() {
    this.spinner.hide();
    this.toastr.error("Failed to add Commission");
  }

  updateCommission() {
    const value = this.commissionForm.getRawValue();

    const datePart =
      value.commissionExpiration.getDate() < 10
        ? `0${value.commissionExpiration.getDate()}`
        : value.commissionExpiration.getDate();
    const monthPart =
      (value.commissionExpiration.getMonth() + 1) < 10
        ? `0${value.commissionExpiration.getMonth() + 1}`
        : value.commissionExpiration.getMonth() + 1;
    const expirationDate = new Date(
      value.commissionExpiration.getFullYear(),
      Number(monthPart) - 1,
      Number(datePart)
    );

    if (this.commission.id) {
      const commissionPatch: Commission = {
        id: this.commission.id,
        accountId: this.commission.accountId,
        certificate: '',
        countyFIPSCode: value.county,
        county: this.getCountyNameIfCountySelected(value.county),
        expiresOn: expirationDate,
        name: value.commissionName,
        number: value.commissionNumber,
        stamp: this.selectedStampBase64,
        stateCode: this.commissionState.code,
        signature: null,
      };
      this.commissionService.updateCommission(commissionPatch.id, commissionPatch).pipe(
        tap(() => {
          this.closeOnSuccess();
        }),
        catchError(() => {
          this.errorOnSave();
          return [];
        })
      )
        .subscribe();
    }
    else {
      const commissionInfoPatch: CommissionInfoPatch = {
        commissionInfoId: this.commission.commissionInfoId,
        accountId: this.commission.accountId,
        stamp: this.selectedStampBase64,
        stateCode: this.commissionState.code,
        expiresOn: expirationDate,
        number: value.commissionNumber,
        countyFipsCode: value.county,
        name: value.commissionName,
      };
      this.commissionService.patchDraftCommission(commissionInfoPatch).pipe(
        tap(() => {
          this.closeOnSuccess();
        }),
        catchError(() => {
          this.errorOnSave();
          return [];
        })
      )
        .subscribe();
    }
  }

  resetCommissionFormAndRelatedProperties() {
    this.commissionForm.reset();
    this.commissionForm.controls.county.setValue(null);
  }

  getCountyListForCurrentCommissionState(stateCode: string) {
    this.countyLookupService.getCountyList(stateCode).pipe(
      tap(result => {
        this.countyDropDownList = [];
        result.countyLookups.forEach((county) => {
          this.countyDropDownList.push({
            countyFIPSCode: county.countyFIPSCode,
            countyName: county.countyName,
          });
        });
      }),
      take(1)
    ).subscribe();
  }

  getCountyNameIfCountySelected(countyFIPS: string) {
    const commissionCounty = this.countyDropDownList.find((county) => {
      return county.countyFIPSCode === countyFIPS;
    });
    return commissionCounty?.countyName ?? "";
  }

  getSanitizedImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpg;base64," + image
    );
  }

  async onFileSelected(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    if (target.files === null || target.files.length === 0) {
      return;
    }
    const selectedStampFile = target.files[0];

    this.spinner.show();

    await this.loadFileAsync(selectedStampFile);

    target.value = "";
    this.spinner.hide();
  }

  private async loadFileAsync(file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          this.selectedStampBase64 = this.convertFileToBase64(reader.result as ArrayBuffer);
          this.stampUploaded = new Observable<boolean>((observer) => {
            observer.next(!this.commissionForm.get("stamp")?.hasError("requiredFileType"));
            observer.complete();
          });
          resolve();
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private convertFileToBase64(fileData: ArrayBuffer): string {
    const bytes = new Uint8Array(fileData);
    let binary = '';
    for (const byte of bytes) {
      binary += String.fromCharCode(byte);
    }
    return window.btoa(binary);
  }

  requireStampBasedOnStateRule(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.parent) {
        const stampIsTouched = !!control?.value ?? false;
        if (
          this.selectedRuleValue?.isAutoGeneratedStampAllowed ||
          (!stampIsTouched && this.mode === "edit")
        ) {
          return null;
        }
      }

      if (control.value) {
        return null;
      }

      return { required: true };
    };
  }

  requireCommissionNumberBasedOnStateRule(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.parent) {
        const commissionNumberIsTouched = control?.dirty ? true : false;
        if (
          !this.selectedRuleValue?.isCommissionNumberRequired ||
          (!commissionNumberIsTouched && this.mode === "edit")
        ) {
          return null;
        }
      }

      if (control.value) {
        return null;
      }

      return { required: true };
    };
  }

  generateStamp(): void {
    const formCounty = this.commissionForm.controls["county"].value;
    this.commissionService
      .getOrGenerateStamp(
        this.commissionForm.controls["state"].value,
        formCounty
          ? this.countyDropDownList.filter(
            (county) => county.countyFIPSCode === formCounty
          )[0].countyName
          : "",
        this.commissionForm.controls["commissionExpiration"].value,
        this.commissionForm.controls["commissionName"].value,
        this.commissionForm.controls["commissionNumber"].value
      )
      .pipe(
        tap((result) => {
          if (this.selectedRuleValue?.isAutoGeneratedStampAllowed) {
            this.selectedStampBase64 = result.base64;
            this.hasStamp = true;
          }
        })
      )
      .subscribe();
  }

  close(): void {
    this.activeModal.close();
  }
}
