import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PdfjsService } from 'src/app/shared/services/pdfjs/pdfjs.service';

@Injectable({
  providedIn: 'root'
})
export class PdfToPngService {

  pdfToPngUrl: string = environment.pdfToPngUri;
  pdfToPngCode: string = environment.pdfToPngKey;

  pdfjs: PdfjsService;

  constructor(private http: HttpClient, pdfjs: PdfjsService) {
    this.pdfjs = pdfjs
   }

  getPngFromPdf(pdfBytes: string) {
    return this.http.post(this.pdfToPngUrl + '?code=' + this.pdfToPngCode, pdfBytes,
      {
        responseType: "text"
      });
  }

  async getPngFromPdfjs(pdfBytes: ArrayBuffer, scale: number = 1): Promise<string> {
    const result = await this.pdfjs.getPdfPages(pdfBytes);

    if (result) {
      return (await this.pdfjs.stichPdfPagesToSinglePng(result[0], scale)).dataUrl
    }

    return '';
  }
}
