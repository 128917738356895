import {
  Inject,
  Injectable,
  OnDestroy
} from '@angular/core';
import { MsalService } from "@azure/msal-angular";
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  forkJoin
} from 'rxjs';
import {
  take
} from 'rxjs/operators';
import { windowToken } from 'src/app/window.token';
import { environment } from 'src/environments/environment';
import { MsalIdToken } from "../../../models/account.models";
import { AccountService } from '../../account.service';
import { PendoVisitorAccount } from '../models/pendo-visitor-account.model';
import { snippet } from '../pendo-adapter.constants';

declare global {
    interface Window {
        pendo: any;
    }
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class PendoService implements OnDestroy {
    private isInitialized = false;
    
    constructor(
      @Inject(windowToken) private windowRef: Window,
      private msalService: MsalService,
      private accountService: AccountService
    ) {
      /* Method intentionally empty */
    }

    ngOnDestroy(): void {
      /* Method intentionally empty */
    }

    getSnippet(apiKey: string): HTMLScriptElement {
      const scriptElement = document.createElement('script');
      scriptElement.innerHTML = snippet.replace('pendo-api-key', apiKey);
      return scriptElement;
    }

    pendoIdentify(pendoVisitor: PendoVisitorAccount) {
      if (!this.isInitialized) {
        this.windowRef.pendo.initialize(pendoVisitor);
        this.isInitialized = true;
      }
      else 
      {
        this.windowRef.pendo.identify(pendoVisitor);
      }
    }

    getEnvName() : string {
      return environment.envName === 'root' ? 'local' : environment.envName;
    }

    initializePendoAsAnonymous() {
      const environmentName = this.getEnvName();
      const tzName = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "Unknown";

      const pendoVisitor: PendoVisitorAccount = {
        visitor: {
          timeZone: tzName,
          environmentName: environmentName,
          screenWidth: screen.width,
          screenHeight: screen.height,
          screenPixelDepth: screen.pixelDepth,
          screenSize: `${screen.width}x${screen.height} (${screen.pixelDepth} bpp)`
        },
        account: {
          id: `portal-${environmentName}`.toLowerCase(),
          environmentName: environmentName,
        },
      };

      this.pendoIdentify(pendoVisitor);
    }
  
    initializePendoAsUser() {
      const msalAccount = this.msalService.instance.getAllAccounts()?.[0];
      const msalToken = msalAccount?.idTokenClaims as MsalIdToken;

      const environmentName = this.getEnvName();
      const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

      forkJoin([
        this.accountService.getCurrentAccount(),
        this.accountService.getClientAccounts()]).pipe(
        take(1)
      ).subscribe(([userAccount, clients]) => {
        const pendoVisitor: PendoVisitorAccount = {
          visitor: {
            id: `${userAccount.accountId}`,
            firstName: msalToken.given_name,
            lastName: msalToken.family_name,
            email: msalToken.emails?.[0] ?? '',
            clients: clients.clients.map(
                (client) => 
                  `[${client.clientId}] ${client.clientName}: ${client.accountType}`
            ),
            timeZone: tzName,
            environmentName: environmentName,
            screenWidth: screen.width,
            screenHeight: screen.height,
            screenPixelDepth: screen.pixelDepth,
            screenSize: `${screen.width}x${screen.height} (${screen.pixelDepth} bpp)`
          },
          account: {
            id: `portal-${environmentName}`.toLowerCase(),
            environmentName: environmentName,
          },
        };

        this.pendoIdentify(pendoVisitor);
      });
    }
  }
  