import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { OrderDetailStore } from "../../+state/order-detail.store";
import { OrderOrganizerAction } from "./actions/order-organizer-action";
import { OrderOrganizerEditAction } from "./actions/order-organizer-edit-action";
import { OrderOrganizerRemoveAction } from "./actions/order-organizer-remove-action";
import { OrderOrganizer, OrderOrganizerListItem } from "src/app/shared/models/order-organizer.model";
import { OrderOrganizerService } from "src/app/shared/services/order-organizer.service";
import { Order } from "src/app/shared/models/order";
import { ClientAccountInfoList } from "src/app/shared/models/client-account-info-list";
import { OrderOrganizerFormModalComponent } from "../order-organizer-form-modal/order-organizer-form-modal.component";
import { AppDialogModule } from "src/app/shared/components/app-dialog/app-dialog.component";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { CapabilitiesService } from "src/app/shared/services/capabilities/capabilities.service";

@Component({
  selector: "app-order-organizer-list",
  templateUrl: "./order-organizer-list.component.html",
  styleUrls: ["./order-organizer-list.component.scss"]
})
export class OrderOrganizerListComponent implements OnInit, OnChanges {
  private actionHandler: (ParticipantListUser) => void;

  constructor(
    public store: OrderDetailStore,
    private readonly orderOrganizerService: OrderOrganizerService,
    private readonly modalService: NgbModal,
    private readonly dialog: AppDialogModule,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
    private readonly capabilitiesService: CapabilitiesService
    ) {}

  @Input() showActions: boolean;
  @Input() orderId: number;

  orderRequest$: Observable<OrderOrganizer[]>;
  organizers$: Observable<OrderOrganizer[]>;
  order$: Observable<Order>;
  clientAccountInfoList$: Observable<ClientAccountInfoList>;

  combinedOrganizers$: Observable<OrderOrganizerListItem[]>;

  productType: string;

  ngOnInit() {
    this.order$ = this.store.order$;
    this.organizers$ = this.store.organizers$;
    this.clientAccountInfoList$ = this.store.clientAccountInfoList$;

    this.combinedOrganizers$ = combineLatest([
      this.order$,
      this.organizers$,
      this.clientAccountInfoList$,
      this.store.organizerCapabilities$
    ]).pipe(
      switchMap(([order, organizers, clientAccountInfoList]) =>
        this.combineOrganizers(order, organizers, clientAccountInfoList)
      )
    );
  }

  combineOrganizers(order: Order, organizers: OrderOrganizer[], clientAccountInfoList: ClientAccountInfoList): Observable<OrderOrganizerListItem[]> {

    // Create new array to mutate
    const newOrganizers = [...organizers];

    // Add 'CREATOR' item to organizers to show which user created the order
    if (order?.createdBy && clientAccountInfoList?.clients) {
      const createdByClientAccount = clientAccountInfoList.clients.find(x => x.accountId === order.createdBy);

      if (createdByClientAccount) {
        newOrganizers.unshift({
          id: createdByClientAccount.accountId,
          email: createdByClientAccount.emailAddress,
          firstName: createdByClientAccount.firstName,
          lastName: createdByClientAccount.lastName,
          role: 'CREATOR',
        });
      }
    }

    // If the order is locked, we don't need to add any actions to the list item,
    // so just convert each organizer to a list item
    if(!this.showActions) {
      return of(newOrganizers.map(organizer => {
        return {
          ...organizer,
          actions: []
        }
      }));
    }

    // The order is not locked, so we need to figure out the capabilities allowed on each order organizer.
    // Transform the order organizers into list items for the UI
    // During the transformation, also include the actions allowed on the list item based on capabilities
    return combineLatest(
      newOrganizers.map(organizer =>
        combineLatest([
          this.capabilitiesService.userHasCapability('organizer', 'CanEditOrganizer', organizer?.id),
          this.capabilitiesService.userHasCapability('organizer', 'CanRemoveOrganizer', organizer?.id)
        ])
        .pipe(
          map( ([canEditOrganizer, canRemoveOrganizer]) => {
            const listItem: OrderOrganizerListItem = {
              ...organizer,
              actions: []
            };

            if(organizer.role === 'CREATOR'){
              return listItem;
            }

            if(canRemoveOrganizer) {
              listItem.actions.unshift(new OrderOrganizerRemoveAction(organizer, this.openRemoveOrderOrganizer));
            }

            if(canEditOrganizer) {
              listItem.actions.unshift(new OrderOrganizerEditAction(organizer, this.openEditOrderOrganizer));
            }

            return listItem;
          })
        )
    ));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.orderId?.currentValue) {
      this.loadOrganizers();
    }
  }

  loadOrganizers(): void {
    this.orderRequest$ = this.orderOrganizerService.getOrderOrganizers(this.orderId).pipe(
      tap(result => {
        this.store.setOrganizers(result);
      })
    );

    this.orderRequest$.subscribe();
  }

  getFriendlyRole(organizer: OrderOrganizer): string {
    switch (organizer?.role) {
      case 'CREATOR':
        return 'Order Creator';
      default:
        return 'Order Manager';
    }
  }

  openEditOrderOrganizer(organizer: OrderOrganizer): void {
    const modalRef = this.modalService.open(OrderOrganizerFormModalComponent, {
      backdrop: "static",
      size: "lg",
    });

    modalRef.componentInstance.orderId = this.orderId;
    modalRef.componentInstance.selectedOrderOrganizer = organizer;
  }

  openRemoveOrderOrganizer(organizer: OrderOrganizer) {
    const name = `${organizer.firstName} ${organizer.lastName}`;
    this.dialog
      .show({
        title: "Delete Order Organizer",
        message:
          `Remove ${name} from the order?`,
        noButtonText: "Cancel",
        yesButtonText: "Delete Order Organizer",
      })
      .result.then((response) => {
        if (response === "yes") {
          this.removeOrganizer(organizer);
        }
      });
  }

  removeOrganizer(organizer: OrderOrganizer) {
    this.spinner.show();
    this.orderOrganizerService.deleteOrderOrganizer(organizer.id).subscribe(
      () => {
        this.loadOrganizers();
        this.capabilitiesService.getCapabilitiesByOrderId(this.orderId);
        this.toastr.success("Organizer removed.");
      },
      () => {
        this.toastr.error(`Unable to remove organizer`);
      }
    ).add(() => this.spinner.hide());;
  }

  handleActionCall(action: OrderOrganizerAction) {
    this.actionHandler = action.action;
    this.actionHandler(action.organizer);
  }
}
