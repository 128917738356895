import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Component, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs/operators";
import { AccountService } from "src/app/shared/services/account.service";
import { AddAccountSignatureComponent } from "src/app/user-profile/add-account-signature/add-account-signature.component";
import { AppDialogModule } from "src/app/shared/components/app-dialog/app-dialog.component";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";
import { Client } from "src/app/shared/models/client.model";
import { ClientService } from "src/app/shared/services/client.service";
import { DisplayClientAccountWithClientName } from "src/app/shared/models/display-client-accounts";
import { DisplayRole } from "src/app/shared/models/display-role";
import { NewClientAccount } from "src/app/shared/models/account";
import { SupportAddUserModalComponent } from "../support-add-user-modal/support-add-user-modal.component";

@Component({
  selector: 'app-client-user-table-display',
  templateUrl: 'client-user-table-display.component.html',
  styleUrls: ['client-user-table-display.component.scss']
})

export class ClientUserTableDisplayComponent {
  @Input() activeTab: 'system' | 'client' = 'client';
  clientList: Client[];
  displayClientAccounts: DisplayClientAccountWithClientName[] = [];
  fullName: string;
  emailAddress: string;
  isSearchResults: boolean;
  searchPerformed: boolean;
  loggedInUsersAccountId: number;
  currentSearchedUsersAccountId: number;
  searchForm: UntypedFormGroup;
  accountRoles: DisplayRole[] = [
    {
      value: "CLIENTADMIN",
      displayValue: "Client Admin",
    },
    {
      value: "ORDERMANAGER",
      displayValue: "Order Manager",
    },
    {
      value: "SETTLEMENTAGENT",
      displayValue: "Settlement Agent",
    },
    {
      value: "SIGNINGAGENT",
      displayValue: "Signing Agent",
    },
  ];

  constructor(
    private readonly accountService: AccountService,
    private readonly clientService: ClientService,
    private readonly dialog: AppDialogModule,
    private readonly modalService: NgbModal,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.initSearchFilters();
    this.getCurrentAccount();
    this.getClientList();
  }

  initSearchFilters(): void {
    this.searchForm = new UntypedFormGroup({
      searchEmail: new UntypedFormControl("", Validators.maxLength(100))
    });
  }

  getCurrentAccount(): void {
    this.accountService.getCurrentAccount()
    .pipe(
      tap((account) => {
        this.loggedInUsersAccountId = account.accountId;
      }),
    )
    .subscribe();
  }

  getClientList(): void {
    this.clientService.getClientListByAccountType([])
      .pipe(
        tap((clients) => {
          this.clientList = clients;
        })
      )
      .subscribe();
  }

  search() {
    this.emailAddress = this.searchForm.get("searchEmail")?.value.toUpperCase();
    this.fullName = "";
    this.searchPerformed = true;
    this.isSearchResults = false;
    this.displayClientAccounts = [];

    if(this.emailAddress === "") {
      return;
    }

    this.spinner.show();
    this.accountService
      .getClientAccountsByEmailAddress(this.emailAddress)
      .pipe(
        tap((account) => {
          if(this.loggedInUsersAccountId !== account.accountId) {
            this.currentSearchedUsersAccountId = account.accountId;
            this.isSearchResults = true;
            this.fullName = account.fullName;
            this.emailAddress = account.emailAddress;

            account.clientAccounts.forEach((clientAccount) => {
              if(!this.displayClientAccounts.some(ca => ca.clientId === clientAccount.clientId)) {
                const displayClientAccount: DisplayClientAccountWithClientName = {
                  accountId: clientAccount.accountId,
                  clientId: clientAccount.clientId,
                  clientName: clientAccount.clientName,
                  roles: []
                };

                account.clientAccounts.forEach((cas) => {
                  if(cas.clientId === clientAccount.clientId) {
                    displayClientAccount.roles.push(cas.accountType.toUpperCase());
                  }
                })
                this.displayClientAccounts.push(displayClientAccount);
              }
            })
          }
        }),
      )
      .subscribe(
        () => {
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      ); 
  
     this.displayClientAccounts.sort((a, b) => (a.clientName.toUpperCase() < b.clientName.toUpperCase()) ? -1 : 1);
  }

  editClientAccount(selected: boolean, clientAccount: DisplayClientAccountWithClientName, role: string): void {
    if(selected === true) {
      const newClientAccount: NewClientAccount = {
        clientId: clientAccount.clientId,
        accountType: role
      }
  
      this.accountService.postClientAccount(clientAccount.accountId, newClientAccount).subscribe();
    }
    else {
      this.accountService
      .getClientAccountsFromClientId(clientAccount.clientId)
        .pipe(
          tap((cas) => {
            const clientAccounts = cas.clients.filter((ca) => ca.accountType.toUpperCase() === role.toUpperCase() && ca.accountId === clientAccount.accountId);
            clientAccounts.forEach((ca) => {
              this.accountService.deleteClientAccount(Number(ca.id)).subscribe();         
            })
          })
        )
        .subscribe();
    }
  }

  onDelete(clientAccount: DisplayClientAccountWithClientName): void {
    this.dialog
      .show({
        title: `Remove ${this.fullName} from ${clientAccount.clientName}`,
        message: "Are you sure you want to remove this user from this client?",
        noButtonText: "Cancel",
        yesButtonText: "Remove User",
      })
      .result.then((response) => {
        if (response === "yes") {
          this.spinner.show();

          this.accountService
          .getClientAccountsFromClientId(clientAccount.clientId)
          .pipe(
            tap((cas) => {
              const clientAccounts = cas.clients.filter((ca) => ca.accountId === clientAccount.accountId);
              let numAccounts = clientAccounts.length;
              clientAccounts.forEach((ca) => {
                this.accountService.deleteClientAccount(Number(ca.id)).subscribe(
                  () => {
                    numAccounts--;
                    if(numAccounts === 0) {
                      this.displayClientAccounts = this.displayClientAccounts.filter((ca) => ca.clientId !== clientAccount.clientId);
                      this.toastr.success("User removed successfully.");
                    }
                  },
                  () => {
                    this.toastr.error("Sorry, an error occurred while removing the User.");
                  }
                ); 
              })
            })
          )
          .subscribe(
            () => {
              this.spinner.hide();
            },
            () => {
              this.spinner.hide();
              this.toastr.error("Sorry, an error occurred.");
            }
          );
        }
      });
  }

  onDeleteAll(): void {
    this.dialog
      .show({
        title: `Delete all client accounts for ${this.emailAddress}`,
        message: "Are you sure you want to delete this user from all clients?",
        noButtonText: "Cancel",
        yesButtonText: "Delete",
      })
      .result.then((response) => {
        if (response === "yes") {
          this.spinner.show();

          this.accountService.deleteAllClientAccounts(Number(this.currentSearchedUsersAccountId)).subscribe(
            () => {
              this.toastr.success("Client accounts deleted successfully");
              this.search();
            },
            () => {
              this.spinner.hide();
              this.toastr.error("Sorry, an error occurred while deleting the client accounts");
            }
          );
        }
      }); 
  }

  openAddAccountSignatureModal() {
    const modalRef = this.modalService.open(AddAccountSignatureComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.mode = "add";
    modalRef.componentInstance.currentAccountId = this.currentSearchedUsersAccountId;
  }

  openAddUserModal(): void {
    const modal = this.modalService.open(SupportAddUserModalComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.clientList = this.clientList;
  }
}
