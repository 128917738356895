import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

export class PaginationEvent
{
    currentPage: number;
    numPages: number;
    itemsPerPage: number;
}

@UntilDestroy()
@Component({
    selector: "app-pagination-form",
    templateUrl: "./pagination.component.html",
    styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit, OnChanges {
    @Input() currentPage: number = 1;
    @Input() numPages: number = 1;
    @Input() itemsPerPage: number[] = [10, 25, 50, 100];
    @Input() currentItemsPerPage: number = 10;

    @Input() canGoForward: boolean = true;
    @Input() canGoBackward: boolean = true;
    @Input() canGoFirst: boolean = true;
    @Input() canGoLast: boolean = true;

    @Output() change = new EventEmitter<PaginationEvent>();

    _btnDisabledGoFirst = false;
    _btnDisabledGoPrevious = false;
    _btnDisabledGoNext = false;
    _btnDisabledGoLast = false;

    constructor() {
        /* Method intentionally blank */
    }

    ngOnInit(): void {
        this.validate();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.validate();
    }

    changeSelectedItemsPerPage() : void {
        this.currentPage = 1;
        this.validate();
        this.emitChangeEvent();
    }

    goToLastPage() : void {
        this.currentPage = this.numPages;
        this.validate();
        this.emitChangeEvent();
    }

    goToFirstPage() : void {
        this.currentPage = 1;
        this.validate();
        this.emitChangeEvent();
    }

    goToNextPage() : void {
        this.currentPage += 1;
        this.validate();
        this.emitChangeEvent();
    }

    goToPreviousPage() : void {
        this.currentPage -= 1;
        this.validate();
        this.emitChangeEvent();
    }

    emitChangeEvent() : void {
        this.change.emit({
            currentPage: this.currentPage,
            numPages: this.numPages,
            itemsPerPage: this.currentItemsPerPage
        });
    }

    setButtonStates() : void {
        let states = {
            goFirst: this.canGoFirst,
            goPrevious: this.canGoBackward,
            goNext: this.canGoForward,
            goLast: this.canGoLast
        }

        if (this.currentPage <= 1)
        {
            states.goFirst = false;
            states.goPrevious = false;
        }
        
        if (this.currentPage >= this.numPages)
        {
            states.goNext = false;
            states.goLast = false;
        }

        this._btnDisabledGoFirst = !states.goFirst;
        this._btnDisabledGoPrevious = !states.goPrevious;
        this._btnDisabledGoNext = !states.goNext;
        this._btnDisabledGoLast = !states.goLast;
    }

    validate() : void {
        if (this.numPages < 1)
        {
            this.numPages = 1;
        }

        if (this.currentPage < 1 || this.currentPage > this.numPages)
        {
            this.currentPage = Math.max(1, Math.min(this.currentPage, this.numPages));
        }

        this.setButtonStates();
    }
}