import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OrderRuleAudit } from 'src/app/shared/models/order-rule-audit.model';


@Component({
  selector: 'app-rule-violation-modal',
  templateUrl: './rule-violation-modal.component.html',
  styleUrls: ['./rule-violation-modal.component.scss']
})
export class RuleViolationModalComponent implements OnInit {
  
  @Input() ruleViolations: OrderRuleAudit;
  @Input() orderId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private readonly router: Router
    ) { }

  ngOnInit(): void {
  }

  openDigiDocs(): void {
    this.router.navigate(["/digi-docs/" + this.orderId]);
  }

  closeModal() {
    this.activeModal.close();
  }
}
