<div *ngIf="this.shouldShowTerms">
  <div class="page-header-bar">
    <div class="content85percent">
        <div class="float-left">
            <h1>Terms & Conditions</h1>
        </div>
    </div>
  </div>
  <div class="nex-terms">
    <div class="row margin-top-thirty-px ">
      <div class="nex-terms-card nex-scrollBar">
        <p>
          Video provides a powerful way to help you prove your point. When you click Online Video,
          you can paste in the embed code for the video you want to add.
          You can also type a keyword to search online for the video that best fits your document.
          To make your document look professionally produced, Word provides header, footer, cover page,
          and text box designs that complement each other. For example, you can add a matching cover page,
          header, and sidebar. Click Insert and then choose the elements you want from the different galleries.
          <br>
          <br>
          Themes and styles also help keep your document coordinated.
          When you click Design and choose a new Theme, the pictures, charts,
          and SmartArt graphics change to match your new theme.
          When you apply styles, your headings change to match the new theme.
          Save time in Word with new buttons that show up where you need them.
          To change the way a picture fits in your document, click it and a button for layout options appears next to it.
          When you work on a table, click where you want to add a row or a column, and then click the plus sign.
          <br>
          <br>
          Video provides a powerful way to help you prove your point. When you click Online Video,
          you can paste in the embed code for the video you want to add.
          You can also type a keyword to search online for the video that best fits your document.
          To make your document look professionally produced, Word provides header, footer, cover page,
          and text box designs that complement each other. For example, you can add a matching cover page,
          header, and sidebar. Click Insert and then choose the elements you want from the different galleries.
          <br>
          <br>
          Themes and styles also help keep your document coordinated.
          When you click Design and choose a new Theme, the pictures, charts,
          and SmartArt graphics change to match your new theme.
          When you apply styles, your headings change to match the new theme.
          Save time in Word with new buttons that show up where you need them.
          To change the way a picture fits in your document, click it and a button for layout options appears next to it.
          When you work on a table, click where you want to add a row or a column, and then click the plus sign.
        </p>
      </div>
    </div>
    <div class="row margin-top-thirty-px">
      <p>
        I here by agree to the terms & conditions by Nexsys ClearSign.
      </p>
    </div>
    <div class="row">
      <button type="button" class="nex-btn-outline nex-btn-outline-danger" (click)="declineTerms()" style="margin-right: 10px;">Decline</button>
      <button type="button" class="nex-btn nex-btn-default" (click)="acceptTerms()" style="margin-right: 10px;">Accept</button>
    </div>
  </div>

</div>
