<div class="tab-container">
<mat-button-toggle-group
        name="userAccountListSelection"
        [value]="activeTab"
>
  <mat-button-toggle value="client" (click)="changeTab('client')" [class.active]="activeTab === 'client'">
    All Users
  </mat-button-toggle>
  <mat-button-toggle value="system" (click)="changeTab('system')" [class.active]="activeTab === 'system'">
    System Users
  </mat-button-toggle>
</mat-button-toggle-group>
</div>
