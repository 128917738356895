import { NgModule, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./app-dialog.component.html",
  encapsulation: ViewEncapsulation.None
})
export class AppDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  options: AppDialogOptions;
  title: string;
  message: string;
  noButtonText: string;
  yesButtonText: string;

  ngOnInit() {
    this.title = this.options.title || "Confirmation";
    this.message = this.options.message || "";
    this.noButtonText = this.options.noButtonText || "No";
    this.yesButtonText = this.options.yesButtonText || "Yes";
  }
}

@NgModule({})
export class AppDialogModule {
  constructor(private readonly modalService: NgbModal) { }

  show(options: AppDialogOptions): NgbModalRef {
    const modal = this.modalService.open(AppDialogComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false
    });

    modal.componentInstance.options = options;
    return modal;
  }
}

export interface AppDialogOptions {
  title?: string;
  message?: string;
  noButtonText?: string;
  yesButtonText?: string;
}
