<ng-container>
  <div class="modal-header">
    <h5 class="modal-title" id="saveTemplateModalLabel">Save Template</h5>
  </div>
  <form [formGroup]="saveTemplateForm" (ngSubmit)="submitDocumentTemplateAndEndorsements()">
    <div class="modal-body">
        <div class="form-row margin-left-ten-px">
          <div class="form-group">
            <label for="templateName">Template Name:</label>
            <input type="text" class="form-control" formControlName="templateName" />
          </div>
        </div>
      </div>
    <div class="modal-footer">
      <button type="button" class="amr-btn-outline-primary" (click)="closeModal()">Cancel</button> &nbsp;
      <button type="submit" class="amr-btn-default" [disabled]="saveTemplateForm?.invalid">Save</button>
    </div>
  </form>
</ng-container>
