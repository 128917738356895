import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { MSAL_INSTANCE } from "@azure/msal-angular";
import { IPublicClientApplication } from "@azure/msal-browser";
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../services/account.service';
import { EmailInviteService } from "../services/email-invite.service";
import { TermsAndConditionsService } from "../services/terms-and-conditions.service";

@Injectable({
  providedIn: "root"
})
export class AccountGuard {
  constructor(
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    private readonly accountService: AccountService,
    private readonly emailInviteService: EmailInviteService,
    private readonly router: Router,
    private readonly termsAndConditionsService: TermsAndConditionsService,
    private readonly toastr: ToastrService
  ) { }

  readonly typeCode = "EULATerms";
  userEmail: string;

  canActivate = async (route: ActivatedRouteSnapshot) => {
    const users = this.msalInstance.getAllAccounts();
    if (users.length <= 0) {
      return false;
    }

    this.userEmail = users[0].username;

    const accountStorageKey = "AccountRegistered_" + this.userEmail;
    if (!sessionStorage.getItem(accountStorageKey)) {
      await this.registerAccount();
    }

    const storageKey = this.typeCode + "_" + this.userEmail;
    if (sessionStorage.getItem(storageKey)) {
      return true;
    }

    if (!(await this.termsAndConditionsService.hasAcceptedAsync(this.typeCode))) {
      const currentUrl = this.getResolvedUrl(route);
      sessionStorage.setItem("EULAReturnURL", currentUrl);
      this.router.navigate(["/eula"]);
      return false;
    }

    this.emailInviteService.insertRolesFromInvite().subscribe();

    sessionStorage.setItem(storageKey, "1");
    return true;
  }

  getResolvedUrl = (route: ActivatedRouteSnapshot): string => {
    return route.pathFromRoot
      .map(path => path.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

  registerAccount = async () => {
    await this.accountService.registerIdentity()
      .then(() => {
        sessionStorage.setItem("AccountRegistered_" + this.userEmail, "1")
      },
        (err) => {
          console.error(err);
          this.toastr.error("Sorry, an error occurred while registering your account.");
        }
      );
  }
}
