import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ModifiedOrderOrganizer, OrderOrganizer } from '../models/order-organizer.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OrderOrganizerService extends BaseService {
  protected className: string = "OrderOrganizerService";

  constructor(injector: Injector) {
    super(injector);
  }

  public getOrderOrganizers(orderId: number) : Observable<OrderOrganizer[]> {
    return this.get<OrderOrganizer[]>(`/v1/orders/${orderId}/organizers`);
  }

  public addOrderOrganizers(orderId: number, orderOrganizers: ModifiedOrderOrganizer[]) : Observable<ModifiedOrderOrganizer[]> {
    return this.post<ModifiedOrderOrganizer[]>(`/v1/orders/${orderId}/organizers`, orderOrganizers);
  }

  public updateOrderOrganizer(orderOrganizerId: number, modifiedOrderOrganizer: ModifiedOrderOrganizer) : Observable<ModifiedOrderOrganizer> {
    return this.put<ModifiedOrderOrganizer>(`/v1/organizers/${orderOrganizerId}`, modifiedOrderOrganizer);
  }

  public deleteOrderOrganizer(orderOrganizerId: number) : Observable<object> {
    return this.delete(`/v1/organizers/${orderOrganizerId}`);
  }
}
