import { DocumentCapabilityStrings } from "../../../shared/directives/capabilities/capabilities-params.model";
import { Action } from "../document-editor-list-dropdown-button/document-editor-list-dropdown-button";

const deleteAction: Action = {
  actionText: "Delete",
  isDestructive: true,
};

const downloadAction: Action = {
  actionText: "Download",
  isDestructive: false,
};

const editAction: Action = {
  actionText: "Edit",
  isDestructive: false,
};

export const getAvailableActions = (
  element: Document & { capabilities: DocumentCapabilityStrings[] }
): Action[] => {
  const actions: Action[] = [];

  let hasEdit = false;
  let hasDelete = false;
  let hasDownload = false;

  element?.capabilities?.forEach((capability) => {
    if (capability === "CanRemoveDocument" && !hasDelete) {
      hasDelete = true;
      actions.push(deleteAction);
    }

    if (capability === "CanReadDownloadMyDocument" && !hasDownload) {
      hasDownload = true;
      actions.push(downloadAction);
    }

    if (capability === "CanReadDownloadAnyDocument" && !hasDownload) {
      hasDownload = true;
      actions.push(downloadAction);
    }

    if (capability === "CanEditDocument" && !hasEdit) {
      hasEdit = true;
      actions.push(editAction);
    }
  });

  return actions;
};
