import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCaseStatusCode'
})
export class TitleCaseStatusCodePipe implements PipeTransform {

  transform(value: any): string {
    switch (value){
      case "READY":
        return "Ready";
      case "COMPLETE":
        return "Complete";
      case "CANCELLED":
        return "Cancelled";
      case "EXPIRED":
        return "Expired";
      case "IMPORTING":
        return "Importing";
      case "INPROGRESS":
        return "In Progress";
      case "OPTOUT":
        return "Opt-out";
      case "IMPORTFAILED":
        return "Import Failed";
      case "DELETED":
        return "Deleted";
      case "SESSIONCOMPLETE":
        return "Session Complete";
      case "SIGNING":
        return "Signing";
      case "SIGNINGFAILED":
        return "Signing Failed";
      case "VAULTING":
        return "Vaulting";
      case "VAULTINGFAILED":
        return "Vaulting Failed";
      case "PROCESSING":
        return "Processing";
      default:
        return "";
    }
  }

}
