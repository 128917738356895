<table class="table marginTopTwentyPx packageParticipantListTable">
  <tbody>
      <ng-container *ngIf="tableName === 'participants'">
        <tr class="skeleton-row participants" *ngFor="let number of rows">
          <td><ion-skeleton-text class="nexsys-green" animated></ion-skeleton-text></td>
          <td><ion-skeleton-text class="nexsys-green" animated></ion-skeleton-text></td>
          <td><ion-skeleton-text class="nexsys-green" animated></ion-skeleton-text></td>
          <td><ion-skeleton-text class="nexsys-green" animated></ion-skeleton-text></td>
          <td><ion-skeleton-text class="nexsys-green" animated></ion-skeleton-text></td>
          <td><ion-skeleton-text class="nexsys-green" animated></ion-skeleton-text></td>
        </tr>
      </ng-container>
      <ng-container *ngIf="tableName === 'documents'">
        <tr class="skeleton-row documents" *ngFor="let number of rows">
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
        </tr>
      </ng-container>
      <ng-container *ngIf="tableName === 'organizers'">
        <tr class="skeleton-row organizers" *ngFor="let number of rows">
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
        </tr>
      </ng-container>
  </tbody>
</table>
