import { BaseService } from "./base.service";
import { Injectable, Injector } from "@angular/core";
import { SystemAccountByEmail } from "../models/system-account-by.email";
import { SystemAccount } from "../models/system-account.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SystemAccountService extends BaseService {
  className = "SystemAccountService";

  constructor(injector: Injector) {
    super(injector);
  }

  postNewSystemAccountByEmail(model: SystemAccountByEmail) {
    return this.post<SystemAccountByEmail>('/v1/SystemAccountByEmail', model)
  }

  deleteSystemAccountById(systemAccountId: number) : Observable<object> {
    return this.delete(`/v1/SystemAccountById/${systemAccountId}`)
  }

  getCurrentSystemAccounts() : Observable<SystemAccount[]> {
    return this.get<SystemAccount[]>(`/v1/systemaccounts/current`);
  }
}
