import { Injectable } from '@angular/core';
import { KeyValuePair } from '../../../shared/models/key-value-pair';

@Injectable({
  providedIn: 'root'
})

export class EndorsementTypesService {

  constructor() { }

  fetchEndorsementsTypes(endorsementRole: string): KeyValuePair[] {

    if (endorsementRole === "Borrower" || endorsementRole === "Seller" || endorsementRole === "Non-Borrowing Title Holder") {
      return [{ key: "Signature", value: "Signature" },
      { key: "Initials", value: "Initials" },
      { key: "Textbox", value: "Textbox" },
      { key: "Checkbox", value: "Checkbox" },
      { key: "YesNoNaDropDown", value: "Y/N/NA Dropdown" },
      { key: "YesNoNaSignature", value: "Y/N/NA Signature" }];
    }

    if (endorsementRole === "SigningAgent") {
      return [{ key: "Signature", value: "Signature" },
      { key: "Initials", value: "Initials" },
      { key: "Textbox", value: "Textbox" },
      { key: "NotaryStamp", value: "Stamp" }];
    }

    if (endorsementRole === "System") {
      return [{ key: "Date", value: "Date" },
      { key: "SigningAgentName", value: "Signing Agent Name" },
      { key: "State", value: "State" },
      { key: "SigningAgentCounty", value: "Signing Agent County" },
      { key: "SigningAgentCommExpire", value: "Commission Expiration Date" },
      { key: "SigningAgentCommNumber", value: "Commission Number" },
      { key: "SigningAgentCommCounty", value: "Commission County" },
      { key: "SigningAgentCommState", value: "Commission State" },
      { key: "BorrowerIdDocNumber", value: "Borrower Id Doc Number" }];
    }

    return KeyValuePair[0];

  }

  fetchEndorsementTextValue(endorsementType: string): string {

    switch (endorsementType) {
      case "SIGNATURE":
        return "Signature";
      case "INITIALS":
        return "Initials";
      case "TEXTBOX":
        return "Textbox";
      case "CHECKBOX":
        return "Checkbox";
      case "YESNONADROPDOWN":
        return "Y/N/NA Dropdown";
      case "YESNONASIGNATURE":
        return "Y/N/NA Signature";
      case "NOTARYSTAMP":
        return "Stamp";
      case "DATE":
        return "Date";
      case "SIGNINGAGENTNAME":
        return "Signing Agent Name";
      case "PRINTEDNAME":
        return "Printed Name";
      case "STATE":
        return "State";
      case "SIGNINGAGENTCOUNTY":
        return "Signing Agent County";
      case "SIGNINGAGENTCOMMEXPIRE":
        return "Commission Expiration Date";
      case "SIGNINGAGENTCOMMNUMBER":
        return "Commission Number";
      case "SIGNINGAGENTCOMMCOUNTY":
        return "Commission County";
      case "SIGNINGAGENTCOMMSTATE":
        return "Commission State";
      case "BORROWERIDDOCNUMBER":
        return "Borrower Id Doc Number";
      default:
        return "";
    }

  }

  fetchEndorsementDefaultDimensions(endorsementType: string): any {

    switch (endorsementType) {
      case "BORROWERIDDOCNUMBER":     return { width: 150, height: 30 };
      case "CHECKBOX":                return { width: 20, height: 20 };
      case "INITIALS":                return { width: 50, height: 30 };
      case "NOTARYSTAMP":             return { width: 215, height: 115 };
      case "SIGNATURE":               return { width: 250, height: 30 };
      case "TEXTBOX":                 return { width: 250, height: 30 };
      case "YESNONADROPDOWN":         return { width: 150, height: 30 };
      case "YESNONASIGNATURE":        return { width: 150, height: 30 };
      // System fields
      case "DATE":                    return { width: 80, height: 11 };
      case "PRINTEDNAME":             return { width: 150, height: 11 };
      case "SIGNINGAGENTCOMMCOUNTY":  return { width: 150, height: 11 };
      case "SIGNINGAGENTCOMMEXPIRE":  return { width: 80, height: 11 };
      case "SIGNINGAGENTCOMMNUMBER":  return { width: 150, height: 11 };
      case "SIGNINGAGENTCOMMSTATE":   return { width: 50, height: 11 };
      case "SIGNINGAGENTCOUNTY":      return { width: 150, height: 11 };
      case "SIGNINGAGENTNAME":        return { width: 150, height: 11 };
      case "STATE":                   return { width: 50, height: 11 };

      default:
        return null;
    }
  }
}
