import {Component, Input, AfterViewInit} from "@angular/core";

export interface EndorsementComponentInteractions {
  deleteEndorsementComponentByIndex(index: number);
}

@Component({
  selector: 'app-endorsement-draggable',
  templateUrl: './endorsement-draggable.component.html',
  styleUrls: ['./endorsement-draggable.component.css']
})

export class EndorsementDraggableComponent implements AfterViewInit {

  @Input() public id: number;
  @Input() public dynamicEndorsementLocation: any;
  @Input() public name: string;
  @Input() public endorsementRole: string;
  @Input() public endorsementType: any;
  @Input() public endorsementControlDimensions: any;
  @Input() public index: number;
  @Input() public selfRef: EndorsementDraggableComponent;
  @Input() public participantId: number;
  @Input() public isRequired: boolean;
  @Input() public isEditable: boolean;
  @Input() public documentEndorsementLocationId?: number;
  @Input() public format: string;

  public toolTipText: string;

  public compInteraction: EndorsementComponentInteractions;

  public toolTipOptions: any;

  constructor() { }

  ngOnInit() {
    this.toolTipOptions = {
      'white-space': 'nowrap',
      'placement': 'bottom',
      'hide-delay': 0
    };
  }

  ngAfterViewInit(){

  }

  removeMe(index) {
    this.compInteraction.deleteEndorsementComponentByIndex(index)
  }


}
