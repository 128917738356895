import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';

import { AccountType } from "src/app/shared/enums/accountTypes";
import { AppSpinnerModule } from 'src/app/shared/modules/app-spinner.module';
import { FormError } from 'src/app/shared/components/app-form-error/form-error';
import { FormValidators } from "src/app/shared/validators/form.validators";
import { ListViewParticipant, ParticipantModifiable } from 'src/app/shared/models/participant.model';
import { OrderDetailStore } from 'src/app/order-detail/+state/order-detail.store';
import { OrderParticipantPresignStatus } from 'src/app/shared/models/order-presign-status';
import { ParticipantsService } from 'src/app/shared/services/participants.service';
import { State } from 'src/app/shared/services/state';
import { StatesService } from 'src/app/shared/services/states.service';
import { VerificationType } from 'src/app/shared/enums/verificationType';

import { environment } from '../../../../../environments/environment';
import { FormName, UserRole } from '../participant-form-modal.models';

@UntilDestroy()
@Component({
  selector: 'app-participant-information-form',
  templateUrl: './participant-information-form.component.html',
  styleUrls: ['./participant-information-form.component.scss']
})
export class ParticipantInformationFormComponent implements OnInit {
  isEditing$: Observable<boolean>;
  selectedParticipantRole$: Observable<string | null>;
  participantRoleValueChanges$: Observable<any> | undefined;

  participantInformationForm: UntypedFormGroup;
  isEditingSSN = false;
  states: State[];
  selectedStates: State[];
  participants$: Observable<ListViewParticipant[]>;
  customErrorMessage: string;

  @Input() userRoles: UserRole[];
  @Input() sequenceNumbers: number[];
  @Input() verificationTypes: VerificationType[];
  @Input() selectedParticipant: ListViewParticipant;
  @Input() orderId: number;
  @Input() participantPresignStatuses: Record<number, OrderParticipantPresignStatus>;

  constructor(
    private statesService: StatesService,
    private modalService: NgbModal,
    private spinner: AppSpinnerModule,
    public store: OrderDetailStore,
    private participantsService: ParticipantsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.participants$ = this.store.participants$;
    this.states = this.statesService.all;
    this.selectedStates = this.states;
    this.isEditing$ = this.store.isEditing$;
    combineLatest([
      this.participants$,
      this.isEditing$
    ]).pipe(
      tap(([participants, isEditing]) => {
        this.initForm(participants, isEditing);
        this.togglePresignRelatedFields();
        this.watchAndToggleForm();
        this.watchAndUpdateSelectedParticipantRole();
        if (isEditing) {
          this.patchForm();
          this.determineEditAccessForParticipant();
        } else {
          this.editParticipantSsn();
          this.participantInformationForm.updateValueAndValidity();
        }
      }),
      take(1)
    ).subscribe();
  }

  initForm(participants: ListViewParticipant[], isEditing: boolean) {
    this.participantInformationForm = new UntypedFormGroup(
      {
        participantRole: new UntypedFormControl(null, {
          validators: [Validators.maxLength(200), Validators.required],
          updateOn: "change",
        }),
        partnerParticipantIdentifier: new UntypedFormControl(null, [
          Validators.maxLength(12),
        ]),
        sequenceNumber: new UntypedFormControl(null, {
          updateOn: "change",
        }),
        emailAddress: new UntypedFormControl(null, [
          Validators.maxLength(128),
          Validators.email,
          Validators.required,
          this.duplicateNonSAandSAEmailsValidator(participants)
        ]),
        confirmEmailAddress: new UntypedFormControl(null, [
          Validators.maxLength(128),
          Validators.email,
          Validators.required
        ]),
        phoneNumber: new UntypedFormControl(null, [
          Validators.pattern(/^[2-9]\d{9}$/),
        ]),
        verificationType: new UntypedFormControl(null, {
          updateOn: "change",
        }),
        firstName: new UntypedFormControl(null, [
          Validators.maxLength(200),
          Validators.required,
        ]),
        lastName: new UntypedFormControl(null, [
          Validators.maxLength(200),
          Validators.required,
        ]),
        prefix: new UntypedFormControl(null, Validators.maxLength(200)),
        suffix: new UntypedFormControl(null, Validators.maxLength(200)),
        signatureName: new UntypedFormControl("", [
          Validators.maxLength(250),
          Validators.required,
        ]),
        lastFourSsn: new UntypedFormControl(null, [
          Validators.maxLength(4),
          Validators.minLength(4),
        ]),

        // Address stuff
        propertyAddress1: new UntypedFormControl(null, [
          Validators.maxLength(100),
          Validators.required,
        ]),
        propertyAddress2: new UntypedFormControl("", [Validators.maxLength(50)]),
        city: new UntypedFormControl(null, [
          Validators.maxLength(50),
          Validators.required,
        ]),
        propertyState: new UntypedFormControl(null, [Validators.required]),
        zip: new UntypedFormControl(null, [
          Validators.maxLength(5),
          Validators.minLength(5),
          Validators.required,
        ]),
      }, {
      updateOn: "blur",
      validators: [
        FormValidators.sequenceNumberValidator(participants, this.selectedParticipant?.id ?? 0, 'sequenceNumber', isEditing),
        FormValidators.confirmEmailAddressValidator('emailAddress', 'confirmEmailAddress'),
        FormValidators.confirmValidSsn(this.isEditingSSN)
      ]
    });
  }

  duplicateNonSAandSAEmailsValidator(
    participants: ListViewParticipant[],
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = { duplicateNonSAandSAEmailsValidator: true };
      const currentEmailValue: string = control?.value;
      const currentSigningAgent = participants?.find(
        (p) => p.participantModifiable.role === "SIGNINGAGENT"
      );
      let duplicateEmailsExist: boolean = false;
      const currentEmailLower = currentEmailValue?.toLowerCase() ?? "";

      duplicateEmailsExist = currentSigningAgent?.participantModifiable?.email?.toLowerCase() === currentEmailLower

      if (duplicateEmailsExist) {
        this.customErrorMessage = "Email is being used by a Signing Agent";
        return error;
      }
      return null;
    };
  }

  patchForm() {
    this.participantInformationForm
      .get("participantRole")
      ?.setValue(this.selectedParticipant?.participantModifiable?.role, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("partnerParticipantIdentifier")
      ?.setValue(
        this.selectedParticipant?.participantModifiable
          ?.partnerParticipantIdentifier,
        { onlySelf: true }
      );
    this.participantInformationForm
      .get("emailAddress")
      ?.setValue(this.selectedParticipant?.participantModifiable?.email, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("confirmEmailAddress")
      ?.setValue(this.selectedParticipant?.participantModifiable?.email, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("phoneNumber")
      ?.setValue(this.selectedParticipant?.participantModifiable?.phoneNumber, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("verificationType")
      ?.setValue(this.selectedParticipant?.participantModifiable?.verificationType, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("firstName")
      ?.setValue(this.selectedParticipant?.participantModifiable?.firstName, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("lastName")
      ?.setValue(this.selectedParticipant?.participantModifiable?.lastName, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("prefix")
      ?.setValue(this.selectedParticipant?.participantModifiable?.prefix, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("suffix")
      ?.setValue(this.selectedParticipant?.participantModifiable?.suffix, {
        onlySelf: true,
      });
    this.participantInformationForm
      .get("signatureName")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.signatureName,
        { onlySelf: true }
      );
    this.participantInformationForm
      .get("propertyAddress1")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.streetAddress1,
        { onlySelf: true }
      );
    this.participantInformationForm
      .get("propertyAddress2")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.streetAddress2,
        { onlySelf: true }
      );
    this.participantInformationForm
      .get("city")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.city,
        { onlySelf: true }
      );
    this.participantInformationForm
      .get("propertyState")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.stateCode,
        { onlySelf: true }
      );
    this.participantInformationForm
      .get("zip")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.participantAddress
          ?.zipCode,
        { onlySelf: true }
      );
    this.participantInformationForm
      .get("sequenceNumber")
      ?.setValue(
        this.selectedParticipant?.participantModifiable?.sequenceNumber,
        { onlySelf: true }
      );
  }

  togglePresignRelatedFields() {
    const participantId = this.selectedParticipant?.id ?? 0;
    this.canEditAccessForParticipant(participantId) ?
      this.enablePresignRelatedFields() :
      this.disablePresignRelatedFields();
  }

  canEditAccessForParticipant(participantId: number): boolean {
    const participantPresignStatus = this.participantPresignStatuses[participantId];
    if (!participantPresignStatus) {
      return true;
    }

    if (participantPresignStatus.isPreSign && (participantPresignStatus.isStarted || participantPresignStatus.isComplete)) {
      return false;
    }

    return true;
  }

  watchAndToggleForm() {
    this.participantRoleValueChanges$ = this.participantInformationForm
      .get("participantRole")
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        tap((value) => {
          if (value === AccountType.NonBorrowingTitleHolder) {
            this.store.setSelectedParticipantRole(value);
            this.store.setCurrentForm(FormName.NonBorrowingTitleHolderInformation);
          }
          if (value === AccountType.Witness) {
            this.store.setSelectedParticipantRole(value);
            this.store.setCurrentForm(FormName.WitnessInformation);
          }
          if (value === AccountType.SigningAgent) {
            this.store.setSelectedParticipantRole(value);
            this.store.setCurrentForm(FormName.SigningAgentInformation);
          }
        }),
        untilDestroyed(this)
      );

    this.participantRoleValueChanges$?.subscribe();
  }

  watchAndUpdateSelectedParticipantRole() {
    this.selectedParticipantRole$ = this.store.selectedParticipantRole$.pipe(
      tap(selectedParticipantRole => {
        this.participantInformationForm.get('participantRole')?.setValue(selectedParticipantRole);
      }),
      untilDestroyed(this)
    );
    this.selectedParticipantRole$.subscribe();
  }

  isFieldInvalidDirty(field: string): boolean {
    return this.participantInformationForm.get(field)?.invalid &&
      (this.participantInformationForm.get(field)?.touched ||
        this.participantInformationForm.get(field)?.dirty)
      ? true
      : false;
  }

  disablePresignRelatedFields() {
    this.participantInformationForm.get("participantRole")?.disable();
    this.participantInformationForm.get("sequenceNumber")?.disable();
    this.participantInformationForm.get("signatureName")?.disable();
  }

  enablePresignRelatedFields() {
    this.participantInformationForm.get("participantRole")?.enable();
    this.participantInformationForm.get("sequenceNumber")?.enable();
    this.participantInformationForm.get("signatureName")?.enable();
  }

  resetModal(reason: "cancel" | "saved") {
    this.modalService.dismissAll(reason);
    this.store.setSelectedParticipantRole(null);
    this.store.setIsEditing(false);
    this.spinner.hide();
  }

  addParticipant() {
    if (this.participantInformationForm.valid) {
      this.spinner.show();
      this.removeWhiteSpaceFromAllFields();
      const participant = this.createParticipant();
      this.participantsService
        .addParticipant(this.orderId, participant)
        .subscribe(
          (_) => {
            this.onParticipantSaveSuccess();
            this.spinner.hide();
          },
          (error) => {
            this.onParticipantSaveError(error);
            this.spinner.hide();
          }
        );
    } else {
      FormError.ValidateAllFormFields(this.participantInformationForm);
    }
  }

  updateParticipant() {
    if (this.participantInformationForm.valid) {
      this.spinner.show();
      this.removeWhiteSpaceFromAllFields();
      const participant = this.createParticipant();
      this.participantsService.updateParticipant(this.selectedParticipant.id, participant).subscribe(
        (_) => {
          this.onParticipantSaveSuccess();
          this.spinner.hide();
        },
        (error) => {
          this.onParticipantSaveError(error);
          this.spinner.hide();
        }
      );
    } else {
      FormError.ValidateAllFormFields(this.participantInformationForm);
    }
  }

  removeWhiteSpaceFromAllFields() {
    Object.keys(this.participantInformationForm.controls).forEach(key => {
      const currentValue = this.participantInformationForm.get(key)?.value;
      if (typeof currentValue === "string" && currentValue !== null && currentValue !== undefined) {
        const trimmedValue = currentValue.trim();
        this.participantInformationForm.get(key)?.setValue(trimmedValue);
      }
    });
  }

  createParticipant(): ParticipantModifiable {
    const formValue = this.participantInformationForm.getRawValue();
    return {
      email: formValue.emailAddress,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phoneNumber: this.filterToNumbers(formValue.phoneNumber),
      verificationType: formValue.verificationType,
      partnerParticipantIdentifier: formValue.partnerParticipantIdentifier,
      prefix: formValue.prefix,
      role: formValue.participantRole,
      sequenceNumber: formValue.sequenceNumber,
      signatureName: formValue.signatureName,
      suffix: formValue.suffix,
      participantAddress: {
        city: formValue.city,
        stateCode: formValue.propertyState,
        streetAddress1: formValue.propertyAddress1,
        streetAddress2: formValue.propertyAddress2,
        zipCode: formValue.zip,
        zip4: null
      },
      pin: formValue.lastFourSsn,
      ssn: formValue.lastFourSsn
    };
  }

  onParticipantSaveSuccess() {
    this.toastr.success('Participant saved successfully.');
    this.resetModal("saved");
  }

  onParticipantSaveError(error: any) {
    this.toastr.error(error.message);
  }

  editParticipantSsn() {
    this.isEditingSSN = true;
    this.participantInformationForm?.get('lastFourSsn')?.setValidators([
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
      FormValidators.ssnLastFourValidator(),
    ]);
  }

  filterToNumbers(value: string): string {
    if (!value) {
      return value;
    }

    return value.replace(/\D/g, "");
  }

  stateSearch(value: string): State[] { 
    let filter = value.toLowerCase();
    return this.states.filter(option => option.name.toLowerCase().startsWith(filter));
  }

  determineEditAccessForParticipant() {
    const participantPresignStatus = this.participantPresignStatuses[this.selectedParticipant.id];

    if (!participantPresignStatus) {
      return;
    }

    if (participantPresignStatus.isPreSign && (participantPresignStatus.isStarted || participantPresignStatus.isComplete)) {
      this.disablePresignRelatedFields();
    }
  }

  isMultiFactorEnabled() {
    return environment.features.isMultiFactorEnabled === "true" && this.verificationTypes.includes(VerificationType.MultiFactor);
  }
}
