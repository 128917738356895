import { ListViewParticipant } from "src/app/shared/models/participant.model";
import { DropdownAction } from 'src/app/action-dropdown-button/dropdown-action';

export abstract class PackageParticipantAction extends DropdownAction {
  participant: ListViewParticipant;

  constructor(actionText: string, isDestructive: boolean, participant: ListViewParticipant, action: any) {
    super(actionText, isDestructive, action);
    this.participant = participant;
  }
}
