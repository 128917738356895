import { Injectable, Injector, Inject } from "@angular/core";
import { HttpClient, HttpParams, HttpErrorResponse, } from "@angular/common/http";
import { Observable, throwError, NEVER } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ErrorResponse } from "../models/error-response";
import { HttpHeaders } from "@angular/common/http";
import { AuthInterceptor } from "../interceptors/auth.interceptor";
import { createHttpClient } from "./http-interceptor-handler";

@Injectable({
  providedIn: "root"
})
export abstract class BaseService {

  protected constructor(@Inject(Injector) injector: Injector) {
    this.http = injector.get(HttpClient);
    this.httpExternal = createHttpClient(injector, [ AuthInterceptor ]);
  }

  protected readonly http: HttpClient;
  protected readonly httpExternal: HttpClient;
  protected abstract className: string;

  protected getWithoutErrorHandling<T>(uriPath: string, paramsObject: object = []): Observable<T> {
    return this.http.get<T>(`${environment.apiUri}${uriPath}`,
      { params: Object.entries(paramsObject).reduce((params, [key, value]) => params.set(key, value), new HttpParams()) });
  }

  protected get<T>(uriPath: string, paramsObject: object = []): Observable<T> {
    return this.http.get<T>(`${environment.apiUri}${uriPath}`,
      { params: Object.entries(paramsObject).reduce((params, [key, value]) => params.set(key, value), new HttpParams()) })
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected getText(uriPath: string): Observable<string> {
    const headers = new HttpHeaders().set("Accept", "text/html");
    return this.http.get(`${environment.apiUri}${uriPath}`, { headers, responseType: "text" });
  }

  protected post<T>(uriPath: string, model: T | null): Observable<T> {
    model = (model || Object.create(null));
    return this.http.post<T>(`${environment.apiUri}${uriPath}`, model)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected postAny<T>(uriPath: string, model: object = []): Observable<T> {
    model = (model || Object.create(null));
    return this.http.post<T>(`${environment.apiUri}${uriPath}`, model)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected postWithHeaders<T>(uriPath: string, model: T | null, headers: any): Observable<T> {
    model = (model || Object.create(null));
    const requestOptions = { headers: new HttpHeaders(headers) };
    return this.httpExternal.post<T>(`${uriPath}`, model, requestOptions)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected put<T>(uriPath: string, model: T | null): Observable<T> {
    model = (model || Object.create(null));
    return this.http.put<T>(`${environment.apiUri}${uriPath}`, model)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected putAs<TInput, TResult>(uriPath: string, model: TInput | null): Observable<TResult> {
    model = (model || Object.create(null));
    return this.http.put<TResult>(`${environment.apiUri}${uriPath}`, model)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected patch<T>(uriPath: string, model: T): Observable<T> {
    return this.http.patch<T>(`${environment.apiUri}${uriPath}`, model)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected delete(uriPath: string): Observable<object> {
    return this.http.delete(`${environment.apiUri}${uriPath}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected handleError(err: HttpErrorResponse): Observable<never> {
    if (err.statusText === "OK" && err.status === 404) {
      // Do not Handle purposeful 404 not found but OK errors returned from the API
      return NEVER;
    }
    if (err.error instanceof ErrorEvent) {
      console.error(`[${this.className}] An error occurred:`, err.error.message);
    } else {
      console.error(`[${this.className}] Status code: ${err.status}, errobj:`, err.error);
    }

    const errorObj = new ErrorResponse();
    errorObj.message = "Sorry an error occurred.";
    errorObj.error = err;
    return throwError(errorObj);
  }
}
