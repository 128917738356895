import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { Document } from "../models/document.models";


@Injectable({
  providedIn: "root"
})
export class NotarizationInformationService extends BaseService {
  className = "NotarizationInformationService";

  constructor(injector: Injector) {
    super(injector);
  }

  getListByOrderId(orderId: number): Observable<Array<Document>> {
    return this.get<Array<Document>>(`/v1/orders/${orderId}/notarizationinformation`);
  } 
}
