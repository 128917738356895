import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/services/base.service';
import { CapabilityHttpResponse } from './models/capability-http-response.model';
import { CapabilitySystemResponse } from './models/capability-system-response.model';

@Injectable({
  providedIn: 'root'
})
export class CapabilitiesHttpService extends BaseService {
  className = "CapabilitiesHttpService";

  constructor(injector: Injector) {
    super(injector);
  }

  getCapabilitiesByOrder(orderId: number): Observable<CapabilityHttpResponse> {
    return this.get<CapabilityHttpResponse>(`/v1/capabilities/me/order/${orderId}`).pipe(
      shareReplay(1)
    );
  }

  getSystemCapabilities(): Observable<CapabilitySystemResponse> {
    return this.get<CapabilitySystemResponse>("/v1/capabilities/me");
  }
}
