import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DigitizationToolComponent } from "./digitization-tool/digitization-tool.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { ManageOrdersComponent } from "./manage-orders/manage-orders.component";
import { OrderDetailComponent } from "./order-detail/order-detail.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { EulaComponent } from "./eula/eula.component";
import { AccountGuard } from "./shared/guards/account.guard";
import { MsalAuthGuard } from "./msal/msal-auth.guard";
import { ManageUsersComponent } from "./manage-users/manage-users.component";
import { ManageSystemUsersComponent} from "./manage-system-users/components/manage-system-users.component";
import { AccountSignatureComponent } from "./user-profile/account-signature/account-signature.component";
import { ManageCommissionsComponent } from "./user-profile/manage-commissions/manage-commissions.component";
import { StateCommissionPage } from "./user-profile/manage-commissions/state-commission-page/state-commission-page.component";

export const routes: Routes = [
  { path: "", redirectTo: "manage-orders", pathMatch: "full" },
  {
    path: "digi-docs/:orderId",
    component: DigitizationToolComponent,
    canActivate: [MsalAuthGuard],
    data: { title: "Document Digitization Tool" },
  },
  {
    path: "not-found",
    component: ErrorPageComponent,
    data: { message: "Page not found!", title: "Page Not Found" },
  },
  {
    path: "manage-orders",
    component: ManageOrdersComponent,
    canActivate: [MsalAuthGuard, AccountGuard],
    data: { title: "Manage Orders" },
  },
  {
    path: "manage-users",
    component: ManageUsersComponent,
    canActivate: [MsalAuthGuard],
    data: { title: "Manage Users" },
  },
  {
    path: "support-manage-users",
    component: ManageSystemUsersComponent,
    canActivate: [MsalAuthGuard],
    data: { title: "Manage System Users" },
  },
  {
    path: "eula",
    component: EulaComponent,
    canActivate: [MsalAuthGuard],
    data: { title: "End User License Agreement" },
  },
  {
    path: "order-detail/:id",
    component: OrderDetailComponent,
    canActivate: [MsalAuthGuard],
    data: { title: "Order Detail" },
  },
  {
    path: "terms-and-conditions",
    component: TermsAndConditionsComponent,
    canActivate: [MsalAuthGuard],
    data: { title: "Terms and Conditions" },
  },
  {
    path: "myaccount",
    component: UserProfileComponent,
    canActivate: [MsalAuthGuard],
    data: { title: "My Account" },
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "manage-commissions",
        data: { title: "Redirect to Manage Commissions" },
      },
      {
        path: "manage-commissions",
        component: ManageCommissionsComponent,
        canActivate: [MsalAuthGuard, AccountGuard],
        data: { title: "Manage Commissions" },
      },
      {
        path: "manage-account-signatures",
        component: AccountSignatureComponent,
        canActivate: [MsalAuthGuard, AccountGuard],
        data: { title: "Manage Account Signatures" },
      },
      {
        path: "state-commission-page/edit/:statecode",
        component: StateCommissionPage,
        canActivate: [MsalAuthGuard, AccountGuard],
        data: { title: "Edit State Commission Page" },
      }
    ],
  },
  {
    path: "**",
    redirectTo: "/",
    data: { title: "Home" },
  },// needs to be last route
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: !isIframe ? "enabledBlocking" : "disabled"
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
