<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">Order Cancellation Confirmation</h5>
  </div>
  <div class="modal-body">
    <p >Are you sure you want to cancel this order?</p>

    <form [formGroup]="cancellationForm">
      <div class="form-group" *ngIf="isTypeCommentsOnly">
        <mat-form-field appearance="fill" class="clrsgn-form-field">
          <mat-label for="reason" class="clrsgn-label">Please enter comments regarding cancelling this order</mat-label>
          <textarea formControlName="reason"
                    matInput
                    maxlength="200"
                    rows="3"></textarea>
        </mat-form-field>
      </div>

      <div class="form-group" *ngIf="!isTypeCommentsOnly">
        <mat-form-field appearance="fill" class="clrsgn-form-field">
          <mat-label>Reason</mat-label>
          <input formControlName="reason"
                 [matAutocomplete]="autocompleteTypeLookupCode"
                 aria-label="Reason"
                 matInput
                 type="text" />
          <mat-autocomplete [displayWith]="optOutReasonsDisplay(optOutReasons)"
                            #autocompleteTypeLookupCode="matAutocomplete">
            <mat-option *ngFor="let optOutReason of filteredOptOutReasons | async"
                        [value]="optOutReason.typeLookupCode">
              {{ optOutReason.value }}
            </mat-option>
          </mat-autocomplete>
          <app-form-error [group]="cancellationForm"
                          [controlName]="'reason'"
                          customErrorMessage="Choose an option from the list."></app-form-error>
        </mat-form-field>
      </div>
      
      <div class="form-group mb-0" *ngIf="!isTypeCommentsOnly">
        <mat-form-field appearance="fill" class="clrsgn-form-field">
          <mat-label for="comments" class="clrsgn-label">Comments</mat-label>
          <textarea formControlName="comments"
                    matInput
                    maxlength="3000"
                    rows="3"></textarea>
        </mat-form-field>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="amr-btn-outline-primary" (click)="close()">Back</button>
    <button type="button" class="amr-btn-outline-warning" (click)="cancel()">Cancel Order</button>
  </div>
</ng-container>
