import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Endorsement, NewEndorsement } from '../models/endorsement.models';

@Injectable({
  providedIn: "root"
})
export class EndorsementService extends BaseService {
  className = "EndorsementService";

  constructor(injector: Injector) {
    super(injector);
  }

  getListByDocumentId(documentId: number): Observable<Array<Endorsement>> {
    return this.get<Array<Endorsement>>(`/v1/documents/${documentId}/endorsements`);
  }

  addDocument(documentId: number, model: NewEndorsement): Observable<NewEndorsement> {
    return this.put<NewEndorsement>(`/v1/documents/${documentId}/endorsements`, model);
  }

  updateEndorsements(documentId: number, model: Array<NewEndorsement>): Observable<Array<NewEndorsement>> {
    return this.put<Array<NewEndorsement>>(`/v1/documents/${documentId}/endorsements`, model);
  }
}
