import { ParticipantAddress } from "../models/participant-address";
import { ParticipantUrl } from "./participant-url";
import { VerificationType } from "../enums/verificationType";

export class ParticipantBase {
  id?: number;
  email: string;
  phoneNumber?: string;
  verificationType?: VerificationType;
  firstName: string;
  lastName: string;
  middleName?: string;
  partnerParticipantIdentifier?: string;
  prefix?: string;
  role: string;
  sequenceNumber: number;
  signatureName?: string;
  suffix?: string;
  participantAddress?: ParticipantAddress | null;
}

class ParticipantDboBase extends ParticipantBase {
  createdBy: number;
  createdDate: Date;
  updatedBy: number;
  updatedDate: Date;
}

export class Participant extends ParticipantDboBase {
  id: number;
  accountId: number;
}

export class ParticipantModifiable extends ParticipantBase {
  pin?: string | null;
  ssn?: string | null;
}

export class ParticipantVerificationPatch {
  phoneNumber: string;
  pin: string;
  verificationType: VerificationType;
}

export class ListViewParticipant {
  id: number;
  participantModifiable: ParticipantModifiable;
  participantUrl: ParticipantUrl | null;

  constructor(participant: Participant, participantUrl: ParticipantUrl | null) {
    this.id = participant.id;
    this.participantUrl = participantUrl;
    this.participantModifiable = participant as ParticipantBase as ParticipantModifiable;
  }
}
