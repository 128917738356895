import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { Order } from 'src/app/shared/models/order';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppSpinnerModule } from 'src/app/shared/modules/app-spinner.module';
import { ToastrService } from 'ngx-toastr';
import { OrderFormModalComponent } from 'src/app/order-form/order-form.modal';
import { CompleteOrderModalComponent } from '../complete-order-modal/complete-order-modal.component';
import { CancelOrderModalComponent } from '../cancel-order-modal/cancel-order-modal.component';
import { CapabilitiesService } from 'src/app/shared/services/capabilities/capabilities.service';

@Component({
  selector: 'app-order-detail-header',
  templateUrl: './order-detail-header.component.html',
  styleUrls: ['./order-detail-header.component.scss']
})
export class OrderDetailHeaderComponent {
  @Input() order: Order;
  @Input() isOrderLocked: boolean;
  @Input() toggleLoanInformationFormEdit: boolean;
  @Input() isCompleteCancelledOrOptout: boolean;
  @Input() canCompleteOrder: boolean;
  @Output() cancelled = new EventEmitter<number>();

  constructor(
    private readonly spinner: AppSpinnerModule,
    private readonly modalService: NgbModal,
    private readonly toastr: ToastrService,
    public readonly capabilitiesService: CapabilitiesService
  ) { }

  editLoanInformationForm() {
    const clientDropDownList: { clientId: number, displayString: string }[] = [];
    const modal = this.modalService.open(OrderFormModalComponent,
      {
        size: "lg",
        backdrop: "static",
        keyboard: false
      });

    modal.componentInstance.clientDropDownList = clientDropDownList;
    modal.componentInstance.isEdit = true;
    modal.componentInstance.currentOrder = this.order;

  }

  cancelOrder() {
    const modal = this.modalService.open(CancelOrderModalComponent,
      {
        windowClass : "modal-md",
        backdrop: "static",
        keyboard: false,
        centered: true
      });

    const modalComponentInstance = <CancelOrderModalComponent>modal.componentInstance;
    modalComponentInstance.orderId = this.order.id;
  }

  completeOrder() {
    if(this.canCompleteOrder) {
      const modal = this.modalService.open(CompleteOrderModalComponent,
        {
          size: "lg",
          backdrop: "static",
          keyboard: false
        });
      const modalComponentInstance = <CompleteOrderModalComponent>modal.componentInstance;
      modalComponentInstance.orderId = this.order.id;
      modalComponentInstance.completing.subscribe(
        () => this.spinner.show(),
        () => this.spinner.hide());
    }
    else {
      this.toastr.error(
        "Order cannot be completed at this time.<br/><br/>Please ensure that:<ul><li>At least 1 document has been added to the order</li><li>At least 1 document has been signed and completed</li></ul>",
        undefined,
        {
          enableHtml: true,
          extendedTimeOut: 5000
        });
    }
  }
}
