import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { shareReplay } from 'rxjs/operators';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { PDFThumbnail } from 'src/app/shared/services/pdfjs/pdfjs.service';
import { Document } from '../../shared/models/document.models';
import { DigitizationParameter } from '../models/digitization-parameter.model';
import { ImageData } from '../models/image-data.model';
import { ImageOverlayDivDimensions } from "../models/image-overlay-div-dimensions.model";

export enum DigiDocsSection {
  "THUMBNAILS" = "Thumbnails",
  "LIST" = "List",
  "DOCUMENTS" = "Documents"
}

export interface DigiDocsState {
  documentUploadResult: "pending" | "failure" | "success" | "";
  documentFileName: string;
  imageData: ImageData;
  ribbonTabToDisplay: "File" | "Document" | "Apply";
  sectionToDisplay: DigiDocsSection;
  templateNameToSave: string;
  pdfBytes: string;
  digitizationParameters: DigitizationParameter;
  draggableElementId: number;
  orderId: number;
  currentElementIndex: number;
  imageOverlayDivDimensions: ImageOverlayDivDimensions;
  isDocumentUploading: boolean;
  templateOptions: KeyValuePair[];
  voidedDocuments: Document[];
  showVoidedDocuments: boolean;
  pdfThumbnails: PDFThumbnail[];
}

@Injectable({
  providedIn: 'root'
})
export class DigiDocsStore extends ComponentStore<DigiDocsState> {
  readonly documentFileName$ = this.select(state => state.documentFileName);
  readonly documentUploadResult$ = this.select(state => state.documentUploadResult);
  readonly ribbonTabToDisplay$ = this.select(state => state.ribbonTabToDisplay).pipe(shareReplay());
  readonly sectionToDisplay$ = this.select(state => state.sectionToDisplay).pipe(shareReplay());
  readonly imageData$ = this.select(state => state.imageData);
  readonly templateNameToSave$ = this.select(state => state.templateNameToSave);
  readonly pdfBytes$ = this.select(state => state.pdfBytes);
  readonly digitizationParameters$ = this.select(state => state.digitizationParameters);
  readonly myDocuments$ = this.select(state => state.digitizationParameters.myDocuments);
  readonly draggableElementId$ = this.select(state => state.draggableElementId);
  readonly orderId$ = this.select(state => state.orderId);
  readonly currentElementIndex$ = this.select(state => state.currentElementIndex);
  readonly imageOverlayDivDimensions$ = this.select(state => state.imageOverlayDivDimensions);
  readonly isDocumentUploading$ = this.select(state => state.isDocumentUploading);
  readonly templateOptions$ = this.select(state => state.templateOptions);
  readonly voidedDocuments$ = this.select(state => state.voidedDocuments);
  readonly showVoidedDocuments$ = this.select(state => state.showVoidedDocuments);
  readonly pdfThumbnails$ = this.select(state => state.pdfThumbnails);

  readonly setDocumentUploadResult = this.updater((state, documentUploadResult: "pending" | "failure" | "success" | "") => ({
    ...state,
    documentUploadResult
  }));

  readonly setDocumentFileName = this.updater((state, documentFileName: string) => ({
    ...state,
    documentFileName
  }));

  readonly setRibbonTabToDisplay = this.updater((state, ribbonTabToDisplay: "File" | "Document" | "Apply") => ({
    ...state,
    ribbonTabToDisplay
  }));

  readonly sectionToDisplay = this.updater((state, sectionToDisplay: DigiDocsSection) => ({
    ...state,
    sectionToDisplay
  }));

  readonly setTemplateNameToSave = this.updater((state, value: string) => ({
    ...state,
    templateNameToSave: value,
  }));

  readonly setPdfBytes = this.updater((state, pdfBytes: string) =>  ({
    ...state,
    pdfBytes
  }));

  readonly setOrderId = this.updater((state, orderId: number) => ({
    ...state,
    orderId
  }));

  readonly setImageOverlayDivDimensions = this.updater((state, imageOverlayDivDimensions: ImageOverlayDivDimensions) => ({
    ...state,
    imageOverlayDivDimensions
  }));

  readonly setIsDocumentUploading = this.updater((state, isDocumentUploading: boolean) => ({
    ...state,
    isDocumentUploading
  }));

  readonly setTemplateOptions = this.updater((state, templateOptions: KeyValuePair[]) => ({
    ...state,
    templateOptions
  }));

  readonly addTemplateOption = this.updater((state, templateOption: KeyValuePair) => {
    const templateOptions = state.templateOptions;
    templateOptions.push(templateOption);
    return ({
      ...state,
      templateOptions
    });
  });

  readonly incrementDraggableElementId = this.updater((state) => {
    const draggableElementId = ++state.draggableElementId;
    return ({
      ...state,
      draggableElementId
    });
  });

  readonly incrementCurrentElementIndex = this.updater((state) => {
    const currentElementIndex = ++state.currentElementIndex;
    return ({
      ...state,
      currentElementIndex
    });
  });

  readonly updateDocumentStatus = this.updater(
    (state, documents: Document[]) => ({
      ...state,
      documents
    })
  );

  readonly addPdfThumbnails = this.updater(
    (state, pdfThumbnails: PDFThumbnail[]) => ({
      ...state,
      pdfThumbnails
    })
  );

  readonly removeThumbnail = this.updater(
    (state: DigiDocsState, docId: number) => ({
      ...state,
      pdfThumbnails: state.pdfThumbnails.filter(
        (thumbnail: PDFThumbnail) => thumbnail.docId !== docId
      ),
    })
  );

  readonly updatePdfThumbnail = this.updater(
    (state, thumbnail: PDFThumbnail) => {
      const hasThumbnail =
        state.pdfThumbnails.filter(
          (existingThumbnail) =>
            existingThumbnail.docId === thumbnail.docId &&
            existingThumbnail.image !== null
        ).length === 1;

      return hasThumbnail
        ? state
        : {
            ...state,
            pdfThumbnails: state.pdfThumbnails.map((existingThumbnail) =>
              existingThumbnail.docId === thumbnail.docId
                ? thumbnail
                : existingThumbnail
            ),
          };
    }
  );

  readonly updateImageData = this.updater((state, imageData: Partial<ImageData>) => {
    const updatedImageData: ImageData = Object.assign(state.imageData, imageData);
    return {
      ...state,
      imageData: updatedImageData
    };
  });

  readonly updateDigitizationParameters = this.updater((state, digitizationParameters: Partial<DigitizationParameter>) => {
    const updatedDigitizationParameters: DigitizationParameter = Object.assign(state.digitizationParameters, digitizationParameters);
    return {
      ...state,
      digitizationParameters: updatedDigitizationParameters
    };
  });

  readonly addDocumentToDigitizationParameters = this.updater((state, newDocument: Document) => {
    const updatedDigitizationParameters: DigitizationParameter = Object.assign(state.digitizationParameters);
    updatedDigitizationParameters.myDocuments.push(newDocument);
    return {
      ...state,
      digitizationParameters: updatedDigitizationParameters
    };
  });

  readonly removeDocumentFromDigitizationParameters = this.updater(
    (state: DigiDocsState, docId: number) => ({
      ...state,
      ...state.digitizationParameters.myDocuments.filter(
        (document) => document.id !== docId
      ),
    })
  );

  readonly addVoidedDocuments = this.updater(
    (state, documents: Document[]) => ({
      ...state,
      voidedDocuments: documents.map((document) => {
        document.status = "VOIDED";

        return document;
      }),
    })
  );

  readonly setShowVoidedDocuments = this.updater(
    (state, showVoidedDocuments: boolean) => ({
      ...state,
      showVoidedDocuments
    })
  );

  readonly resetImageData = this.updater((state) =>  {
    const imageData = new ImageData();
    return ({ ...state, imageData });
  });

  readonly resetStore = this.updater((state) => {
    const digitizationParameters = new DigitizationParameter();
    const imageData = new ImageData();
    const imageOverlayDivDimensions = new ImageOverlayDivDimensions();
    return ({
      ...state,
      currentElementIndex: 0,
      digitizationParameters,
      draggableElementId: 0,
      imageData,
      imageOverlayDivDimensions,
      documentFileName: "",
      ribbonTabToDisplay: "File",
      showVoidedDocuments: false,
      voidedDocuments: [],
      pdfThumbnails: [],
      documentUploadResult: ""
    });
  });

  constructor() {
    super({
      documentUploadResult: "",
      ribbonTabToDisplay: "Apply",
      showVoidedDocuments: false,
      voidedDocuments: [],
      pdfThumbnails: [],
      sectionToDisplay: DigiDocsSection.LIST,
      documentFileName: "",
      imageData: new ImageData(),
      templateNameToSave: '',
      pdfBytes: '',
      digitizationParameters: new DigitizationParameter(),
      draggableElementId: 0,
      orderId: 0,
      currentElementIndex: 0,
      imageOverlayDivDimensions: new ImageOverlayDivDimensions(),
      isDocumentUploading: false,
      templateOptions: []
    });
  }
}
