import { Injectable, Injector } from "@angular/core";
import { BaseService } from "./base.service";
import { EmailInviteModel, EmailInviteNew } from "../models/email-invite.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class EmailInviteService extends BaseService {
  className = "EmailInviteService";

   constructor(injector: Injector) {
    super(injector);
  }

  saveInvite(model: EmailInviteNew) {
    return this.post<EmailInviteNew>(`/v1/EmailInvite`, model);
  }

  getInviteByGuid(emailInviteGuid: string) : Observable<EmailInviteModel> {
    return this.get<EmailInviteModel>(`/v1/EmailInvite/${emailInviteGuid}`);
  }

  insertRolesFromInvite(){
    return this.post<EmailInviteModel>(`/v1/EmailInvite/AddRoles`, null);
  }
}
