import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DropdownAction } from "./dropdown-action";

@Component({
  selector: "action-dropdown-button",
  templateUrl: "./action-dropdown-button.component.html",
  styleUrls: ["./action-dropdown-button.component.scss"]
})
export class ActionDropdownButtonComponent {
  @Input() actions: DropdownAction[];
  @Output() actionCalled = new EventEmitter<DropdownAction>();

  constructor() { }

  handleActionCall(action: DropdownAction) {
    this.actionCalled.emit(action);
  }
}
