<div class="d-inline-block" ngbDropdown>
  <button class="btn dropdown-menu-button"
    id="actionDropdownButton"
    ngbDropdownToggle
    aria-haspopup="true"
    aria-expanded="false"
    aria-label="Drop down button, ellipsis icon"
  >
    <span class="fas fa-ellipsis-h"></span>
  </button>
  <div ngbDropdownMenu aria-labelledby="actionDropdownButton">
    <button *ngIf="shouldEdit"
        class="action action-normal"
        ngbDropdownItem
        (click)="handleEdit()">
      Edit
    </button>

    <button *ngIf="shouldRemove"
        class="action action-destructive"
        ngbDropdownItem
        (click)="handleRemove()">
      {{ shouldVoid ? 'Void' : 'Remove' }}
    </button>
  </div>
</div>

