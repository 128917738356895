import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-document-endorsements-upload-modal',
  templateUrl: './document-endorsements-upload-modal.component.html'
})
export class DocumentEndorsementsUploadModalComponent {
  documentUploadResult$: Observable<string>;
  constructor(
    private modalService: NgbModal
  ) { }

  closeModal() {
    if (this.modalService != null) {
      this.modalService.dismissAll();
    }
  }
}
