import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";

import { BaseService } from "../../shared/services/base.service";
import { OrderSearchModel } from "../models/order-search.models";
import { OrderSearchResultList } from "../../shared/models/orderSearchList";
import { share } from 'rxjs/operators';


@Injectable({
  providedIn: "root"
})
export class OrderSearchService extends BaseService {
  className = "OrderSearchService";

  constructor(injector: Injector) {
    super(injector);
  }

  search(searchParams: OrderSearchModel): Observable<OrderSearchResultList> {
    return this.getWithoutErrorHandling<OrderSearchResultList>("/v1/orders/search", searchParams).pipe(
      share()
    );
  }
}
