import { Component, Input, OnInit } from '@angular/core';
import { OrderDetailStore } from '../../+state/order-detail.store';
import { VerificationType } from 'src/app/shared/enums/verificationType';
import { ListViewParticipant } from 'src/app/shared/models/participant.model';
import { ParticipantsService } from 'src/app/shared/services/participants.service';
import { ProductType } from 'src/app/shared/enums/productType';

@Component({
  selector: 'app-reset-login-modal',
  templateUrl: './reset-login-modal.component.html',
  styleUrls: ['./reset-login-modal.component.scss']
})

export class ResetLoginModalComponent implements OnInit {

  VerificationType = VerificationType;

  @Input() selectedParticipant: ListViewParticipant;
  @Input() productType: ProductType;

  verificationTypes: VerificationType[];

  currentVerificationType: VerificationType = VerificationType.MultiFactor;

  constructor(
    public store: OrderDetailStore,
    public participantService: ParticipantsService,
  ) { }

  ngOnInit(): void {
    this.verificationTypes = this.participantService.getProductVerificationTypes(this.productType);

    let currentVerificationType = this.selectedParticipant?.participantModifiable?.verificationType ?? VerificationType.MultiFactor;

    if (!this.verificationTypes.includes(currentVerificationType) && this.verificationTypes.length > 0) {
      currentVerificationType = this.verificationTypes[0];
    }

    this.currentVerificationType = currentVerificationType;
  }

  setVerificationType(type: VerificationType ){
    this.currentVerificationType = type;
  }
}
