<ng-container>
  <div class="modal-header">
    <h4 *ngIf="(isEditing$ | async) === true; else isAdding" class="modal-title" id="participantModalLabel">Edit Participant</h4>
    <ng-template #isAdding>
      <h4 class="modal-title" id="participantModalLabel"> Add Participant</h4>
    </ng-template>
    <p>*Required Information</p>
  </div>
  <app-participant-information-form
    *ngIf="(currentForm$ | async) === FormName.ParticipantInformation"
    [userRoles]="userRoles"
    [sequenceNumbers]="sequenceNumbers"
    [verificationTypes]="verificationTypes"
    [selectedParticipant]="selectedParticipant"
    [orderId]="order.id"
    [participantPresignStatuses]="participantPresignStatuses"
  ></app-participant-information-form>
  <app-non-borrowing-title-holder-information-form
    *ngIf="(currentForm$ | async) === FormName.NonBorrowingTitleHolderInformation"
    [userRoles]="userRoles"
    [sequenceNumbers]="sequenceNumbers"
    [verificationTypes]="verificationTypes"
    [selectedParticipant]="selectedParticipant"
    [orderId]="order.id"
    [participantPresignStatuses]="participantPresignStatuses"
  ></app-non-borrowing-title-holder-information-form>
  <app-witness-information-form
    *ngIf="(currentForm$ | async) === FormName.WitnessInformation"
    [userRoles]="userRoles"
    [sequenceNumbers]="sequenceNumbers"
    [verificationTypes]="verificationTypes"
    [selectedParticipant]="selectedParticipant"
    [orderId]="order.id"
    [participantPresignStatuses]="participantPresignStatuses"
  ></app-witness-information-form>
  <app-signing-agent-information-form
    *ngIf="(currentForm$ | async) === FormName.SigningAgentInformation"
    [userRoles]="userRoles"
    [sequenceNumbers]="sequenceNumbers"
    [selectedParticipant]="selectedParticipant"
    [clients]="clients"
    [orderId]="order.id"
  ></app-signing-agent-information-form>
</ng-container>
