<ng-container>
  <div class="modal-header">
    <h5 class="modal-title">{{'Comments'}}</h5>
  </div>
  <ng-container *ngIf="(orderStatusDetails$ | async) as orderStatusDetails">
    <div class="modal-section">
      <ng-container *ngFor="let orderStatusDetail of orderStatusDetails.orderStatusDetails">
          <ng-container *ngIf="(orderStatus$ | async) as orderStatus">
            <div class="modal-status">Order Status: {{orderStatus | titleCaseStatusCode}} - {{orderStatusDetail.categoryTypeValue}}</div>
            <div class="modal-comments">Comment: {{orderStatusDetail.comments}}</div>
            <hr>
          </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <div class="modal-footer">
    <button type="button" class="amr-btn-outline-primary close-button" (click)="closeModal()">Close</button>
  </div>
</ng-container>
