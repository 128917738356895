import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoLink } from 'src/app/shared/models/videolink.model';
import { saveAs } from "file-saver";


@Component({
  selector: 'app-download-video-modal',
  templateUrl: './download-video-modal.component.html',
  styleUrls: ['./download-video-modal.component.scss']
})
export class DownloadVideoModalComponent implements OnInit {
  

  constructor(private activeModal: NgbActiveModal) { }

  videoLinks: VideoLink[];


  ngOnInit(): void {
  }

  download(index: number) {
    saveAs(this.videoLinks[index].url, 'archive.mp4');
  }

  closeModal() {
    this.activeModal.close();
  }
}
