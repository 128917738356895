<ng-container>
  <div class="modal-body">
    <form [formGroup]="signingAgentInformationForm" class="nex-form">
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="filter-mat-form">
            <mat-label for="participantRole" class="filter-label">
              Participant Role
            </mat-label>
            <input matInput *ngIf="(isEditing$ | async)" formControlName="participantRole" [readonly]="true" />
            <mat-select *ngIf="!(isEditing$ | async)"
                        formControlName="participantRole"
                        id="participantRole">
              <mat-option *ngFor="let userRole of userRoles"
                          [value]="userRole.key">
                {{ userRole.value }}
              </mat-option>
            </mat-select>
            <app-form-error [group]="signingAgentInformationForm"
                            [controlName]="'participantRole'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="(clients && clients.length && isSettlementAgent) || isSystemAccountUser" class="row">
        <div class="col-12">
          <mat-form-field appearance="fill" class="filter-mat-form">
            <mat-label for="existingClient" class="filter-label">
              Agent
            </mat-label>
            <mat-select (selectionChange)="onClientChanged($event.value)"
                        formControlName="existingClient"
                        id="existingClient">
              <mat-option value="removeSelection">
                Remove Selection
              </mat-option>
              <mat-option *ngFor="let client of clientsSearch"
                          [value]="client">
                {{ client.firstName }} {{ client.lastName }} ({{ client.emailAddress }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="filter-mat-form">
            <mat-label for="emailAddress" class="filter-label">
              Email Address
            </mat-label>
            <input matInput
                   maxlength="128"
                   type="text"
                   formControlName="emailAddress"
                   id="emailAddress" />
            <app-form-error [group]="signingAgentInformationForm"
                            [controlName]="'emailAddress'"
                            [customErrorMessage]="customErrorMessage"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="filter-mat-form">
            <mat-label for="confirmEmailAddress" class="filter-label">
              Confirm Email Address
            </mat-label>
            <input matInput
                   maxlength="128"
                   type="text"
                   formControlName="confirmEmailAddress"
                   id="confirmEmailAddress" />
            <app-form-error [group]="signingAgentInformationForm"
                            [controlName]="'confirmEmailAddress'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="filter-mat-form">
            <mat-label for="firstName" class="filter-label">
              First Name
            </mat-label>
            <input matInput
                   maxlength="200"
                   type="text"
                   formControlName="firstName"
                   id="firstName" />
            <app-form-error [group]="signingAgentInformationForm"
                            [controlName]="'firstName'"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="filter-mat-form">
            <mat-label for="lastName" class="filter-label">
              Last Name
            </mat-label>
            <input matInput
                   maxlength="200"
                   type="text"
                   formControlName="lastName"
                   id="lastName" />
            <app-form-error [group]="signingAgentInformationForm"
                            [controlName]="'lastName'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="amr-btn-outline-primary"
            data-dismiss="modal"
            (click)="resetModal('cancel')">
      Cancel
    </button>
    <button *ngIf="isEditing$ | async"
            type="button"
            class="amr-btn-default"
            (click)="updateParticipant()">
      Save Participant
    </button>
    <button *ngIf="!(isEditing$ | async)"
            type="button"
            class="amr-btn-default"
            data-dismiss="modal"
            (click)="addParticipant()">
      Add Participant
    </button>
  </div>
</ng-container>
