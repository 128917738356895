
export enum userFriendlyRoleMap {
    BORROWER = "Borrower",
    SIGNINGAGENT = "Signing Agent",
    SYSTEM = "System",
    NBTH = "Non-Borrowing Title Holder",
    SELLER = "Seller",
    WITNESS = "Witness",
    SIGNER = "Signer"
}
