import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalBroadcastService, MSAL_INSTANCE } from "@azure/msal-angular";
import {
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  EventType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { CapabilitiesService } from "src/app/shared/services/capabilities/capabilities.service";

import { MsalIdToken } from "../shared/models/account.models";
import { FeaturesService } from "../shared/services/features.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { OrderDetailStore } from "../order-detail/+state/order-detail.store";

@UntilDestroy()
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  faExclamationTriangle = faExclamationTriangle;
  userName: string;
  environmentIsProduction: boolean;
  userToken: MsalIdToken;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    private router: Router,
    private readonly broadcastService: MsalBroadcastService,
    private readonly capabilitiesService: CapabilitiesService,
    private readonly orderDetailStore: OrderDetailStore,
    private readonly featuresService: FeaturesService
  ) {}

  ngOnInit() {
    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload: AuthenticationResult = (<AuthenticationResult>(
          result.payload
        )) as AuthenticationResult;

        this.msalInstance.setActiveAccount(payload.account);
        this.userName = this.initUserName();
        this.capabilitiesService.getSystemCapabilities();

        return result;
      });

    this.environmentIsProduction = environment.production;
  }

  initUserName(): string {
    const users = this.msalInstance.getAllAccounts();

    this.userToken = users[0].idTokenClaims as MsalIdToken;

    return `${this.userToken.given_name} ${this.userToken.family_name}`;
  }

  isUserLoggedIn() {
    return this.msalInstance.getAllAccounts().length > 0;
  }

  logout() {
    let postLogoutRedirectUri: string = environment.azureB2C.postLogoutRedirectUri;

    if (
      this.userToken &&
      this.userToken["idp"] &&
      this.userToken["idp"].includes("amrock")
    ) {
      postLogoutRedirectUri = environment.azureB2C.amrockPostLogoutRedirectUri;
    }

    let logoutRequest: EndSessionRequest;
    logoutRequest = {
      postLogoutRedirectUri: postLogoutRedirectUri,
    };

    sessionStorage.clear();
    this.msalInstance.logoutRedirect(logoutRequest);
  }

  goToMyAccount() {
    this.router.navigate(["/myaccount"]);
  }

  setFeatures(): void {
    this.featuresService
      .getFeatures()
      .pipe(
        tap(async (result) => {
          this.orderDetailStore.setFeatures(result);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
