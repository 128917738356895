import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FeatureConfiguration } from "src/app/shared/models/feature-configuration.model";
import { OrderDetailStore } from "src/app/order-detail/+state/order-detail.store";

import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class FeaturesService extends BaseService {
  className = "FeaturesService";

  constructor(injector: Injector, private orderDetailStore: OrderDetailStore) {
    super(injector);
  }

  getFeatures(): Observable<FeatureConfiguration[]> {
    return this.get<FeatureConfiguration[]>(`/v1/features`);
  }

  isFeatureEnabled(featureName: string): Observable<boolean> {
    return this.orderDetailStore.enabledFeatures$.pipe(
      map((result) => {
        return result?.some((x) => x.name === featureName);
      })
    );
  }
}
