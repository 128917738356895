import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormGroup, AbstractControl, Validators, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { Router } from '@angular/router';
import { NgbActiveModal, NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { ToastrService } from "ngx-toastr";
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap, take, switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { FormError } from 'src/app/shared/components/app-form-error/form-error';

import { AppSpinnerModule } from "../shared/modules/app-spinner.module";
import { CapabilitiesService } from "../shared/services/capabilities/capabilities.service";
import { DateHelper } from "../shared/helpers/date.helper";
import { FormValidators } from "../shared/validators/form.validators";
import { Order } from '../shared/models/order';
import { OrderDetailStore } from "../order-detail/+state/order-detail.store";
import { OrderFormService } from "./order-form.service";
import { OrdersService } from "../shared/services/orders.service";
import { State } from '../shared/services/state';
import { StatesService } from '../shared/services/states.service';
import { UtcConversionsService } from '../shared/services/utc-conversions.service';
import { ZipCodesService } from '../shared/services/zipcodes.service';
import { ZipCodeInfo } from '../shared/models/zipcode-info';
import { Client } from "../shared/models/client.model";
import { ClientService } from "../shared/services/client.service";
import { SystemAccountService } from "../shared/services/system-account.service";

@UntilDestroy()
@Component({
  templateUrl: "./order-form.modal.html",
  styleUrls: ["./order-form.modal.scss"],
})

export class OrderFormModalComponent implements OnInit {
  clientIdChanges: Subscription;
  formChanges: Subscription;
  order$: Observable<Order>;
  states: State[];
  meridian = true;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private orderService: OrdersService,
    private readonly spinner: AppSpinnerModule,
    private toastr: ToastrService,
    private router: Router,
    private readonly clientService: ClientService,
    private readonly systemAccountService: SystemAccountService,
    private readonly orderCreateService: OrderFormService,
    private readonly utcConversionsService: UtcConversionsService,
    private zipCodesService: ZipCodesService,
    public datePickerConfig: NgbDatepickerConfig,
    private dateHelper: DateHelper,
    private datePipe: DatePipe,
    private orderDetailStore: OrderDetailStore,
    private cdr: ChangeDetectorRef,
    private statesService: StatesService,
    private capabilitiesService: CapabilitiesService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.minDate = new Date();
  }

  orderForm: UntypedFormGroup ;
  public clientDropDownList: { clientId: number, displayString: string }[];
  public scheduledUTCDateTime: Date;
  public minDate: {};
  clients$: Observable<Client[]>;
  isEdit: boolean;
  showWarning$: Observable<boolean> | undefined;

  filteredClients: Client[];
  displayDate: string;
  displayTimeMinute: string | null;
  displayTimeHour: string | null;
  displayTimeModifier: string | null;
  orderId: number;

  readonly isFieldInvalidDirty = FormError.isFieldInvalidDirty;

  readonly closingTypes = this.getAvailableProductTypes();

  private readonly timePickerInputEventHandlers: HTMLInputElement[] = [];

  ngOnInit() {
    this.order$ = this.orderDetailStore.order$;
    this.clientLoader();
    this.canEditClient();
    this.initSearchFilters();
    this.setDisplayDateTime();
    this.states = this.statesService.all;
    this.showWarning$ = this.control('closingType')?.valueChanges.pipe(
      map(closingType => (closingType === 'KnownSignerRON'))
    );
  }

  handleTimePickerInput(event: InputEvent) {
    const target = event.target as HTMLInputElement;

    if (target.value === "Invalid DateTime") {
      target.value = "00";
    }
  }

  handleTimePickerClosed() {
    this.timePickerInputEventHandlers.forEach((timePickerInput: HTMLInputElement) => {
      timePickerInput.removeEventListener('input', this.handleTimePickerInput);
    })
  }

  handleTimePickerOpened() {
    setTimeout(() => {
      this.document
        .querySelectorAll("ngx-mat-timepicker-dial-control")
        .forEach((timePickerInput: HTMLInputElement) => {
          timePickerInput.addEventListener("input", this.handleTimePickerInput);

          this.timePickerInputEventHandlers.push(timePickerInput);
        });
    }, 0)
  }

  canEditClient() {
    if (this.isEdit) {
      this.capabilitiesService.userHasCapability("order", "CanEditClient", this.orderId)
        .pipe(
          tap(hasCapability => {
            if (!hasCapability && this.orderForm.get('clientId')?.enabled) {
              this.orderForm.get('clientId')?.disable();
            }
          })
        );
    }
  }

  private getAvailableProductTypes(): any[] {
    const productTypes = [
      { key: "Hybrid", value: "Hybrid" },
      { key: "KnownSignerRON", value: "Known Signer RON" },
      { key: "RemoteSigning", value: "Remote Online Notarization" },
    ];

    if (environment.features.isIPENEnabled === "true") {
      productTypes.push({ key: "IPEN", value: "IPEN" });
    }

    if (environment.features.isESignEnabled === "true") {
      productTypes.push({ key: "eSign", value: "eSign" });
    }

    return productTypes.sort((a, b) => (a.key.toUpperCase() > b.key.toUpperCase() ? 1 : -1));
  }

  private setDisplayDateTime() {
    if (this.isEdit)
    {
      this.order$.pipe(
        tap(currentOrder => {
          this.zipCodesService.getZipCodeInfo(currentOrder.propertyAddress.zipCode).then((zipInfo: ZipCodeInfo) =>
            {
              const dateTimeString = this.dateHelper.getDateTimeStringWithoutOffset(currentOrder.scheduledDateTime.toString());
              this.utcConversionsService.createLocalDateTimeBasedOnZip(zipInfo, dateTimeString).then(scheduledDate => {
                const scheduledTime = this.dateHelper.convertDateTimeToLocalDateTime(scheduledDate);
                this.displayTimeHour = this.datePipe.transform(
                  scheduledTime,
                  "H"
                );
                this.displayTimeMinute = this.datePipe.transform(
                  scheduledTime,
                  "mm"
                );
                this.orderForm.get('scheduledDate')?.patchValue(this.getDisplayDate(scheduledDate));
                this.orderForm.get('scheduledTime')?.setValue(`${(this.displayTimeHour)}:${(this.displayTimeMinute)}`);
              });
            });
        }),
        untilDestroyed(this)
      ).subscribe();
    }
    else
    {
      this.displayDate = "";
      this.displayTimeMinute = "00";
      this.displayTimeHour = "00";
    }
  }

  private initSearchFilters(): void {
    this.order$.pipe(
      tap(currentOrder => {
        this.orderId = currentOrder.id;
        this.orderForm = this.fb.group({
          clientId: [this.isEdit ? currentOrder.clientId : null, {
            validators: [Validators.required]
          }],
          clientFilter1: [''],
          scheduledDate: ['', {
            validators: [
              Validators.required
            ],
          }],
          scheduledTime: ['', {
            validators: [
              Validators.required
            ],
          }],
          partnerOrderIdentifier: [
            {
              value: this.isEdit ? currentOrder.partnerOrderIdentifier : '',
              disabled: !this.isEdit
            },
            [Validators.maxLength(20)]
          ],
          thirdPartyOrderIdentifier: [this.isEdit ? currentOrder.thirdPartyOrderIdentifier : "", {
            validators: [Validators.maxLength(32)]
          }],
          closingType: [{
            value: (this.isEdit ? currentOrder.productType : null),
            disabled: this.isEdit
          }, {
            validators: [Validators.required]
          }],
          loanNumber: [this.isEdit ? currentOrder.loanIdentifier : "", {
            validators: [Validators.maxLength(32)]
          }],
          address: [this.isEdit ? currentOrder.propertyAddress.streetAddress1 : "", {
            validators: [Validators.required, Validators.maxLength(100)]
          }],
          address2: [this.isEdit ? currentOrder.propertyAddress.streetAddress2 : "", {
            validators: [Validators.maxLength(50)]
          }],
          city: [this.isEdit ? currentOrder.propertyAddress.city : "", {
            validators: [Validators.required, Validators.maxLength(50)]
          }],
          state: [this.isEdit ? currentOrder.propertyAddress.stateCode : null, {
            validators: [Validators.required]
          }],
          zip: [this.isEdit ? currentOrder.propertyAddress.zipCode : "", {
            validators: [Validators.required, FormValidators.zipcode()],
            asyncValidators: [ this.zipAsyncValidator(this.zipCodesService) ]
          }],
        });
      }),
      take(1)
    ).subscribe();

    this.orderForm.get('scheduledTime')?.setValue('12:00 AM');

    this.control('clientId')?.valueChanges.pipe(
      tap(clientId => {
        if (clientId) {
          this.orderForm.get('partnerOrderIdentifier')?.enable();
        } else {
          this.orderForm.get('partnerOrderIdentifier')?.disable();
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    this.orderForm?.valueChanges.pipe(
      tap(() => {
        this.cdr.detectChanges();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  control(name): AbstractControl | null {
    const control = this.orderForm.get(name);
    return control;
  }

  async updateOrder() {
    if (this.orderForm.valid) {
      this.spinner.show();
      const form = this.orderForm.getRawValue();

      const datePart = form.scheduledDate.getDate() < 10 ? `0${form.scheduledDate.getDate()}` : form.scheduledDate.getDate();
      const monthPart = (form.scheduledDate.getMonth() + 1) < 10 ? `0${form.scheduledDate.getMonth() + 1}` : form.scheduledDate.getMonth() + 1;
      const dateString = `${form.scheduledDate.getFullYear()}-${monthPart}-${datePart}`;
      const timeString = this.getTimeString(this.convertTime12to24(form.scheduledTime));

      const zipInformation = await this.zipCodesService.getZipCodeInfo(form.zip) as ZipCodeInfo;
      const utcScheduledDateTime = await this.utcConversionsService.createUTCDateTimeBasedOnZip(zipInformation, dateString, timeString);

      this.orderDetailStore.currentOrderId$.pipe(
        switchMap((orderId) => {
          const updatedOrder = new Order();
          updatedOrder.id = orderId;
          updatedOrder.clientId = +form.clientId;
          updatedOrder.loanIdentifier = form.loanNumber;
          updatedOrder.partnerOrderIdentifier = form.partnerOrderIdentifier;
          updatedOrder.productType = form.closingType;
          updatedOrder.propertyAddress = {
              streetAddress1: form.address,
              streetAddress2: form.address2,
              city: form.city,
              stateCode: form.state,
              zipCode: form.zip,
              zip4: "0000"
            },
          updatedOrder.scheduledDateTime = utcScheduledDateTime;
          updatedOrder.signingAddresses = [];
          updatedOrder.signingStateCode = form.state;
          updatedOrder.thirdPartyOrderIdentifier = form.thirdPartyOrderIdentifier;
          this.getAndSetScheduledTime(updatedOrder, zipInformation);
          this.orderDetailStore.setOrder(updatedOrder);
          return this.orderService.updateOrder(orderId, updatedOrder);
        }),
        tap(result => {
          this.toastr.success("Order Updated Successfully");
          this.activeModal.close("update");
          this.router.navigate(['/order-detail', result.id]);
          this.spinner.hide();
        }),
        catchError(err => {
          if (err.error.status === 409) {
            const partnerOrderIdentifierControl = this.orderForm.controls.partnerOrderIdentifier;
            if (partnerOrderIdentifierControl !== null) {
              partnerOrderIdentifierControl.markAsTouched({ onlySelf: true });
              partnerOrderIdentifierControl.markAsDirty({ onlySelf: true });
            }
          } else if (err.error.status === 422) {
            this.toastr.error("This order has been moved out of 'Ready' status and is no longer allowed to be changed. Please Refresh for details.");
          }
          else {
            this.toastr.error("Sorry, an error occurred while saving your order.", "Error");
          }
          this.spinner.hide();
          return {} as any;
        }),
        take(1)
      ).subscribe();
    } else {
      FormError.ValidateAllFormFields(this.orderForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      return;
    }
  }

  zipAsyncValidator = (zipCodesService: ZipCodesService) => (c: UntypedFormControl) => {
    if (String(c.value).length !== 5) {
        return of(null);
    }

    return zipCodesService.getZipCodeInfoObservable(String(c.value)).pipe(
        map(() => {
          return null;
        }),
        catchError((err: any) => {
          return err.error.status === 404 ? of(null) : of({ zipAsyncValidator: true });
        })
      );
    }

  private async getAndSetScheduledTime(updatedOrder: Order, zipInformation: ZipCodeInfo) {
    const dateTimeString = this.dateHelper.getDateTimeStringWithoutOffset(updatedOrder.scheduledDateTime.toString());
    const scheduledDate = await this.utcConversionsService.createLocalDateTimeBasedOnZip(zipInformation, dateTimeString);
    const scheduledTime = this.dateHelper.convertDateTimeToLocalDateTime(scheduledDate);
    this.orderDetailStore.setScheduledTime(scheduledTime);
  }

  private getDisplayDate(scheduledDate: string): any {
    return new Date(`${scheduledDate.substr(0, scheduledDate.indexOf('T')) }T00:00:00`);
  }

  private convertTime12to24(time12h: string) {
    const [time, modifier] = time12h.split(" ");
    
    let [hours, minutes] = time.split(":");
    if (typeof modifier !== 'undefined') {
      if (hours === "12") {
        hours = "0";
      }

      if (modifier === "PM") {
        hours = `${parseInt(hours, 10) + 12}`;
      }
    }    

    var timeString = { hour: hours, minute: minutes };
    return timeString;
  };

  private getTimeString(timeString: any) {
    if (timeString.hour < 10 ) {
      return `0${timeString.hour}:${timeString.minute}:00`;
    }

    return `${timeString.hour}:${timeString.minute}:00`;
  }

  async save() {
    if (this.orderForm.valid) {
      this.spinner.show();
      const form = this.orderForm.value;

      const datePart = form.scheduledDate.getDate() < 10 ? `0${form.scheduledDate.getDate()}` : form.scheduledDate.getDate();
      const monthPart = (form.scheduledDate.getMonth() + 1) < 10 ? `0${form.scheduledDate.getMonth() + 1}` : form.scheduledDate.getMonth() + 1;
      const dateString = `${form.scheduledDate.getFullYear()}-${monthPart}-${datePart}`;
      const timeString = this.getTimeString(this.convertTime12to24(form.scheduledTime));

      const zipInformation = await this.zipCodesService.getZipCodeInfo(form.zip) as ZipCodeInfo;
      const utcScheduledDateTime = await this.utcConversionsService.createUTCDateTimeBasedOnZip(zipInformation, dateString, timeString);

      this.orderCreateService.save({
        id: 0,
        clientId: +form.clientId,
        loanIdentifier: form.loanNumber,
        partnerOrderIdentifier: form.partnerOrderIdentifier,
        productType: form.closingType,
        propertyAddress: {
          streetAddress1: form.address,
          streetAddress2: form.address2,
          city: form.city,
          stateCode: form.state,
          zipCode: form.zip,
          zip4: "0000"
        },
        scheduledDateTime: utcScheduledDateTime,
        signingAddresses: [],
        signingStateCode: form.state,
        thirdPartyOrderIdentifier: form.thirdPartyOrderIdentifier,
      }).pipe(
        tap(result => {
          this.toastr.success("Order Saved Successfully");
          this.activeModal.close("save");
          this.router.navigate(['/order-detail', result.id]);
          this.spinner.hide();
        }),
        catchError((err) => {
          if (err.error.status === 409) {
            const partnerOrderIdentifierControl = this.orderForm.controls.partnerOrderIdentifier;
            if (partnerOrderIdentifierControl !== null) {
              partnerOrderIdentifierControl.markAsTouched({ onlySelf: true });
              partnerOrderIdentifierControl.markAsDirty({ onlySelf: true });
              partnerOrderIdentifierControl.setErrors({ existingPartnerOrderIdentifier: true });
            } else {
              console.error(err.error.message);
              this.toastr.error("Sorry, an error occurred while saving your order.", "Error");
            }
          }
          this.spinner.hide();
          return {} as any;
        })
      ).subscribe();

    }
    else {
      FormError.ValidateAllFormFields(this.orderForm);
      this.toastr.warning("Please correct all errors.", "Attention");
      return;
    }
  }

  clientLoader() {
    this.systemAccountService.getCurrentSystemAccounts()
      .pipe(
        tap((systemAccounts) => {
          if (systemAccounts.length > 0) {
            this.clients$ = this.clientService.getClientListByAccountType([]).pipe();
            this.performClientFilter();
          }
          else {
            this.clients$ = this.clientService
              .getClientListByAccountType(["SettlementAgent"])
              .pipe();
              this.performClientFilter();
          }
        })
      ).subscribe();
  }

  private sortClients(clients: Client[]): Client[] {
    return clients.slice().sort((a, b) => {
      if (a.clientName.toLowerCase() === 'default') {
        return -1;
      } else if (b.clientName.toLowerCase() === 'default') {
        return 1;
      } else {
        return a.clientName.toLowerCase().localeCompare(b.clientName.toLowerCase());
      }
    });
  }

  performClientFilter() {
    const filterBy = this.orderForm.get("clientFilter1")?.value;
    this.clients$.pipe(
      map((clients) => {
        return clients.filter((client) => {
          return client.clientName
            .toLocaleLowerCase()
            .includes(filterBy?.toLocaleLowerCase());
        });
      })
    ).subscribe((clients) => {
      this.filteredClients = this.sortClients(clients);
    })
  }


  scheduledDateHasErrors(isEdit: boolean) {
    const scheduledDateControl = this.control('scheduledDate');
    return isEdit && (scheduledDateControl?.dirty || scheduledDateControl?.errors);
  }
}
