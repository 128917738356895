import { Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { SystemUserAccount } from "../../shared/models/system-user-account.model";
import { SystemUserAccountService } from "../../shared/services/system-user-account.service";
import { AddSystemUserModalComponent } from "./add-system-user-modal/add-system-user-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";

@UntilDestroy()
@Component({
  selector: 'manage-system-users',
  templateUrl: './manage-system-users.component.html',
  styleUrls: ['./manage-system-users.component.scss']
})
export class ManageSystemUsersComponent implements OnInit {
  systemUserAccounts: SystemUserAccount[] = [];
  clientUserAccounts: any[] = [];  // replace 'any' with the correct model/interface for client accounts
  filteredUserList: (SystemUserAccount[] | any[]); // replace 'any' with the correct model/interface for client accounts
  activeTab: 'system' | 'client' = 'client';

  constructor(
    private readonly systemUserAccountService: SystemUserAccountService,
    private readonly modalService: NgbModal,
    private readonly spinner: AppSpinnerModule
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.getSystemUserAccountList();
  }

  onFilteredUserList(filteredUsers: (SystemUserAccount[] | any[])) {
    this.filteredUserList = filteredUsers;
  }

  onSystemAccountDeleted(): void {
    this.getSystemUserAccountList();
  }

  onSystemAccountAdded(): void {
    this.getSystemUserAccountList();
  }

  getSystemUserAccountList(): void {
    this.systemUserAccountService.getAllSystemAccountUsers()
      .subscribe(accounts => {
          this.systemUserAccounts = accounts;
          this.spinner.hide();
      });
  }

  onTabChange(tab: 'system' | 'client') {
    this.activeTab = tab;
  }

  openAddSystemUserModal(): void {
    const modalRef = this.modalService.open(AddSystemUserModalComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.result.then((result) => {
      if (result === "systemAccountAdded") {
        this.onSystemAccountAdded();
      }
    });
  }
}
