import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { SystemUserAccount } from "../../../shared/models/system-user-account.model";

@Component({
  selector: 'app-user-filter',
  templateUrl: "user-filter-form.component.html",
  styleUrls: ['user-filter-form.component.scss']
})

export class UserFilterForm implements OnChanges {
  filter = "";

  @Input() userList: SystemUserAccount[] = [];
  @Input() activeTab: 'system' | 'client' = 'client';
  @Output() filteredUserList: EventEmitter<SystemUserAccount[]> = new EventEmitter<SystemUserAccount[]>();

  ngOnChanges(changes: any) {
    if (changes.userList) {
      this.performFilter(this.filter, this.userList);
    }
  }

  performFilter(filterBy: string, dataSet: SystemUserAccount[]): void {
    filterBy = filterBy.toLocaleLowerCase();
    const filteredList = dataSet.filter(user => {
      return Object.values(user).some((property) =>
      String(property).toLocaleLowerCase().includes(filterBy)
      );
    });
    this.filteredUserList.emit(filteredList);
  }
}
