import { Injectable } from '@angular/core';
import { State } from './state';

export enum TerritoryType {
  STATE = 'States'
}

@Injectable({
  providedIn: 'root'
})
export class StatesService {
  public get all() {
    let list: State[];
    const states = this.states;

    list = [...states];
    return list;
  }

  public get states(): State[] {
    let states: State[];

    states = [
      { code: "AL", name: "Alabama" , type: TerritoryType.STATE},
      { code: "AK", name: "Alaska" , type: TerritoryType.STATE},
      { code: "AZ", name: "Arizona" , type: TerritoryType.STATE},
      { code: "AR", name: "Arkansas" , type: TerritoryType.STATE},
      { code: "CA", name: "California" , type: TerritoryType.STATE},
      { code: "CO", name: "Colorado" , type: TerritoryType.STATE},
      { code: "CT", name: "Connecticut" , type: TerritoryType.STATE},
      { code: "DE", name: "Delaware" , type: TerritoryType.STATE},
      { code: "DC", name: "District Of Columbia" , type: TerritoryType.STATE},
      { code: "FL", name: "Florida" , type: TerritoryType.STATE},
      { code: "GA", name: "Georgia" , type: TerritoryType.STATE},
      { code: "HI", name: "Hawaii" , type: TerritoryType.STATE},
      { code: "ID", name: "Idaho" , type: TerritoryType.STATE},
      { code: "IL", name: "Illinois" , type: TerritoryType.STATE},
      { code: "IN", name: "Indiana" , type: TerritoryType.STATE},
      { code: "IA", name: "Iowa" , type: TerritoryType.STATE},
      { code: "KS", name: "Kansas" , type: TerritoryType.STATE},
      { code: "KY", name: "Kentucky" , type: TerritoryType.STATE},
      { code: "LA", name: "Louisiana" , type: TerritoryType.STATE},
      { code: "ME", name: "Maine" , type: TerritoryType.STATE},
      { code: "MD", name: "Maryland" , type: TerritoryType.STATE},
      { code: "MA", name: "Massachusetts" , type: TerritoryType.STATE},
      { code: "MI", name: "Michigan" , type: TerritoryType.STATE},
      { code: "MN", name: "Minnesota" , type: TerritoryType.STATE},
      { code: "MS", name: "Mississippi" , type: TerritoryType.STATE},
      { code: "MO", name: "Missouri" , type: TerritoryType.STATE},
      { code: "MT", name: "Montana" , type: TerritoryType.STATE},
      { code: "NE", name: "Nebraska" , type: TerritoryType.STATE},
      { code: "NV", name: "Nevada" , type: TerritoryType.STATE},
      { code: "NH", name: "New Hampshire" , type: TerritoryType.STATE},
      { code: "NJ", name: "New Jersey" , type: TerritoryType.STATE},
      { code: "NM", name: "New Mexico" , type: TerritoryType.STATE},
      { code: "NY", name: "New York" , type: TerritoryType.STATE},
      { code: "NC", name: "North Carolina" , type: TerritoryType.STATE},
      { code: "ND", name: "North Dakota" , type: TerritoryType.STATE},
      { code: "OH", name: "Ohio" , type: TerritoryType.STATE},
      { code: "OK", name: "Oklahoma" , type: TerritoryType.STATE},
      { code: "OR", name: "Oregon" , type: TerritoryType.STATE},
      { code: "PA", name: "Pennsylvania" , type: TerritoryType.STATE},
      { code: "RI", name: "Rhode Island" , type: TerritoryType.STATE},
      { code: "SC", name: "South Carolina" , type: TerritoryType.STATE},
      { code: "SD", name: "South Dakota" , type: TerritoryType.STATE},
      { code: "TN", name: "Tennessee" , type: TerritoryType.STATE},
      { code: "TX", name: "Texas" , type: TerritoryType.STATE},
      { code: "UT", name: "Utah" , type: TerritoryType.STATE},
      { code: "VT", name: "Vermont" , type: TerritoryType.STATE},
      { code: "VA", name: "Virginia" , type: TerritoryType.STATE},
      { code: "WA", name: "Washington" , type: TerritoryType.STATE},
      { code: "WV", name: "West Virginia" , type: TerritoryType.STATE},
      { code: "WI", name: "Wisconsin" , type: TerritoryType.STATE},
      { code: "WY", name: "Wyoming", type: TerritoryType.STATE }];
    return states;
  }

  public getStateByCode(stateCode: string | undefined): State | undefined {
    return this.all.find(state => state.code === stateCode);
  }
}
