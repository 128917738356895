<div *ngIf="actions.length > 0" class="d-inline-block" ngbDropdown>
  <button class="btn dropdown-menu-button"
    id="actionDropdownButton"
    ngbDropdownToggle
    aria-haspopup="true"
    aria-expanded="false"
    aria-label="Drop down button, ellipsis icon"
  >
    <span class="fas fa-ellipsis-h"></span>
  </button>
  <div ngbDropdownMenu aria-labelledby="actionDropdownButton" class="open-align-left">
    <button *ngFor="let action of actions" ngbDropdownItem (click)="handleActionCall(action)"
      [ngClass]="{'action-normal': !action.isDestructive, 'action-destructive': action.isDestructive}">
      {{action.actionText}}
    </button>
  </div>
</div>
