import { CommonModule } from "@angular/common";
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { MatSelectModule } from "@angular/material/select";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import * as _ from "cypress/types/lodash";
import { forEach, find } from "lodash-es";
import { ToastrService } from "ngx-toastr";
import { combineLatest, firstValueFrom, NEVER, Observable, of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { PdfjsService, PDFMetadata, PDFThumbnail, PNG } from "src/app/shared/services/pdfjs/pdfjs.service";

import { AppSpinnerModule } from "../shared/modules/app-spinner.module";
import { CapabilitiesHttpService } from "../shared/services/capabilities/capabilities.http.service.service";
import { DigiDocsStore, DigiDocsSection } from "./+state/digi-docs.store";
import { DigitizationParameter, PAGE_DIMENSIONS } from "./models/digitization-parameter.model";
import { DocumentAndEndorsementsService } from "../shared/services/document-and-endorsements.service";
import { DocumentCapabilityStrings } from "../shared/directives/capabilities/capabilities-params.model";
import { DocumentEditFormComponent } from "./components/ribbon/document-edit-form/document-edit-form.component";
import { DocumentEditorListComponent } from "./components/document-editor-list/document-editor-list.component"
import { DocumentEditorThumbnailsComponent } from "./components/document-editor-thumbnails/document-editor-thumbnails.component";
import { DocumentEndorsementsUploadModalComponent } from "./components/document-endorsements-upload-modal/document-endorsements-upload-modal.component";
import { DocumentService } from "../shared/services/document.service";
import {
  DocumentTemplate,
  NewDocumentTemplate,
} from "./models/document-template.model";
import { DocumentTemplateService } from "./services/document-template/document-template.service";
import { DocumentUploadModalComponent } from "./components/document-upload-modal/document-upload-modal.component";
import {
  Endorsement,
  EndorsementLocation,
  NewEndorsement,
} from "../shared/models/endorsement.models";
import { EndorsementDraggableComponent } from "./components/endorsement-draggable/endorsement-draggable.component";
import { EndorsementTypesService } from "./services/endorsement-types/endorsement-types.service";
import { EndorsementService } from "../shared/services/endorsements.service";
import { EndorsementComponentOutput, EndorsementsSidenavComponent, EndorsementUsingTemplateOutput, NotaryEndorsementComponentOutput, OpenSaveTemplateModalForTemplateNameOutput, SubmitDocumentAndEndorsementsOutput, UpdateTemplateEndorsementsOutput } from "./components/endorsements-sidenav/endorsements-sidenav.component";
import { environment } from "src/environments/environment";
import { GenerateDocEndorsementLocationService } from "./services/generate-doc-endorsement-location/generate-doc-endorsement-location.service";
import { ImageData } from "./models/image-data.model";
import { ImageOverlayDivDimensions } from "./models/image-overlay-div-dimensions.model";
import { KeyValuePair } from "../shared/models/key-value-pair";
import { LazyInjectService } from "../shared/services/lazy-inject/lazy-inject.service";
import { NewDocumentAndEndorsements } from "../shared/models/document-and-endorsements.models";
import { NewDocument, Document } from "../shared/models/document.models";
import { NewDocumentTemplateEndorsement } from "./models/document-template-endorsement.model";
import { Order } from "../shared/models/order";
import { OrdersService } from "../shared/services/orders.service";
import { Participant } from "../shared/models/participant.model";
import { ParticipantDisplayNamePipe, ParticipantSignatureNamePipe } from "../shared/pipes";
import { ParticipantsService } from "../shared/services/participants.service";
import { RoleCodeValueService } from "./services/role-code-value/role-code-value.service";
import { RoleValuePipe } from './pipes/role-value.pipe';
import { SaveTemplateModalComponent } from "./components/save-template-modal/save-template-modal.component";
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { OrderRuleAudit } from "../shared/models/order-rule-audit.model";
import { DocumentRuleModel } from "../shared/models/document-rule.model";
import { DocumentStatus } from "../shared/enums/documentStatus";

@UntilDestroy()
@Component({
  standalone: true,
  selector: "app-digitization-tool",
  templateUrl: "./digitization-tool.component.html",
  styleUrls: ["./digitization-tool.component.scss"],
  providers: [ParticipantDisplayNamePipe],
  imports: [
    CommonModule,
    DocumentEditFormComponent,
    DocumentEditorListComponent,
    DocumentEditorThumbnailsComponent,
    EndorsementsSidenavComponent,
    MatSelectModule,
    ParticipantDisplayNamePipe,
    ParticipantSignatureNamePipe,
    RoleValuePipe,
    SubHeaderComponent,
    NgbModule
  ],
})
export class DigitizationToolComponent implements OnInit, OnDestroy {
  public documentTemplateEndorsements: NewDocumentTemplateEndorsement[];
  public endorsementDraggableComponentsRef: ComponentRef<EndorsementDraggableComponent>[];
  public finalEndorsementLocationsOutputPdf: any;
  public finalEndorsementLocationsOutputPng: EndorsementLocation[];
  public finalTemplateEndorsementLocationsOutputPng: any;
  public isOrderLocked = false;
  public participants$: Observable<Participant[]>;
  public incomingDocId: number;
  public incomingDocList: Document[];
  public paperSizes: KeyValuePair[];
  public order: Order = new Order();

  private pdfjsService: PdfjsService;
  public pdfThumbnails$: Observable<PDFThumbnail[]>;

  digitizationParameters$: Observable<DigitizationParameter>;
  docImagechanges$: Observable<any>;
  documentFileName$: Observable<string>;
  documentUploadResult$: Observable<"pending" | "failure" | "success" | "">;
  draggableElementId$: Observable<number>;
  imageData$: Observable<ImageData>;
  imageOverlayDivDimensions$: Observable<ImageOverlayDivDimensions>;
  orderId$: Observable<number>;
  pdfBytes$: Observable<string>;
  sectionToDisplay$: Observable<DigiDocsSection>;
  ribbonTabToDisplay$: Observable<"File" | "Document" | "Apply"> = of("File");
  templateEndorsementsGenerator$: Observable<any>;
  systemEndorsementText: string;
  newTemplateName: string;
  savedDocumentTemplate$: Observable<DocumentTemplate>;
  templateID: number | null;
  ruleViolations: OrderRuleAudit;

  @ViewChild("documentThumbnails") documentThumbnails: ElementRef;
  @ViewChild("documentDisplayContainer") documentDisplayContainer: ElementRef;
  @ViewChild("ec", { read: ViewContainerRef })
  endorsementContainer: ViewContainerRef;
  @ViewChild("imageDivOverlay") imageDivOverlay: ElementRef;
  @ViewChildren("docImage", { read: ElementRef })
  docImage: QueryList<ElementRef>;
  @ViewChildren("endorsement") endorsements: QueryList<ElementRef>;

  templateOptions$: Observable<KeyValuePair[]>;

  constructor(
    private capabilitiesHttpService: CapabilitiesHttpService,
    private documentAndEndorsementsService: DocumentAndEndorsementsService,
    private documentService: DocumentService,
    private documentTemplateService: DocumentTemplateService,
    private endorsementsService: EndorsementService,
    private endorsementTypesService: EndorsementTypesService,
    private generateDocEndorsementLocationService: GenerateDocEndorsementLocationService,
    private modalService: NgbModal,
    private ordersService: OrdersService,
    private readonly participantDisplayNamePipe: ParticipantDisplayNamePipe,
    private participantsService: ParticipantsService,
    private readonly componentStore: DigiDocsStore,
    private readonly spinner: AppSpinnerModule,
    private resolver: ComponentFactoryResolver,
    private roleCodeValueService: RoleCodeValueService,
    private route: ActivatedRoute,
    private router: Router,
    private lazyInjector: LazyInjectService,
    private toastr: ToastrService,
  ) {
      this.incomingDocId = this.router.getCurrentNavigation()?.extras?.state?.docId;
      this.incomingDocList = this.router.getCurrentNavigation()?.extras?.state?.docList;
  }

  async ngOnInit() {
    window.scrollTo(0, 0);
    this.pdfjsService = await this.lazyInjector.get<PdfjsService>(() =>
      import("src/app/shared/services/pdfjs/pdfjs.service").then(
        (m) => m.PdfjsService
      )
    );

    this.ribbonTabToDisplay$ = this.componentStore.ribbonTabToDisplay$;
    this.sectionToDisplay$ = this.componentStore.sectionToDisplay$;
    this.participants$ = this.componentStore.orderId$.pipe(
      switchMap((orderId) => this.ordersService.getOrder(orderId)),
      tap((order) => {
        this.order = order;
        this.isOrderLocked = order.statusCode !== "READY";
      }),
      switchMap((order) => this.participantsService.getParticipants(order.id)),
      shareReplay(),
      take(1)
    );

    this.participants$.subscribe();
    this.resetDigiDocs();
    this.componentStore.setOrderId(this.route.snapshot.params.orderId);
    this.orderId$ = this.componentStore.orderId$;
    this.getAllDigitizableDocuments();
    this.getAvailableTemplates();
    this.documentTemplateEndorsements =
      new Array<NewDocumentTemplateEndorsement>();
    this.imageData$ = this.componentStore.imageData$;
    this.pdfThumbnails$ = this.componentStore.pdfThumbnails$;
    this.pdfBytes$ = this.componentStore.pdfBytes$;
    this.digitizationParameters$ = this.componentStore.digitizationParameters$;
    this.documentFileName$ = this.componentStore.documentFileName$;
    this.imageOverlayDivDimensions$ =
      this.componentStore.imageOverlayDivDimensions$;
    this.watchAndDisplayUploadingModal().subscribe();
    this.watchImageData().subscribe();
    this.showSpinnerWhenDocIsUploading().subscribe();
    this.documentService.getListByOrderId(this.order.id)
      .subscribe(docList => {
        if(docList.length === 0) {
          this.openUploadDocumentModel();
        }
      }
    );    
    if(this.incomingDocId) {
      this.digitizationParameters$.pipe(
        tap((dp) => {
          this.paperSizes = dp.paperSizes;
        }),
        take(1)
      )
        .subscribe();
      this.displaySelectedDocument(this.incomingDocId, this.incomingDocList, this.paperSizes);      
    } else {
      this.toggleSectionToDisplay(DigiDocsSection.LIST);
    }
    this.getRuleAudit();
  }

  private async generateDocumentThumbnails(
    documents: Document[]): Promise<void> {
    // setup "skeleton loading"
    const skeletonThumbnails = documents.map((doc) => ({
      alt: doc.name,
      docId: doc.id,
      docStatus: doc.status,
      figcaption: doc.name,
      isVoid: false,
      image: null,
      isLoading: true,
      capabilities: [],
      errorText: doc.errorText
    }));

    this.componentStore.addPdfThumbnails(skeletonThumbnails);

    // setup void thumbnails
    const voidImage = document.createElement("img");
    voidImage.setAttribute("src", "/assets/images/document_void.png");

    documents.forEach(async (doc: Document & { capabilities: DocumentCapabilityStrings[] }) => {
      // populate void thumbnails
      if (doc.isVoid) {
        this.componentStore.updatePdfThumbnail({
          alt: doc.name,
          docId: doc.id,
          docStatus: doc.status,
          figcaption: doc.name,
          isVoid: true,
          image: voidImage,
          isLoading: false,
          capabilities: doc.capabilities,
          errorText: doc.errorText
        });

        return;
      }

      // populate pdf thumbnail
      if (doc?.id && !doc.isVoid) {
        const pdfBlob = await firstValueFrom(this.documentService
          .getPdfBlobByDocumentId(doc.id));

        const pdfArrayBuffer = await pdfBlob?.arrayBuffer();
        const pdfPagesResult = pdfArrayBuffer
          ? await this.pdfjsService?.getPdfPages(
              pdfArrayBuffer,
              {
                start: 1,
                max: 1,
              },
              {
                start: 1,
                max: 1,
              }
            )
          : null;

        if (pdfPagesResult) {
          const meta: PDFMetadata = pdfPagesResult[1];
          const pngs: PNG[] = pdfPagesResult[2];

          this.componentStore.updatePdfThumbnail({
            alt: meta?.info["Title"] ?? doc.name,
            docId: doc.id,
            docStatus: doc.status,
            figcaption: doc.name,
            isVoid: false,
            image: pngs[0],
            isLoading: false,
            capabilities: doc.capabilities,
            errorText: doc.errorText
          });
        }
      }
    });
  }

  updateImageOverlayDivDimensions(): ImageOverlayDivDimensions {
    const docImage = this.docImage.first.nativeElement;
    const doc = this.documentDisplayContainer.nativeElement;
    const imageOverlayDivDimensions: ImageOverlayDivDimensions = {
      width: `${docImage.offsetWidth}px`,
      widthRaw: docImage.offsetWidth,
      height: `${docImage.offsetHeight}px`,
      heightRaw: docImage.offsetHeight,
    };
    this.componentStore.setImageOverlayDivDimensions(imageOverlayDivDimensions);
    doc?.scrollTo({ top: 0, behavior: "instant" });
    return imageOverlayDivDimensions;
  }

  watchImageData(): Observable<[ImageData, DigitizationParameter]> {
    return combineLatest([this.imageData$, this.digitizationParameters$]).pipe(
      tap(([imageData, digitizationParameters]) => {
        if (
          imageData?.width !== 0 &&
          imageData?.height !== 0 &&
          digitizationParameters?.selectedPaperSize !== null
        ) {
          this.populateImagePixelData(digitizationParameters);
          this.toggleRibbonTab("Document");
        } else {
          this.toggleRibbonTab("File");
        }
        this.componentStore.setIsDocumentUploading(false);
        this.spinner.hide();
        this.resetTemplates();
        this.componentStore.setDocumentFileName(
          digitizationParameters.documentName.substring(
            0,
            digitizationParameters.documentName.indexOf(".")
          )
        );
        this.getTemporaryDocument(digitizationParameters);
        this.componentStore.updateDigitizationParameters({
          fileNameText: "Choose File",
        } as any);
      }),
      untilDestroyed(this)
    );
  }

  watchAndDisplayUploadingModal(): Observable<
    "pending" | "failure" | "success" | ""
  > {
    return this.componentStore.documentUploadResult$.pipe(
      tap((result) => {
        if (result === "pending") {
          this.modalService.open(DocumentEndorsementsUploadModalComponent);
        } else if (result === "failure" || "success") {
          this.modalService.dismissAll();
        }
      })
    );
  }

  showSpinnerWhenDocIsUploading(): Observable<boolean> {
    return this.componentStore.isDocumentUploading$.pipe(
      tap((isDocumentUploading) => {
        if (isDocumentUploading) {
          this.spinner.show();
        }
      }),
      take(1)
    );
  }

  toggleRibbonTab(tab: "File" | "Document" | "Apply"): void {
    this.componentStore.setRibbonTabToDisplay(tab);
  }

  toggleSectionToDisplay(section: DigiDocsSection): void {
    this.componentStore.sectionToDisplay(section);
  }

  toggleFileTab(): void {
    this.toggleRibbonTab("File");
    this.toggleSectionToDisplay(DigiDocsSection.DOCUMENTS);
  }

  private getTemporaryDocument(digitizationParameters: DigitizationParameter) {
    const newDocument = new Document();
    newDocument.id = -1;
    newDocument.name = digitizationParameters.documentName;
    newDocument.pageType = digitizationParameters.selectedPaperSize?.value;
    this.componentStore.addDocumentToDigitizationParameters(newDocument);
    this.componentStore.updateDigitizationParameters({
      mySelectedDocument: newDocument,
    });
  }

  bailoutFromDocumentBytesNotFound(err) {
    console.log(`ERROR: getBytesByDocumentId`, err);
    this.toastr.error(
      "Document has been deleted due to a data retention policy.",
      "Error Loading Document"
    );
    this.spinner.hide();
  }

  displaySelectedDocument(
    documentId: number,
    documents: Document[],
    paperSizes: KeyValuePair[]
  ) {
    this.resetEndorsementComponents();
    this.templateID = 0;
    this.spinner.show();
    this.documentService
      .getBytesByDocumentId(documentId)
      .pipe(
        catchError((err) => {
          this.bailoutFromDocumentBytesNotFound(err);
          return [];
        }),
        tap((result) => {
          if (result === null || result.length <= 0) {
            this.bailoutFromDocumentBytesNotFound(
              "Empty document bytes result"
            );
            return;
          }

          const imageDataToUpdate: Partial<ImageData> = {};
          imageDataToUpdate.src = result;
          this.componentStore.updateImageData(imageDataToUpdate as any);
          const selectedDoc = documents.find((u) => u.id === documentId);
          const paramsToUpdate: Partial<DigitizationParameter> = {};
          paramsToUpdate.selectedTemplate = null;

          if (selectedDoc !== undefined) {
            paramsToUpdate.mySelectedDocument = selectedDoc;
            paramsToUpdate.selectedPaperSize = paperSizes.find(
              (p) => p.value === selectedDoc?.pageType
            );
            paramsToUpdate.documentName = selectedDoc.name;
            paramsToUpdate.selectedDocumentType = selectedDoc.type;
          }

          this.spinner.hide();
          this.componentStore.updateDigitizationParameters(
            paramsToUpdate as any
          );
          this.toggleRibbonTab("Document");
          this.toggleSectionToDisplay(DigiDocsSection.DOCUMENTS);
        }),
        take(1),
        switchMap(() =>
          this.endorsementsService.getListByDocumentId(documentId)
        ),
        withLatestFrom(this.participants$, this.digitizationParameters$),
        map(
          ([endorsementsFromSubject, participants, digitizationParameters]) => {
            if (digitizationParameters.selectedPaperSize !== null) {
              this.populateImagePixelData(digitizationParameters);
            }
            return {
              endorsementsFromSubject,
              participants,
              digitizationParameters,
            };
          }
        ),
        withLatestFrom(this.imageData$),
        tap(([params, imageData]) => {
          if (params.endorsementsFromSubject.length > 0) {
            this.drawEndorsements(
              params.endorsementsFromSubject,
              imageData,
              params.participants
            );
          }
        }),
        tap(() => {
          this.updateImageOverlayDivDimensions();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setDocumentStatus(documents: Document[]) {
    var violations = this.ruleViolations.documentRules;
    documents.forEach(function (doc: Document) {
      if (doc.status === DocumentStatus.Signable.toString()) {        
        if (violations?.some(d => d.packageDocumentId === doc.id)) {
          doc.status = 'Needs Attention';
          violations.forEach(function (violation: DocumentRuleModel) {
            if(violation.packageDocumentId === doc.id) {
              if(doc.errorText) {
                doc.errorText += '. ' + violation.errorText;
              }
              else {
                doc.errorText = "Document Rule Violations: " + violation.errorText;
              }
            }
          })
        }
        else {
          doc.status = 'Ready';
        }
      }
    })
    return documents;
  }

  getRuleAudit() {
    this.ordersService
      .getRuleAudit(this.order.id)
      .pipe(
        tap((ruleViolations) => {
          this.ruleViolations = ruleViolations;
        })
      )
      .subscribe();
  }

  populateImagePixelData(digitizationParameters: DigitizationParameter): void {
    const imageDataToUpdate: Partial<ImageData> = {};
    imageDataToUpdate.fileName = digitizationParameters.documentName;
    imageDataToUpdate.width = +this.docImage?.first?.nativeElement?.offsetWidth;
    imageDataToUpdate.height =
      +this.docImage?.first?.nativeElement?.offsetHeight;
    if (digitizationParameters.selectedPaperSize?.value) {
      imageDataToUpdate.dpiActual = Math.round(
        imageDataToUpdate.width /
          digitizationParameters.selectedPaperSize.key.width
      );
      imageDataToUpdate.pagePixelHeightLender =
        digitizationParameters.selectedPaperSize.key.height *
        digitizationParameters.lenderDpi;
      imageDataToUpdate.pagePixelHeightActual =
        digitizationParameters.selectedPaperSize.key.height *
        imageDataToUpdate.dpiActual;
      imageDataToUpdate.dpiConversionFactor =
        digitizationParameters.lenderDpi / imageDataToUpdate.dpiActual;
    } else {
      imageDataToUpdate.dpiActual = 0;
      imageDataToUpdate.pagePixelHeightLender = 0;
      imageDataToUpdate.pagePixelHeightActual = 0;
      imageDataToUpdate.dpiConversionFactor = 0;
    }
    this.componentStore.updateImageData(imageDataToUpdate as any);
  }

  generateNotaryEndorsement(
    imageData: ImageData | null,
    participants: Participant[]
  ) {
    const notaryParticipant = participants.find(
      (p) => p.role === "SIGNINGAGENT"
    );

    if (notaryParticipant !== undefined) {
      this.generateEndorsementComponent(
        notaryParticipant,
        imageData,
        false,
        "NOTARYSTAMP"
      );
    }
  }

  handleGenerateNotaryEndorsementComponent({
    imageData,
    participants,
  }: NotaryEndorsementComponentOutput) {
    this.generateNotaryEndorsement(
      imageData,
      participants,
    );
  }

  generateEndorsementComponent(
    participant: Participant,
    imageData: ImageData | null,
    isSystem: boolean = false,
    endorsementType: string,
    format?: string,
    xPosition: number = 0,
    yPosition: number = 0,
    width: number = 0,
    height: number = 0,
    isRequired: boolean = true,
    isEditable: boolean = true,
    existingEndorsementLocationId?: number
  ) {
    if (imageData === null || imageData?.src === "") {
      const friendlyEndorsementName =
        this.endorsementTypesService.fetchEndorsementTextValue(endorsementType);
      this.toastr.warning(
        "Please upload a document to add " + friendlyEndorsementName + "."
      );
    } else {
      combineLatest([
        this.componentStore.draggableElementId$,
        this.componentStore.currentElementIndex$,
      ])
        .pipe(
          take(1),
          distinctUntilChanged(),
          tap(([draggableElementId, currentElementIndex]) => {
            if (xPosition === 0) {
              xPosition = imageData.width / 2;
            }

            if (yPosition === 0) {
              yPosition = this.documentDisplayContainer.nativeElement.scrollTop === 0 ? 0 : this.documentDisplayContainer.nativeElement.scrollTop + 50;
            }

            if (width === 0 && height === 0) {
              const endorsementTypeDimensions =
                this.endorsementTypesService.fetchEndorsementDefaultDimensions(
                  endorsementType
                );
              width = endorsementTypeDimensions.width;
              height = endorsementTypeDimensions.height;
            }

            if (isSystem) {
              this.getSystemTooltiptext(endorsementType, format);
            }

            const factory: ComponentFactory<EndorsementDraggableComponent> =
              this.resolver.resolveComponentFactory(
                EndorsementDraggableComponent
              );
            const draggableElement: ComponentRef<EndorsementDraggableComponent> =
              this.endorsementContainer.createComponent(factory);
            const newComponent = draggableElement.instance;
            const endorsementTypeValue =
              this.endorsementTypesService.fetchEndorsementTextValue(
                endorsementType
              );
            const requiredText = isRequired ? "Required" : "Not Required";
            const editableText = isEditable ? "Editable" : "Not Editable";

            draggableElement.instance.selfRef = newComponent;
            draggableElement.instance.index = ++currentElementIndex;
            draggableElement.instance.dynamicEndorsementLocation = {
              x: xPosition,
              y: yPosition,
            };
            draggableElement.instance.id = draggableElementId;
            draggableElement.instance.name = "endorsement";
            draggableElement.instance.participantId = !isSystem
              ? participant.id
              : 0;
            draggableElement.instance.toolTipText = !isSystem
              ? `${this.getDisplayName(this.participantDisplayNamePipe.transform(participant), participant.sequenceNumber)} ${this.roleCodeValueService.returnRoleCodeValue(
                  participant.role
                )}` +
                `<br/>Type: ${endorsementTypeValue}` +
                `<br/>${requiredText}` +
                `<br/>${editableText}`
              : `${this.systemEndorsementText}`;
            draggableElement.instance.endorsementRole = !isSystem
              ? participant.role
              : "SYSTEM";
            draggableElement.instance.endorsementType = {
              key: endorsementType,
              value: endorsementTypeValue,
            };
            draggableElement.instance.isRequired = isRequired;
            if (format) {
              draggableElement.instance.format = format;
            }
            draggableElement.instance.endorsementControlDimensions = {
              width: `${width}px`,
              height: `${height}px`,
            };
            draggableElement.instance.isEditable = isEditable;
            draggableElement.instance.documentEndorsementLocationId =
              existingEndorsementLocationId;

            newComponent.compInteraction = this;
            this.endorsementDraggableComponentsRef.push(draggableElement);

            this.componentStore.incrementDraggableElementId();
            this.componentStore.incrementCurrentElementIndex();
          })
        )
        .subscribe();
    }
  }

  handleEndorsementComponentOutput({
    participant,
    imageData,
    isSystem,
    endorsementType,
    format,
    isRequired
  }: EndorsementComponentOutput) {
    this.generateEndorsementComponent(
      participant,
      imageData,
      isSystem,
      endorsementType,
      format,
      0,
      0,
      0,
      0,
      isRequired
    );
  }

  getSystemTooltiptext(endorsementType: string, format?: string): void {
    if (endorsementType !== "DATE") {
      this.systemEndorsementText =
        this.roleCodeValueService.returnRoleCodeValue(endorsementType);
    } else {
      let maskDisplayStrings = new Map<string, string>();
      let formatText;

      maskDisplayStrings.set("MM/dd/yyyy", "Full Date (MM/dd/yyyy)");
      maskDisplayStrings.set("yyyy", "Four Digit Year (yyyy)");
      maskDisplayStrings.set("yy", "Two Digit Year (yy)");
      maskDisplayStrings.set("dd", "Day Of Month (dd)");
      maskDisplayStrings.set("MMMM", "Month Name (January)");
      maskDisplayStrings.set("MMM", "Month Abbreviated (Jan)");
      maskDisplayStrings.set("MM", "Month Number (MM)");

      formatText = format;
      if (!format) {
        formatText = "Full Date (MM/dd/yyyy)";
      } else if (maskDisplayStrings.has(format)) {
        formatText = maskDisplayStrings.get(format);
      }

      this.systemEndorsementText = formatText;
    }
  }

  drawEndorsements(
    endorsements: Endorsement[],
    imageData: ImageData,
    participants: Participant[]
  ): void {
    this.resetEndorsementComponents();
    forEach(endorsements, (endorsementInformation) => {
      const participant = participants.find(
        (p) => p.id === endorsementInformation.participantId
      );
      const endorsementAlreadySigned =
        endorsementInformation.status?.toUpperCase() === "SIGNED";
      const existingEndorsementLocationId = endorsementInformation.id
        ? endorsementInformation.id
        : null;

      if (participant !== undefined) {
        this.generateEndorsementComponent(
          participant,
          imageData,
          false,
          endorsementInformation.type,
          endorsementInformation.format,
          endorsementInformation.adjustedXPosition,
          endorsementInformation.adjustedYPosition,
          endorsementInformation.width,
          endorsementInformation.height,
          endorsementInformation.isRequired,
          !endorsementAlreadySigned,
          existingEndorsementLocationId
        );
      } else if (endorsementInformation.participantRole === "SYSTEM") {
        this.generateEndorsementComponent(
          new Participant(),
          imageData,
          true,
          endorsementInformation.type,
          endorsementInformation.format,
          endorsementInformation.adjustedXPosition,
          endorsementInformation.adjustedYPosition,
          endorsementInformation.width,
          endorsementInformation.height,
          true,
          !endorsementAlreadySigned,
          existingEndorsementLocationId
        );
      }
    });
  }

  deleteEndorsementComponentByIndex(index: number): void {
    if (this.endorsementContainer.length < 1) {
      return;
    }

    const endorsementComponentRef = find(
      this.endorsementDraggableComponentsRef,
      (endorsement) => {
        return endorsement.instance.index === index;
      }
    );
    const endorsementContainerIndex: number = this.endorsementContainer.indexOf(
      endorsementComponentRef?.hostView
    );

    this.endorsementContainer.remove(endorsementContainerIndex);

    this.endorsementDraggableComponentsRef =
      this.endorsementDraggableComponentsRef.filter(
        (x) => x.instance.index !== index
      );
  }

  submitDocumentAndEndorsements(
    imageData: ImageData,
    digitizationParameters: DigitizationParameter
  ): void {
    imageData = this.regenerateImageDataHeightWidth(
      imageData,
      digitizationParameters
    );
    this.finalEndorsementLocationsOutputPng =
      this.generateDocEndorsementLocationService.generateLocationsForPng(
        this.imageDivOverlay,
        imageData,
        digitizationParameters
      );
    const selectedDocumentName =
      digitizationParameters?.mySelectedDocument?.name ?? "";
    const selectedDocumentId =
      digitizationParameters?.mySelectedDocument?.id ?? 0;

    const canBeSaved =
      selectedDocumentName.length > 0 &&
      !!digitizationParameters?.mySelectedDocument?.pageType;
    if (canBeSaved) {
      if (selectedDocumentId > 0) {
        this.updateDocumentAndEndorsements(imageData, digitizationParameters);
      } else {
        this.saveNewDocumentAndEndorsements(imageData, digitizationParameters);
      }
    } else {
      this.toastr.error("Please Select a Document");
    }
  }

  handleSubmitDocumentAndEndorsements({
    digitizationParameters,
    imageData,
  }: SubmitDocumentAndEndorsementsOutput) {
    this.submitDocumentAndEndorsements(
      imageData as ImageData,
      digitizationParameters,
    );
  }

  updateDocumentAndEndorsements(
    imageData: ImageData,
    digitizationParameters: DigitizationParameter
  ): void {
    this.componentStore.setDocumentUploadResult("pending");
    const document = new Document();
    document.attributes = digitizationParameters.mySelectedDocument?.attributes;
    document.isPaper = false;
    document.name = digitizationParameters.documentName;
    document.pageType = digitizationParameters.selectedPaperSize?.value;
    document.sequenceNumber =
      digitizationParameters.mySelectedDocument?.sequenceNumber ?? 1;
    document.totalPages =
      this.generateDocEndorsementLocationService.fetchImageTotalPageNumber(
        imageData
      );
    document.type = digitizationParameters.selectedDocumentType;
    if (!document.type) {
      this.componentStore.setDocumentUploadResult("failure");
      this.toastr.error("Document Type Required.");
      return;
    }
    document.isDocPreSign =
      digitizationParameters?.mySelectedDocument?.isDocPreSign;
    const endorsements = this.getFinalEndorsements(imageData);

    document.isDigitized = endorsements?.length > 0;

    this.componentStore.setDocumentUploadResult("pending");

    this.documentService
      .patchDocument(
        digitizationParameters?.mySelectedDocument?.id ?? 0,
        document
      )
      .pipe(
        switchMap(() =>
          this.endorsementsService.updateEndorsements(
            digitizationParameters?.mySelectedDocument?.id ?? 0,
            endorsements
          )
        ),
        catchError((err) => {
          this.componentStore.setDocumentUploadResult("failure");
          console.log(`ERROR: updateDocumentAndEndorsements`, err);
          this.toastr.error("Document and Endorsements Update Failed.");
          throw err;
        }),
        tap(() => {
          this.componentStore.setDocumentUploadResult("success");
          this.getRuleAudit();
          this.getAllDigitizableDocuments(true);
          this.toastr.success("Document and Endorsements have been updated.");
        }),
        take(1)
      )
      .subscribe();
  }

  saveNewDocumentAndEndorsements(
    imageData: ImageData,
    digitizationParameters: DigitizationParameter
  ): void {
    this.componentStore.setDocumentUploadResult("pending");
    const docEnd = new NewDocumentAndEndorsements();
    docEnd.document = new NewDocument();
    docEnd.document.isPaper = false;
    docEnd.document.name = digitizationParameters.documentName;
    docEnd.document.pageType = digitizationParameters.selectedPaperSize?.value;
    const nextSequenceNumber: number = this.getNextSequenceNumber(
      digitizationParameters.myDocuments
    );
    docEnd.document.sequenceNumber = nextSequenceNumber;
    docEnd.document.totalPages =
      this.generateDocEndorsementLocationService.fetchImageTotalPageNumber(
        imageData
      );
    docEnd.document.type = digitizationParameters.selectedDocumentType;
    if (!docEnd.document.type) {
      this.componentStore.setDocumentUploadResult("failure");
      this.toastr.error("Document Type Required.");
      return;
    }
    docEnd.document.isDocPreSign =
      digitizationParameters?.mySelectedDocument?.isDocPreSign;
    docEnd.endorsements = this.getFinalEndorsements(imageData);

    this.componentStore.pdfBytes$
      .pipe(
        map((pdfBytes) => {
          const docWithPdfBytes = { ...docEnd.document, bytes: pdfBytes };
          return {
            ...docEnd,
            document: docWithPdfBytes,
          } as NewDocumentAndEndorsements;
        }),
        withLatestFrom(this.orderId$),
        distinctUntilChanged(),
        switchMap(([docEndorsement, orderId]) =>
          this.documentAndEndorsementsService.saveDocumentAndEndorsements(
            orderId,
            [docEndorsement]
          )
        ),
        tap((result) => {
          const documentResult = result.find((d) => d.document.id > 0);
          if (documentResult !== undefined) {
            this.componentStore.updateDigitizationParameters({
              mySelectedDocument: documentResult.document,
            } as any);
            this.getAllDigitizableDocuments(true);
          }
          this.toastr.success("Document and Endorsements have been saved.");
          this.componentStore.setDocumentUploadResult("success");
        }),
        catchError((err) => {
          this.toastr.error("Document and Endorsements Save Failed.");
          this.componentStore.setDocumentUploadResult("failure");
          throw err;
        }),
        take(1)
      )
      .subscribe();
  }

  getNextSequenceNumber(myDocuments: Document[]): number {
    const sortedSequenceNumbers = [
      ...myDocuments?.map((x) => x.sequenceNumber).sort(),
    ];
    const lastDocumentIndex = sortedSequenceNumbers.length - 1 ?? 0;
    return (sortedSequenceNumbers[lastDocumentIndex] ?? 0) + 1;
  }

  getAllDigitizableDocuments(updateSelectedDocument?: boolean): void {
    this.orderId$
      .pipe(
        tap((orderId) => this.order.id = orderId),
        switchMap((orderId) => this.documentService.getListByOrderId(orderId)),
        map((result) => {
          return result.filter(
            (doc) =>
              !doc.isPaper &&
              (doc.status === "SIGNABLE" ||
                doc.status === "PARTSIGNED" ||
                doc.status === "SIGNED")
          );
        }),
        map((eSignDocs) => eSignDocs.filter((doc) => !doc.isSmartDoc)),
        switchMap((digitizableDocuments) =>
          this.capabilitiesHttpService
            .getCapabilitiesByOrder(this.order.id)
            .pipe(
              map((capabilities) =>
                digitizableDocuments.map((document) => ({
                  ...document,
                  capabilities: capabilities?.documentCapabilities.filter(
                    (documentCapability) =>
                      documentCapability.documentId === document.id
                  )[0]?.capabilities,
                }))
              )
            )
        ),
        tap((digitizableDocuments) => {
          this.componentStore.updateDigitizationParameters({
            myDocuments: digitizableDocuments,
          });
        }),
        // There is a possibility when this method is first called that
        // this.digitizationParameters$ may be null or undefined.
        withLatestFrom(this.digitizationParameters$ ?? of(null)),
        filter(
          ([_, digitizationParameters]) => digitizationParameters !== undefined
        ),
        tap(([digitizableDocuments, digitizationParameters]) => {
          if (
            updateSelectedDocument !== undefined &&
            !!updateSelectedDocument
          ) {
            let selectedDocument: Document | null =
              digitizableDocuments.filter(
                (value: Document) =>
                  value.id === digitizationParameters?.mySelectedDocument?.id
              )?.[0] ?? null;

            if (selectedDocument !== null) {
              let paramsToUpdate: Partial<DigitizationParameter> = {};

              paramsToUpdate.mySelectedDocument = selectedDocument;
              paramsToUpdate.selectedPaperSize =
                digitizationParameters.paperSizes.find(
                  (p) => p.value === selectedDocument?.pageType
                );
              paramsToUpdate.documentName = selectedDocument.name;
              paramsToUpdate.selectedDocumentType = selectedDocument.type;

              this.componentStore.updateDigitizationParameters(
                paramsToUpdate as any
              );
            }
          }
        }),
        switchMap(([digitizableDocuments]) =>
          combineLatest([
            of(digitizableDocuments),
            this.getVoidedDocuments(this.order.id)
          ])
        ),
        tap(([digitizableDocuments, voidedDocuments]) => {
          this.generateDocumentThumbnails([...this.setDocumentStatus(digitizableDocuments), ...voidedDocuments])
        }),
        take(1)
      )
      .subscribe();
  }

  async handleDownloadByDocumentId({
    docId,
    docName,
  }: {
    docId: number;
    docName: string;
  }) {
    await firstValueFrom(this.documentService
      .getDocument(
        `${environment.apiUri}/v1/documents/${docId}/download`,
        "application/pdf",
        docName
      ));
  }

  async handleDeleteByDocumentId(docId: number) {
    this.spinner.show();

    await firstValueFrom(this.documentService.deleteDocument(docId));

    this.componentStore.removeDocumentFromDigitizationParameters(docId);
    this.componentStore.removeThumbnail(docId);
    this.getAllDigitizableDocuments();

    this.spinner.hide();
  }

  getVoidedDocuments(orderId: number): Observable<Document[]> {
    return this.documentService.getVoidListByOrderId(orderId).pipe(
      map((voidedDocuments: Document[]) =>
        voidedDocuments.map((document) => ({ ...document, isVoid: true }))
      ),
      tap((voidedDocuments: Document[]) => {
        this.componentStore.addVoidedDocuments(voidedDocuments);
      }),
      untilDestroyed(this)
    );
  }

  getFinalEndorsements(imageData: ImageData): NewEndorsement[] {
    const endorsements = new Array<NewEndorsement>();

    this.finalEndorsementLocationsOutputPng.forEach((endorsement) => {
      const newEnd = new NewEndorsement();
      newEnd.height = endorsement.height;
      newEnd.isProviderCalculated = endorsement.isProviderCalculated;
      newEnd.isRequired = endorsement.isRequired;
      newEnd.pageNumber = this.getPageNumber(imageData, endorsement.yPosition);
      newEnd.participantId = +endorsement.participantId;
      newEnd.signatureReference = endorsement.signatureReference;
      newEnd.type = endorsement.endorsementType;
      newEnd.participantRole = endorsement.participantRole;
      newEnd.width = endorsement.width;
      newEnd.xPosition = endorsement.xPosition;
      newEnd.yPosition = endorsement.yPosition;
      newEnd.documentEndorsementLocationId =
        +endorsement.documentEndorsementLocationId;
      newEnd.format = endorsement.format;
      endorsements.push(newEnd);
    });

    return endorsements;
  }

  getPageNumber(imageData: ImageData, yPosition: number): number {
    return Math.ceil(yPosition / imageData.pagePixelHeightActual);
  }

  exitDocumentEditor(): void {
    this.orderId$
      .pipe(
        tap((orderId) => this.router.navigate(["/order-detail/" + orderId])),
        take(1)
      )
      .subscribe();
  }

  resetDigiDocs(): void {
    this.endorsementDraggableComponentsRef = [];
    this.finalEndorsementLocationsOutputPdf = [];
    this.finalEndorsementLocationsOutputPng = [];
    this.componentStore.resetStore();
  }

  openUploadDocumentModel(): void {
    const modal = this.modalService.open(DocumentUploadModalComponent, {
      windowClass: "document-upload-modal",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.orderId = this.order.id;
    modal.componentInstance.closingType = this.order.productType;

    modal.result.then((result) => {
      if (result !== "cancel") {
        this.getAllDigitizableDocuments();
      }
    });
  }

  // Templates
  getAvailableTemplates(): void {
    this.documentTemplateService
      .getTemplates()
      .pipe(
        catchError((err) => {
          if (err.code === 403) {
            return NEVER;
          }
          throw err;
        }),
        map((result: DocumentTemplate[]) => {
          const templates = new Array<KeyValuePair>();
          result.forEach((template) => {
            templates.push({ key: template.id, value: template.name });
          });
          this.componentStore.setTemplateOptions(templates);
          return templates;
        }),
        take(1)
      )
      .subscribe();
    this.templateOptions$ = this.componentStore.templateOptions$;
  }

  handleGenerateEndorsementUsingTemplate({
    imageData,
    templateId,
  }: EndorsementUsingTemplateOutput) {
    this.generateEndorsementsUsingTemplate(
      templateId,
      imageData,
    );
  }

  generateEndorsementsUsingTemplate(
    templateId: number | null,
    imageData: ImageData | null
  ): void {
    if (imageData === null || imageData.src === "") {
      this.toastr.warning("Please upload a document to apply the template.");
      this.componentStore.updateDigitizationParameters({
        selectedTemplate: null,
      } as any);
    } else {
      this.resetEndorsementComponents();
      this.spinner.show(); 
      if (templateId) {
        this.templateID = templateId;
        this.templateEndorsementsGenerator$ = combineLatest([
          this.documentTemplateService.getTemplateEndorsements(templateId),
          this.participants$,
        ]).pipe(
          tap(([endorsements, participants]) => {
            forEach(endorsements, (endorsementInformation) => {
              const participant = participants.find(
                (p) =>
                  p.role === endorsementInformation.participantRole &&
                  p.sequenceNumber === endorsementInformation.roleSequence
              );
              if (participant !== undefined) {
                this.generateEndorsementComponent(
                  participant,
                  imageData,
                  false,
                  endorsementInformation.endorsementType,
                  endorsementInformation.format,
                  endorsementInformation.xPosition,
                  endorsementInformation.yPosition,
                  endorsementInformation.width,
                  endorsementInformation.height,
                  endorsementInformation.isRequired
                );
              } else if (endorsementInformation.participantRole === "SYSTEM") {
                this.generateEndorsementComponent(
                  new Participant(),
                  imageData,
                  true,
                  endorsementInformation.endorsementType,
                  endorsementInformation.format,
                  endorsementInformation.xPosition,
                  endorsementInformation.yPosition,
                  endorsementInformation.width,
                  endorsementInformation.height,
                  endorsementInformation.isRequired
                );
              }
            });
            this.toastr.success("Template successfully applied.");
          }),
          catchError((err) => {
            this.toastr.error("Failed to generate template.");
            console.log("generateEndorsementsUsingTemplate:", err);
            throw err;
          }),
          untilDestroyed(this)
        );
        this.templateEndorsementsGenerator$.subscribe();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.success("Template successfully removed.");
      }
    }
  }

  handleOpenSaveTemplateModalForTemplateName({
    imageData,
    digitizationParameters,
    participants
  }: OpenSaveTemplateModalForTemplateNameOutput) {
    this.openSaveTemplateModalForTemplateName(
      imageData,
      digitizationParameters,
      participants
    );
  }

  openSaveTemplateModalForTemplateName(
    imageData: ImageData | null,
    digitizationParameters: DigitizationParameter,
    participants: Participant[]
  ) {
    if (imageData === null || imageData.src === "") {
      this.toastr.warning(
        "Please upload a document and add endorsements to save a template."
      );
      this.componentStore.updateDigitizationParameters({
        selectedTemplate: null,
      } as any);
    } else {
      this.finalTemplateEndorsementLocationsOutputPng =
        this.generateDocEndorsementLocationService.generateLocationsForPng(
          this.imageDivOverlay,
          imageData,
          digitizationParameters
        );

      if (this.finalTemplateEndorsementLocationsOutputPng.length === 0) {
        this.toastr.warning("Please add endorsements to save the template.");
      } else {
        this.openSaveTemplateModal(
          imageData,
          digitizationParameters,
          participants
        );
      }
    }
  }

  openSaveTemplateModal(
    imageData: ImageData,
    digitizationParameters: DigitizationParameter,
    participants: Participant[]
  ): void {
    const modalRef = this.modalService.open(SaveTemplateModalComponent);
    modalRef.result.then(
      () => {},
      (reason) => {
        if (reason === "save") {
          this.submitDocumentTemplateAndEndorsements(
            imageData,
            digitizationParameters,
            participants
          );
        }
      }
    );
  }

  submitDocumentTemplateAndEndorsements(
    imageData: ImageData | null,
    digitizationParameters: DigitizationParameter,
    participants: Participant[]
  ): void {
    if (imageData === null || imageData.src === "") {
      this.toastr.warning("Please upload a document to save the template.");
      this.componentStore.updateDigitizationParameters({
        selectedTemplate: null,
      } as any);
    } else {
      this.spinner.show();
      this.documentTemplateEndorsements =
        this.generateFinalTemplateEndorsementsLocationsForPng(
          imageData,
          digitizationParameters,
          participants
        );

      if (this.documentTemplateEndorsements.length !== 0) {
        this.saveDocumentTemplate();
        this.saveDocumentTemplateEndorsments();
      }
    }
  }

  saveDocumentTemplate(): void {
    this.componentStore.templateNameToSave$
      .pipe(
        map((newTemplateName) => {
          this.newTemplateName = newTemplateName;
        })
      )
      .subscribe();

    const documentTemplate = new NewDocumentTemplate();
    documentTemplate.name = this.newTemplateName;
    this.savedDocumentTemplate$ =
      this.documentTemplateService.saveDocumentTemplate(documentTemplate);
  }

  saveDocumentTemplateEndorsments(): void {
    this.savedDocumentTemplate$
      .pipe(
        map((result) => {
          const templateOption: KeyValuePair = {
            key: result.id,
            value: result.name,
          };
          this.componentStore.addTemplateOption(templateOption);
          const documentTemplateId = result?.id ?? 0;
          this.templateID = documentTemplateId;
          this.componentStore.updateDigitizationParameters({
            selectedTemplate: documentTemplateId,
          } as any);

          this.documentTemplateEndorsements.forEach((templateEndorsement) => {
            templateEndorsement.templateId = documentTemplateId;
          });
          return documentTemplateId;
        }),
        switchMap((documentTemplateId) =>
          this.documentTemplateService.saveDocumentTemplateEndorsements(
            documentTemplateId,
            this.documentTemplateEndorsements
          )
        ),
        distinctUntilChanged(),
        catchError((err) => {
          if (err?.error?.status === 409) {
            this.spinner.hide();
            this.toastr.warning(
              "Template already exists. Please choose a different name or select the exisiting template and make the changes."
            );
            throw err;
          } else {
            this.spinner.hide();
            this.toastr.error("Template Save Failed.");
            console.log("submitDocumentTemplateAndEndorsements:", err);
            this.modalService.dismissAll();
            throw err;
          }
        }),
        tap(() => {
          this.spinner.hide();
          this.modalService.dismissAll();
          this.toastr.success("Template has been saved.");
          this.getAvailableTemplates();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  handleUpdateTemplateEndorsements({
    imageData,
    digitizationParameters,
    templateId,
    participants
  }: UpdateTemplateEndorsementsOutput) {
    this.updateTemplateEndorsements(
      imageData,
      digitizationParameters,
      templateId,
      participants
    );
  }

  updateTemplateEndorsements(
    imageData: ImageData,
    digitizationParameters: DigitizationParameter,
    template: number,
    participants: Participant[]
  ): void {
    if (template != null &&  template !== 0) {
      this.spinner.show();
      const documentTemplateId = template;
      this.documentTemplateEndorsements =
        this.generateFinalTemplateEndorsementsLocationsForPng(
          imageData,
          digitizationParameters,
          participants
        );
      if (this.documentTemplateEndorsements.length !== 0) {
        this.documentTemplateService
          .saveDocumentTemplateEndorsements(
            documentTemplateId,
            this.documentTemplateEndorsements
          )
          .pipe(
            catchError((err) => {
              this.spinner.hide();
              this.modalService.dismissAll();
              this.toastr.error("Template Update Failed.");
              console.log("submitDocumentTemplateAndEndorsements:", err);
              throw err;
            }),
            tap(() => {
              this.spinner.hide();
              this.toastr.success("Template has been updated.");
            }),
            untilDestroyed(this)
          )
          .subscribe();
      }
    } else {
      this.toastr.warning("Please select a template to make changes.");
      this.spinner.hide();
    }
  }

  generateFinalTemplateEndorsementsLocationsForPng(
    imageData: ImageData,
    digitizationParameters: DigitizationParameter,
    participants: Participant[]
  ): NewDocumentTemplateEndorsement[] {
    imageData = this.regenerateImageDataHeightWidth(
      imageData,
      digitizationParameters
    );
    this.finalTemplateEndorsementLocationsOutputPng =
      this.generateDocEndorsementLocationService.generateLocationsForPng(
        this.imageDivOverlay,
        imageData,
        digitizationParameters
      );

    if (this.finalTemplateEndorsementLocationsOutputPng.length === 0) {
      this.modalService.dismissAll();
      this.toastr.warning("Please add endorsements to save the template.");
      this.spinner.hide();
      return new Array<NewDocumentTemplateEndorsement>();
    } else {
      const documentTemplateEndorsements =
        new Array<NewDocumentTemplateEndorsement>();
      this.finalTemplateEndorsementLocationsOutputPng.forEach(
        (templateEndorsementLocation) => {
          const newTemplateEndorsement = new NewDocumentTemplateEndorsement();
          let roleSequence = 0;
          if (
            participants !== undefined &&
            templateEndorsementLocation.participantRole !== "SYSTEM"
          ) {
            const participant = participants.find(
              (p) => p.id === +templateEndorsementLocation.participantId
            );
            roleSequence = participant?.sequenceNumber ?? 0;
          }

          newTemplateEndorsement.height = templateEndorsementLocation.height;
          newTemplateEndorsement.pageNumber =
            templateEndorsementLocation.pageNumber;
          newTemplateEndorsement.signatureReference =
            templateEndorsementLocation.signatureReference;
          newTemplateEndorsement.endorsementType =
            templateEndorsementLocation.endorsementType;
          newTemplateEndorsement.participantRole =
            templateEndorsementLocation.participantRole;
          newTemplateEndorsement.width = templateEndorsementLocation.width;
          newTemplateEndorsement.xPosition =
            templateEndorsementLocation.xPosition;
          newTemplateEndorsement.yPosition =
            templateEndorsementLocation.yPosition;
          newTemplateEndorsement.roleSequence = roleSequence;
          newTemplateEndorsement.status = "SIGNABLE";
          newTemplateEndorsement.isRequired =
            templateEndorsementLocation.isRequired;
          newTemplateEndorsement.format = templateEndorsementLocation.format;

          documentTemplateEndorsements.push(newTemplateEndorsement);
        }
      );

      return documentTemplateEndorsements;
    }
  }

  regenerateImageDataHeightWidth(
    imageData: ImageData,
    digitizationParameters: DigitizationParameter
  ) {
    if (imageData?.height <= 0 || imageData?.width <= 0) {
      const imageOverlayDivDimensions = this.updateImageOverlayDivDimensions();
      const dpiActual = Math.round(
        imageOverlayDivDimensions.widthRaw /
          (digitizationParameters.selectedPaperSize?.key?.width ??
            PAGE_DIMENSIONS.LETTER_WIDTH)
      );
      const pagePixelHeightActual =
        (digitizationParameters.selectedPaperSize?.key?.height ??
          PAGE_DIMENSIONS.LETTER_HEIGHT) * dpiActual;
      const dpiConversionFactor = digitizationParameters.lenderDpi / dpiActual;
      const documentName = digitizationParameters.documentName;
      imageData = {
        ...imageData,
        documentName,
        height: imageOverlayDivDimensions.heightRaw,
        width: imageOverlayDivDimensions.widthRaw,
        pagePixelHeightActual,
        dpiActual,
        dpiConversionFactor,
      };
    }
    this.componentStore.updateImageData(imageData);
    return imageData;
  }

  resetTemplates(): void {
    this.componentStore.updateDigitizationParameters({
      selectedTemplate: null,
    });
    this.resetEndorsementComponents();
    this.endorsementDraggableComponentsRef = [];
  }

  resetEndorsementComponents(): void {
    this.endorsementContainer?.clear();
    this.templateID = null;
  }

  refreshWindow(): void {
    location.reload();
  }

  participantCanParticipate(
    participant: Participant
  ) {
    // Signing agent actions and system fields are not valid on hybrid orders
    if (this.order?.productType === "Hybrid") {
      if (participant.role === "SIGNINGAGENT") {
        return false;
      }
    } 

    return true;
  }

  signingAgent(
    participant: Participant
  ) {
    // Signing agent printed name are not valid, use Signing Agent Name system endorsement
    if (participant.role === "SIGNINGAGENT") {
        return false;
      }

    return true;
  }

  getDisplayName(participantName: string, participantSequenceNumber: number) {
    if(!participantName) {
      participantName = `Witness ${participantSequenceNumber}`;
    }

    return participantName;
  }

  getParticipants() {
    return this.participants$.pipe(
      tap((participants: Participant[]) =>
        participants.map((participant) => {
          if (participant.role === "WITNESS" && !participant.firstName && !participant.lastName) {
            participant.firstName = "Witness";
            participant.lastName = participant.sequenceNumber.toString();
          }
          return participant;
        })
      )
    );
  }

  endorsmentDisabledForOrder() {
    // Signing agent actions and system fields are not valid on hybrid orders
    if (this.order?.productType === "Hybrid") {
      return true;
    }

    return false;
  }

  updateReviewedByAttribute(document: Document) {
    this.documentService.patchDocument(document.id, document).pipe(
      tap(() => {
        this.getRuleAudit();
        this.getAllDigitizableDocuments();
      }),
    )
    .subscribe();
  }

  ngOnDestroy(): void {
    this.resetTemplates();
    this.componentStore.resetStore();
    this.modalService.dismissAll();
  }
}
