import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { Assignee, AssigneeResponseModel } from "../models/assignee.model";

import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class AssigneeService extends BaseService {
  className = "AssigneeService";

  constructor(injector: Injector) {
    super(injector);
  }

  getAssignees(): Observable<AssigneeResponseModel>
  {
    return this.get<AssigneeResponseModel>(`/v1/assignees`);
  }

  getEligibleAssignees(orderId: number): Observable<AssigneeResponseModel>
  {
    return this.get<AssigneeResponseModel>(`/v1/orders/${orderId}/eligibleAssignees`);
  }

  getOrderAssigneesByOrderId(orderId: number): Observable<Assignee>
  {
    return this.get<Assignee>(`/v1/orders/${orderId}/assignees`);
  }

  updateAssignees(orderId: number, assignee: Assignee) : Observable<Assignee> {
    return this.put<Assignee>(`/v1/orders/${orderId}/assignees`, assignee);
  }

  deleteAssignees(orderId: number) : Observable<object> {
    return this.delete(`/v1/orders/${orderId}/assignees`);
  }
}
