import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { tap } from "rxjs/operators";

import { CommissionInfo } from "src/app/shared/models/commission-info.model";
import { FeaturesService } from "src/app/shared/services/features.service";
import { OrderDetailStore } from "src/app/order-detail/+state/order-detail.store";

import { AppSpinnerModule } from "../../shared/modules/app-spinner.module";
import { Commission } from "../../shared/models/commission.model";
import { CommissionService } from "../../shared/services/commission.service";
import { StatesService } from "../../shared/services/states.service";
import { State } from "../../shared/services/state";

@UntilDestroy()
@Component({
  selector: "app-manage-commissions",
  templateUrl: "./manage-commissions.component.html",
  styleUrls: ["./manage-commissions.component.scss"],
})
export class ManageCommissionsComponent implements OnInit {
  activeCommissions: Commission[];
  addingNewState: boolean;
  availableCommissionStates: State[];
  currentCommissionStates: string[] = [];
  draftCommissions: CommissionInfo[];
  states: State[];

  constructor(
    public readonly commissionService: CommissionService,
    private readonly featuresService: FeaturesService,
    private readonly orderDetailStore: OrderDetailStore,
    private readonly router: Router,
    public readonly spinner: AppSpinnerModule,
    public readonly statesService: StatesService
  ) { }

  ngOnInit() {
    this.setFeatureFlags();
    this.states = this.statesService.states;
    this.getCommissions();
  }

  getCommissions(): void {
    this.spinner.show();
    this.commissionService
      .getCommissions()
      .pipe(
        tap((result) => {
          this.activeCommissions = result;
          this.activeCommissions.sort((a,b) => a.stateCode.localeCompare(b.stateCode));
          result.forEach((commission) => { 
            this.currentCommissionStates.push(commission.stateCode);
          });
          this.getDraftCommissions();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getDraftCommissions(): void {
    this.spinner.show();
    this.commissionService
      .getDraftCommissions()
      .pipe(
        tap((result) => {
          this.draftCommissions = result.commissions;
          this.draftCommissions.sort((a,b) => a.stateCode.localeCompare(b.stateCode));
          result.commissions.forEach((commission) => { 
            this.currentCommissionStates.push(commission.stateCode);
          });
          this.spinner.hide();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getStateImage(stateCode: string): string {
    return `assets/images/states/${stateCode.toUpperCase()}.png`;
  }

  getStateByStateCode(stateCode: string | null | undefined): string {
    if (stateCode) {
      const commissionState = this.states.find((state) => {
        return state.code === stateCode;
      });

      return commissionState?.name ?? "";
    }

    return "";
  }

  editCommission(stateCode: string): void {
    this.router.navigate([`/myaccount/state-commission-page/edit/${stateCode}`]);
  }

  addNewState(): void {
    this.availableCommissionStates = this.states
      .filter((state) => !this.currentCommissionStates.includes(state.code));
    this.addingNewState = true;
  }

  isCommissionExpired(commission: Commission): boolean {
    return new Date(commission.expiresOn).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  }

  setFeatureFlags(): void {
    this.featuresService
      .getFeatures()
      .pipe(
        tap(async (result) => {
          this.orderDetailStore.setFeatures(result);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
