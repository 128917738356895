import { Component, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ListViewParticipant } from "src/app/shared/models/participant.model";

@Component({
  templateUrl: "./copy-closing-url-modal.component.html",
  styleUrls: ["./copy-closing-url-modal.component.scss"],
})
export class CopyClosingUrlModalComponent {
  @Input() participant: ListViewParticipant;

  isSigningSessionLinkCopied = false;
  isConsumerPortalLinkCopied = false;

  constructor(
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) {}

  copySigningSessionLink() {
    this.isSigningSessionLinkCopied = true;
    this.isConsumerPortalLinkCopied = false;
    this.toastr.success("The link has been copied!");
  }

  copyConsumerPortalLink() {
    this.isConsumerPortalLinkCopied = true;
    this.isSigningSessionLinkCopied = false;
    this.toastr.success("The link has been copied!");
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
