import { Injectable } from "@angular/core";
import { PageViewport, PDFDocumentProxy, PDFPageProxy, getDocument } from "pdfjs-dist";
import { Metadata } from "pdfjs-dist/types/src/display/metadata";

import {
  PAGE_RATIOS,
  PAGE_SIZES,
} from "src/app/digitization-tool/models/digitization-parameter.model";

import { DocumentCapabilityStrings } from "../../directives/capabilities/capabilities-params.model";

export interface PDFMetadata {
  info: Object;
  metadata: Metadata;
}

export interface PDFPage {
  width: number;
  height: number;
  ratio: number;
  size: PAGE_SIZES;
}

export interface PDFPageRange {
  start: number | null;
  max: number | null;
}

export interface PDFThumbnail {
  alt: string;
  docId: number;
  docStatus?: string;
  figcaption: string;
  image: PNG | MediaImage | null;
  isVoid: boolean;
  isLoading: boolean;
  capabilities: DocumentCapabilityStrings[];
  errorText?: string;
}

export interface PNG {
  height: number;
  width: number;
  dataUrl: string;
}

@Injectable({
  providedIn: "root",
})
export class PdfjsService {
  private pdfjs;

  constructor() {
    import("pdfjs-dist").then((pdfjs) => {
      this.pdfjs = pdfjs;
      this.pdfjs.GlobalWorkerOptions.workerSrc = "/assets/pdf.worker.mjs";
    });
  }

  private getMaxSize(images: HTMLImageElement[], dimension: string): number {
    return Math.max(...images.map((image: HTMLImageElement) => image[dimension]));
  }

  private getTotalSize(images: HTMLImageElement[], dimension: string): number {
    return images
      .map((image: HTMLImageElement) => image[dimension])
      .reduce((acc, cur) => acc + cur, 0);
  }

  public async pdfToPng(
    page: PDFPageProxy,
    desiredWidth: number = 0,
    scale: number = 1
  ): Promise<PNG> {
    const viewport: PageViewport = page?.getViewport({ scale });
    const width = desiredWidth === 0 ? viewport.width : desiredWidth;
    const scaledViewport = page?.getViewport({
      scale: (width / viewport.width) * scale,
    });

    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const canvasContext: CanvasRenderingContext2D | null = canvas.getContext("2d");

    canvas.height = scaledViewport?.height;
    canvas.width = scaledViewport?.width;

    const png: PNG = {
      height: 0,
      width: 0,
      dataUrl: "",
    };

    if (canvasContext) {
      return page
        ?.render({
          canvasContext,
          viewport: scaledViewport,
        })
        .promise.then(() => ({
          ...png,
          height: canvas.height,
          width: canvas.width,
          dataUrl: canvas.toDataURL(),
        }));
    }

    return new Promise(() => png);
  }

  public async stichPdfPagesToSinglePng(
    pages: PDFPageProxy[],
    scale = 1
  ): Promise<PNG> {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const canvasContext: CanvasRenderingContext2D | null = canvas.getContext("2d");

    const pngSources = await Promise.all(pages.map((page) => this.pdfToPng(page, 0, scale)));
    const images = await Promise.all(pngSources.map(
      (pngSource) =>
        new Promise((resolve) => {
          const img: HTMLImageElement = new Image();
          img.onload = () => resolve(img);
          img.src = (pngSource).dataUrl;
        })
    ));

    await Promise.all(images).then((images: HTMLImageElement[]) => {
      const maxWidth = this.getMaxSize(images, "width");
      const totalHeight = this.getTotalSize(images, "height");

      canvas.width = maxWidth;
      canvas.height = totalHeight;

      let y = 0;

      images.forEach((image: HTMLImageElement) => {
        if (canvasContext) {
          canvasContext.drawImage(image, 0, y);
          y = y + image.height;
        }
      });
    });

    return {
      height: canvas.height,
      width: canvas.height,
      dataUrl: canvas.toDataURL(),
    };
  }

  public async getPdfPages(
    src: string | Uint8Array | ArrayBuffer,
    pageRange: PDFPageRange = {
      start: 1,
      max: null,
    },
    thumbnailRange: PDFPageRange = {
      start: null,
      max: null,
    },
    thumbnailSize: number = 100
  ): Promise<[PDFPageProxy[], PDFMetadata, PNG[]] | null> {
    if (!src) {
      return null;
    }

    let pdfPages: PDFPageProxy[] = [];
    let pdfThumbnails: PNG[] = [];

    const pdfDocument: PDFDocumentProxy = await getDocument(src).promise;
    const pdfMetadata: PDFMetadata = await pdfDocument?.getMetadata();

    const lastPage = pageRange.max === null ? pdfDocument.numPages : pageRange.max;
    const lastThumbnail = thumbnailRange.max === null ? pdfDocument.numPages : thumbnailRange.max;

    for (let index = pageRange.start ?? 1; index < lastPage + 1; index++) {
      const pdfPage: PDFPageProxy = await pdfDocument?.getPage(index);

      if (
        thumbnailRange.start !== null &&
        index >= thumbnailRange.start &&
        index <= lastThumbnail
      ) {
        const thumbnail: PNG = await this.pdfToPng(pdfPage, thumbnailSize);
        pdfThumbnails.push(thumbnail);
      }

      pdfPages.push(pdfPage);
    }

    return [pdfPages, pdfMetadata, pdfThumbnails];
  }

  public async getPageSizes(
    readerResult: ArrayBuffer,
    pageRange: PDFPageRange = {
      start: 1,
      max: null,
    }
  ): Promise<[PDFMetadata | null, PDFPage[] | null]> {
    const pdfPagesResult = await this.getPdfPages(
      readerResult.slice(0), // Ensure the ArrayBuffer is not detached
      pageRange
    );

    let pdfPages: PDFPageProxy[] | null = null;
    let pdfMetadata: PDFMetadata | null = null;

    if (pdfPagesResult) {
      pdfPages = pdfPagesResult[0];
      pdfMetadata = pdfPagesResult[1];
    }

    const mappedPdfPages = pdfPages?.map((page: PDFPageProxy) => {
      const [, , width, height] = page.view;
      const ratio = width > height
        ? Number((height / width).toFixed(4))
        : Number((width / height).toFixed(4));

      let size = PAGE_SIZES.UNKNOWN;

      switch (ratio) {
        case PAGE_RATIOS.LETTER:
          size = PAGE_SIZES.LETTER;
          break;
        case PAGE_RATIOS.LEGAL:
          size = PAGE_SIZES.LEGAL;
          break;
        default:
          break;
      }

      return {
        pageNumber: page.pageNumber,
        width,
        height,
        ratio,
        size,
      };
    }) ?? null;

    return [
      pdfMetadata,
      mappedPdfPages
    ];
  }
}
