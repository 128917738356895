export class CommissionInfo {
  commissionInfoId: number;
  accountId: number;
  certificateFilePathId: number;
  stampImageFilePathId: number;
  stamp: string | null;
  stateCode: string;
  expiresOn: Date | string;
  number: string;
  countyFipsCode: string;
  county?: string;
  signatureFilePathId: number;
  signature: string | null;
  name: string;
  accountSignatureId: number;
  certificateMetaDataId: number;
}

export class CommissionInfoPatch {
  commissionInfoId: number;
  accountId?: number | null;
  stamp?: string | null;
  stateCode?: string | null;
  expiresOn?: Date | null;
  number?: string | null;
  countyFipsCode?: string | null;
  name?: string | null;
  accountSignatureId?: number | null;
  certificateMetaDataId?: number | null;
}

export class CommissionInfosModel {
  commissions: CommissionInfo[];
}
